import {
  CREATE_LAPORAN_AKHIR_FAILURE,
  CREATE_LAPORAN_AKHIR_LUARAN_AKTUAL_FAILURE,
  CREATE_LAPORAN_AKHIR_LUARAN_AKTUAL_REQUEST,
  CREATE_LAPORAN_AKHIR_LUARAN_AKTUAL_SUCCESS,
  CREATE_LAPORAN_AKHIR_REQUEST,
  CREATE_LAPORAN_AKHIR_SUCCESS,
  GET_DETAIL_LAPORAN_AKHIR_FAILURE,
  GET_DETAIL_LAPORAN_AKHIR_LUARAN_AKTUAL_FAILURE,
  GET_DETAIL_LAPORAN_AKHIR_LUARAN_AKTUAL_REQUEST,
  GET_DETAIL_LAPORAN_AKHIR_LUARAN_AKTUAL_SUCCESS,
  GET_DETAIL_LAPORAN_AKHIR_REQUEST,
  GET_DETAIL_LAPORAN_AKHIR_SUCCESS,
  GET_DETAIL_LAPORAN_AKHIR_V2_FAILURE,
  GET_DETAIL_LAPORAN_AKHIR_V2_REQUEST,
  GET_DETAIL_LAPORAN_AKHIR_V2_SUCCESS,
  GET_DOKUMEN_LUARAN_LAPORAN_AKHIR_FAILURE,
  GET_DOKUMEN_LUARAN_LAPORAN_AKHIR_REQUEST,
  GET_DOKUMEN_LUARAN_LAPORAN_AKHIR_SUCCESS,
  GET_FORM_MITRA_DETAIL_LAPORAN_AKHIR_PENGABDIAN_FAILURE,
  GET_FORM_MITRA_DETAIL_LAPORAN_AKHIR_PENGABDIAN_REQUEST,
  GET_FORM_MITRA_DETAIL_LAPORAN_AKHIR_PENGABDIAN_SUCCESS,
  GET_LIST_LAPORAN_AKHIR_FAILURE,
  GET_LIST_LAPORAN_AKHIR_REQUEST,
  GET_LIST_LAPORAN_AKHIR_SUCCESS,
  GET_LIST_LAPORAN_AKHIR_V2_FAILURE,
  GET_LIST_LAPORAN_AKHIR_V2_REQUEST,
  GET_LIST_LAPORAN_AKHIR_V2_SUCCESS,
  GET_SPTB_LAPORAN_AKHIR_FAILURE,
  GET_SPTB_LAPORAN_AKHIR_REQUEST,
  GET_SPTB_LAPORAN_AKHIR_SUCCESS,
  POST_FORM_MITRA_LAPORAN_AKHIR_PENGABDIAN_FAILURE,
  POST_FORM_MITRA_LAPORAN_AKHIR_PENGABDIAN_REQUEST,
  POST_FORM_MITRA_LAPORAN_AKHIR_PENGABDIAN_SUCCESS,
  PUT_FORM_MITRA_LAPORAN_AKHIR_PENGABDIAN_FAILURE,
  PUT_FORM_MITRA_LAPORAN_AKHIR_PENGABDIAN_REQUEST,
  PUT_FORM_MITRA_LAPORAN_AKHIR_PENGABDIAN_SUCCESS,
  UPDATE_LAPORAN_AKHIR_V2_FAILURE,
  UPDATE_LAPORAN_AKHIR_V2_REQUEST,
  UPDATE_LAPORAN_AKHIR_V2_SUCCESS,
  UPLOAD_DOKUMEN_LAPORAN_AKHIR_FAILURE,
  UPLOAD_DOKUMEN_LAPORAN_AKHIR_REQUEST,
  UPLOAD_DOKUMEN_LAPORAN_AKHIR_SUCCESS,
  UPLOAD_DOKUMEN_SPTB_LAPORAN_AKHIR_FAILURE,
  UPLOAD_DOKUMEN_SPTB_LAPORAN_AKHIR_REQUEST,
  UPLOAD_DOKUMEN_SPTB_LAPORAN_AKHIR_SUCCESS,
} from "./actionTypes";

const initialState = {
  isFetching: false,
  isFetchingList: false,
  errorMessage: "",
  errors: null,
  listLaporanAkhir: [],
  laporanAkhirDetail: [],
  uploaded_dokumen_laporan_akhir: {
    isFetching: false,
    item: {},
  },
  uploaded_dokumen_sptb_laporan_akhir: {
    isFetching: false,
    item: {},
  },
  createLaporanAkhir: {
    isFetching: false,
    item: [],
  },
  form_mitra_pengabdian: {
    isFetching: false,
    item: [],
  },
  create_form_mitra_pengabdian: {
    isFetching: false,
    item: [],
  },
  update_form_mitra_pengabdian: {
    isFetching: false,
    item: [],
  },
  dokumen_luaran_laporan_akhir: {
    isFetching: false,
    item: [],
  },
  sptb_laporan_akhir: {
    isFetching: false,
    item: [],
  },
  laporan_akhir_luaran_aktual: {
    isFetching: false,
    items: [],
  },
  save_laporan_akhir_luaran_aktual: {
    isFetching: false,
    item: {},
  },
  // Laporan Akhir v2
  list_laporan_akhir_v2: {
    items: [],
    isFetching: false,
    errorMessage: "",
    errors: null,
  },
  detail_laporan_akhir_v2: {
    item: {},
    isFetching: false,
    errorMessage: "",
    errors: null,
  },
  update_laporan_akhir_v2: {
    item: {},
    isFetching: false,
    errorMessage: "",
    errors: null,
  },
};

const LaporanAkhir = (state = initialState, action) => {
  switch (action.type) {
    // * Dokumen luaran
    case GET_DOKUMEN_LUARAN_LAPORAN_AKHIR_REQUEST:
      return (state = {
        ...state,
        dokumen_luaran_laporan_akhir: {
          ...state.dokumen_luaran_laporan_akhir,
          isFetching: true,
          item: [],
        },
      });
    case GET_DOKUMEN_LUARAN_LAPORAN_AKHIR_SUCCESS:
      return (state = {
        ...state,
        dokumen_luaran_laporan_akhir: {
          ...state.dokumen_luaran_laporan_akhir,
          isFetching: false,
          item: action.payload,
        },
      });
    case GET_DOKUMEN_LUARAN_LAPORAN_AKHIR_FAILURE:
      return (state = {
        ...state,
        dokumen_luaran_laporan_akhir: {
          ...state.dokumen_luaran_laporan_akhir,
          isFetching: false,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });
    // sptb laporan akhir
    case GET_SPTB_LAPORAN_AKHIR_REQUEST:
      return (state = {
        ...state,
        sptb_laporan_akhir: {
          ...state.sptb_laporan_akhir,
          isFetching: true,
        },
      });
    case GET_SPTB_LAPORAN_AKHIR_SUCCESS:
      return (state = {
        ...state,
        sptb_laporan_akhir: {
          ...state.sptb_laporan_akhir,
          isFetching: false,
          item: action.payload,
        },
      });
    case GET_SPTB_LAPORAN_AKHIR_FAILURE:
      return (state = {
        ...state,
        sptb_laporan_akhir: {
          ...state.sptb_laporan_akhir,
          isFetching: false,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });
    // luaran aktual
    case GET_DETAIL_LAPORAN_AKHIR_LUARAN_AKTUAL_REQUEST:
      return (state = {
        ...state,
        laporan_akhir_luaran_aktual: {
          ...state.laporan_akhir_luaran_aktual,
          isFetching: true,
        },
      });
    case GET_DETAIL_LAPORAN_AKHIR_LUARAN_AKTUAL_SUCCESS:
      return (state = {
        ...state,
        laporan_akhir_luaran_aktual: {
          ...state.laporan_akhir_luaran_aktual,
          isFetching: false,
          items: action.payload,
        },
      });
    case GET_DETAIL_LAPORAN_AKHIR_LUARAN_AKTUAL_FAILURE:
      return (state = {
        ...state,
        laporan_akhir_luaran_aktual: {
          ...state.laporan_akhir_luaran_aktual,
          isFetching: false,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });
    case CREATE_LAPORAN_AKHIR_LUARAN_AKTUAL_REQUEST:
      return (state = {
        ...state,
        save_laporan_akhir_luaran_aktual: {
          ...state.save_laporan_akhir_luaran_aktual,
          isFetching: true,
        },
      });
    case CREATE_LAPORAN_AKHIR_LUARAN_AKTUAL_SUCCESS:
      return (state = {
        ...state,
        save_laporan_akhir_luaran_aktual: {
          ...state.save_laporan_akhir_luaran_aktual,
          isFetching: false,
          item: action.payload,
        },
      });
    case CREATE_LAPORAN_AKHIR_LUARAN_AKTUAL_FAILURE:
      return (state = {
        ...state,
        save_laporan_akhir_luaran_aktual: {
          ...state.save_laporan_akhir_luaran_aktual,
          isFetching: false,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });
    // upload dokumen laporan akhir
    case UPLOAD_DOKUMEN_LAPORAN_AKHIR_REQUEST:
      return (state = {
        ...state,
        uploaded_dokumen_laporan_akhir: {
          ...state.uploaded_dokumen_laporan_akhir,
          isFetching: true,
        },
      });
    case UPLOAD_DOKUMEN_LAPORAN_AKHIR_SUCCESS:
      return (state = {
        ...state,
        uploaded_dokumen_laporan_akhir: {
          ...state.uploaded_dokumen_laporan_akhir,
          isFetching: false,
          item: action.payload,
        },
      });
    case UPLOAD_DOKUMEN_LAPORAN_AKHIR_FAILURE:
      return (state = {
        ...state,
        uploaded_dokumen_laporan_akhir: {
          ...state.uploaded_dokumen_laporan_akhir,
          isFetching: false,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });
    // upload dokumen sptb laporan akhir
    case UPLOAD_DOKUMEN_SPTB_LAPORAN_AKHIR_REQUEST:
      return (state = {
        ...state,
        uploaded_dokumen_sptb_laporan_akhir: {
          ...state.uploaded_dokumen_sptb_laporan_akhir,
          isFetching: true,
        },
      });
    case UPLOAD_DOKUMEN_SPTB_LAPORAN_AKHIR_SUCCESS:
      return (state = {
        ...state,
        uploaded_dokumen_sptb_laporan_akhir: {
          ...state.uploaded_dokumen_sptb_laporan_akhir,
          isFetching: false,
          item: action.payload,
        },
      });
    case UPLOAD_DOKUMEN_SPTB_LAPORAN_AKHIR_FAILURE:
      return (state = {
        ...state,
        uploaded_dokumen_sptb_laporan_akhir: {
          ...state.uploaded_dokumen_sptb_laporan_akhir,
          isFetching: false,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });
    // list laporan akhir
    case GET_LIST_LAPORAN_AKHIR_REQUEST:
      return {
        ...state,
        isFetchingList: true,
      };
    case GET_LIST_LAPORAN_AKHIR_SUCCESS:
      return {
        ...state,
        isFetchingList: false,
        listLaporanAkhir: action.payload,
      };
    case GET_LIST_LAPORAN_AKHIR_FAILURE:
      return {
        ...state,
        isFetchingList: false,
        errors: action.payload.errors,
        errorMessage: action.payload.message,
      };
    // detail laporan akhir
    case GET_DETAIL_LAPORAN_AKHIR_REQUEST:
      return {
        ...state,
        isFetching: true,
        laporanAkhirDetail: [],
      };
    case GET_DETAIL_LAPORAN_AKHIR_SUCCESS:
      return {
        ...state,
        isFetching: false,
        laporanAkhirDetail: action.payload,
      };
    case GET_DETAIL_LAPORAN_AKHIR_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: action.payload.errors,
        errorMessage: action.payload.message,
      };
    //create laporan akhir
    case CREATE_LAPORAN_AKHIR_REQUEST:
      return (state = {
        ...state,
        createLaporanAkhir: {
          ...state.createLaporanAkhir,
          isFetching: true,
        },
      });
    case CREATE_LAPORAN_AKHIR_SUCCESS:
      return (state = {
        ...state,
        createLaporanAkhir: {
          ...state.createLaporanAkhir,
          isFetching: false,
          item: action.payload,
        },
      });
    case CREATE_LAPORAN_AKHIR_FAILURE:
      return (state = {
        ...state,
        createLaporanAkhir: {
          ...state.createLaporanAkhir,
          isFetching: false,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });
    // form mitra detail laporan akhir pengabdian
    case GET_FORM_MITRA_DETAIL_LAPORAN_AKHIR_PENGABDIAN_REQUEST:
      return (state = {
        ...state,
        form_mitra_pengabdian: {
          ...state.form_mitra_pengabdian,
          isFetching: true,
        },
      });
    case GET_FORM_MITRA_DETAIL_LAPORAN_AKHIR_PENGABDIAN_SUCCESS:
      return (state = {
        ...state,
        form_mitra_pengabdian: {
          ...state.form_mitra_pengabdian,
          isFetching: false,
          item: action.payload,
        },
      });
    case GET_FORM_MITRA_DETAIL_LAPORAN_AKHIR_PENGABDIAN_FAILURE:
      return (state = {
        ...state,
        form_mitra_pengabdian: {
          ...state.form_mitra_pengabdian,
          isFetching: false,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });
    // create form mitra detail laporan akhir pengabdian
    case POST_FORM_MITRA_LAPORAN_AKHIR_PENGABDIAN_REQUEST:
      return (state = {
        ...state,
        create_form_mitra_pengabdian: {
          ...state.create_form_mitra_pengabdian,
          isFetching: true,
        },
      });
    case POST_FORM_MITRA_LAPORAN_AKHIR_PENGABDIAN_SUCCESS:
      return (state = {
        ...state,
        create_form_mitra_pengabdian: {
          ...state.create_form_mitra_pengabdian,
          isFetching: false,
          item: action.payload,
        },
      });
    case POST_FORM_MITRA_LAPORAN_AKHIR_PENGABDIAN_FAILURE:
      return (state = {
        ...state,
        create_form_mitra_pengabdian: {
          ...state.create_form_mitra_pengabdian,
          isFetching: false,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });
    // update form mitra detail laporan akhir pengabdian
    case PUT_FORM_MITRA_LAPORAN_AKHIR_PENGABDIAN_REQUEST:
      return (state = {
        ...state,
        update_form_mitra_pengabdian: {
          ...state.update_form_mitra_pengabdian,
          isFetching: true,
        },
      });
    case PUT_FORM_MITRA_LAPORAN_AKHIR_PENGABDIAN_SUCCESS:
      return (state = {
        ...state,
        update_form_mitra_pengabdian: {
          ...state.update_form_mitra_pengabdian,
          isFetching: false,
          item: action.payload,
        },
      });
    case PUT_FORM_MITRA_LAPORAN_AKHIR_PENGABDIAN_FAILURE:
      return (state = {
        ...state,
        update_form_mitra_pengabdian: {
          ...state.update_form_mitra_pengabdian,
          isFetching: false,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });
    // laporan akhir v2
    case GET_LIST_LAPORAN_AKHIR_V2_REQUEST:
      return (state = {
        ...state,
        list_laporan_akhir_v2: {
          ...state.list_laporan_akhir_v2,
          isFetching: true,
          items: [],
        },
      });
    case GET_LIST_LAPORAN_AKHIR_V2_SUCCESS:
      return (state = {
        ...state,
        list_laporan_akhir_v2: {
          ...state.list_laporan_akhir_v2,
          isFetching: false,
          items: action.payload.data || [],
        },
      });
    case GET_LIST_LAPORAN_AKHIR_V2_FAILURE:
      return (state = {
        ...state,
        list_laporan_akhir_v2: {
          ...state.list_laporan_akhir_v2,
          isFetching: false,
          errorMessage: action.payload.message,
          errors: action.payload.errors,
        },
      });
    case GET_DETAIL_LAPORAN_AKHIR_V2_REQUEST:
      return (state = {
        ...state,
        detail_laporan_akhir_v2: {
          ...state.detail_laporan_akhir_v2,
          isFetching: true,
          item: {},
        },
      });
    case GET_DETAIL_LAPORAN_AKHIR_V2_SUCCESS:
      return (state = {
        ...state,
        detail_laporan_akhir_v2: {
          ...state.detail_laporan_akhir_v2,
          isFetching: false,
          item: action.payload.data || {},
        },
      });
    case GET_DETAIL_LAPORAN_AKHIR_V2_FAILURE:
      return (state = {
        ...state,
        detail_laporan_akhir_v2: {
          ...state.detail_laporan_akhir_v2,
          isFetching: false,
          errorMessage: action.payload.message,
          errors: action.payload.errors,
        },
      });
    case UPDATE_LAPORAN_AKHIR_V2_REQUEST:
      return (state = {
        ...state,
        update_laporan_akhir_v2: {
          ...state.update_laporan_akhir_v2,
          isFetching: true,
          item: {},
        },
      });
    case UPDATE_LAPORAN_AKHIR_V2_SUCCESS:
      return (state = {
        ...state,
        update_laporan_akhir_v2: {
          ...state.update_laporan_akhir_v2,
          isFetching: false,
          item: action.payload.data || {},
        },
      });
    case UPDATE_LAPORAN_AKHIR_V2_FAILURE:
      return (state = {
        ...state,
        update_laporan_akhir_v2: {
          ...state.update_laporan_akhir_v2,
          isFetching: false,
          errorMessage: action.payload.message,
          errors: action.payload.errors,
        },
      });
    default:
      return state;
  }
};

export default LaporanAkhir;
