import stylePDF from "./styles";
import header from "./header";
import utilities from "helpers/utilities";

const buildTblLuaran = (luaran = [], kelompok_luaran = "") => {
  const layout = {
    style: ["center"],
    layout: {
      hLineWidth: (_i, _node) => 0.3,
      vLineWidth: _i => 0.3,
      padding: () => 1,
    },
    table: {
      widths: ["7%","22%", "30%", "21%", "20%"],
      body: [
        [
          {
            text: "Tahun Luaran",
            style: ["center"],
          },
          {
            text: "Kategori Luaran",
            style: ["center"],
          },
          {
            text: "Jenis Luaran",
            style: ["center"],
          },
          {
            text: "Status target capaian",
            style: ["center"],
          },
          {
            text: "Keterangan",
          },
        ],
      ],
    },
  };
  luaran
    .sort((a, b) => a.urutan_thn_usulan_kegiatan - b.urutan_thn_usulan_kegiatan)
    .filter(item => item.kelompok_luaran === kelompok_luaran && item.id_jenis_luaran)
    .forEach(item => {
      layout.table.body.push([
        {
          text: item.urutan_thn_usulan_kegiatan,
        },
        {
          text: item.nama_kategori_jenis_luaran,
        },
        {
          text: item.nama_jenis_luaran,
        },
        {
          text: item.nama_target_capaian_luaran,
        },
        {
          text: item.keterangan || "-",
        },
      ]);
    });
  return layout;
};

const buildTblIdentitas1 = (data = {}) => {
  const layout = {
    style: ["center", "mb-10"],
    layout: {
      hLineWidth: (_i, _node) => 0.5,
      vLineWidth: _i => 0.5,
      padding: () => 0,
    },
    table: {
      widths: ["25%", "30%", "25%", "20%"],
      body: [
        [
          {
            text: `Bidang Fokus RIRN / Bidang Unggulan Perguruan Tinggi`,
            style: ["center"],
          },
          {
            text: `Tema`,
            style: ["center"],
          },
          {
            text: `Topik (jika ada)`,
            style: ["center"],
          },
          {
            text: `Rumpun Bidang Ilmu`,
            style: ["center"],
          },
        ],
      ],
    },
  };
  const idsSkemaKerjasamaLn = [103, 142];
  const rows = [
    {
      text: data.nama_bidang_fokus || "-",
    },
    {
      text: data.nama_tema || "-",
    },
    {
      text: data.nama_topik || "-",
    },
    {
      text: `${data.nama_rumpun_ilmu_3}`,
    },
  ];
  if (idsSkemaKerjasamaLn.includes(parseInt(data.id_skema))) {
    layout.table.widths = ["20%", "25%", "20%", "20%", "15%"];
    layout.table.body[0].push({
      text: `Program Kerjasama Luar Negeri`,
      style: ["center"],
    });
    rows.push({
      text: `${data.program_kerjasama_ln || "-"}`,
    })
  }

  layout.table.body.push(rows);

  return layout;
};

const buildTblIdentitas2 = (data = {}) => {
  const layout = {
    style: ["center"],
    layout: {
      hLineWidth: (_i, _node) => 0.3,
      vLineWidth: _i => 0.3,
      padding: () => 0,
    },
    table: {
      widths: ["20%", "16%", "17%", "17%", "15%", "15%"],
      body: [
        [
          {
            text: `Kategori (Kompetitif Nasional/Desentralisasi/Penugasan)`,
            style: ["center"],
          },
          {
            text: `Skema Penelitian`,
            style: ["center"],
          },
          {
            text: `Strata (Dasar/Terapan/Pengembangan)`,
            style: ["center"],
          },
          {
            text: `SBK (Dasar, Terapan, Pengembangan)`,
            style: ["center"],
          },
          {
            text: `Target Akhir TKT`,
            style: ["center"],
          },
          {
            text: `Lama Penelitian (Tahun)`,
            style: ["center"],
          },
        ],
      ],
    },
  };

  layout.table.body.push([
    {
      text: data.nama_program_hibah || "-",
    },
    {
      text: data.nama_skema,
    },
    {
      text: data.nama_kategori_penelitian,
    },
    {
      text: data.nama_kategori_sbk,
    },
    {
      text: `${data.tkt_target}`,
    },
    {
      text: `${data.lama_kegiatan}`,
    },
  ]);
  return layout;
};

const buildTblPengusul = (personil = []) => {
  const layout = {
    layout: {
      hLineWidth: (_i, _node) => 0.3,
      vLineWidth: _i => 0.3,
      padding: () => 0,
    },
    table: {
      headerRows: 1,
      widths: ["22%", "20%", "20%", "22%", "16%"],
      body: [
        [
          {
            text: `Nama, Peran`,
            style: ["center"],
          },
          {
            text: `Perguruan Tinggi/ Institusi`,
            style: ["center"],
          },
          {
            text: `Program Studi/Bagian `,
            style: ["center"],
          },
          {
            text: `Bidang Tugas`,
            style: ["center"],
          },
          {
            text: `ID Sinta`,
            style: ["center"],
          },
        ],
      ],
    },
  };
  personil.personil_dosen
    .sort((a, b) => (a.urutan > b.urutan ? 1 : b.urutan > a.urutan ? -1 : 0))
    .forEach(item => {
      layout.table.body.push([
        {
          text: `${item.nama} \n\n ${item.nama_peran}`,
          style: ["center"],
        },
        {
          text: `${item?.nama_institusi || "-"}`,
          style: ["center"],
        },
        {
          text: `${item?.nama_program_studi || "-"}`,
          style: ["center"],
        },
        {
          text: `${item.uraian_tugas || "-"}`,
        },
        {
          text: `${item?.id_sinta || "-"}`,
          link: `https://sinta.kemdikbud.go.id/authors/profile/${item?.id_sinta}`,
          style: ["center", "link"],
        },
      ]);
    });
  personil.personil_non_dosen_mahasiswa.forEach(item => {
    layout.table.body.push([
      {
        text: `${item.nama} \n\n ${item.nama_peran}`,
        style: ["center"],
      },
      {
        text: `${item?.nama_instansi}`,
        style: ["center"],
      },
      {
        text: `${item?.nama_prodi || "-"}`,
        style: ["center"],
      },
      {
        text: `${item.uraian_tugas || "-"}`,
      },
      {
        text: `${"-"}`,
        style: ["center"],
      },
    ]);
  });
  personil.personil_non_dosen_mitra.forEach(item => {
    layout.table.body.push([
      {
        text: `${item.nama} \n\n ${item.nama_peran}`,
        style: ["center"],
      },
      {
        text: `${item?.instansi}`,
        style: ["center"],
      },
      {
        text: `-`,
        style: ["center"],
      },
      {
        text: `${item.uraian_tugas || "-"}`,
      },
      {
        text: `${"-"}`,
        style: ["center"],
      },
    ]);
  });
  personil.personil_non_dosen_umum.forEach(item => {
    layout.table.body.push([
      {
        text: `${item.nama} \n\n ${item.nama_peran}`,
        style: ["center"],
      },
      {
        text: `${item?.instansi}`,
        style: ["center"],
      },
      {
        text: "-",
        style: ["center"],
      },
      {
        text: `${item.uraian_tugas || "-"}`,
      },
      {
        text: `${"-"}`,
        style: ["center"],
      },
    ]);
  });

  return layout;
};

const buildTblAnggaran = (data = [], version = 1) => {
  const layout = {
    style: ["mb-10"],
    layout: {
      hLineWidth: (_i, _node) => 0.3,
      vLineWidth: _i => 0.3,
      padding: () => 0,
    },
    table: {
      headerRows: 1,
      widths: ["25%", "15%", "25%", "10%", "5%", "15%", "15%"],
      body: [
        [
          {
            text: `Jenis Pembelanjaan`,
            style: ["center", "bgColorHeaderTable"],
          },
          {
            text: `Komponen`,
            style: ["center", "bgColorHeaderTable"],
          },
          {
            text: `Item`,
            style: ["center", "bgColorHeaderTable"],
          },
          {
            text: `Satuan`,
            style: ["center", "bgColorHeaderTable"],
          },
          {
            text: `Vol.`,
            style: ["center", "bgColorHeaderTable"],
          },
          {
            text: `Biaya Satuan`,
            style: ["center", "bgColorHeaderTable"],
          },
          {
            text: `Total`,
            style: ["center", "bgColorHeaderTable"],
          },
        ],
      ],
    },
  };

  if (version === 2) {
    //if version 2 remove column item
    layout.table.widths = ["25%", "25%", "10%", "5%", "15%", "20%"];
    layout.table.body[0].splice(2, 1);
  }

  data.forEach(item => {
    const volume = parseInt(item.volume);
    const harga_satuan = utilities.unmaskMoney(item.harga_satuan);
    const total = volume * harga_satuan;

    const tblBodyItem = [
      {
        text: `${item.nama_rab_kelompok_biaya}`,
      },
      {
        text: `${item.nama_rab_komponen_belanja}`,
        style: ["center"],
      },
      {
        text: `${item?.nama_item}`,
        style: ["center"],
      },
      {
        text: `${item.satuan || "-"}`,
        style: ["center"],
      },
      {
        text: `${volume || "-"}`,
        style: ["center"],
      },
      {
        text: `${utilities.toCurrency(harga_satuan || 0, ".", 0)}`,
        style: ["right"],
      },
      {
        text: `${utilities.toCurrency(total || 0, ".", 0)}`,
        style: ["right"],
      },
    ];

    if (version === 2) {
      //if version 2 remove column item
      tblBodyItem.splice(2, 1);
    }

    layout.table.body.push(tblBodyItem);
  });

  return layout;
};

const getThnAkhirUsulan = (lamaKegiatan, thnPertamaUsulan) => {
  let thnAkhirUsulan = parseInt(thnPertamaUsulan);
  for (let tahun = 1; tahun < parseInt(lamaKegiatan); tahun++) {
    thnAkhirUsulan += tahun;
  }
  return thnAkhirUsulan;
};

const content = (data, _pageBreak = false) => {
  const { hr } = stylePDF;
  const {
    identitas: identitas_usulan,
    substansi: subtansi_usulan,
    rab,
    mitra,
  } = data;

  let anggaran = utilities.groupBy(rab, "urutan_thn_usulan");

  const totalAnggaran = rab.reduce(
    (a, { harga_satuan, volume }) =>
      a + utilities.unmaskMoney(harga_satuan) * parseInt(volume),
    0
  );
  const luaranTambahanExist =
    subtansi_usulan.luaran_dijanjikan.filter(
      item => item.kelompok_luaran === "Luaran Tambahan"
    ).length > 0;
  return [
    header(),
    hr(5, 0),
    {
      stack: [
        {
          text: "PROTEKSI ISI PROPOSAL",
          style: ["bold"],
        },
        {
          text: "Dilarang menyalin, menyimpan, memperbanyak sebagian atau seluruh isi proposal ini dalam bentuk apapun \n kecuali oleh pengusul dan pengelola administrasi pengabdian kepada masyarakat",
          style: ["fontSizeXs"],
        },
        {
          text: `PROPOSAL ${data?.jenis_kegiatan.toUpperCase()} ${identitas_usulan.thn_pertama_usulan ?? ""
            }`,
          color: "#FFFFFF",
          background: "#000",
          style: ["center", "bold"],
        },
        {
          text: `Rencana Pelaksanaan Penelitian: tahun ${identitas_usulan?.thn_pertama_usulan ?? ""
            } s.d. tahun ${getThnAkhirUsulan(
              identitas_usulan.lama_kegiatan,
              identitas_usulan.thn_pertama_usulan
            )}`,
          style: ["fontSizeXs"],
        },
      ],
      style: ["center", "mb-5"],
    },
    {
      stack: [
        {
          text: "1. JUDUL PENELITIAN",
          style: ["bold"],
        },
        {
          style: ["text", "mb-10"],
          layout: {
            hLineWidth: (_i, _node) => 0.3,
            vLineWidth: _i => 0.3,
            padding: () => 1,
          },
          table: {
            widths: ["*"],
            body: [
              [
                {
                  text: identitas_usulan.judul,
                },
              ],
            ],
          },
        },

        buildTblIdentitas1(identitas_usulan),
        buildTblIdentitas2(identitas_usulan),
      ],
      style: ["mb-10"],
    },
    {
      stack: [
        {
          text: "2. IDENTITAS PENGUSUL",
          style: ["bold"],
        },
        buildTblPengusul(identitas_usulan),
      ],
      style: ["mb-10"],
    },
    {
      stack: [
        {
          text: "3. MITRA KERJASAMA PENELITIAN (JIKA ADA)",
          style: ["bold"],
        },
        {
          text: "Pelaksanaan penelitian dapat melibatkan mitra kerjasama yaitu mitra kerjasama dalam melaksanakan penelitian, mitra sebagai calon pengguna hasil penelitian, atau mitra investor",
          style: ["justify"],
        },
        {
          style: ["text", "mb-10"],
          layout: {
            hLineWidth: (_i, _node) => 0.3,
            vLineWidth: _i => 0.3,
            padding: () => 0,
          },
          table: {
            widths: ["30%", "35%", "35%"],
            body: [
              [
                {
                  text: `Mitra`,
                  style: ["center"],
                },
                {
                  text: `Nama Mitra`,
                  style: ["center"],
                },

                {
                  text: `Dana`,
                  style: ["center"],
                },
              ],
              ...mitra.map(row => {
                let danas = ``;
                row.dana.forEach(dana => {
                  danas += `Tahun ${dana.urutan_tahun_usulan
                    }: ${utilities.toCurrency(dana.dana, ".", 2, "Rp. ")} \n`;
                });
                return [
                  {
                    text: `${row.nama_institusi_mitra}`,
                  },
                  {
                    text: `${row.nama_mitra}`,
                  },
                  {
                    text: danas,
                  },
                ];
              }),
            ],
          },
        },
      ],
      style: ["mb-10"],
    },
    {
      stack: [
        {
          text: "4. LUARAN DAN TARGET CAPAIAN",
          style: ["bold"],
        },
        {
          text: "Luaran Wajib",
          style: ["bold", "mt-10"],
        },
        buildTblLuaran(subtansi_usulan.luaran_dijanjikan, "Luaran Wajib"),
        luaranTambahanExist
          ? {
            text: "Luaran Tambahan",
            style: ["bold", "mt-10"],
          }
          : {},
        luaranTambahanExist
          ? buildTblLuaran(subtansi_usulan.luaran_dijanjikan, "Luaran Tambahan")
          : {},
      ],
      style: ["mb-10"],
    },
    {
      stack: [
        {
          text: "5. ANGGARAN",
          style: ["bold"],
        },
        {
          text: "Rencana Anggaran Biaya penelitian mengacu pada PMK dan buku Panduan Penelitian dan Pengabdian kepada Masyarakat yang berlaku.",
          style: ["justify", "mb-10"],
        },
        {
          text: `Total RAB ${Object.keys(anggaran)?.length || 0
            } Tahun ${utilities.toCurrency(totalAnggaran, ".", 2, "Rp. ")}`,
          style: ["bold", "mb-10"],
        },
      ],
    },
    {
      stack: [
        ...(Object.entries(anggaran) || []).map(([key, anggaranItem]) => {
          const totalAnggaranPertahun =
            anggaranItem.reduce(
              (a, b) =>
                parseInt(a) +
                utilities.unmaskMoney(b.harga_satuan) * parseInt(b.volume),
              0
            ) || 0;
          return {
            style: ["mb-10"],
            stack: [
              {
                text: `Tahun ${key} Total ${utilities.toCurrency(
                  totalAnggaranPertahun,
                  ".",
                  2,
                  "Rp"
                )}`,
                style: ["bold"],
              },
              buildTblAnggaran(
                anggaranItem.filter(item => item.harga_satuan !== 0)
              ),
            ],
          };
        }),
      ],
    },
  ];
};

const contentPersetujuan = (data = {}) => {
  const layout = {
    style: ["mb-10"],
    layout: {
      hLineWidth: (_i, _node) => 0.3,
      vLineWidth: _i => 0.3,
      padding: () => 0,
    },
    table: {
      headerRows: 1,
      widths: ["*", "*", "*", "*", "*"],
      body: [
        [
          {
            text: `Tanggal Pengiriman`,
            style: ["center"],
          },
          {
            text: `Tanggal Persetujuan`,
            style: ["center"],
          },
          {
            text: `Nama Pimpinan Pemberi Persetujuan`,
            style: ["center"],
          },
          {
            text: `Sebutan Jabatan Unit`,
            style: ["center"],
          },
          {
            text: `Nama Unit Lembaga Pengusul`,
            style: ["center"],
          },
        ],
      ],
    },
  };
  layout.table.body.push([
    {
      text: `${data?.tanggal_pengiriman
        ? utilities.convertDate(data?.tanggal_pengiriman, "DD/MM/YYYY")
        : "-"
        }`,
      style: ["center"],
    },
    {
      text: `${data?.tgl_created
        ? utilities.convertDate(data?.tgl_created, "DD/MM/YYYY")
        : "-"
        }`,
      style: ["center"],
    },
    {
      text: `${data?.approval_by || "-"}`,
      style: ["center"],
    },
    {
      text: `${data?.approval_peran || "-"}`,
      style: ["center"],
    },
    {
      text: `${data?.lembaga?.nama_lembaga || "-"}`,
      style: ["center"],
    },
  ]);

  return [
    {
      stack: [
        {
          text: "PERSETUJUAN PENGUSUL",
          style: ["bold"],
        },
        {
          ...layout,
        },
      ],
    },
    {
      style: ["mt-10"],
      stack: [
        {
          text: `Komentar : ${data?.kd_sts_approvel?.toString() === "0"
            ? "Tidak Disetujui"
            : data?.kd_sts_approvel?.toString() === "1"
              ? "Disetujui"
              : "-"
            } `,
          style: ["bold", "mb-1"],
        },
        {
          style: ["text"],
          layout: {
            hLineWidth: (_i, _node) => (data?.komentar ? 0.3 : 0),
            vLineWidth: _i => (data?.komentar ? 0.3 : 0),
            padding: () => 1,
          },
          table: {
            widths: ["*"],
            body: [
              [
                {
                  text: data?.komentar,
                },
              ],
            ],
          },
        },
      ],
    },
  ];
};

export { contentPersetujuan, content, buildTblAnggaran };
