import Content from "./content";
import { contentSptbPengabdian } from "./content";
// import Header from "./headers";
import stylePDF from "./styles";

export default function PdfCreating(data) {
  const { pageMargins, pageSize, styles, defaultStyle } = stylePDF;
  return {
    // header: [...Header()],
    content: [...Content(data)],
    defaultStyle,
    pageMargins,
    pageSize,
    styles,
  };
}
export const pdfCreateSptbPengabdian = item => {
  const { pageSize, pageMargins, styles, defaultStyle } = stylePDF;
  return {
    content: [...contentSptbPengabdian(item)],
    defaultStyle,
    pageMargins,
    pageSize,
    styles,
  };
};
