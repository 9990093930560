import {
  BIDANG_FOKUS_FAILURE,
  BIDANG_FOKUS_REQUEST,
  BIDANG_FOKUS_SUCCESS,
  BIDANG_UNGGULAN_FAILURE,
  BIDANG_UNGGULAN_REQUEST,
  BIDANG_UNGGULAN_SUCCESS,
  CLEAN_MAHASISWA,
  CREATE_KETERLIBATAN_MAHASISWA_FAILURE,
  CREATE_KETERLIBATAN_MAHASISWA_REQUEST,
  CREATE_KETERLIBATAN_MAHASISWA_SUCCESS,
  DELETE_KETERLIBATAN_MAHASISWA_FAILURE,
  DELETE_KETERLIBATAN_MAHASISWA_REQUEST,
  DELETE_KETERLIBATAN_MAHASISWA_SUCCESS,
  DOSEN_FAILURE,
  DOSEN_REQUEST,
  DOSEN_SUCCESS,
  GET_AVAILABLE_SKEMA_FAILURE,
  GET_AVAILABLE_SKEMA_REQUEST,
  GET_AVAILABLE_SKEMA_SUCCESS,
  GET_ELIGIBILITY_FAILURE,
  GET_ELIGIBILITY_REQUEST,
  GET_ELIGIBILITY_SUCCESS,
  GET_JUMLAH_MAHASISWA_TERLIBAT_FAILURE,
  GET_JUMLAH_MAHASISWA_TERLIBAT_REQUEST,
  GET_JUMLAH_MAHASISWA_TERLIBAT_SUCCESS,
  GET_KATEGORI_SBK_FAILURE,
  GET_KATEGORI_SBK_REQUEST,
  GET_KATEGORI_SBK_SUCCES,
  GET_KETERLIBATAN_MAHASISWA_FAILURE,
  GET_KETERLIBATAN_MAHASISWA_REQUEST,
  GET_KETERLIBATAN_MAHASISWA_SUCCESS,
  GET_LIST_NEGARA_FAILURE,
  GET_LIST_NEGARA_REQUEST,
  GET_LIST_NEGARA_SUCCESS,
  GET_LIST_USULAN_PENELITIAN_FAILURE,
  GET_LIST_USULAN_PENELITIAN_REQUEST,
  GET_LIST_USULAN_PENELITIAN_SUCCESS,
  GET_MAHASISWA_FAILURE,
  GET_MAHASISWA_REQUEST,
  GET_MAHASISWA_SUCCESS,
  GET_PENELITIAN_FAILURE,
  GET_PENELITIAN_REQUEST,
  GET_PENELITIAN_SUCCESS,
  DEL_PENELITIAN_REQUEST,
  DEL_PENELITIAN_SUCCESS,
  DEL_PENELITIAN_FAILURE,
  GET_PRIORITAS_RISET_FAILURE,
  GET_PRIORITAS_RISET_REQUEST,
  GET_PRIORITAS_RISET_SUCCESS,
  GET_RULE_SKEMA_FAILURE,
  GET_RULE_SKEMA_REQUEST,
  GET_RULE_SKEMA_SUCCESS,
  GET_TARGET_CAPAIAN_LUARAN_FAILURE,
  GET_TARGET_CAPAIAN_LUARAN_REQUEST,
  GET_TARGET_CAPAIAN_LUARAN_SUCCESS,
  GET_USULAN_PENELITIAN_BYID_FAILURE,
  GET_USULAN_PENELITIAN_BYID_REQUEST,
  GET_USULAN_PENELITIAN_BYID_SUCCESS,
  INDIKATOR_TKT_BY_KATEGORI_FAILURE,
  INDIKATOR_TKT_BY_KATEGORI_REQUEST,
  INDIKATOR_TKT_BY_KATEGORI_SUCCESS,
  JENIS_LUARAN_FAILURE,
  JENIS_LUARAN_REQUEST,
  JENIS_LUARAN_SUCCESS,
  KATEGORI_INDIKATOR_TKT_FAILURE,
  KATEGORI_INDIKATOR_TKT_REQUEST,
  KATEGORI_INDIKATOR_TKT_SUCCESS,
  KATEGORI_LUARAN_FAILURE,
  KATEGORI_LUARAN_REQUEST,
  KATEGORI_LUARAN_SUCCESS,
  KATEGORI_PENELITIAN_FAILURE,
  KATEGORI_PENELITIAN_REQUEST,
  KATEGORI_PENELITIAN_SUCCESS,
  KELOMPOK_MAKRO_RISET_FAILURE,
  KELOMPOK_MAKRO_RISET_REQUEST,
  KELOMPOK_MAKRO_RISET_SUCCESS,
  KELOMPOK_RAB_BIAYA_FAILURE,
  KELOMPOK_RAB_BIAYA_REQUEST,
  KELOMPOK_RAB_BIAYA_SUCCESS,
  KELOMPOK_SKEMA_FAILURE,
  KELOMPOK_SKEMA_REQUEST,
  KELOMPOK_SKEMA_SUCCESS,
  PERAN_PERSONIL_FAILURE,
  PERAN_PERSONIL_REQUEST,
  PERAN_PERSONIL_SUCCESS,
  RAB_KOMPONEN_FAILURE,
  RAB_KOMPONEN_REQUEST,
  RAB_KOMPONEN_SUCCESS,
  RUMPUN_ILMU_FAILURE,
  RUMPUN_ILMU_REQUEST,
  RUMPUN_ILMU_SUCCESS,
  SAVE_IDENTITAS_USULAN_FAILURE,
  SAVE_IDENTITAS_USULAN_REQUEST,
  SAVE_IDENTITAS_USULAN_SUCCESS,
  SAVE_MITRA_USULAN_FAILURE,
  SAVE_MITRA_USULAN_REQUEST,
  SAVE_MITRA_USULAN_SUCCESS,
  SAVE_RAB_USULAN_FAILURE,
  SAVE_RAB_USULAN_REQUEST,
  SAVE_RAB_USULAN_SUCCESS,
  SAVE_SUBTANSI_USULAN_FAILURE,
  SAVE_SUBTANSI_USULAN_REQUEST,
  SAVE_SUBTANSI_USULAN_SUCCESS,
  SBK_FAILURE,
  SBK_REQUEST,
  SBK_SUCCESS,
  SKEMA_KEGIATAN_FAILURE,
  SKEMA_KEGIATAN_REQUEST,
  SKEMA_KEGIATAN_SUCCESS,
  TARGET_AKHIR_FAILURE,
  TARGET_AKHIR_REQUEST,
  TARGET_AKHIR_SUCCESS,
  TEMA_FAILURE,
  TEMA_REQUEST,
  TEMA_SUCCESS,
  TOPIK_FAILURE,
  TOPIK_REQUEST,
  TOPIK_SUCCESS,
  TOPIK_UNGGULAN_FAILURE,
  TOPIK_UNGGULAN_REQUEST,
  TOPIK_UNGGULAN_SUCCESS,
  UPDATE_IDENTITAS_USULAN_FAILURE,
  UPDATE_IDENTITAS_USULAN_REQUEST,
  UPDATE_IDENTITAS_USULAN_SUCCESS,
  UPDATE_KETERLIBATAN_MAHASISWA_FAILURE,
  UPDATE_KETERLIBATAN_MAHASISWA_REQUEST,
  UPDATE_KETERLIBATAN_MAHASISWA_SUCCESS,
  UPDATE_SUBTANSI_USULAN_FAILURE,
  UPDATE_SUBTANSI_USULAN_REQUEST,
  UPDATE_SUBTANSI_USULAN_SUCCESS,
  UPDATE_USULAN_PENELITIAN_FAILURE,
  UPDATE_USULAN_PENELITIAN_REQUEST,
  UPDATE_USULAN_PENELITIAN_SUCCESS,
  UPLOAD_DOKUMEN_FAILURE,
  UPLOAD_DOKUMEN_REQUEST,
  UPLOAD_DOKUMEN_SUCCESS,
  GET_TEMA_TEMATIK_REQUEST,
  GET_TEMA_TEMATIK_SUCCESS,
  GET_TEMA_TEMATIK_FAILURE,
  POST_STEP_1_IDENTITAS_PENGABDIAN_REQUEST,
  POST_STEP_1_IDENTITAS_PENGABDIAN_SUCCESS,
  POST_STEP_1_IDENTITAS_PENGABDIAN_FAILURE,
  PUT_ALL_STEP_USULAN_PENGABDIAN_REQUEST,
  PUT_ALL_STEP_USULAN_PENGABDIAN_SUCCESS,
  PUT_ALL_STEP_USULAN_PENGABDIAN_FAILURE,
  GET_LIST_RIRN_REQUEST,
  GET_LIST_RIRN_SUCCESS,
  GET_LIST_RIRN_FAILURE,
  VALIDASI_KEANGGOTAAN_DOSEN_REQUEST,
  VALIDASI_KEANGGOTAAN_DOSEN_SUCCESS,
  VALIDASI_KEANGGOTAAN_DOSEN_FAILURE,
  GET_LIST_PROVINSI_REQUEST,
  GET_LIST_PROVINSI_SUCCESS,
  GET_LIST_KOTA_FAILURE,
  GET_LIST_KOTA_REQUEST,
  GET_LIST_KOTA_SUCCESS,
  GET_LIST_PROVINSI_FAILURE,
  MHS_SET_ITEM,
  GET_OPTION_MITRA_KELOMPOK_REQUEST,
  GET_OPTION_MITRA_KELOMPOK_SUCCESS,
  GET_OPTION_MITRA_KELOMPOK_FAILURE,
  GET_OPTION_MITRA_JENIS_MITRA_REQUEST,
  GET_OPTION_MITRA_JENIS_MITRA_SUCCESS,
  GET_OPTION_MITRA_JENIS_MITRA_FAILURE,
  GET_WHITELIST_USULAN_REQUEST,
  GET_WHITELIST_USULAN_SUCCESS,
  GET_WHITELIST_USULAN_FAILURE,
  GET_LIST_KERJASAMA_LUAR_NEGERI_REQUEST,
  GET_LIST_KERJASAMA_LUAR_NEGERI_SUCCESS,
  GET_LIST_KERJASAMA_LUAR_NEGERI_FAILURE,
  SAVE_MINAT_KATALIS_REQUEST,
  SAVE_MINAT_KATALIS_SUCCESS,
  SAVE_MINAT_KATALIS_FAILURE,
  GET_MINAT_KATALIS_REQUEST,
  GET_MINAT_KATALIS_SUCCESS,
  GET_MINAT_KATALIS_FAILURE,
  GET_DETAILS_MINAT_KATALIS_REQUEST,
  GET_DETAILS_MINAT_KATALIS_SUCCESS,
  GET_DETAILS_MINAT_KATALIS_FAILURE,
  CLEAN_DETAILS_MINAT_KATALIS,
  DELETE_MINAT_KATALIS_SUCCESS,
  DELETE_MINAT_KATALIS_FAILURE,
  APPROVAL_MEMBER_MINAT_KATALIS_REQUEST,
  APPROVAL_MEMBER_MINAT_KATALIS_SUCCESS,
  APPROVAL_MEMBER_MINAT_KATALIS_FAILURE,
  APPROVAL_PUSAT_MINAT_KATALIS_FAILURE,
  APPROVAL_PUSAT_MINAT_KATALIS_REQUEST,
  APPROVAL_PUSAT_MINAT_KATALIS_SUCCESS
} from "./actionTypes";

const initialState = {
  tema: {
    items: [],
    isFetching: false,
  },
  validasi_keanggotaan_dosen: {
    isFetching: false,
    errorMessage: "",
    errors: null,
    result: null,
  },
  list_negara: {
    isFetching: false,
    errorMessage: "",
    errors: null,
    items: [],
  },
  usulan_penelitian_list: {
    isFetching: false,
    errorMessage: "",
    errors: null,
    items: [],
  },
  delete_usulan: {
    isFetching: false,
    errorMessage: "",
    errors: null,
    result: null,
  },
  usulan_penelitian: {
    isFetching: false,
    errorMessage: "",
    errors: null,
    item: {},
  },
  topik: {
    items: [],
    isFetching: false,
  },
  sbk: {
    items: [],
    isFetching: false,
  },
  bidang_fokus: {
    items: [],
    isFetching: false,
  },
  rumpun_ilmu1: {
    items: [],
    isFetching: false,
  },
  rumpun_ilmu2: {
    items: [],
    isFetching: false,
  },
  rumpun_ilmu3: {
    items: [],
    isFetching: false,
  },
  kategori_penelitian: {
    isFetching: false,
    errorMessage: "",
    errors: null,
    items: [],
  },
  target_tkt: {
    isFetching: false,
    items: [],
  },
  skema_kegiatan: {
    isFetching: false,
    items: [],
  },
  bidang_unggulan: {
    isFetching: false,
    items: [],
  },
  topik_unggulan: {
    isFetching: false,
    items: [],
  },
  dosen: {
    isFetching: false,
    items: [],
  },
  peran_personil: {
    isFetching: false,
    items: [],
  },
  kategori_sbk: {
    isFetching: false,
    items: [],
  },
  kategori_indikator_tkt: {
    isFetching: false,
    errorMessage: "",
    errors: null,
    items: [],
  },
  prioritas_riset: {
    isFetching: false,
    errorMessage: "",
    errors: null,
    items: [],
  },
  indikator_tkt: {
    isFetching: false,
    errorMessage: "",
    errors: null,
    level1: [],
    level2: [],
  },
  lama_kegiatan: [
    {
      id: "1",
      name: "1",
    },
    {
      id: "2",
      name: "2",
    },
    {
      id: "3",
      name: "3",
    },
  ],
  kelompok_makro: {
    isFetching: false,
    items: [],
  },
  uploaded_dokumen: {
    isFetching: false,
    item: {},
  },
  jenis_luaran: {
    isFetching: false,
    items: [],
  },
  kategori_luaran: {
    isFetching: false,
    items: [],
  },
  penelitian: {
    isFetching: false,
    items: [],
    item: {},
  },
  kelompok_rab: {
    isFetching: false,
    items: [],
    item: {},
  },
  rab_komponen: {
    isFetching: false,
    items: [],
    item: {},
  },
  keterlibatanMahasiswa: {
    items: [],
    isFetching: false,
    errors: "",
    errorMessage: "",
  },
  jumlahMahasiswaTerlibat: {
    total: 0,
    isFetching: false,
    errors: "",
    errorMessage: "",
  },
  mahasiswa: {
    items: [],
    item: {},
    isFetching: false,
    errors: "",
    errorMessage: "",
  },
  target_jenis_luaran: {
    items: [],
    isFetching: false,
    errors: "",
    errorMessage: "",
  },
  target_capaian_luaran: {
    // yg ini unutk get by kode kategori luaran
    items: [],
    isFetching: false,
    errors: "",
    errorMessage: "",
  },
  kelompok_skema: {
    items: [],
    isFetching: false,
    errors: "",
    errorMessage: "",
  },
  rule_skema: {
    items: [],
    isFetching: false,
    errors: "",
    errorMessage: "",
  },
  save_identitas_usulan: {
    item: {},
    isFetching: false,
    errors: "",
    errorMessage: "",
  },
  save_mitra_usulan: {
    item: {},
    isFetching: false,
    errors: "",
    errorMessage: "",
  },
  update_identitas_usulan: {
    item: {},
    isFetching: false,
    errors: "",
    errorMessage: "",
  },
  update_usulan: {
    item: {},
    isFetching: false,
    errors: "",
    errorMessage: "",
  },
  save_subtansi_usulan: {
    item: {},
    isFetching: false,
    errors: "",
    errorMessage: "",
  },
  update_subtansi_usulan: {
    item: {},
    isFetching: false,
    errors: "",
    errorMessage: "",
  },
  save_rab_usulan: {
    item: {},
    isFetching: false,
    errors: "",
    errorMessage: "",
  },
  eligibilitas_dosen: {
    item: {},
    isFetching: false,
    errors: "",
    errorMessage: "",
  },
  available_skema: {
    items: [],
    isFetching: false,
    errors: "",
    errorMessage: "",
  },
  pengabdian_list_tema_prioritas: {
    items: [],
    isFetching: false,
    errors: "",
    errorMessage: "",
  },
  pengabdian_list_rirn: {
    items: [],
    isFetching: false,
    errors: "",
    errorMessage: "",
  },
  pengabdian_step_1_result: {
    items: [],
    isFetching: false,
    errors: "",
    errorMessage: "",
  },
  pengabdian_all_step_result: {
    items: [],
    isFetching: false,
    errors: "",
    errorMessage: "",
  },
  pengabdian_list_option_mitra_kelompok: {
    isFetching: false,
    errorMessage: "",
    errors: null,
    items: [],
  },
  pengabdian_list_option_mitra_jenis_mitra: {
    isFetching: false,
    errorMessage: "",
    errors: null,
    items: [],
  },
  list_provinsi: {
    isFetching: false,
    errorMessage: "",
    errors: null,
    items: [],
  },
  list_kota: {
    isFetching: false,
    errorMessage: "",
    errors: null,
    items: [],
  },
  whitelist_usulan: {
    isFetching: false,
    hasFetching: false,
    errorMessage: "",
    errors: null,
    items: []
  },
  program_kerjasama_ln: {
    isFetching: false,
    errorMessage: "",
    errors: null,
    items: []
  },
  minat_katalis: {
    items: [],
    item: null,
    errors: null,
    errorMessage: "",
    isFetching: false,
    isProsesSubmit: false,
    isProsesApproval: false,
  }

};

const usulanPenelitian = (state = initialState, action) => {
  switch (action.type) {
    case DELETE_MINAT_KATALIS_SUCCESS: {
      const idx = state.minat_katalis.items.findIndex(row => row.id === action.id);
      let newItems = [...state.minat_katalis.items];
      if (idx > -1) {
        newItems = newItems.splice(idx, 0);
      }

      return (state = {
        ...state,
        minat_katalis: {
          ...state.minat_katalis,
          items: newItems
        },
      });
    }
    case CLEAN_DETAILS_MINAT_KATALIS:
      return (state = {
        ...state,
        minat_katalis: {
          ...state.minat_katalis,
          isFetching: false,
          item: null
        },
      });
    case GET_DETAILS_MINAT_KATALIS_REQUEST:
    case GET_MINAT_KATALIS_REQUEST:
      return (state = {
        ...state,
        minat_katalis: {
          ...state.minat_katalis,
          isFetching: true,
        },
      });
    case SAVE_MINAT_KATALIS_REQUEST:
      return (state = {
        ...state,
        minat_katalis: {
          ...state.minat_katalis,
          isProsesSubmit: true,
        },
      });
    case APPROVAL_MEMBER_MINAT_KATALIS_REQUEST:
    case APPROVAL_PUSAT_MINAT_KATALIS_REQUEST:
      return (state = {
        ...state,
        minat_katalis: {
          ...state.minat_katalis,
          isProsesApproval: true,
        },
      });
    case GET_DETAILS_MINAT_KATALIS_FAILURE:
    case APPROVAL_MEMBER_MINAT_KATALIS_FAILURE:
    case GET_MINAT_KATALIS_FAILURE:
    case SAVE_MINAT_KATALIS_FAILURE:
    case DELETE_MINAT_KATALIS_FAILURE:
    case APPROVAL_PUSAT_MINAT_KATALIS_FAILURE:
      return (state = {
        ...state,
        minat_katalis: {
          ...state.minat_katalis,
          isFetching: false,
          isProsesSubmit: false,
          isProsesApproval: false,
          errors: action.errors,
          errorMessage: action.message,
        },
      });
    case GET_DETAILS_MINAT_KATALIS_SUCCESS:
      return (state = {
        ...state,
        minat_katalis: {
          ...state.minat_katalis,
          item: action.payload.length > 0 ? action.payload[0] : null,
          isFetching: false,
        },
      });
    case GET_MINAT_KATALIS_SUCCESS:
      return (state = {
        ...state,
        minat_katalis: {
          ...state.minat_katalis,
          items: action.payload,
          isFetching: false,
        },
      });
    case SAVE_MINAT_KATALIS_SUCCESS:
      return (state = {
        ...state,
        minat_katalis: {
          ...state.minat_katalis,
          item: action.payload.data,
          isProsesSubmit: false,
        },
      });
    case APPROVAL_MEMBER_MINAT_KATALIS_SUCCESS:
    case APPROVAL_PUSAT_MINAT_KATALIS_SUCCESS:
      return (state = {
        ...state,
        minat_katalis: {
          ...state.minat_katalis,
          isProsesApproval: false,
        },
      });

    case GET_LIST_KERJASAMA_LUAR_NEGERI_REQUEST:
      return (state = {
        ...state,
        program_kerjasama_ln: {
          ...state.program_kerjasama_ln,
          isFetching: true,
        },
      });
    case GET_LIST_KERJASAMA_LUAR_NEGERI_SUCCESS:
      return (state = {
        ...state,
        program_kerjasama_ln: {
          ...state.program_kerjasama_ln,
          isFetching: false,
          items: action.payload.data,
        },
      });
    case GET_LIST_KERJASAMA_LUAR_NEGERI_FAILURE:
      return (state = {
        ...state,
        program_kerjasama_ln: {
          ...state.program_kerjasama_ln,
          isFetching: false,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });
    case GET_WHITELIST_USULAN_REQUEST:
      return (state = {
        ...state,
        whitelist_usulan: {
          ...state.whitelist_usulan,
          isFetching: true,
        },
      });
    case GET_WHITELIST_USULAN_SUCCESS:
      return (state = {
        ...state,
        whitelist_usulan: {
          ...state.whitelist_usulan,
          hasFetching: true,
          isFetching: false,
          items: action.payload.data,
        },
      });
    case GET_WHITELIST_USULAN_FAILURE:
      return (state = {
        ...state,
        whitelist_usulan: {
          ...state.whitelist_usulan,
          isFetching: false,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });
    case GET_LIST_NEGARA_REQUEST:
      return (state = {
        ...state,
        list_negara: {
          ...state.list_negara,
          isFetching: true,
        },
      });
    case GET_LIST_NEGARA_SUCCESS:
      return (state = {
        ...state,
        list_negara: {
          ...state.list_negara,
          isFetching: false,
          items: action.payload.data,
        },
      });
    case GET_LIST_NEGARA_FAILURE:
      return (state = {
        ...state,
        list_negara: {
          ...state.list_negara,
          isFetching: false,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });
    case GET_LIST_USULAN_PENELITIAN_REQUEST:
      return (state = {
        ...state,
        usulan_penelitian_list: {
          ...state.usulan_penelitian_list,
          isFetching: true,
        },
      });
    case GET_LIST_USULAN_PENELITIAN_SUCCESS:
      return (state = {
        ...state,
        usulan_penelitian_list: {
          ...state.usulan_penelitian_list,
          isFetching: false,
          items: action.payload.data,
        },
      });
    case GET_LIST_USULAN_PENELITIAN_FAILURE:
      return (state = {
        ...state,
        usulan_penelitian_list: {
          ...state.usulan_penelitian_list,
          isFetching: false,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });
    case GET_USULAN_PENELITIAN_BYID_REQUEST:
      return (state = {
        ...state,
        usulan_penelitian: {
          ...state.usulan_penelitian,
          isFetching: true,
        },
      });
    case GET_USULAN_PENELITIAN_BYID_SUCCESS:
      return (state = {
        ...state,
        usulan_penelitian: {
          ...state.usulan_penelitian,
          isFetching: false,
          item: action.payload.data,
        },
      });
    case GET_USULAN_PENELITIAN_BYID_FAILURE:
      return (state = {
        ...state,
        usulan_penelitian: {
          ...state.usulan_penelitian,
          isFetching: false,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });

    case DEL_PENELITIAN_REQUEST:
      return (state = {
        ...state,
        delete_usulan: {
          ...state.delete_usulan,
          isFetching: true,
        },
      });
    case DEL_PENELITIAN_SUCCESS:
      return (state = {
        ...state,
        delete_usulan: {
          ...state.delete_usulan,
          isFetching: false,
          result: action.payload.data,
        },
      });
    case DEL_PENELITIAN_FAILURE:
      return (state = {
        ...state,
        delete_usulan: {
          ...state.delete_usulan,
          isFetching: false,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });
    case SAVE_IDENTITAS_USULAN_REQUEST:
      return (state = {
        ...state,
        save_identitas_usulan: {
          ...state.save_identitas_usulan,
          isFetching: true,
        },
      });
    case SAVE_IDENTITAS_USULAN_SUCCESS:
      return (state = {
        ...state,
        save_identitas_usulan: {
          ...state.save_identitas_usulan,
          isFetching: false,
          item: action.payload.data,
        },
      });
    case SAVE_IDENTITAS_USULAN_FAILURE:
      return (state = {
        ...state,
        save_identitas_usulan: {
          ...state.save_identitas_usulan,
          isFetching: false,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });
    case SAVE_SUBTANSI_USULAN_REQUEST:
      return (state = {
        ...state,
        save_subtansi_usulan: {
          ...state.save_subtansi_usulan,
          isFetching: true,
        },
      });
    case SAVE_SUBTANSI_USULAN_SUCCESS:
      return (state = {
        ...state,
        save_subtansi_usulan: {
          ...state.save_subtansi_usulan,
          isFetching: false,
          item: action.payload.data,
        },
      });
    case SAVE_SUBTANSI_USULAN_FAILURE:
      return (state = {
        ...state,
        save_subtansi_usulan: {
          ...state.save_subtansi_usulan,
          isFetching: false,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });
    case UPDATE_USULAN_PENELITIAN_REQUEST:
      return (state = {
        ...state,
        update_usulan: {
          ...state.update_usulan,
          isFetching: true,
        },
      });
    case UPDATE_USULAN_PENELITIAN_SUCCESS:
      return (state = {
        ...state,
        update_usulan: {
          ...state.update_usulan,
          isFetching: false,
          item: action.payload.data,
        },
      });
    case UPDATE_USULAN_PENELITIAN_FAILURE:
      return (state = {
        ...state,
        update_usulan: {
          ...state.update_usulan,
          isFetching: false,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });

    case UPDATE_SUBTANSI_USULAN_REQUEST:
      return (state = {
        ...state,
        update_subtansi_usulan: {
          ...state.update_subtansi_usulan,
          isFetching: true,
        },
      });
    case UPDATE_SUBTANSI_USULAN_SUCCESS:
      return (state = {
        ...state,
        update_subtansi_usulan: {
          ...state.update_subtansi_usulan,
          isFetching: false,
          item: action.payload.data,
        },
      });
    case UPDATE_SUBTANSI_USULAN_FAILURE:
      return (state = {
        ...state,
        update_subtansi_usulan: {
          ...state.update_subtansi_usulan,
          isFetching: false,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });
    case UPDATE_IDENTITAS_USULAN_REQUEST:
      return (state = {
        ...state,
        update_identitas_usulan: {
          ...state.update_identitas_usulan,
          isFetching: true,
        },
      });
    case UPDATE_IDENTITAS_USULAN_SUCCESS:
      return (state = {
        ...state,
        update_identitas_usulan: {
          ...state.update_identitas_usulan,
          isFetching: false,
          item: action.payload.data,
        },
      });
    case UPDATE_IDENTITAS_USULAN_FAILURE:
      return (state = {
        ...state,
        update_identitas_usulan: {
          ...state.update_identitas_usulan,
          isFetching: false,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });

    case SAVE_RAB_USULAN_REQUEST:
      return (state = {
        ...state,
        save_rab_usulan: {
          ...state.save_rab_usulan,
          isFetching: true,
        },
      });
    case SAVE_RAB_USULAN_SUCCESS:
      return (state = {
        ...state,
        save_rab_usulan: {
          ...state.save_rab_usulan,
          isFetching: false,
          item: action.payload.data,
        },
      });
    case SAVE_RAB_USULAN_FAILURE:
      return (state = {
        ...state,
        save_rab_usulan: {
          ...state.save_rab_usulan,
          isFetching: false,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });
    case SAVE_MITRA_USULAN_REQUEST:
      return (state = {
        ...state,
        save_mitra_usulan: {
          ...state.save_mitra_usulan,
          isFetching: true,
        },
      });
    case SAVE_MITRA_USULAN_SUCCESS:
      return (state = {
        ...state,
        save_mitra_usulan: {
          ...state.save_mitra_usulan,
          isFetching: false,
          item: action.payload.data,
        },
      });
    case SAVE_MITRA_USULAN_FAILURE:
      return (state = {
        ...state,
        save_mitra_usulan: {
          ...state.save_mitra_usulan,
          isFetching: false,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });
    case GET_AVAILABLE_SKEMA_REQUEST:
      return (state = {
        ...state,
        available_skema: {
          ...state.available_skema,
          isFetching: true,
        },
      });
    case GET_AVAILABLE_SKEMA_SUCCESS:
      return (state = {
        ...state,
        available_skema: {
          ...state.available_skema,
          isFetching: false,
          items: action.payload.data,
        },
      });
    case GET_AVAILABLE_SKEMA_FAILURE:
      return (state = {
        ...state,
        available_skema: {
          ...state.available_skema,
          isFetching: false,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });
    case GET_ELIGIBILITY_REQUEST:
      return (state = {
        ...state,
        eligibilitas_dosen: {
          ...state.eligibilitas_dosen,
          isFetching: true,
        },
      });
    case GET_ELIGIBILITY_SUCCESS:
      return (state = {
        ...state,
        eligibilitas_dosen: {
          ...state.eligibilitas_dosen,
          isFetching: false,
          item: action.payload.data,
        },
      });
    case GET_ELIGIBILITY_FAILURE:
      return (state = {
        ...state,
        eligibilitas_dosen: {
          ...state.eligibilitas_dosen,
          isFetching: false,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });
    case GET_RULE_SKEMA_REQUEST:
      return (state = {
        ...state,
        rule_skema: {
          ...state.rule_skema,
          isFetching: true,
        },
      });
    case GET_RULE_SKEMA_SUCCESS:
      return (state = {
        ...state,
        rule_skema: {
          ...state.rule_skema,
          isFetching: false,
          items: action.payload.data,
        },
      });
    case GET_RULE_SKEMA_FAILURE:
      return (state = {
        ...state,
        rule_skema: {
          ...state.rule_skema,
          isFetching: false,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });
    case KELOMPOK_SKEMA_REQUEST:
      return (state = {
        ...state,
        kelompok_skema: {
          ...state.kelompok_skema,
          isFetching: true,
        },
      });
    case KELOMPOK_SKEMA_SUCCESS:
      return (state = {
        ...state,
        kelompok_skema: {
          ...state.kelompok_skema,
          isFetching: false,
          items: action.payload.data,
        },
      });
    case KELOMPOK_SKEMA_FAILURE:
      return (state = {
        ...state,
        kelompok_skema: {
          ...state.kelompok_skema,
          isFetching: false,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });
    case GET_TARGET_CAPAIAN_LUARAN_REQUEST:
      return (state = {
        ...state,
        target_capaian_luaran: {
          ...state.target_capaian_luaran,
          isFetching: true,
        },
      });
    case GET_TARGET_CAPAIAN_LUARAN_SUCCESS:
      return (state = {
        ...state,
        target_capaian_luaran: {
          ...state.target_capaian_luaran,
          isFetching: false,
          items: [action.payload.data],
        },
      });
    case GET_TARGET_CAPAIAN_LUARAN_FAILURE:
      return (state = {
        ...state,
        target_capaian_luaran: {
          ...state.target_capaian_luaran,
          isFetching: false,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });
    case GET_MAHASISWA_REQUEST:
      return (state = {
        ...state,
        mahasiswa: {
          ...state.mahasiswa,
          isFetching: true,
          items: [],
        },
      });
    case GET_MAHASISWA_SUCCESS:
      return (state = {
        ...state,
        mahasiswa: {
          ...state.mahasiswa,
          isFetching: false,
          items: action.payload.data,
        },
      });
    case GET_MAHASISWA_FAILURE:
      return (state = {
        ...state,
        mahasiswa: {
          ...state.mahasiswa,
          isFetching: false,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });
    case MHS_SET_ITEM:
      return (state = {
        ...state,
        mahasiswa: {
          ...state.mahasiswa,
          // isFetching: false,
          item: action.payload.data,
        },
      });
    case CLEAN_MAHASISWA:
      return (state = {
        ...state,
        mahasiswa: {
          items: [],
          isFetching: false,
          errors: "",
          errorMessage: "",
        },
      });
    case GET_KATEGORI_SBK_REQUEST:
      return (state = {
        ...state,
        kategori_sbk: {
          ...state.kategori_sbk,
          isFetching: true,
        },
      });
    case GET_KATEGORI_SBK_SUCCES:
      return (state = {
        ...state,
        kategori_sbk: {
          ...state.kategori_sbk,
          isFetching: false,
          items: action.payload.data,
        },
      });
    case GET_KATEGORI_SBK_FAILURE:
      return (state = {
        ...state,
        kategori_sbk: {
          ...state.kategori_sbk,
          isFetching: false,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });
    case GET_PRIORITAS_RISET_REQUEST:
      return (state = {
        ...state,
        prioritas_riset: {
          ...state.prioritas_riset,
          isFetching: true,
        },
      });
    case GET_PRIORITAS_RISET_SUCCESS:
      return (state = {
        ...state,
        prioritas_riset: {
          ...state.prioritas_riset,
          isFetching: false,
          items: action.payload.data,
        },
      });
    case GET_PRIORITAS_RISET_FAILURE:
      return (state = {
        ...state,
        prioritas_riset: {
          ...state.prioritas_riset,
          isFetching: false,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });
    case KATEGORI_PENELITIAN_REQUEST:
      return (state = {
        ...state,
        kategori_penelitian: {
          ...state.kategori_penelitian,
          isFetching: true,
        },
      });
    case KATEGORI_PENELITIAN_SUCCESS:
      return (state = {
        ...state,
        kategori_penelitian: {
          ...state.kategori_penelitian,
          isFetching: false,
          items: action.payload.data,
        },
      });
    case KATEGORI_PENELITIAN_FAILURE:
      return (state = {
        ...state,
        kategori_penelitian: {
          ...state.kategori_penelitian,
          isFetching: false,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });

    case KATEGORI_INDIKATOR_TKT_REQUEST:
      return (state = {
        ...state,
        kategori_indikator_tkt: {
          ...state.kategori_indikator_tkt,
          isFetching: true,
        },
      });
    case KATEGORI_INDIKATOR_TKT_SUCCESS:
      return (state = {
        ...state,
        kategori_indikator_tkt: {
          ...state.kategori_indikator_tkt,
          isFetching: false,
          items: action.payload,
        },
      });
    case KATEGORI_INDIKATOR_TKT_FAILURE:
      return (state = {
        ...state,
        kategori_indikator_tkt: {
          ...state.kategori_indikator_tkt,
          isFetching: false,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });

    case INDIKATOR_TKT_BY_KATEGORI_REQUEST:
      return (state = {
        ...state,
        indikator_tkt: {
          ...state.indikator_tkt,
          isFetching: true,
        },
      });
    case INDIKATOR_TKT_BY_KATEGORI_SUCCESS:
      return (state = {
        ...state,
        indikator_tkt: {
          ...state.indikator_tkt,
          isFetching: false,
          [`level${action.payload.level}`]: action.payload.data,
        },
      });
    case INDIKATOR_TKT_BY_KATEGORI_FAILURE:
      return (state = {
        ...state,
        indikator_tkt: {
          ...state.indikator_tkt,
          isFetching: false,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });

    case TEMA_REQUEST:
      return (state = {
        ...state,
        tema: {
          ...state.tema,
          isFetching: true,
        },
      });
    case TEMA_SUCCESS:
      return (state = {
        ...state,
        tema: {
          ...state.tema,
          isFetching: false,
          items: action.payload.data,
        },
      });
    case TEMA_FAILURE:
      return (state = {
        ...state,
        tema: {
          ...state.tema,
          isFetching: false,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });

    case TOPIK_REQUEST:
      return (state = {
        ...state,
        topik: {
          ...state.topik,
          isFetching: true,
        },
      });
    case TOPIK_SUCCESS:
      return (state = {
        ...state,
        topik: {
          ...state.topik,
          isFetching: false,
          items: action.payload.data,
        },
      });
    case TOPIK_FAILURE:
      return (state = {
        ...state,
        topik: {
          ...state.topik,
          isFetching: false,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });

    case SBK_REQUEST:
      return (state = {
        ...state,
        sbk: {
          ...state.sbk,
          isFetching: true,
        },
      });
    case SBK_SUCCESS:
      return (state = {
        ...state,
        sbk: {
          ...state.sbk,
          isFetching: false,
          items: action.payload.data,
        },
      });
    case SBK_FAILURE:
      return (state = {
        ...state,
        sbk: {
          ...state.sbk,
          isFetching: false,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });

    case BIDANG_FOKUS_REQUEST:
      return (state = {
        ...state,
        bidang_fokus: {
          ...state.bidang_fokus,
          isFetching: true,
        },
      });
    case BIDANG_FOKUS_SUCCESS:
      return (state = {
        ...state,
        bidang_fokus: {
          ...state.bidang_fokus,
          isFetching: false,
          items: action.payload.data,
        },
      });
    case BIDANG_FOKUS_FAILURE:
      return (state = {
        ...state,
        bidang_fokus: {
          ...state.bidang_fokus,
          isFetching: false,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });

    case RUMPUN_ILMU_REQUEST:
      return (state = {
        ...state,
        [`rumpun_ilmu${action.payload.level}`]: {
          ...state[`rumpun_ilmu${action.payload.level}`],
          isFetching: true,
        },
      });
    case RUMPUN_ILMU_SUCCESS:
      return (state = {
        ...state,
        [`rumpun_ilmu${action.payload.level}`]: {
          ...state[`rumpun_ilmu${action.payload.level}`],
          isFetching: false,
          items: action.payload.data,
        },
      });
    case RUMPUN_ILMU_FAILURE:
      return (state = {
        ...state,
        [`rumpun_ilmu${action.payload.level}`]: {
          ...state[`rumpun_ilmu${action.payload.level}`],
          isFetching: false,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });

    case TARGET_AKHIR_REQUEST:
      return (state = {
        ...state,
        target_tkt: {
          ...state.target_tkt,
          isFetching: true,
        },
      });
    case TARGET_AKHIR_SUCCESS:
      return (state = {
        ...state,
        target_tkt: {
          ...state.target_tkt,
          isFetching: false,
          items: action.payload.data,
        },
      });
    case TARGET_AKHIR_FAILURE:
      return (state = {
        ...state,
        target_tkt: {
          ...state.target_tkt,
          isFetching: false,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });

    case SKEMA_KEGIATAN_REQUEST:
      return (state = {
        ...state,
        skema_kegiatan: {
          ...state.skema_kegiatan,
          isFetching: true,
        },
      });
    case SKEMA_KEGIATAN_SUCCESS:
      return (state = {
        ...state,
        skema_kegiatan: {
          ...state.skema_kegiatan,
          isFetching: false,
          items: action.payload.data,
        },
      });
    case SKEMA_KEGIATAN_FAILURE:
      return (state = {
        ...state,
        skema_kegiatan: {
          ...state.skema_kegiatan,
          isFetching: false,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });

    case BIDANG_UNGGULAN_REQUEST:
      return (state = {
        ...state,
        bidang_unggulan: {
          ...state.bidang_unggulan,
          isFetching: true,
        },
      });
    case BIDANG_UNGGULAN_SUCCESS:
      return (state = {
        ...state,
        bidang_unggulan: {
          ...state.bidang_unggulan,
          isFetching: false,
          items: action.payload.data,
        },
      });
    case BIDANG_UNGGULAN_FAILURE:
      return (state = {
        ...state,
        bidang_unggulan: {
          ...state.bidang_unggulan,
          isFetching: false,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });

    case TOPIK_UNGGULAN_REQUEST:
      return (state = {
        ...state,
        topik_unggulan: {
          ...state.topik_unggulan,
          isFetching: true,
        },
      });
    case TOPIK_UNGGULAN_SUCCESS:
      return (state = {
        ...state,
        topik_unggulan: {
          ...state.topik_unggulan,
          isFetching: false,
          items: action.payload.data,
        },
      });
    case TOPIK_UNGGULAN_FAILURE:
      return (state = {
        ...state,
        topik_unggulan: {
          ...state.topik_unggulan,
          isFetching: false,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });

    case DOSEN_REQUEST:
      return (state = {
        ...state,
        dosen: {
          ...state.dosen,
          isFetching: true,
        },
      });
    case DOSEN_SUCCESS:
      return (state = {
        ...state,
        dosen: {
          ...state.dosen,
          isFetching: false,
          items: action.payload.data,
        },
      });
    case DOSEN_FAILURE:
      return (state = {
        ...state,
        dosen: {
          ...state.dosen,
          isFetching: false,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });
    case VALIDASI_KEANGGOTAAN_DOSEN_REQUEST:
      return (state = {
        ...state,
        validasi_keanggotaan_dosen: {
          ...state.validasi_keanggotaan_dosen,
          isFetching: true,
        },
      });
    case VALIDASI_KEANGGOTAAN_DOSEN_SUCCESS:
      return (state = {
        ...state,
        validasi_keanggotaan_dosen: {
          ...state.validasi_keanggotaan_dosen,
          isFetching: false,
          result: action.payload.data,
        },
      });
    case VALIDASI_KEANGGOTAAN_DOSEN_FAILURE:
      return (state = {
        ...state,
        validasi_keanggotaan_dosen: {
          ...state.validasi_keanggotaan_dosen,
          isFetching: false,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });

    case PERAN_PERSONIL_REQUEST:
      return (state = {
        ...state,
        peran_personil: {
          ...state.peran_personil,
          isFetching: true,
        },
      });
    case PERAN_PERSONIL_SUCCESS:
      return (state = {
        ...state,
        peran_personil: {
          ...state.peran_personil,
          isFetching: false,
          items: action.payload.data,
        },
      });
    case PERAN_PERSONIL_FAILURE:
      return (state = {
        ...state,
        peran_personil: {
          ...state.peran_personil,
          isFetching: false,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });

    case KELOMPOK_MAKRO_RISET_REQUEST:
      return (state = {
        ...state,
        kelompok_makro: {
          ...state.kelompok_makro,
          isFetching: true,
        },
      });
    case KELOMPOK_MAKRO_RISET_SUCCESS:
      return (state = {
        ...state,
        kelompok_makro: {
          ...state.kelompok_makro,
          isFetching: false,
          items: action.payload.data,
        },
      });
    case KELOMPOK_MAKRO_RISET_FAILURE:
      return (state = {
        ...state,
        kelompok_makro: {
          ...state.kelompok_makro,
          isFetching: false,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });

    case UPLOAD_DOKUMEN_REQUEST:
      return (state = {
        ...state,
        uploaded_dokumen: {
          ...state.uploaded_dokumen,
          isFetching: true,
        },
      });
    case UPLOAD_DOKUMEN_SUCCESS:
      return (state = {
        ...state,
        uploaded_dokumen: {
          ...state.uploaded_dokumen,
          isFetching: false,
          item: action.payload.data,
        },
      });
    case UPLOAD_DOKUMEN_FAILURE:
      return (state = {
        ...state,
        uploaded_dokumen: {
          ...state.uploaded_dokumen,
          isFetching: false,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });

    case JENIS_LUARAN_REQUEST:
      return (state = {
        ...state,
        jenis_luaran: {
          ...state.jenis_luaran,
          isFetching: true,
        },
      });
    case JENIS_LUARAN_SUCCESS:
      return (state = {
        ...state,
        jenis_luaran: {
          ...state.jenis_luaran,
          isFetching: false,
          items: action.payload.data,
        },
      });
    case JENIS_LUARAN_FAILURE:
      return (state = {
        ...state,
        jenis_luaran: {
          ...state.jenis_luaran,
          isFetching: false,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });

    case KATEGORI_LUARAN_REQUEST:
      return (state = {
        ...state,
        kategori_luaran: {
          ...state.kategori_luaran,
          isFetching: true,
        },
      });
    case KATEGORI_LUARAN_SUCCESS:
      return (state = {
        ...state,
        kategori_luaran: {
          ...state.kategori_luaran,
          isFetching: false,
          items: action.payload.data,
        },
      });
    case KATEGORI_LUARAN_FAILURE:
      return (state = {
        ...state,
        kategori_luaran: {
          ...state.kategori_luaran,
          isFetching: false,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });

    case GET_PENELITIAN_REQUEST:
      return (state = {
        ...state,
        penelitian: {
          ...state.penelitian,
          isFetching: true,
        },
      });
    case GET_PENELITIAN_SUCCESS:
      if (action.payload.state == "detail") {
        return (state = {
          ...state,
          penelitian: {
            ...state.penelitian,
            isFetching: false,
            item: action.payload.data ?? {},
          },
        });
      } else {
        return (state = {
          ...state,
          penelitian: {
            ...state.penelitian,
            isFetching: false,
            items: action.payload.data ?? [],
          },
        });
      }
    case GET_PENELITIAN_FAILURE:
      return (state = {
        ...state,
        penelitian: {
          ...state.penelitian,
          isFetching: false,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });

    case KELOMPOK_RAB_BIAYA_REQUEST:
      return (state = {
        ...state,
        kelompok_rab: {
          ...state.kelompok_rab,
          isFetching: true,
        },
      });
    case KELOMPOK_RAB_BIAYA_SUCCESS:
      if (action.payload.state == "detail") {
        return (state = {
          ...state,
          kelompok_rab: {
            ...state.kelompok_rab,
            isFetching: false,
            item: action.payload.data ?? {},
          },
        });
      } else {
        return (state = {
          ...state,
          kelompok_rab: {
            ...state.kelompok_rab,
            isFetching: false,
            items: action.payload.data ?? [],
          },
        });
      }
    case KELOMPOK_RAB_BIAYA_FAILURE:
      return (state = {
        ...state,
        kelompok_rab: {
          ...state.kelompok_rab,
          isFetching: false,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });

    case RAB_KOMPONEN_REQUEST:
      return (state = {
        ...state,
        rab_komponen: {
          ...state.rab_komponen,
          isFetching: true,
        },
      });
    case RAB_KOMPONEN_SUCCESS:
      if (action.payload.state == "detail") {
        return (state = {
          ...state,
          rab_komponen: {
            ...state.rab_komponen,
            isFetching: false,
            item: action.payload.data ?? {},
          },
        });
      } else {
        return (state = {
          ...state,
          rab_komponen: {
            ...state.rab_komponen,
            isFetching: false,
            items: action.payload.data ?? [],
          },
        });
      }
    case RAB_KOMPONEN_FAILURE:
      return (state = {
        ...state,
        rab_komponen: {
          ...state.rab_komponen,
          isFetching: false,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });
    case GET_KETERLIBATAN_MAHASISWA_REQUEST:
      return (state = {
        ...state,
        keterlibatanMahasiswa: {
          ...state.keterlibatanMahasiswa,
          isFetching: true,
        },
      });
    case GET_KETERLIBATAN_MAHASISWA_SUCCESS:
      return (state = {
        ...state,
        keterlibatanMahasiswa: {
          ...state.keterlibatanMahasiswa,
          isFetching: false,
          items:
            action.payload.data?.map(e => ({
              ...e,
              namaProdi: e.program_studi?.nama,
            })) ?? [],
        },
      });
    case GET_KETERLIBATAN_MAHASISWA_FAILURE:
      return (state = {
        ...state,
        keterlibatanMahasiswa: {
          ...state.keterlibatanMahasiswa,
          isFetching: false,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });
    case CREATE_KETERLIBATAN_MAHASISWA_REQUEST:
      return (state = {
        ...state,
        keterlibatanMahasiswa: {
          ...state.keterlibatanMahasiswa,
          isFetching: true,
        },
      });
    case CREATE_KETERLIBATAN_MAHASISWA_SUCCESS:
      return (state = {
        ...state,
        keterlibatanMahasiswa: {
          ...state.keterlibatanMahasiswa,
          isFetching: false,
          item: action.payload ?? {},
        },
      });
    case CREATE_KETERLIBATAN_MAHASISWA_FAILURE:
      return (state = {
        ...state,
        keterlibatanMahasiswa: {
          ...state.keterlibatanMahasiswa,
          isFetching: false,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });
    case UPDATE_KETERLIBATAN_MAHASISWA_REQUEST:
      return (state = {
        ...state,
        keterlibatanMahasiswa: {
          ...state.keterlibatanMahasiswa,
          isFetching: true,
        },
      });
    case UPDATE_KETERLIBATAN_MAHASISWA_SUCCESS:
      return (state = {
        ...state,
        keterlibatanMahasiswa: {
          ...state.keterlibatanMahasiswa,
          isFetching: false,
          item: action.payload ?? {},
        },
      });
    case UPDATE_KETERLIBATAN_MAHASISWA_FAILURE:
      return (state = {
        ...state,
        keterlibatanMahasiswa: {
          ...state.keterlibatanMahasiswa,
          isFetching: false,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });
    case DELETE_KETERLIBATAN_MAHASISWA_REQUEST:
      return (state = {
        ...state,
        keterlibatanMahasiswa: {
          ...state.keterlibatanMahasiswa,
          isFetching: true,
        },
      });
    case DELETE_KETERLIBATAN_MAHASISWA_SUCCESS:
      return (state = {
        ...state,
        keterlibatanMahasiswa: {
          ...state.keterlibatanMahasiswa,
          isFetching: false,
          items: state.keterlibatanMahasiswa.items.filter(
            e => e.id !== action.payload.id
          ),
        },
      });
    case DELETE_KETERLIBATAN_MAHASISWA_FAILURE:
      return (state = {
        ...state,
        keterlibatanMahasiswa: {
          ...state.keterlibatanMahasiswa,
          isFetching: false,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });
    case GET_JUMLAH_MAHASISWA_TERLIBAT_REQUEST:
      return (state = {
        ...state,
        jumlahMahasiswaTerlibat: {
          ...state.jumlahMahasiswaTerlibat,
          isFetching: true,
        },
      });
    case GET_JUMLAH_MAHASISWA_TERLIBAT_SUCCESS:
      return (state = {
        ...state,
        jumlahMahasiswaTerlibat: {
          ...state.jumlahMahasiswaTerlibat,
          isFetching: false,
          total: action.payload.data,
        },
      });
    case GET_JUMLAH_MAHASISWA_TERLIBAT_FAILURE:
      return (state = {
        ...state,
        jumlahMahasiswaTerlibat: {
          ...state.jumlahMahasiswaTerlibat,
          isFetching: false,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });
    // pengabdian get list tema prioritas
    case GET_TEMA_TEMATIK_REQUEST:
      return (state = {
        ...state,
        pengabdian_list_tema_prioritas: {
          ...state.pengabdian_list_tema_prioritas,
          isFetching: true,
        },
      });
    case GET_TEMA_TEMATIK_SUCCESS:
      return (state = {
        ...state,
        pengabdian_list_tema_prioritas: {
          ...state.pengabdian_list_tema_prioritas,
          isFetching: false,
          items: action.payload,
        },
      });
    case GET_TEMA_TEMATIK_FAILURE:
      return (state = {
        ...state,
        pengabdian_list_tema_prioritas: {
          ...state.pengabdian_list_tema_prioritas,
          isFetching: false,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });
    // pengabdian get list rirn
    case GET_LIST_RIRN_REQUEST:
      return (state = {
        ...state,
        pengabdian_list_rirn: {
          ...state.pengabdian_list_rirn,
          isFetching: true,
        },
      });
    case GET_LIST_RIRN_SUCCESS:
      return (state = {
        ...state,
        pengabdian_list_rirn: {
          ...state.pengabdian_list_rirn,
          isFetching: false,
          items: action.payload,
        },
      });
    case GET_LIST_RIRN_FAILURE:
      return (state = {
        ...state,
        pengabdian_list_rirn: {
          ...state.pengabdian_list_rirn,
          isFetching: false,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });
    // pengabdian post step 1
    case POST_STEP_1_IDENTITAS_PENGABDIAN_REQUEST:
      return (state = {
        ...state,
        pengabdian_step_1_result: {
          ...state.pengabdian_step_1_result,
          isFetching: true,
        },
      });
    case POST_STEP_1_IDENTITAS_PENGABDIAN_SUCCESS:
      return (state = {
        ...state,
        pengabdian_step_1_result: {
          ...state.pengabdian_step_1_result,
          isFetching: false,
          items: action.payload,
        },
      });
    case POST_STEP_1_IDENTITAS_PENGABDIAN_FAILURE:
      return (state = {
        ...state,
        pengabdian_step_1_result: {
          ...state.pengabdian_step_1_result,
          isFetching: false,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });
    // pengabdian all step
    case PUT_ALL_STEP_USULAN_PENGABDIAN_REQUEST:
      return (state = {
        ...state,
        pengabdian_all_step_result: {
          ...state.pengabdian_all_step_result,
          isFetching: true,
        },
      });
    case PUT_ALL_STEP_USULAN_PENGABDIAN_SUCCESS:
      return (state = {
        ...state,
        pengabdian_all_step_result: {
          ...state.pengabdian_all_step_result,
          isFetching: false,
          items: action.payload,
        },
      });
    case PUT_ALL_STEP_USULAN_PENGABDIAN_FAILURE:
      return (state = {
        ...state,
        pengabdian_all_step_result: {
          ...state.pengabdian_all_step_result,
          isFetching: false,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });
    case GET_LIST_PROVINSI_REQUEST:
      return (state = {
        ...state,
        list_provinsi: {
          ...state.list_provinsi,
          isFetching: true,
        },
      });
    case GET_LIST_PROVINSI_SUCCESS:
      return (state = {
        ...state,
        list_provinsi: {
          ...state.list_provinsi,
          isFetching: false,
          items: action.payload.data,
        },
      });
    case GET_LIST_PROVINSI_FAILURE:
      return (state = {
        ...state,
        list_provinsi: {
          ...state.list_provinsi,
          isFetching: false,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });
    case GET_LIST_KOTA_REQUEST:
      return (state = {
        ...state,
        list_kota: {
          ...state.list_kota,
          isFetching: true,
        },
      });
    case GET_LIST_KOTA_SUCCESS:
      return (state = {
        ...state,
        list_kota: {
          ...state.list_kota,
          isFetching: false,
          items: action.payload.data,
        },
      });
    case GET_LIST_KOTA_FAILURE:
      return (state = {
        ...state,
        list_kota: {
          ...state.list_kota,
          isFetching: false,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });
    case GET_OPTION_MITRA_KELOMPOK_REQUEST:
      return (state = {
        ...state,
        pengabdian_list_option_mitra_kelompok: {
          ...state.pengabdian_list_option_mitra_kelompok,
          isFetching: true,
        },
      });
    case GET_OPTION_MITRA_KELOMPOK_SUCCESS:
      return (state = {
        ...state,
        pengabdian_list_option_mitra_kelompok: {
          ...state.pengabdian_list_option_mitra_kelompok,
          isFetching: false,
          items: action.payload.data,
        },
      });
    case GET_OPTION_MITRA_KELOMPOK_FAILURE:
      return (state = {
        ...state,
        pengabdian_list_option_mitra_kelompok: {
          ...state.pengabdian_list_option_mitra_kelompok,
          isFetching: false,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });
    case GET_OPTION_MITRA_JENIS_MITRA_REQUEST:
      return (state = {
        ...state,
        pengabdian_list_option_mitra_jenis_mitra: {
          ...state.pengabdian_list_option_mitra_jenis_mitra,
          isFetching: true,
        },
      });
    case GET_OPTION_MITRA_JENIS_MITRA_SUCCESS:
      return (state = {
        ...state,
        pengabdian_list_option_mitra_jenis_mitra: {
          ...state.pengabdian_list_option_mitra_jenis_mitra,
          isFetching: false,
          items: action.payload.data,
        },
      });
    case GET_OPTION_MITRA_JENIS_MITRA_FAILURE:
      return (state = {
        ...state,
        pengabdian_list_option_mitra_jenis_mitra: {
          ...state.pengabdian_list_option_mitra_jenis_mitra,
          isFetching: false,
          errors: action.payload.errors,
          errorMessage: action.payload.message,
        },
      });
    default:
      return state;
  }
};

export default usulanPenelitian;
