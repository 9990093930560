// dokumen luaran
export const GET_DOKUMEN_LUARAN_LAPORAN_AKHIR_REQUEST =
  "GET_DOKUMEN_LUARAN_LAPORAN_AKHIR_REQUEST";
export const GET_DOKUMEN_LUARAN_LAPORAN_AKHIR_SUCCESS =
  "GET_DOKUMEN_LUARAN_LAPORAN_AKHIR_SUCCESS";
export const GET_DOKUMEN_LUARAN_LAPORAN_AKHIR_FAILURE =
  "GET_DOKUMEN_LUARAN_LAPORAN_AKHIR_FAILURE";
// laporan akhir list
export const GET_LIST_LAPORAN_AKHIR_REQUEST = "GET_LIST_LAPORAN_AKHIR_REQUEST";
export const GET_LIST_LAPORAN_AKHIR_SUCCESS = "GET_LIST_LAPORAN_AKHIR_SUCCESS";
export const GET_LIST_LAPORAN_AKHIR_FAILURE = "GET_LIST_LAPORAN_AKHIR_FAILURE";

// get detail
export const GET_DETAIL_LAPORAN_AKHIR_REQUEST =
  "GET_DETAIL_LAPORAN_AKHIR_REQUEST";
export const GET_DETAIL_LAPORAN_AKHIR_SUCCESS =
  "GET_DETAIL_LAPORAN_AKHIR_SUCCESS";
export const GET_DETAIL_LAPORAN_AKHIR_FAILURE =
  "GET_DETAIL_LAPORAN_AKHIR_FAILURE";

// get detail
export const GET_DETAIL_LAPORAN_AKHIR_LUARAN_AKTUAL_REQUEST =
  "GET_DETAIL_LAPORAN_AKHIR_LUARAN_AKTUAL_REQUEST";
export const GET_DETAIL_LAPORAN_AKHIR_LUARAN_AKTUAL_SUCCESS =
  "GET_DETAIL_LAPORAN_AKHIR_LUARAN_AKTUAL_SUCCESS";
export const GET_DETAIL_LAPORAN_AKHIR_LUARAN_AKTUAL_FAILURE =
  "GET_DETAIL_LAPORAN_AKHIR_LUARAN_AKTUAL_FAILURE";

export const CREATE_LAPORAN_AKHIR_LUARAN_AKTUAL_REQUEST =
  "CREATE_LAPORAN_AKHIR_LUARAN_AKTUAL_REQUEST";
export const CREATE_LAPORAN_AKHIR_LUARAN_AKTUAL_SUCCESS =
  "CREATE_LAPORAN_AKHIR_LUARAN_AKTUAL_SUCCESS";
export const CREATE_LAPORAN_AKHIR_LUARAN_AKTUAL_FAILURE =
  "CREATE_LAPORAN_AKHIR_LUARAN_AKTUAL_FAILURE";

// create laporan akhir
export const CREATE_LAPORAN_AKHIR_REQUEST = "CREATE_LAPORAN_AKHIR_REQUEST";
export const CREATE_LAPORAN_AKHIR_SUCCESS = "CREATE_LAPORAN_AKHIR_SUCCESS";
export const CREATE_LAPORAN_AKHIR_FAILURE = "CREATE_LAPORAN_AKHIR_FAILURE";

// upload docs
export const UPLOAD_DOKUMEN_LAPORAN_AKHIR_REQUEST =
  "UPLOAD_DOKUMEN_LAPORAN_AKHIR_REQUEST";
export const UPLOAD_DOKUMEN_LAPORAN_AKHIR_SUCCESS =
  "UPLOAD_DOKUMEN_LAPORAN_AKHIR_SUCCESS";
export const UPLOAD_DOKUMEN_LAPORAN_AKHIR_FAILURE =
  "UPLOAD_DOKUMEN_LAPORAN_AKHIR_FAILURE";

// upload docs sptb
export const UPLOAD_DOKUMEN_SPTB_LAPORAN_AKHIR_REQUEST =
  "UPLOAD_DOKUMEN_SPTB_LAPORAN_AKHIR_REQUEST";
export const UPLOAD_DOKUMEN_SPTB_LAPORAN_AKHIR_SUCCESS =
  "UPLOAD_DOKUMEN_SPTB_LAPORAN_AKHIR_SUCCESS";
export const UPLOAD_DOKUMEN_SPTB_LAPORAN_AKHIR_FAILURE =
  "UPLOAD_DOKUMEN_SPTB_LAPORAN_AKHIR_FAILURE";

// Get sptb laporan akhir
export const GET_SPTB_LAPORAN_AKHIR_REQUEST = "GET_SPTB_LAPORAN_AKHIR_REQUEST";
export const GET_SPTB_LAPORAN_AKHIR_SUCCESS = "GET_SPTB_LAPORAN_AKHIR_SUCCESS";
export const GET_SPTB_LAPORAN_AKHIR_FAILURE = "GET_SPTB_LAPORAN_AKHIR_FAILURE";
// pengabdian laporan akhir form mitra

export const GET_FORM_MITRA_DETAIL_LAPORAN_AKHIR_PENGABDIAN_REQUEST =
  "GET_FORM_MITRA_DETAIL_LAPORAN_AKHIR_PENGABDIAN_REQUEST";
export const GET_FORM_MITRA_DETAIL_LAPORAN_AKHIR_PENGABDIAN_SUCCESS =
  "GET_FORM_MITRA_DETAIL_LAPORAN_AKHIR_PENGABDIAN_SUCCESS";
export const GET_FORM_MITRA_DETAIL_LAPORAN_AKHIR_PENGABDIAN_FAILURE =
  "GET_FORM_MITRA_DETAIL_LAPORAN_AKHIR_PENGABDIAN_FAILURE";

export const POST_FORM_MITRA_LAPORAN_AKHIR_PENGABDIAN_REQUEST =
  "POST_FORM_MITRA_LAPORAN_AKHIR_PENGABDIAN_REQUEST";
export const POST_FORM_MITRA_LAPORAN_AKHIR_PENGABDIAN_SUCCESS =
  "POST_FORM_MITRA_LAPORAN_AKHIR_PENGABDIAN_SUCCESS";
export const POST_FORM_MITRA_LAPORAN_AKHIR_PENGABDIAN_FAILURE =
  "POST_FORM_MITRA_LAPORAN_AKHIR_PENGABDIAN_FAILURE";

export const PUT_FORM_MITRA_LAPORAN_AKHIR_PENGABDIAN_REQUEST =
  "PUT_FORM_MITRA_LAPORAN_AKHIR_PENGABDIAN_REQUEST";
export const PUT_FORM_MITRA_LAPORAN_AKHIR_PENGABDIAN_SUCCESS =
  "PUT_FORM_MITRA_LAPORAN_AKHIR_PENGABDIAN_SUCCESS";
export const PUT_FORM_MITRA_LAPORAN_AKHIR_PENGABDIAN_FAILURE =
  "PUT_FORM_MITRA_LAPORAN_AKHIR_PENGABDIAN_FAILURE";

//LAPORAN AKHIR V2
export const GET_LIST_LAPORAN_AKHIR_V2_REQUEST =
  "GET_LIST_LAPORAN_AKHIR_V2_REQUEST";
export const GET_LIST_LAPORAN_AKHIR_V2_SUCCESS =
  "GET_LIST_LAPORAN_AKHIR_V2_SUCCESS";
export const GET_LIST_LAPORAN_AKHIR_V2_FAILURE =
  "GET_LIST_LAPORAN_AKHIR_V2_FAILURE";

export const GET_DETAIL_LAPORAN_AKHIR_V2_REQUEST =
  "GET_DETAIL_LAPORAN_AKHIR_V2_REQUEST";
export const GET_DETAIL_LAPORAN_AKHIR_V2_SUCCESS =
  "GET_DETAIL_LAPORAN_AKHIR_V2_SUCCESS";
export const GET_DETAIL_LAPORAN_AKHIR_V2_FAILURE =
  "GET_DETAIL_LAPORAN_AKHIR_V2_FAILURE";

export const UPDATE_LAPORAN_AKHIR_V2_REQUEST =
  "UPDATE_LAPORAN_AKHIR_V2_REQUEST";
export const UPDATE_LAPORAN_AKHIR_V2_SUCCESS =
  "UPDATE_LAPORAN_AKHIR_V2_SUCCESS";
export const UPDATE_LAPORAN_AKHIR_V2_FAILURE =
  "UPDATE_LAPORAN_AKHIR_V2_FAILURE";
