import {
  OPERATOR_MONITORING_LIST_USULAN_PERBAIKAN_FAILURE,
  OPERATOR_MONITORING_LIST_USULAN_PERBAIKAN_REQUEST,
  OPERATOR_MONITORING_LIST_USULAN_PERBAIKAN_SUCCESS,
  OPERATOR_MONITORING_CATATAN_HARIAN_FAILURE,
  OPERATOR_MONITORING_CATATAN_HARIAN_REQUEST,
  OPERATOR_MONITORING_CATATAN_HARIAN_SUCCESS,
  OPERATOR_MONITORING_LIST_LAPORAN_KEMAJUAN_FAILURE,
  OPERATOR_MONITORING_LIST_LAPORAN_KEMAJUAN_REQUEST,
  OPERATOR_MONITORING_LIST_LAPORAN_KEMAJUAN_SUCCESS,
  OPERATOR_MONITORING_LAPORAN_KEMAJUAN_DETAIL_FAILURE,
  OPERATOR_MONITORING_LAPORAN_KEMAJUAN_DETAIL_REQUEST,
  OPERATOR_MONITORING_LAPORAN_KEMAJUAN_DETAIL_SUCCESS,
  RESET_CATATAN_HARIAN,
  CATATAN_HARIAN_LAST_DETAIL,
  GET_ALL_INSTITUSI_FAILURE,
  GET_ALL_INSTITUSI_REQUEST,
  GET_ALL_INSTITUSI_SUCCESS,
  OPERATOR_MONITORING_LIST_LAPORAN_AKHIR_REQUEST,
  OPERATOR_MONITORING_LIST_LAPORAN_AKHIR_SUCCESS,
  OPERATOR_MONITORING_LIST_LAPORAN_AKHIR_FAILURE,
  OPERATOR_MONITORING_DETAIL_LAPORAN_AKHIR_REQUEST,
  OPERATOR_MONITORING_DETAIL_LAPORAN_AKHIR_SUCCESS,
  OPERATOR_MONITORING_DETAIL_LAPORAN_AKHIR_FAILURE,
  OPERATOR_MONITORING_LIST_KONTAK_PIC_REQUEST,
  OPERATOR_MONITORING_LIST_KONTAK_PIC_SUCCESS,
  OPERATOR_MONITORING_LIST_KONTAK_PIC_FAILURE,
  OPERATOR_MONITORING_PUT_KONTAK_PIC_REQUEST,
  OPERATOR_MONITORING_PUT_KONTAK_PIC_SUCCESS,
  OPERATOR_MONITORING_PUT_KONTAK_PIC_FAILURE,
  OPERATOR_MONITORING_LIST_MONEV_REQUEST,
  OPERATOR_MONITORING_LIST_MONEV_SUCCESS,
  OPERATOR_MONITORING_LIST_MONEV_FAILURE,
  OPERATOR_MONITORING_CATATAN_HARIAN_V2_REQUEST,
  OPERATOR_MONITORING_CATATAN_HARIAN_V2_SUCCESS,
  OPERATOR_MONITORING_CATATAN_HARIAN_V2_FAILURE,
  CLEAN_OPERATOR_MONITORING_CATATAN_HARIAN_V2,
} from "./actionTypes";
export const getOperatorListUsulanPerbaikan = (request = "") => {
  return {
    type: OPERATOR_MONITORING_LIST_USULAN_PERBAIKAN_REQUEST,
    payload: request,
  };
};

export const getOperatorListUsulanPerbaikanSuccess = response => {
  return {
    type: OPERATOR_MONITORING_LIST_USULAN_PERBAIKAN_SUCCESS,
    payload: response,
  };
};

export const getOperatorListUsulanPerbaikanFailure = (message, response) => {
  return {
    type: OPERATOR_MONITORING_LIST_USULAN_PERBAIKAN_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

export const getOperatorListCatatanHarian = (request = "") => {
  return {
    type: OPERATOR_MONITORING_CATATAN_HARIAN_REQUEST,
    payload: request,
  };
};

export const getOperatorListCatatanHarianSuccess = response => {
  return {
    type: OPERATOR_MONITORING_CATATAN_HARIAN_SUCCESS,
    payload: response,
  };
};

export const getOperatorListCatatanHarianFailure = (message, response) => {
  return {
    type: OPERATOR_MONITORING_CATATAN_HARIAN_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

export const getOperatorListCatatanHarianV2 = (request = "") => {
  return {
    type: OPERATOR_MONITORING_CATATAN_HARIAN_V2_REQUEST,
    payload: request,
  };
};

export const getOperatorListCatatanHarianV2Success = response => {
  return {
    type: OPERATOR_MONITORING_CATATAN_HARIAN_V2_SUCCESS,
    payload: response,
  };
};

export const getOperatorListCatatanHarianV2Failure = (message, response) => {
  return {
    type: OPERATOR_MONITORING_CATATAN_HARIAN_V2_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

export const cleanOperatorListCatatanHarianV2 = () => {
  return {
    type: CLEAN_OPERATOR_MONITORING_CATATAN_HARIAN_V2,
  };
};

export const resetCatatanHarian = () => {
  return {
    type: RESET_CATATAN_HARIAN,
  };
};
export const catatanHarianLastDetail = (request = "Cantik") => {
  return {
    type: CATATAN_HARIAN_LAST_DETAIL,
    payload: request,
  };
};

export const getOperatorListLaporanKemajuan = (request = "") => {
  return {
    type: OPERATOR_MONITORING_LIST_LAPORAN_KEMAJUAN_REQUEST,
    payload: request,
  };
};

export const getOperatorListLaporanKemajuanSuccess = response => {
  return {
    type: OPERATOR_MONITORING_LIST_LAPORAN_KEMAJUAN_SUCCESS,
    payload: response,
  };
};

export const getOperatorListLaporanKemajuanFailure = (message, response) => {
  return {
    type: OPERATOR_MONITORING_LIST_LAPORAN_KEMAJUAN_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

export const getOperatorLaporanKemajuanDetail = (request = "") => {
  return {
    type: OPERATOR_MONITORING_LAPORAN_KEMAJUAN_DETAIL_REQUEST,
    payload: request,
  };
};

export const getOperatorLaporanKemajuanDetailSuccess = response => {
  return {
    type: OPERATOR_MONITORING_LAPORAN_KEMAJUAN_DETAIL_SUCCESS,
    payload: response,
  };
};

export const getOperatorLaporanKemajuanDetailFailure = (message, response) => {
  return {
    type: OPERATOR_MONITORING_LAPORAN_KEMAJUAN_DETAIL_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

export const getAllInstitusi = (request = "", cb = () => { }) => {
  return {
    type: GET_ALL_INSTITUSI_REQUEST,
    payload: request,
    cb,
  };
};

export const getAllInstitusiSuccess = response => {
  return {
    type: GET_ALL_INSTITUSI_SUCCESS,
    payload: response,
  };
};

export const getAllInstitusiFailure = (message, response) => {
  return {
    type: GET_ALL_INSTITUSI_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

// Monitoring Lap Akhir START
export const getOperatorListLaporanAkhir = request => {
  return {
    type: OPERATOR_MONITORING_LIST_LAPORAN_AKHIR_REQUEST,
    payload: request,
  };
};

export const getOperatorListLaporanAkhirSuccess = response => {
  return {
    type: OPERATOR_MONITORING_LIST_LAPORAN_AKHIR_SUCCESS,
    payload: response,
  };
};

export const getOperatorListLaporanAkhirFailure = (message, response) => {
  return {
    type: OPERATOR_MONITORING_LIST_LAPORAN_AKHIR_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

export const getOperatorLaporanAkhirDetail = request => {
  return {
    type: OPERATOR_MONITORING_DETAIL_LAPORAN_AKHIR_REQUEST,
    payload: request,
  };
};

export const getOperatorLaporanAkhirDetailSuccess = response => {
  return {
    type: OPERATOR_MONITORING_DETAIL_LAPORAN_AKHIR_SUCCESS,
    payload: response,
  };
};

export const getOperatorLaporanAkhirDetailFailure = (message, response) => {
  return {
    type: OPERATOR_MONITORING_DETAIL_LAPORAN_AKHIR_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};
// Monitoring Lap Akhir END

// Kontak PIC Opt Pusat Start
export const getAllKontakPic = request => {
  return {
    type: OPERATOR_MONITORING_LIST_KONTAK_PIC_REQUEST,
    payload: request,
  };
};

export const getAllKontakPicSuccess = response => {
  return {
    type: OPERATOR_MONITORING_LIST_KONTAK_PIC_SUCCESS,
    payload: response,
  };
};

export const getAllKontakPicFailure = (message, response) => {
  return {
    type: OPERATOR_MONITORING_LIST_KONTAK_PIC_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

export const putKontakPic = (request, cb) => {
  return {
    type: OPERATOR_MONITORING_PUT_KONTAK_PIC_REQUEST,
    payload: request,
    cb,
  };
};

export const putKontakPicSuccess = response => {
  return {
    type: OPERATOR_MONITORING_PUT_KONTAK_PIC_SUCCESS,
    payload: response,
  };
};

export const putKontakPicFailure = (message, response) => {
  return {
    type: OPERATOR_MONITORING_PUT_KONTAK_PIC_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};
// Kontak PIC Opt Pusat End

// Monitoring Monev Start
export const listOptMonev = request => {
  return {
    type: OPERATOR_MONITORING_LIST_MONEV_REQUEST,
    payload: request,
  };
};

export const listOptMonevSuccess = response => {
  return {
    type: OPERATOR_MONITORING_LIST_MONEV_SUCCESS,
    payload: response,
  };
};

export const listOptMonevFailure = (message, response) => {
  return {
    type: OPERATOR_MONITORING_LIST_MONEV_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};
// Monitoring Monev End
