export const INDIKATOR_TKT_BY_KATEGORI_REQUEST =
  "INDIKATOR_TKT_BY_KATEGORI_REQUEST";
export const INDIKATOR_TKT_BY_KATEGORI_SUCCESS =
  "INDIKATOR_TKT_BY_KATEGORI_SUCCESS";
export const INDIKATOR_TKT_BY_KATEGORI_FAILURE =
  "INDIKATOR_TKT_BY_KATEGORI_FAILURE";

export const KATEGORI_INDIKATOR_TKT_REQUEST = "KATEGORI_INDIKATOR_TKT_REQUEST";
export const KATEGORI_INDIKATOR_TKT_SUCCESS = "KATEGORI_INDIKATOR_TKT_SUCCESS";
export const KATEGORI_INDIKATOR_TKT_FAILURE = "KATEGORI_INDIKATOR_TKT_FAILURE";

export const KELOMPOK_MAKRO_RISET_REQUEST = "KELOMPOK_MAKRO_RISET_REQUEST";
export const KELOMPOK_MAKRO_RISET_SUCCESS = "KELOMPOK_MAKRO_RISET_SUCCESS";
export const KELOMPOK_MAKRO_RISET_FAILURE = "KELOMPOK_MAKRO_RISET_FAILURE";

export const KELOMPOK_RAB_BIAYA_REQUEST = "KELOMPOK_RAB_BIAYA_REQUEST";
export const KELOMPOK_RAB_BIAYA_SUCCESS = "KELOMPOK_RAB_BIAYA_SUCCESS";
export const KELOMPOK_RAB_BIAYA_FAILURE = "KELOMPOK_RAB_BIAYA_FAILURE";

export const KATEGORI_PENELITIAN_REQUEST = "KATEGORI_PENELITIAN_REQUEST";
export const KATEGORI_PENELITIAN_SUCCESS = "KATEGORI_PENELITIAN_SUCCESS";
export const KATEGORI_PENELITIAN_FAILURE = "KATEGORI_PENELITIAN_FAILURE";

export const BIDANG_FOKUS_PRN_REQUEST = "BIDANG_FOKUS_PRN_REQUEST";
export const BIDANG_FOKUS_PRN_SUCCESS = "BIDANG_FOKUS_PRN_SUCCESS";
export const BIDANG_FOKUS_PRN_FAILURE = "BIDANG_FOKUS_PRN_FAILURE";

export const BIDANG_UNGGULAN_REQUEST = "BIDANG_UNGGULAN_REQUEST";
export const BIDANG_UNGGULAN_SUCCESS = "BIDANG_UNGGULAN_SUCCESS";
export const BIDANG_UNGGULAN_FAILURE = "BIDANG_UNGGULAN_FAILURE";

export const TOPIK_UNGGULAN_REQUEST = "TOPIK_UNGGULAN_REQUEST";
export const TOPIK_UNGGULAN_SUCCESS = "TOPIK_UNGGULAN_SUCCESS";
export const TOPIK_UNGGULAN_FAILURE = "TOPIK_UNGGULAN_FAILURE";

export const KELOMPOK_SKEMA_REQUEST = "KELOMPOK_SKEMA_REQUEST";
export const KELOMPOK_SKEMA_SUCCESS = "KELOMPOK_SKEMA_SUCCESS";
export const KELOMPOK_SKEMA_FAILURE = "KELOMPOK_SKEMA_FAILURE";

export const SKEMA_KEGIATAN_REQUEST = "SKEMA_KEGIATAN_REQUEST";
export const SKEMA_KEGIATAN_SUCCESS = "SKEMA_KEGIATAN_SUCCESS";
export const SKEMA_KEGIATAN_FAILURE = "SKEMA_KEGIATAN_FAILURE";

export const PERAN_PERSONIL_REQUEST = "PERAN_PERSONIL_REQUEST";
export const PERAN_PERSONIL_SUCCESS = "PERAN_PERSONIL_SUCCESS";
export const PERAN_PERSONIL_FAILURE = "PERAN_PERSONIL_FAILURE";

export const UPLOAD_DOKUMEN_REQUEST = "UPLOAD_DOKUMEN_REQUEST";
export const UPLOAD_DOKUMEN_SUCCESS = "UPLOAD_DOKUMEN_SUCCESS";
export const UPLOAD_DOKUMEN_FAILURE = "UPLOAD_DOKUMEN_FAILURE";

export const KATEGORI_LUARAN_REQUEST = "KATEGORI_LUARAN_REQUEST";
export const KATEGORI_LUARAN_SUCCESS = "KATEGORI_LUARAN_SUCCESS";
export const KATEGORI_LUARAN_FAILURE = "KATEGORI_LUARAN_FAILURE";

export const GET_PENELITIAN_REQUEST = "GET_PENELITIAN_REQUEST";
export const GET_PENELITIAN_SUCCESS = "GET_PENELITIAN_SUCCESS";
export const GET_PENELITIAN_FAILURE = "GET_PENELITIAN_FAILURE";

export const DEL_PENELITIAN_REQUEST = "DEL_PENELITIAN_REQUEST";
export const DEL_PENELITIAN_SUCCESS = "DEL_PENELITIAN_SUCCESS";
export const DEL_PENELITIAN_FAILURE = "DEL_PENELITIAN_FAILURE";

export const JENIS_LUARAN_REQUEST = "JENIS_LUARAN_REQUEST";
export const JENIS_LUARAN_SUCCESS = "JENIS_LUARAN_SUCCESS";
export const JENIS_LUARAN_FAILURE = "JENIS_LUARAN_FAILURE";

export const BIDANG_FOKUS_REQUEST = "BIDANG_FOKUS_REQUEST";
export const BIDANG_FOKUS_SUCCESS = "BIDANG_FOKUS_SUCCESS";
export const BIDANG_FOKUS_FAILURE = "BIDANG_FOKUS_FAILURE";

export const TARGET_AKHIR_REQUEST = "TARGET_AKHIR_REQUEST";
export const TARGET_AKHIR_SUCCESS = "TARGET_AKHIR_SUCCESS";
export const TARGET_AKHIR_FAILURE = "TARGET_AKHIR_FAILURE";

export const RUMPUN_ILMU_REQUEST = "RUMPUN_ILMU_REQUEST";
export const RUMPUN_ILMU_SUCCESS = "RUMPUN_ILMU_SUCCESS";
export const RUMPUN_ILMU_FAILURE = "RUMPUN_ILMU_FAILURE";

export const GET_KATEGORI_SBK_REQUEST = "GET_KATEGORI_SBK_REQUEST";
export const GET_KATEGORI_SBK_SUCCES = "GET_KATEGORI_SBK_SUCCES";
export const GET_KATEGORI_SBK_FAILURE = "GET_KATEGORI_SBK_FAILURE";

export const GET_PRIORITAS_RISET_REQUEST = "GET_PRIORITAS_RISET_REQUEST";
export const GET_PRIORITAS_RISET_SUCCESS = "GET_KPRIORITASRISET_SUCCESS";
export const GET_PRIORITAS_RISET_FAILURE = "GET_PRIORITAS_RISET_FAILURE";

export const TOPIK_REQUEST = "TOPIK_REQUEST";
export const TOPIK_SUCCESS = "TOPIK_SUCCESS";
export const TOPIK_FAILURE = "TOPIK_FAILURE";

export const DOSEN_REQUEST = "DOSEN_REQUEST";
export const DOSEN_SUCCESS = "DOSEN_SUCCESS";
export const DOSEN_FAILURE = "DOSEN_FAILURE";

export const TEMA_REQUEST = "TEMA_REQUEST";
export const TEMA_SUCCESS = "TEMA_SUCCESS";
export const TEMA_FAILURE = "TEMA_FAILURE";

export const SBK_REQUEST = "SBK_REQUEST";
export const SBK_SUCCESS = "SBK_SUCCESS";
export const SBK_FAILURE = "SBK_FAILURE";

export const RAB_KOMPONEN_REQUEST = "RAB_KOMPONEN_REQUEST";
export const RAB_KOMPONEN_SUCCESS = "RAB_KOMPONEN_SUCCESS";
export const RAB_KOMPONEN_FAILURE = "RAB_KOMPONEN_FAILURE";

export const RAB_KELOMPOK_REQUEST = "RAB_KELOMPOK_REQUEST";
export const RAB_KELOMPOK_SUCCESS = "RAB_KELOMPOK_SUCCESS";
export const RAB_KELOMPOK_FAILURE = "RAB_KELOMPOK_FAILURE";

export const GET_KETERLIBATAN_MAHASISWA_REQUEST =
  "GET_KETERLIBATAN_MAHASISWA_REQUEST";
export const GET_KETERLIBATAN_MAHASISWA_SUCCESS =
  "GET_KETERLIBATAN_MAHASISWA_SUCCESS";
export const GET_KETERLIBATAN_MAHASISWA_FAILURE =
  "GET_KETERLIBATAN_MAHASISWA_FAILURE";

export const CREATE_KETERLIBATAN_MAHASISWA_REQUEST =
  "CREATE_KETERLIBATAN_MAHASISWA_REQUEST";
export const CREATE_KETERLIBATAN_MAHASISWA_SUCCESS =
  "CREATE_KETERLIBATAN_MAHASISWA_SUCCESS";
export const CREATE_KETERLIBATAN_MAHASISWA_FAILURE =
  "CREATE_KETERLIBATAN_MAHASISWA_FAILURE";

export const UPDATE_KETERLIBATAN_MAHASISWA_REQUEST =
  "UPDATE_KETERLIBATAN_MAHASISWA_REQUEST";
export const UPDATE_KETERLIBATAN_MAHASISWA_SUCCESS =
  "UPDATE_KETERLIBATAN_MAHASISWA_SUCCESS";
export const UPDATE_KETERLIBATAN_MAHASISWA_FAILURE =
  "UPDATE_KETERLIBATAN_MAHASISWA_FAILURE";

export const DELETE_KETERLIBATAN_MAHASISWA_REQUEST =
  "DELETE_KETERLIBATAN_MAHASISWA_REQUEST";
export const DELETE_KETERLIBATAN_MAHASISWA_SUCCESS =
  "DELETE_KETERLIBATAN_MAHASISWA_SUCCESS";
export const DELETE_KETERLIBATAN_MAHASISWA_FAILURE =
  "DELETE_KETERLIBATAN_MAHASISWA_FAILURE";

export const GET_JUMLAH_MAHASISWA_TERLIBAT_REQUEST =
  "GET_JUMLAH_MAHASISWA_TERLIBAT_REQUEST";
export const GET_JUMLAH_MAHASISWA_TERLIBAT_SUCCESS =
  "GET_JUMLAH_MAHASISWA_TERLIBAT_SUCCESS";
export const GET_JUMLAH_MAHASISWA_TERLIBAT_FAILURE =
  "GET_JUMLAH_MAHASISWA_TERLIBAT_FAILURE";

export const GET_MAHASISWA_REQUEST = "GET_MAHASISWA_REQUEST";
export const GET_MAHASISWA_SUCCESS = "GET_MAHASISWA_SUCCESS";
export const GET_MAHASISWA_FAILURE = "GET_MAHASISWA_FAILURE";
export const CLEAN_MAHASISWA = "CLEAN_MAHASISWA";

export const GET_LIST_NEGARA_REQUEST = "GET_LIST_NEGARA_REQUEST";
export const GET_LIST_NEGARA_SUCCESS = "GET_LIST_NEGARA_SUCCESS";
export const GET_LIST_NEGARA_FAILURE = "GET_LIST_NEGARA_FAILURE";

export const GET_ENUMERATION_REQUEST = "GET_ENUMERATION_REQUEST";
export const GET_ENUMERATION_SUCCESS = "GET_ENUMERATION_SUCCESS";
export const GET_ENUMERATION_FAILURE = "GET_ENUMERATION_FAILURE";

export const GET_RULE_SKEMA_REQUEST = "GET_RULE_SKEMA_REQUEST";
export const GET_RULE_SKEMA_SUCCESS = "GET_RULE_SKEMA_SUCCESS";
export const GET_RULE_SKEMA_FAILURE = "GET_RULE_SKEMA_FAILURE";

export const GET_ELIGIBILITAS_DOSEN_REQUEST = "GET_ELIGIBILITAS_DOSEN_REQUEST";
export const GET_ELIGIBILITAS_DOSEN_SUCCESS = "GET_ELIGIBILITAS_DOSEN_SUCCESS";
export const GET_ELIGIBILITAS_DOSEN_FAILURE = "GET_ELIGIBILITAS_DOSEN_FAILURE";

export const UPDATE_USULAN_PENELITIAN_REQUEST =
  "UPDATE_USULAN_PENELITIAN_REQUEST";
export const UPDATE_USULAN_PENELITIAN_SUCCESS =
  "UPDATE_USULAN_PENELITIAN_SUCCESS";
export const UPDATE_USULAN_PENELITIAN_FAILURE =
  "UPDATE_USULAN_PENELITIAN_FAILURE";

export const SAVE_IDENTITAS_USULAN_REQUEST = "SAVE_IDENTITAS_USULAN_REQUEST";
export const SAVE_IDENTITAS_USULAN_SUCCESS = "SAVE_IDENTITAS_USULAN_SUCCESS";
export const SAVE_IDENTITAS_USULAN_FAILURE = "SAVE_IDENTITAS_USULAN_FAILURE";

export const SAVE_SUBTANSI_USULAN_REQUEST = "SAVE_SUBTANSI_USULAN_REQUEST";
export const SAVE_SUBTANSI_USULAN_SUCCESS = "SAVE_SUBTANSI_USULAN_SUCCESS";
export const SAVE_SUBTANSI_USULAN_FAILURE = "SAVE_SUBTANSI_USULAN_FAILURE";

export const UPDATE_SUBTANSI_USULAN_REQUEST = "UPDATE_SUBTANSI_USULAN_REQUEST";
export const UPDATE_SUBTANSI_USULAN_SUCCESS = "UPDATE_SUBTANSI_USULAN_SUCCESS";
export const UPDATE_SUBTANSI_USULAN_FAILURE = "SAVE_SUBTANSI_USULAN_FAILURE";

export const SAVE_RAB_USULAN_REQUEST = "SAVE_RAB_USULAN_REQUEST";
export const SAVE_RAB_USULAN_SUCCESS = "SAVE_RAB_USULAN_SUCCESS";
export const SAVE_RAB_USULAN_FAILURE = "SAVE_RAB_USULAN_FAILURE";

export const UPDATE_RAB_USULAN_REQUEST = "UPDATE_RAB_USULAN_REQUEST";
export const UPDATE_RAB_USULAN_SUCCESS = "UPDATE_RAB_USULAN_SUCCESS";
export const UPDATE_RAB_USULAN_FAILURE = "SAVE_RAB_USULAN_FAILURE";

export const SAVE_MITRA_USULAN_REQUEST = "SAVE_MITRA_USULAN_REQUEST";
export const SAVE_MITRA_USULAN_SUCCESS = "SAVE_MITRA_USULAN_SUCCESS";
export const SAVE_MITRA_USULAN_FAILURE = "SAVE_MITRA_USULAN_FAILURE";

export const UPDATE_MITRA_USULAN_REQUEST = "UPDATE_MITRA_USULAN_REQUEST";
export const UPDATE_MITRA_USULAN_SUCCESS = "UPDATE_MITRA_USULAN_SUCCESS";
export const UPDATE_MITRA_USULAN_FAILURE = "UPDATE_MITRA_USULAN_FAILURE";

export const VALIDASI_KEANGGOTAAN_DOSEN_REQUEST =
  "VALIDASI_KEANGGOTAAN_DOSEN_REQUEST";
export const VALIDASI_KEANGGOTAAN_DOSEN_SUCCESS =
  "VALIDASI_KEANGGOTAAN_DOSEN_SUCCESS";
export const VALIDASI_KEANGGOTAAN_DOSEN_FAILURE =
  "VALIDASI_KEANGGOTAAN_DOSEN_FAILURE";

export const UPDATE_IDENTITAS_USULAN_REQUEST =
  "UPDATE_IDENTITAS_USULAN_REQUEST";
export const UPDATE_IDENTITAS_USULAN_SUCCESS =
  "UPDATE_RAB_USIDENTITASN_SUCCESS";
export const UPDATE_IDENTITAS_USULAN_FAILURE =
  "UPDATE_RAB_USULAN_IDENTITASLURE";

export const GET_ELIGIBILITY_REQUEST = "GET_ELIGIBILITY_REQUEST";
export const GET_ELIGIBILITY_SUCCESS = "GET_ELIGIBILITY_SUCCESS";
export const GET_ELIGIBILITY_FAILURE = "GET_ELIGIBILITY_FAILURE";

export const GET_LIST_USULAN_PENELITIAN_REQUEST =
  "GET_LIST_USULAN_PENELITIAN_REQUEST";
export const GET_LIST_USULAN_PENELITIAN_SUCCESS =
  "GET_LIST_USULAN_PENELITIAN_SUCCESS";
export const GET_LIST_USULAN_PENELITIAN_FAILURE =
  "GET_LIST_USULAN_PENELITIAN_FAILURE";

export const GET_USULAN_PENELITIAN_BYID_REQUEST =
  "GET_USULAN_PENELITIAN_BYID_REQUEST";
export const GET_USULAN_PENELITIAN_BYID_SUCCESS =
  "GET_USULAN_PENELITIAN_BYID_SUCCESS";
export const GET_USULAN_PENELITIAN_BYID_FAILURE =
  "GET_USULAN_PENELITIAN_BYID_FAILURE";

export const GET_AVAILABLE_SKEMA_REQUEST = "GET_AVAILABLE_SKEMA_REQUEST";
export const GET_AVAILABLE_SKEMA_SUCCESS = "GET_AVAILABLE_SKEMA_SUCCESS";
export const GET_AVAILABLE_SKEMA_FAILURE = "GET_AVAILABLE_SKEMA_FAILURE";

export const GET_TARGET_CAPAIAN_LUARAN_REQUEST =
  "GET_TARGET_CAPAIAN_LUARAN_REQUEST";
export const GET_TARGET_CAPAIAN_LUARAN_SUCCESS =
  "GET_TARGET_CAPAIAN_LUARAN_SUCCESS";
export const GET_TARGET_CAPAIAN_LUARAN_FAILURE =
  "GET_TARGET_CAPAIAN_LUARAN_FAILURE";

// pengabdian start

export const GET_TEMA_TEMATIK_REQUEST = "GET_TEMA_TEMATIK_REQUEST";
export const GET_TEMA_TEMATIK_SUCCESS = "GET_TEMA_TEMATIK_SUCCESS";
export const GET_TEMA_TEMATIK_FAILURE = "GET_TEMA_TEMATIK_FAILURE";

export const GET_LIST_RIRN_REQUEST = "GET_LIST_RIRN_REQUEST";
export const GET_LIST_RIRN_SUCCESS = "GET_LIST_RIRN_SUCCESS";
export const GET_LIST_RIRN_FAILURE = "GET_LIST_RIRN_FAILURE";

export const POST_STEP_1_IDENTITAS_PENGABDIAN_REQUEST =
  "POST_STEP_1_IDENTITAS_PENGABDIAN_REQUEST";
export const POST_STEP_1_IDENTITAS_PENGABDIAN_SUCCESS =
  "POST_STEP_1_IDENTITAS_PENGABDIAN_SUCCESS";
export const POST_STEP_1_IDENTITAS_PENGABDIAN_FAILURE =
  "POST_STEP_1_IDENTITAS_PENGABDIAN_FAILURE";

export const PUT_ALL_STEP_USULAN_PENGABDIAN_REQUEST =
  "PUT_ALL_STEP_USULAN_PENGABDIAN_REQUEST";
export const PUT_ALL_STEP_USULAN_PENGABDIAN_SUCCESS =
  "PUT_ALL_STEP_USULAN_PENGABDIAN_SUCCESS";
export const PUT_ALL_STEP_USULAN_PENGABDIAN_FAILURE =
  "PUT_ALL_STEP_USULAN_PENGABDIAN_FAILURE";

export const GET_LIST_PROVINSI_REQUEST = "GET_LIST_PROVINSI_REQUEST";
export const GET_LIST_PROVINSI_SUCCESS = "GET_LIST_PROVINSI_SUCCESS";
export const GET_LIST_PROVINSI_FAILURE = "GET_LIST_PROVINSI_FAILURE";

export const GET_LIST_KOTA_REQUEST = "GET_LIST_KOTA_REQUEST";
export const GET_LIST_KOTA_SUCCESS = "GET_LIST_KOTA_SUCCESS";
export const GET_LIST_KOTA_FAILURE = "GET_LIST_KOTA_FAILURE";

export const GET_OPTION_MITRA_KELOMPOK_REQUEST =
  "GET_OPTION_MITRA_KELOMPOK_REQUEST";
export const GET_OPTION_MITRA_KELOMPOK_SUCCESS =
  "GET_OPTION_MITRA_KELOMPOK_SUCCESS";
export const GET_OPTION_MITRA_KELOMPOK_FAILURE =
  "GET_OPTION_MITRA_KELOMPOK_FAILURE";

export const GET_OPTION_MITRA_JENIS_MITRA_REQUEST =
  "GET_OPTION_MITRA_JENIS_MITRA_REQUEST";
export const GET_OPTION_MITRA_JENIS_MITRA_SUCCESS =
  "GET_OPTION_MITRA_JENIS_MITRA_SUCCESS";
export const GET_OPTION_MITRA_JENIS_MITRA_FAILURE =
  "GET_OPTION_MITRA_JENIS_MITRA_FAILURE";

export const MHS_SET_ITEM = "MHS_SET_ITEM";
export const MHS_SET_ITEM_SUCCESS = "MHS_SET_ITEM_SUCCESS";
export const MHS_SET_ITEM_FAILURE = "MHS_SET_ITEM_FAILURE";

export const GET_WHITELIST_USULAN_REQUEST = "GET_WHITELIST_USULAN_REQUEST";
export const GET_WHITELIST_USULAN_SUCCESS = "GET_WHITELIST_USULAN_SUCCESS";
export const GET_WHITELIST_USULAN_FAILURE = "GET_WHITELIST_USULAN_FAILURE";

export const GET_LIST_KERJASAMA_LUAR_NEGERI_REQUEST = "GET_LIST_KERJASAMA_LUAR_NEGERI_REQUEST";
export const GET_LIST_KERJASAMA_LUAR_NEGERI_SUCCESS = "GET_LIST_KERJASAMA_LUAR_NEGERI_SUCCESS";
export const GET_LIST_KERJASAMA_LUAR_NEGERI_FAILURE = "GET_LIST_KERJASAMA_LUAR_NEGERI_FAILURE";

export const SAVE_MINAT_KATALIS_REQUEST = 'SAVE_MINAT_KATALIS_REQUEST';
export const SAVE_MINAT_KATALIS_SUCCESS = 'SAVE_MINAT_KATALIS_SUCCESS';
export const SAVE_MINAT_KATALIS_FAILURE = 'SAVE_MINAT_KATALIS_FAILURE';

export const APPROVAL_MEMBER_MINAT_KATALIS_SUCCESS = 'APPROVAL_MEMBER_MINAT_KATALIS_SUCCESS';
export const APPROVAL_MEMBER_MINAT_KATALIS_REQUEST = 'APPROVAL_MEMBER_MINAT_KATALIS_REQUEST';
export const APPROVAL_MEMBER_MINAT_KATALIS_FAILURE = 'APPROVAL_MEMBER_MINAT_KATALIS_FAILURE';

export const APPROVAL_PUSAT_MINAT_KATALIS_SUCCESS = 'APPROVAL_PUSAT_MINAT_KATALIS_SUCCESS';
export const APPROVAL_PUSAT_MINAT_KATALIS_REQUEST = 'APPROVAL_PUSAT_MINAT_KATALIS_REQUEST';
export const APPROVAL_PUSAT_MINAT_KATALIS_FAILURE = 'APPROVAL_MEMBER_MINAT_KATALIS_FAILURE';

export const GET_MINAT_KATALIS_REQUEST = 'GET_MINAT_KATALIS_REQUEST';
export const GET_MINAT_KATALIS_SUCCESS = 'GET_MINAT_KATALIS_SUCCESS';
export const GET_MINAT_KATALIS_FAILURE = 'GET_MINAT_KATALIS_FAILURE';

export const GET_DETAILS_MINAT_KATALIS_REQUEST = 'GET_DETAILS_MINAT_KATALIS_REQUEST';
export const GET_DETAILS_MINAT_KATALIS_SUCCESS = 'GET_DETAILS_MINAT_KATALIS_SUCCESS';
export const GET_DETAILS_MINAT_KATALIS_FAILURE = 'GET_DETAILS_MINAT_KATALIS_FAILURE';

export const DELETE_MINAT_KATALIS_REQUEST = 'DELETE_MINAT_KATALIS_REQUEST';
export const DELETE_MINAT_KATALIS_SUCCESS = 'DELETE_MINAT_KATALIS_SUCCESS';
export const DELETE_MINAT_KATALIS_FAILURE = 'DELETE_MINAT_KATALIS_FAILURE';

export const CLEAN_DETAILS_MINAT_KATALIS = 'CLEAN_DETAILS_MINAT_KATALIS';