import {
  SEARCH_PROPOSAL_E_CATALOG_REQUEST,
  SEARCH_PROPOSAL_E_CATALOG_SUCCESS,
  SEARCH_PROPOSAL_E_CATALOG_FAILURE,
  GET_DETAIL_PROPOSAL_E_CATALOG_REQUEST,
  GET_DETAIL_PROPOSAL_E_CATALOG_SUCCESS,
  GET_DETAIL_PROPOSAL_E_CATALOG_FAILURE,
  GET_REPORT_PROPOSAL_E_CATALOG_REQUEST,
  GET_REPORT_PROPOSAL_E_CATALOG_SUCCESS,
  GET_REPORT_PROPOSAL_E_CATALOG_FAILURE,
  SEARCH_PERGURUAN_TINGGI_E_CATALOG_REQUEST,
  SEARCH_PERGURUAN_TINGGI_E_CATALOG_SUCCESS,
  SEARCH_PERGURUAN_TINGGI_E_CATALOG_FAILURE,
  GET_ALL_TOTAL_PPM_PERGURUAN_TINGGI_E_CATALOG_REQUEST,
  GET_ALL_TOTAL_PPM_PERGURUAN_TINGGI_E_CATALOG_SUCCESS,
  GET_ALL_TOTAL_PPM_PERGURUAN_TINGGI_E_CATALOG_FAILURE,
  GET_LIST_KEYWORD_MANAGEMENT_E_CATALOG_REQUEST,
  GET_LIST_KEYWORD_MANAGEMENT_E_CATALOG_SUCCESS,
  GET_LIST_KEYWORD_MANAGEMENT_E_CATALOG_FAILURE,
  CREATE_KEYWORD_MANAGEMENT_E_CATALOG_REQUEST,
  CREATE_KEYWORD_MANAGEMENT_E_CATALOG_SUCCESS,
  CREATE_KEYWORD_MANAGEMENT_E_CATALOG_FAILURE,
  GET_DETAIL_KEYWORD_MANAGEMENT_E_CATALOG_REQUEST,
  GET_DETAIL_KEYWORD_MANAGEMENT_E_CATALOG_SUCCESS,
  GET_DETAIL_KEYWORD_MANAGEMENT_E_CATALOG_FAILURE,
  DELETE_KEYWORD_MANAGEMENT_E_CATALOG_REQUEST,
  DELETE_KEYWORD_MANAGEMENT_E_CATALOG_SUCCESS,
  DELETE_KEYWORD_MANAGEMENT_E_CATALOG_FAILURE,
  GET_DANA_PPM_BER_BIDANG_FOKUS_E_CATALOG_REQUEST,
  GET_DANA_PPM_BER_BIDANG_FOKUS_E_CATALOG_SUCCESS,
  GET_DANA_PPM_BER_BIDANG_FOKUS_E_CATALOG_FAILURE,
  GET_LIST_PPM_PERGURUAN_TINGGI_E_CATALOG_REQUEST,
  GET_LIST_PPM_PERGURUAN_TINGGI_E_CATALOG_SUCCESS,
  GET_LIST_PPM_PERGURUAN_TINGGI_E_CATALOG_FAILURE,
  GET_DANA_PPM_PER_YEAR_E_CATALOG_REQUEST,
  GET_DANA_PPM_PER_YEAR_E_CATALOG_SUCCESS,
  GET_DANA_PPM_PER_YEAR_E_CATALOG_FAILURE,
  RESET_PROPOSAL_E_CATALOG,
  SEARCH_SPESIFIK_PROPOSAL_E_CATALOG_REQUEST,
  SEARCH_SPESIFIK_PROPOSAL_E_CATALOG_SUCCESS,
  SEARCH_SPESIFIK_PROPOSAL_E_CATALOG_FAILURE,
  GET_REPORT_PPM_E_CATALOG_REQUEST,
  GET_REPORT_PPM_E_CATALOG_SUCCESS,
  GET_REPORT_PPM_E_CATALOG_FAILURE,
  GET_JABATAN_DOSEN_E_CATALOG_REQUEST,
  GET_JABATAN_DOSEN_E_CATALOG_SUCCESS,
  GET_JABATAN_DOSEN_E_CATALOG_FAILURE,
  GET_LIST_DOSEN_E_CATALOG_REQUEST,
  GET_LIST_DOSEN_E_CATALOG_SUCCESS,
  GET_LIST_DOSEN_E_CATALOG_FAILURE,
  GET_LIST_JUMLAH_LUARAN_E_CATALOG_REQUEST,
  GET_LIST_JUMLAH_LUARAN_E_CATALOG_SUCCESS,
  GET_LIST_JUMLAH_LUARAN_E_CATALOG_FAILURE,
  GET_REPORT_LIST_DOSEN_E_CATALOG_REQUEST,
  GET_REPORT_LIST_DOSEN_E_CATALOG_SUCCESS,
  GET_REPORT_LIST_DOSEN_E_CATALOG_FAILURE,
  RESET_PROPOSAL_SPESIFIK_E_CATALOG,
  GET_LIST_BIDANG_FOKUS_E_CATALOG_REQUEST,
  GET_LIST_BIDANG_FOKUS_E_CATALOG_SUCCESS,
  GET_LIST_BIDANG_FOKUS_E_CATALOG_FAILURE,
  GET_DANA_PPM_BIDANG_FOKUS_E_CATALOG_REQUEST,
  GET_DANA_PPM_BIDANG_FOKUS_E_CATALOG_SUCCESS,
  GET_DANA_PPM_BIDANG_FOKUS_E_CATALOG_FAILURE,
  GET_TOTAL_KLASTER_E_CATALOG_REQUEST,
  GET_TOTAL_KLASTER_E_CATALOG_SUCCESS,
  GET_TOTAL_KLASTER_E_CATALOG_FAILURE,
  GET_LIST_PT_BY_KLASTER_E_CATALOG_REQUEST,
  GET_LIST_PT_BY_KLASTER_E_CATALOG_SUCCESS,
  GET_LIST_PT_BY_KLASTER_E_CATALOG_FAILURE,
  REPORT_LIST_PT_BY_KLASTER_E_CATALOG_REQUEST,
  REPORT_LIST_PT_BY_KLASTER_E_CATALOG_SUCCESS,
  REPORT_LIST_PT_BY_KLASTER_E_CATALOG_FAILURE,
  GET_DROPDOWN_KEYWORD_MANAGEMENT_E_CATALOG_REQUEST,
  GET_DROPDOWN_KEYWORD_MANAGEMENT_E_CATALOG_SUCCESS,
  GET_DROPDOWN_KEYWORD_MANAGEMENT_E_CATALOG_FAILURE,
  GET_LIST_BENCHMARK_PPM_E_CATALOG_REQUEST,
  GET_LIST_BENCHMARK_PPM_E_CATALOG_SUCCESS,
  GET_LIST_BENCHMARK_PPM_E_CATALOG_FAILURE,
  GET_DROPDOWN_PERGURUAN_TINGGI_E_CATALOG_REQUEST,
  GET_DROPDOWN_PERGURUAN_TINGGI_E_CATALOG_SUCCESS,
  GET_DROPDOWN_PERGURUAN_TINGGI_E_CATALOG_FAILURE,
  GET_COMPARE_BENCHMARK_E_CATALOG_REQUEST,
  GET_COMPARE_BENCHMARK_E_CATALOG_SUCCESS,
  GET_COMPARE_BENCHMARK_E_CATALOG_FAILURE,
  GET_LLDIKTI_MAPS_E_CATALOG_REQUEST,
  GET_LLDIKTI_MAPS_E_CATALOG_SUCCESS,
  GET_LLDIKTI_MAPS_E_CATALOG_FAILURE,
  EXPORT_PERGURUAN_TINGGI_E_CATALOG_REQUEST,
  EXPORT_PERGURUAN_TINGGI_E_CATALOG_SUCCESS,
  EXPORT_PERGURUAN_TINGGI_E_CATALOG_FAILURE,
  GET_DETAIL_PROPOSAL_FOR_PDF_E_CATALOG_REQUEST,
  GET_DETAIL_PROPOSAL_FOR_PDF_E_CATALOG_SUCCESS,
  GET_DETAIL_PROPOSAL_FOR_PDF_E_CATALOG_FAILURE,
} from "./actionTypes";

export const searchProposalEKatalog = (request = {}, cb = () => {}) => {
  return {
    type: SEARCH_PROPOSAL_E_CATALOG_REQUEST,
    payload: request,
    cb,
  };
};

export const searchProposalEKatalogSuccess = response => {
  return {
    type: SEARCH_PROPOSAL_E_CATALOG_SUCCESS,
    payload: response,
  };
};

export const searchProposalEKatalogFailure = (message, response) => {
  return {
    type: SEARCH_PROPOSAL_E_CATALOG_FAILURE,
    payload: { errors: response, message },
  };
};
//
export const searchSpesifikProposalEKatalog = (request = {}, cb = () => {}) => {
  return {
    type: SEARCH_SPESIFIK_PROPOSAL_E_CATALOG_REQUEST,
    payload: request,
    cb,
  };
};

export const searchSpesifikProposalEKatalogSuccess = response => {
  return {
    type: SEARCH_SPESIFIK_PROPOSAL_E_CATALOG_SUCCESS,
    payload: response,
  };
};

export const searchSpesifikProposalEKatalogFailure = (message, response) => {
  return {
    type: SEARCH_SPESIFIK_PROPOSAL_E_CATALOG_FAILURE,
    payload: { errors: response, message },
  };
};
//
export const resetProposalSpesifikEKatalog = (request = {}, cb = () => {}) => {
  return {
    type: RESET_PROPOSAL_SPESIFIK_E_CATALOG,
    payload: request,
    cb,
  };
};
//
export const resetProposalEKatalog = (request = {}, cb = () => {}) => {
  return {
    type: RESET_PROPOSAL_E_CATALOG,
    payload: request,
    cb,
  };
};
//
export const getReportProposalEKatalog = (request = {}, cb = () => {}) => {
  return {
    type: GET_REPORT_PROPOSAL_E_CATALOG_REQUEST,
    payload: request,
    cb,
  };
};

export const getReportProposalEKatalogSuccess = response => {
  return {
    type: GET_REPORT_PROPOSAL_E_CATALOG_SUCCESS,
    payload: response,
  };
};

export const getReportProposalEKatalogFailure = (message, response) => {
  return {
    type: GET_REPORT_PROPOSAL_E_CATALOG_FAILURE,
    payload: { errors: response, message },
  };
};
//
export const getDetailProposalEKatalog = (request = {}, cb = () => {}) => {
  return {
    type: GET_DETAIL_PROPOSAL_E_CATALOG_REQUEST,
    payload: request,
    cb,
  };
};

export const getDetailProposalEKatalogSuccess = response => {
  return {
    type: GET_DETAIL_PROPOSAL_E_CATALOG_SUCCESS,
    payload: response,
  };
};

export const getDetailProposalEKatalogFailure = (message, response) => {
  return {
    type: GET_DETAIL_PROPOSAL_E_CATALOG_FAILURE,
    payload: { errors: response, message },
  };
};
//
export const searchPerguruanTinggiEKatalog = (request = {}, cb = () => {}) => {
  return {
    type: SEARCH_PERGURUAN_TINGGI_E_CATALOG_REQUEST,
    payload: request,
    cb,
  };
};

export const searchPerguruanTinggiEKatalogSuccess = response => {
  return {
    type: SEARCH_PERGURUAN_TINGGI_E_CATALOG_SUCCESS,
    payload: response,
  };
};

export const searchPerguruanTinggiEKatalogFailure = (message, response) => {
  return {
    type: SEARCH_PERGURUAN_TINGGI_E_CATALOG_FAILURE,
    payload: { errors: response, message },
  };
};
//
export const exportPerguruanTinggiEKatalog = (request = {}, cb = () => {}) => {
  return {
    type: EXPORT_PERGURUAN_TINGGI_E_CATALOG_REQUEST,
    payload: request,
    cb,
  };
};

export const exportPerguruanTinggiEKatalogSuccess = response => {
  return {
    type: EXPORT_PERGURUAN_TINGGI_E_CATALOG_SUCCESS,
    payload: response,
  };
};

export const exportPerguruanTinggiEKatalogFailure = (message, response) => {
  return {
    type: EXPORT_PERGURUAN_TINGGI_E_CATALOG_FAILURE,
    payload: { errors: response, message },
  };
};
//
export const getAllTotalPpmPerguruanTinggiEKatalog = (
  request = {},
  cb = () => {}
) => {
  return {
    type: GET_ALL_TOTAL_PPM_PERGURUAN_TINGGI_E_CATALOG_REQUEST,
    payload: request,
    cb,
  };
};

export const getAllTotalPpmPerguruanTinggiEKatalogSuccess = response => {
  return {
    type: GET_ALL_TOTAL_PPM_PERGURUAN_TINGGI_E_CATALOG_SUCCESS,
    payload: response,
  };
};

export const getAllTotalPpmPerguruanTinggiEKatalogFailure = (
  message,
  response
) => {
  return {
    type: GET_ALL_TOTAL_PPM_PERGURUAN_TINGGI_E_CATALOG_FAILURE,
    payload: { errors: response, message },
  };
};
//
export const getListKeywordManagementEKatalog = (
  request = {},
  cb = () => {}
) => {
  return {
    type: GET_LIST_KEYWORD_MANAGEMENT_E_CATALOG_REQUEST,
    payload: request,
    cb,
  };
};

export const getListKeywordManagementEKatalogSuccess = response => {
  return {
    type: GET_LIST_KEYWORD_MANAGEMENT_E_CATALOG_SUCCESS,
    payload: response,
  };
};

export const getListKeywordManagementEKatalogFailure = (message, response) => {
  return {
    type: GET_LIST_KEYWORD_MANAGEMENT_E_CATALOG_FAILURE,
    payload: { errors: response, message },
  };
};
//
export const getDropdownKeywordManagementEKatalog = (
  request = {},
  cb = () => {}
) => {
  return {
    type: GET_DROPDOWN_KEYWORD_MANAGEMENT_E_CATALOG_REQUEST,
    payload: request,
    cb,
  };
};

export const getDropdownKeywordManagementEKatalogSuccess = response => {
  return {
    type: GET_DROPDOWN_KEYWORD_MANAGEMENT_E_CATALOG_SUCCESS,
    payload: response,
  };
};

export const getDropdownKeywordManagementEKatalogFailure = (
  message,
  response
) => {
  return {
    type: GET_DROPDOWN_KEYWORD_MANAGEMENT_E_CATALOG_FAILURE,
    payload: { errors: response, message },
  };
};
//
export const createKeywordManagementEKatalog = (
  request = {},
  cb = () => {}
) => {
  return {
    type: CREATE_KEYWORD_MANAGEMENT_E_CATALOG_REQUEST,
    payload: request,
    cb,
  };
};

export const createKeywordManagementEKatalogSuccess = response => {
  return {
    type: CREATE_KEYWORD_MANAGEMENT_E_CATALOG_SUCCESS,
    payload: response,
  };
};

export const createKeywordManagementEKatalogFailure = (message, response) => {
  return {
    type: CREATE_KEYWORD_MANAGEMENT_E_CATALOG_FAILURE,
    payload: { errors: response, message },
  };
};
//
export const getDetailKeywordManagementEKatalog = (
  request = {},
  cb = () => {}
) => {
  return {
    type: GET_DETAIL_KEYWORD_MANAGEMENT_E_CATALOG_REQUEST,
    payload: request,
    cb,
  };
};

export const getDetailKeywordManagementEKatalogSuccess = response => {
  return {
    type: GET_DETAIL_KEYWORD_MANAGEMENT_E_CATALOG_SUCCESS,
    payload: response,
  };
};

export const getDetailKeywordManagementEKatalogFailure = (
  message,
  response
) => {
  return {
    type: GET_DETAIL_KEYWORD_MANAGEMENT_E_CATALOG_FAILURE,
    payload: { errors: response, message },
  };
};
//
export const deleteKeywordManagementEKatalog = (
  request = {},
  cb = () => {}
) => {
  return {
    type: DELETE_KEYWORD_MANAGEMENT_E_CATALOG_REQUEST,
    payload: request,
    cb,
  };
};

export const deleteKeywordManagementEKatalogSuccess = response => {
  return {
    type: DELETE_KEYWORD_MANAGEMENT_E_CATALOG_SUCCESS,
    payload: response,
  };
};

export const deleteKeywordManagementEKatalogFailure = (message, response) => {
  return {
    type: DELETE_KEYWORD_MANAGEMENT_E_CATALOG_FAILURE,
    payload: { errors: response, message },
  };
};
//
export const getDanaPpmPerBidangFokusEKatalog = (
  request = {},
  cb = () => {}
) => {
  return {
    type: GET_DANA_PPM_BER_BIDANG_FOKUS_E_CATALOG_REQUEST,
    payload: request,
    cb,
  };
};

export const getDanaPpmPerBidangFokusEKatalogSuccess = response => {
  return {
    type: GET_DANA_PPM_BER_BIDANG_FOKUS_E_CATALOG_SUCCESS,
    payload: response,
  };
};

export const getDanaPpmPerBidangFokusEKatalogFailure = (message, response) => {
  return {
    type: GET_DANA_PPM_BER_BIDANG_FOKUS_E_CATALOG_FAILURE,
    payload: { errors: response, message },
  };
};
//
export const getListPpmPerguruanTinggiEKatalog = (
  request = {},
  cb = () => {}
) => {
  return {
    type: GET_LIST_PPM_PERGURUAN_TINGGI_E_CATALOG_REQUEST,
    payload: request,
    cb,
  };
};

export const getListPpmPerguruanTinggiEKatalogSuccess = response => {
  return {
    type: GET_LIST_PPM_PERGURUAN_TINGGI_E_CATALOG_SUCCESS,
    payload: response,
  };
};

export const getListPpmPerguruanTinggiEKatalogFailure = (message, response) => {
  return {
    type: GET_LIST_PPM_PERGURUAN_TINGGI_E_CATALOG_FAILURE,
    payload: { errors: response, message },
  };
};
//
export const getDataPpmPerYearEKatalog = (request = {}, cb = () => {}) => {
  return {
    type: GET_DANA_PPM_PER_YEAR_E_CATALOG_REQUEST,
    payload: request,
    cb,
  };
};

export const getDataPpmPerYearEKatalogSuccess = response => {
  return {
    type: GET_DANA_PPM_PER_YEAR_E_CATALOG_SUCCESS,
    payload: response,
  };
};

export const getDataPpmPerYearEKatalogFailure = (message, response) => {
  return {
    type: GET_DANA_PPM_PER_YEAR_E_CATALOG_FAILURE,
    payload: { errors: response, message },
  };
};
//
export const getReportPpmEKatalog = (request = {}, cb = () => {}) => {
  return {
    type: GET_REPORT_PPM_E_CATALOG_REQUEST,
    payload: request,
    cb,
  };
};

export const getReportPpmEKatalogSuccess = response => {
  return {
    type: GET_REPORT_PPM_E_CATALOG_SUCCESS,
    payload: response,
  };
};

export const getReportPpmEKatalogFailure = (message, response) => {
  return {
    type: GET_REPORT_PPM_E_CATALOG_FAILURE,
    payload: { errors: response, message },
  };
};
//
export const getJabatanDosenEKatalog = (request = {}, cb = () => {}) => {
  return {
    type: GET_JABATAN_DOSEN_E_CATALOG_REQUEST,
    payload: request,
    cb,
  };
};

export const getJabatanDosenEKatalogSuccess = response => {
  return {
    type: GET_JABATAN_DOSEN_E_CATALOG_SUCCESS,
    payload: response,
  };
};

export const getJabatanDosenEKatalogFailure = (message, response) => {
  return {
    type: GET_JABATAN_DOSEN_E_CATALOG_FAILURE,
    payload: { errors: response, message },
  };
};
//
export const getListDosenEKatalog = (request = {}, cb = () => {}) => {
  return {
    type: GET_LIST_DOSEN_E_CATALOG_REQUEST,
    payload: request,
    cb,
  };
};

export const getListDosenEKatalogSuccess = response => {
  return {
    type: GET_LIST_DOSEN_E_CATALOG_SUCCESS,
    payload: response,
  };
};

export const getListDosenEKatalogFailure = (message, response) => {
  return {
    type: GET_LIST_DOSEN_E_CATALOG_FAILURE,
    payload: { errors: response, message },
  };
};
//
export const getReportListDosenEKatalog = (request = {}, cb = () => {}) => {
  return {
    type: GET_REPORT_LIST_DOSEN_E_CATALOG_REQUEST,
    payload: request,
    cb,
  };
};

export const getReportListDosenEKatalogSuccess = response => {
  return {
    type: GET_REPORT_LIST_DOSEN_E_CATALOG_SUCCESS,
    payload: response,
  };
};

export const getReportListDosenEKatalogFailure = (message, response) => {
  return {
    type: GET_REPORT_LIST_DOSEN_E_CATALOG_FAILURE,
    payload: { errors: response, message },
  };
};
//
export const getListJumlahLuaranEKatalog = (request = {}, cb = () => {}) => {
  return {
    type: GET_LIST_JUMLAH_LUARAN_E_CATALOG_REQUEST,
    payload: request,
    cb,
  };
};

export const getListJumlahLuaranEKatalogSuccess = response => {
  return {
    type: GET_LIST_JUMLAH_LUARAN_E_CATALOG_SUCCESS,
    payload: response,
  };
};

export const getListJumlahLuaranEKatalogFailure = (message, response) => {
  return {
    type: GET_LIST_JUMLAH_LUARAN_E_CATALOG_FAILURE,
    payload: { errors: response, message },
  };
};
//
export const getListBidangFokusECatalog = (request = {}, cb = () => {}) => {
  return {
    type: GET_LIST_BIDANG_FOKUS_E_CATALOG_REQUEST,
    payload: request,
    cb,
  };
};

export const getListBidangFokusECatalogSuccess = response => {
  return {
    type: GET_LIST_BIDANG_FOKUS_E_CATALOG_SUCCESS,
    payload: response,
  };
};

export const getListBidangFokusECatalogFailure = (message, response) => {
  return {
    type: GET_LIST_BIDANG_FOKUS_E_CATALOG_FAILURE,
    payload: { errors: response, message },
  };
};
//
export const getDanaPpmBidangFokusECatalog = (request = {}, cb = () => {}) => {
  return {
    type: GET_DANA_PPM_BIDANG_FOKUS_E_CATALOG_REQUEST,
    payload: request,
    cb,
  };
};

export const getDanaPpmBidangFokusECatalogSuccess = response => {
  return {
    type: GET_DANA_PPM_BIDANG_FOKUS_E_CATALOG_SUCCESS,
    payload: response,
  };
};

export const getDanaPpmBidangFokusECatalogFailure = (message, response) => {
  return {
    type: GET_DANA_PPM_BIDANG_FOKUS_E_CATALOG_FAILURE,
    payload: { errors: response, message },
  };
};
//
export const getTotalKlasterECatalog = (request = {}, cb = () => {}) => {
  return {
    type: GET_TOTAL_KLASTER_E_CATALOG_REQUEST,
    payload: request,
    cb,
  };
};

export const getTotalKlasterECatalogSuccess = response => {
  return {
    type: GET_TOTAL_KLASTER_E_CATALOG_SUCCESS,
    payload: response,
  };
};

export const getTotalKlasterECatalogFailure = (message, response) => {
  return {
    type: GET_TOTAL_KLASTER_E_CATALOG_FAILURE,
    payload: { errors: response, message },
  };
};

//
export const getListPtByKlasterECatalog = (request = {}, cb = () => {}) => {
  return {
    type: GET_LIST_PT_BY_KLASTER_E_CATALOG_REQUEST,
    payload: request,
    cb,
  };
};

export const getListPtByKlasterECatalogSuccess = response => {
  return {
    type: GET_LIST_PT_BY_KLASTER_E_CATALOG_SUCCESS,
    payload: response,
  };
};

export const getListPtByKlasterECatalogFailure = (message, response) => {
  return {
    type: GET_LIST_PT_BY_KLASTER_E_CATALOG_FAILURE,
    payload: { errors: response, message },
  };
};
//
export const reportListPtByKlasterECatalog = (request = {}, cb = () => {}) => {
  return {
    type: REPORT_LIST_PT_BY_KLASTER_E_CATALOG_REQUEST,
    payload: request,
    cb,
  };
};

export const reportListPtByKlasterECatalogSuccess = response => {
  return {
    type: REPORT_LIST_PT_BY_KLASTER_E_CATALOG_SUCCESS,
    payload: response,
  };
};

export const reportListPtByKlasterECatalogFailure = (message, response) => {
  return {
    type: REPORT_LIST_PT_BY_KLASTER_E_CATALOG_FAILURE,
    payload: { errors: response, message },
  };
};
//
export const getListBenchmarkPpmECatalog = (request = {}, cb = () => {}) => {
  return {
    type: GET_LIST_BENCHMARK_PPM_E_CATALOG_REQUEST,
    payload: request,
    cb,
  };
};

export const getListBenchmarkPpmECatalogSuccess = response => {
  return {
    type: GET_LIST_BENCHMARK_PPM_E_CATALOG_SUCCESS,
    payload: response,
  };
};

export const getListBenchmarkPpmECatalogFailure = (message, response) => {
  return {
    type: GET_LIST_BENCHMARK_PPM_E_CATALOG_FAILURE,
    payload: { errors: response, message },
  };
};
//
export const getCompareBenchmarkECatalog = (request = {}, cb = () => {}) => {
  return {
    type: GET_COMPARE_BENCHMARK_E_CATALOG_REQUEST,
    payload: request,
    cb,
  };
};

export const getCompareBenchmarkECatalogSuccess = response => {
  return {
    type: GET_COMPARE_BENCHMARK_E_CATALOG_SUCCESS,
    payload: response,
  };
};

export const getCompareBenchmarkECatalogFailure = (message, response) => {
  return {
    type: GET_COMPARE_BENCHMARK_E_CATALOG_FAILURE,
    payload: { errors: response, message },
  };
};
//
export const getDropdownPerguruanTinggiECatalog = (
  request = {},
  cb = () => {}
) => {
  return {
    type: GET_DROPDOWN_PERGURUAN_TINGGI_E_CATALOG_REQUEST,
    payload: request,
    cb,
  };
};

export const getDropdownPerguruanTinggiECatalogSuccess = response => {
  return {
    type: GET_DROPDOWN_PERGURUAN_TINGGI_E_CATALOG_SUCCESS,
    payload: response,
  };
};

export const getDropdownPerguruanTinggiECatalogFailure = (
  message,
  response
) => {
  return {
    type: GET_DROPDOWN_PERGURUAN_TINGGI_E_CATALOG_FAILURE,
    payload: { errors: response, message },
  };
};
//
export const getLldiktiMapsECatalog = (request = {}, cb = () => {}) => {
  return {
    type: GET_LLDIKTI_MAPS_E_CATALOG_REQUEST,
    payload: request,
    cb,
  };
};

export const getLldiktiMapsECatalogSuccess = response => {
  return {
    type: GET_LLDIKTI_MAPS_E_CATALOG_SUCCESS,
    payload: response,
  };
};

export const getLldiktiMapsECatalogFailure = (message, response) => {
  return {
    type: GET_LLDIKTI_MAPS_E_CATALOG_FAILURE,
    payload: { errors: response, message },
  };
};
//
export const getDetailProposalForPdfECatalog = (
  request = {},
  cb = () => {}
) => {
  return {
    type: GET_DETAIL_PROPOSAL_FOR_PDF_E_CATALOG_REQUEST,
    payload: request,
    cb,
  };
};

export const getDetailProposalForPdfECatalogSuccess = response => {
  return {
    type: GET_DETAIL_PROPOSAL_FOR_PDF_E_CATALOG_SUCCESS,
    payload: response,
  };
};

export const getDetailProposalForPdfECatalogFailure = (message, response) => {
  return {
    type: GET_DETAIL_PROPOSAL_FOR_PDF_E_CATALOG_FAILURE,
    payload: { errors: response, message },
  };
};
