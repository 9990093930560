import {
  CREATE_LAPORAN_AKHIR_FAILURE,
  CREATE_LAPORAN_AKHIR_LUARAN_AKTUAL_FAILURE,
  CREATE_LAPORAN_AKHIR_LUARAN_AKTUAL_REQUEST,
  CREATE_LAPORAN_AKHIR_LUARAN_AKTUAL_SUCCESS,
  CREATE_LAPORAN_AKHIR_REQUEST,
  CREATE_LAPORAN_AKHIR_SUCCESS,
  GET_DETAIL_LAPORAN_AKHIR_FAILURE,
  GET_DETAIL_LAPORAN_AKHIR_LUARAN_AKTUAL_FAILURE,
  GET_DETAIL_LAPORAN_AKHIR_LUARAN_AKTUAL_REQUEST,
  GET_DETAIL_LAPORAN_AKHIR_LUARAN_AKTUAL_SUCCESS,
  GET_DETAIL_LAPORAN_AKHIR_REQUEST,
  GET_DETAIL_LAPORAN_AKHIR_SUCCESS,
  GET_DETAIL_LAPORAN_AKHIR_V2_FAILURE,
  GET_DETAIL_LAPORAN_AKHIR_V2_REQUEST,
  GET_DETAIL_LAPORAN_AKHIR_V2_SUCCESS,
  GET_DOKUMEN_LUARAN_LAPORAN_AKHIR_FAILURE,
  GET_DOKUMEN_LUARAN_LAPORAN_AKHIR_REQUEST,
  GET_DOKUMEN_LUARAN_LAPORAN_AKHIR_SUCCESS,
  GET_FORM_MITRA_DETAIL_LAPORAN_AKHIR_PENGABDIAN_FAILURE,
  GET_FORM_MITRA_DETAIL_LAPORAN_AKHIR_PENGABDIAN_REQUEST,
  GET_FORM_MITRA_DETAIL_LAPORAN_AKHIR_PENGABDIAN_SUCCESS,
  GET_LIST_LAPORAN_AKHIR_FAILURE,
  GET_LIST_LAPORAN_AKHIR_REQUEST,
  GET_LIST_LAPORAN_AKHIR_SUCCESS,
  GET_LIST_LAPORAN_AKHIR_V2_FAILURE,
  GET_LIST_LAPORAN_AKHIR_V2_REQUEST,
  GET_LIST_LAPORAN_AKHIR_V2_SUCCESS,
  GET_SPTB_LAPORAN_AKHIR_FAILURE,
  GET_SPTB_LAPORAN_AKHIR_REQUEST,
  GET_SPTB_LAPORAN_AKHIR_SUCCESS,
  POST_FORM_MITRA_LAPORAN_AKHIR_PENGABDIAN_FAILURE,
  POST_FORM_MITRA_LAPORAN_AKHIR_PENGABDIAN_REQUEST,
  POST_FORM_MITRA_LAPORAN_AKHIR_PENGABDIAN_SUCCESS,
  PUT_FORM_MITRA_LAPORAN_AKHIR_PENGABDIAN_FAILURE,
  PUT_FORM_MITRA_LAPORAN_AKHIR_PENGABDIAN_REQUEST,
  PUT_FORM_MITRA_LAPORAN_AKHIR_PENGABDIAN_SUCCESS,
  UPDATE_LAPORAN_AKHIR_V2_FAILURE,
  UPDATE_LAPORAN_AKHIR_V2_REQUEST,
  UPDATE_LAPORAN_AKHIR_V2_SUCCESS,
  UPLOAD_DOKUMEN_LAPORAN_AKHIR_FAILURE,
  UPLOAD_DOKUMEN_LAPORAN_AKHIR_REQUEST,
  UPLOAD_DOKUMEN_LAPORAN_AKHIR_SUCCESS,
  UPLOAD_DOKUMEN_SPTB_LAPORAN_AKHIR_FAILURE,
  UPLOAD_DOKUMEN_SPTB_LAPORAN_AKHIR_REQUEST,
  UPLOAD_DOKUMEN_SPTB_LAPORAN_AKHIR_SUCCESS,
} from "./actionTypes";

// get dokumen luaran
export const getDokumenLuaranLaporanAkhir = (request = "") => {
  return {
    type: GET_DOKUMEN_LUARAN_LAPORAN_AKHIR_REQUEST,
    payload: request,
  };
};

export const getDokumenLuaranLaporanAkhirSuccess = response => {
  return {
    type: GET_DOKUMEN_LUARAN_LAPORAN_AKHIR_SUCCESS,
    payload: response,
  };
};

export const getDokumenLuaranLaporanAkhirFailure = (message, response) => {
  return {
    type: GET_DOKUMEN_LUARAN_LAPORAN_AKHIR_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};
// upload dokumen laporan akhir
export const uploadDokumenLaporanAkhir = (request = {}, cb = () => {}) => {
  return {
    type: UPLOAD_DOKUMEN_LAPORAN_AKHIR_REQUEST,
    payload: request,
    cb,
  };
};

export const uploadDokumenLaporanAkhirSuccess = response => {
  return {
    type: UPLOAD_DOKUMEN_LAPORAN_AKHIR_SUCCESS,
    payload: response,
  };
};

export const uploadDokumenLaporanAkhirFailure = (message, response) => {
  return {
    type: UPLOAD_DOKUMEN_LAPORAN_AKHIR_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};
// UPLOAD DOCS SPTB & PENGUNAAN ANGGARAN
export const uploadDokumenSptbLaporanAkhir = (request = {}, cb = () => {}) => {
  return {
    type: UPLOAD_DOKUMEN_SPTB_LAPORAN_AKHIR_REQUEST,
    payload: request,
    cb,
  };
};

export const uploadDokumenSptbLaporanAkhirSuccess = response => {
  return {
    type: UPLOAD_DOKUMEN_SPTB_LAPORAN_AKHIR_SUCCESS,
    payload: response,
  };
};

export const uploadDokumenSptbLaporanAkhirFailure = (message, response) => {
  return {
    type: UPLOAD_DOKUMEN_SPTB_LAPORAN_AKHIR_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};
// get sptb laporan akhir
export const getSptbLaporanAkhir = (request = {}) => {
  return {
    type: GET_SPTB_LAPORAN_AKHIR_REQUEST,
    payload: request,
  };
};

export const getSptbLaporanAkhirSuccess = response => {
  return {
    type: GET_SPTB_LAPORAN_AKHIR_SUCCESS,
    payload: response,
  };
};

export const getSptbLaporanAkhirFailure = (message, response) => {
  return {
    type: GET_SPTB_LAPORAN_AKHIR_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};
// get luaran aktual laporan akhir
export const getLaporanAkhirLuaranAktual = (request = {}, cb = () => {}) => {
  return {
    type: GET_DETAIL_LAPORAN_AKHIR_LUARAN_AKTUAL_REQUEST,
    payload: request,
    cb,
  };
};

export const getLaporanAkhirLuaranAktualSuccess = response => {
  return {
    type: GET_DETAIL_LAPORAN_AKHIR_LUARAN_AKTUAL_SUCCESS,
    payload: response,
  };
};

export const getLaporanAkhirLuaranAktualFailure = (message, response) => {
  return {
    type: GET_DETAIL_LAPORAN_AKHIR_LUARAN_AKTUAL_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};
export const createLaporanAkhirLuaranAktual = (request = {}, cb = () => {}) => {
  return {
    type: CREATE_LAPORAN_AKHIR_LUARAN_AKTUAL_REQUEST,
    payload: request,
    cb,
  };
};

export const createLaporanAkhirLuaranAktualSuccess = response => {
  return {
    type: CREATE_LAPORAN_AKHIR_LUARAN_AKTUAL_SUCCESS,
    payload: response,
  };
};

export const createLaporanAkhirLuaranAktualFailure = (message, response) => {
  return {
    type: CREATE_LAPORAN_AKHIR_LUARAN_AKTUAL_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};
//laporan akhir list
export const getListLaporanAkhir = (request = {}) => {
  return {
    type: GET_LIST_LAPORAN_AKHIR_REQUEST,
    payload: request,
  };
};

export const getListLaporanAkhirSuccess = response => {
  return {
    type: GET_LIST_LAPORAN_AKHIR_SUCCESS,
    payload: response,
  };
};

export const getListLaporanAkhirFailure = (message, response) => {
  return {
    type: GET_LIST_LAPORAN_AKHIR_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};
// laporan akhir detail
export const getDetailLaporanAkhir = (request = {}, cb = () => {}) => {
  return {
    type: GET_DETAIL_LAPORAN_AKHIR_REQUEST,
    payload: request,
    cb,
  };
};

export const getDetailLaporanAkhirSuccess = response => {
  return {
    type: GET_DETAIL_LAPORAN_AKHIR_SUCCESS,
    payload: response,
  };
};

export const getDetailLaporanAkhirFailure = (message, response) => {
  return {
    type: GET_DETAIL_LAPORAN_AKHIR_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};
//create laporan akhir
export const createLaporanAkhir = (request = {}, cb = () => {}) => {
  return {
    type: CREATE_LAPORAN_AKHIR_REQUEST,
    payload: request,
    cb,
  };
};

export const createLaporanAkhirSuccess = response => {
  return {
    type: CREATE_LAPORAN_AKHIR_SUCCESS,
    payload: response,
  };
};

export const createLaporanAkhirFailure = (message, response) => {
  return {
    type: CREATE_LAPORAN_AKHIR_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

// form mitra detail pengabdian laporan akhir
export const getFormMitraDetailLaporanAkhirPengabdian = (request = {}) => {
  return {
    type: GET_FORM_MITRA_DETAIL_LAPORAN_AKHIR_PENGABDIAN_REQUEST,
    payload: request,
  };
};

export const getFormMitraDetailLaporanAkhirPengabdianSuccess = response => {
  return {
    type: GET_FORM_MITRA_DETAIL_LAPORAN_AKHIR_PENGABDIAN_SUCCESS,
    payload: response,
  };
};

export const getFormMitraDetailLaporanAkhirPengabdianFailure = (
  message,
  response
) => {
  return {
    type: GET_FORM_MITRA_DETAIL_LAPORAN_AKHIR_PENGABDIAN_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

export const postFormMitraLaporanAkhirPengabdian = (
  request = {},
  cb = () => {}
) => {
  return {
    type: POST_FORM_MITRA_LAPORAN_AKHIR_PENGABDIAN_REQUEST,
    payload: request,
    cb,
  };
};

export const postFormMitraLaporanAkhirPengabdianSuccess = response => {
  return {
    type: POST_FORM_MITRA_LAPORAN_AKHIR_PENGABDIAN_SUCCESS,
    payload: response,
  };
};

export const postFormMitraLaporanAkhirPengabdianFailure = (
  message,
  response
) => {
  return {
    type: POST_FORM_MITRA_LAPORAN_AKHIR_PENGABDIAN_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

export const putFormMitraLaporanAkhirPengabdian = (
  request = {},
  cb = () => {}
) => {
  return {
    type: PUT_FORM_MITRA_LAPORAN_AKHIR_PENGABDIAN_REQUEST,
    payload: request,
    cb,
  };
};

export const putFormMitraLaporanAkhirPengabdianSuccess = response => {
  return {
    type: PUT_FORM_MITRA_LAPORAN_AKHIR_PENGABDIAN_SUCCESS,
    payload: response,
  };
};

export const putFormMitraLaporanAkhirPengabdianFailure = (
  message,
  response
) => {
  return {
    type: PUT_FORM_MITRA_LAPORAN_AKHIR_PENGABDIAN_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

//laporan akhir v2 by Bagus Fatwan Alfiat
export const getListLaporanAkhirV2 = (request = {}, cb = () => {}) => {
  return {
    type: GET_LIST_LAPORAN_AKHIR_V2_REQUEST,
    payload: request,
    cb,
  };
};

export const getListLaporanAkhirV2Success = response => {
  return {
    type: GET_LIST_LAPORAN_AKHIR_V2_SUCCESS,
    payload: response,
  };
};

export const getListLaporanAkhirV2Failure = (message, response) => {
  return {
    type: GET_LIST_LAPORAN_AKHIR_V2_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

export const getDetailLaporanAkhirV2 = (request = {}, cb = () => {}) => {
  return {
    type: GET_DETAIL_LAPORAN_AKHIR_V2_REQUEST,
    payload: request,
    cb,
  };
};

export const getDetailLaporanAkhirV2Success = response => {
  return {
    type: GET_DETAIL_LAPORAN_AKHIR_V2_SUCCESS,
    payload: response,
  };
};

export const getDetailLaporanAkhirV2Failure = (message, response) => {
  return {
    type: GET_DETAIL_LAPORAN_AKHIR_V2_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};
export const updateLaporanAkhirV2 = (request = {}, cb = () => {}) => {
  return {
    type: UPDATE_LAPORAN_AKHIR_V2_REQUEST,
    payload: request,
    cb,
  };
};

export const updateLaporanAkhirV2Success = response => {
  return {
    type: UPDATE_LAPORAN_AKHIR_V2_SUCCESS,
    payload: response,
  };
};

export const updateLaporanAkhirV2Failure = (message, response) => {
  return {
    type: UPDATE_LAPORAN_AKHIR_V2_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};
