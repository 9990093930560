/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import stylePDF from "./styles";
import header from "./header";
import utilities from "helpers/utilities";
import { KELOMPOK_RAB_KOSABANGSA } from "constants/kosabangsa-2024";

const buildTblIdentitasProposal = (data = {}) => {
  const layout = {
    style: ["mb-10"],
    layout: {
      hLineWidth: (_i, _node) => 0.5,
      vLineWidth: _i => 0.5,
      padding: () => 0,
    },
    table: {
      widths: ["35%", "15%", "15%", "35%"],
      body: [
        [
          {
            text: `Bidang Fokus Kosabangsa`,
            style: ["center"],
          },
          {
            text: `Tahun Pelaksanaan`,
            style: ["center"],
          },
          {
            text: `Durasi`,
            style: ["center"],
          },
          {
            text: `Program`,
            style: ["center"],
          },
        ],
      ],
    },
  };

  layout.table.body.push([
    {
      text: data.nama_bidang_fokus,
      style: ["center"],
    },
    {
      text: data.tahun_pelaksanaan,
      style: ["center"],
    },
    {
      text: data.lama_kegiatan + "  Tahun",
      style: ["center"],
    },
    {
      text: "Kosabangsa",
      style: ["center"],
    },
  ]);

  return layout;
};

const buildTblAnggota = (personil = []) => {
  console.log({ personil });
  const layout = {
    layout: {
      hLineWidth: (_i, _node) => 0.3,
      vLineWidth: _i => 0.3,
      padding: () => 0,
    },
    table: {
      headerRows: 1,
      widths: ["25%", "30%", "35%", "10%"],
      body: [
        [
          {
            text: `Nama, Peran, Institusi`,
            style: ["center"],
          },

          {
            text: `Rumpun Ilmu Level 2`,
            style: ["center"],
          },
          {
            text: `Bidang Tugas`,
            style: ["center"],
          },
          {
            text: `ID Sinta`,
            style: ["center"],
          },
        ],
      ],
    },
  };

  personil
    .sort((a, b) => (a.urutan > b.urutan ? 1 : b.urutan > a.urutan ? -1 : 0))
    .forEach(item => {
      layout.table.body.push([
        {
          text: `${item.nama || item?.raw?.nama} \n\n\n ${
            item.nama_peran || "Anggota"
          } \n\n ${item?.raw?.nama_institusi || "-"}`,
          style: ["center"],
        },
        {
          text: `${item?.raw?.nama_rumpun_ilmu_lvl_2 || "-"}`,
          style: ["center"],
        },
        {
          text: `${item?.uraian_tugas || item?.raw?.uraian_tugas || "-"}`,
        },
        {
          text: `${item?.id_sinta || ""}`,
          link: `${
            item?.id_sinta
              ? `https://sinta.kemdikbud.go.id/authors/profile/${item?.id_sinta}`
              : "#"
          }`,
          style: ["link", "center"],
        },
      ]);
    });

  return layout;
};

const buildTblAnggotaMhs = (personil = []) => {
  const layout = {
    layout: {
      hLineWidth: (_i, _node) => 0.3,
      vLineWidth: _i => 0.3,
      padding: () => 0,
    },
    table: {
      headerRows: 1,
      widths: ["20%", "20%", "15%", "20%", "25%"],
      body: [
        [
          {
            text: `Nama`,
            style: ["center"],
          },

          {
            text: `Institusi`,
            style: ["center"],
          },
          {
            text: `Jumlah SKS`,
            style: ["center"],
          },
          {
            text: `Mata Kuliah`,
            style: ["center"],
          },
          {
            text: `Tugas`,
            style: ["center"],
          },
        ],
      ],
    },
  };

  personil
    .sort((a, b) =>
      a.raw.urutan > b.raw.urutan ? 1 : b.raw.urutan > a.raw.urutan ? -1 : 0
    )
    .forEach(item => {
      layout.table.body.push([
        {
          text: `${item?.raw?.nama}`,
          style: ["center"],
        },
        {
          text: item?.raw?.institusi || "-",
          style: ["center"],
        },
        {
          text: `${item?.raw?.jumlah_sks || "-"}`,
          style: ["center"],
        },
        {
          text: `${item?.raw?.matakuliah || "-"}`,
          style: ["center"],
        },
        {
          text: `${item?.raw?.uraian_tugas || "-"}`,
        },
      ]);
    });

  return layout;
};
const buildTblIKU = (iku = []) => {
  const layout = {
    layout: {
      hLineWidth: (_i, _node) => 0.3,
      vLineWidth: _i => 0.3,
      padding: () => 0,
    },
    table: {
      headerRows: 1,
      widths: ["33.3%", "33.3%", "33.3%"],
      body: [
        [
          {
            text: `Indikator IKU`,
            style: ["center"],
          },
          {
            text: `Uraian IKU`,
            style: ["center"],
          },
          {
            text: `Uraian Kegiatan`,
            style: ["center"],
          },
        ],
      ],
    },
  };
  iku.forEach(item => {
    layout.table.body.push([
      {
        text: `${item.indikator_iku}`,
      },
      {
        text: `${item.uraian_iku}`,
      },
      {
        text: `${item.uraian_kegiatan}`,
      },
    ]);
  });
  return layout;
};
const buildTblSDGS = (sdgs = []) => {
  const layout = {
    layout: {
      hLineWidth: (_i, _node) => 0.3,
      vLineWidth: _i => 0.3,
      padding: () => 0,
    },
    table: {
      headerRows: 1,
      widths: ["33.3%", "33.3%", "33.3%"],
      body: [
        [
          {
            text: `Indikator Keberhasilan`,
            style: ["center"],
          },
          {
            text: `Goal`,
            style: ["center"],
          },
          {
            text: `Uraian Kegiatan`,
            style: ["center"],
          },
        ],
      ],
    },
  };
  sdgs.forEach(item => {
    layout.table.body.push([
      {
        text: `${item.indikator_keberhasilan}`,
      },
      {
        text: `${item.sdgs_goal}`,
      },
      {
        text: `${item.uraian_kegiatan}`,
      },
    ]);
  });
  return layout;
};
const buildBoxArea = text => {
  return {
    style: ["text", "mb-10"],
    layout: {
      hLineWidth: (_i, _node) => 0.3,
      vLineWidth: _i => 0.3,
      padding: () => 1,
    },
    table: {
      widths: ["*"],
      body: [
        [
          {
            text,
          },
        ],
      ],
    },
  };
};

const getThnAkhirUsulan = (lamaKegiatan, thnPertamaUsulan) => {
  let thnAkhirUsulan = parseInt(thnPertamaUsulan);
  for (let tahun = 1; tahun < parseInt(lamaKegiatan); tahun++) {
    thnAkhirUsulan += tahun;
  }
  return thnAkhirUsulan;
};

const buildTblLuaran = (luaran = []) => {
  const layout = {
    style: ["center"],
    layout: {
      hLineWidth: (_i, _node) => 0.3,
      vLineWidth: _i => 0.3,
      padding: () => 1,
    },
    table: {
      widths: ["5%", "24%", "28%", "18%", "25%"],
      body: [
        [
          {
            text: "No.",
            style: ["center"],
          },
          {
            text: "Kategori Luaran",
            style: ["center"],
          },
          {
            text: "Jenis Luaran",
            style: ["center"],
          },
          {
            text: "Status target capaian",
            style: ["center"],
          },
          {
            text: "Keterangan",
          },
        ],
      ],
    },
  };
  luaran.forEach((item, i) => {
    layout.table.body.push([
      {
        text: i + 1,
      },
      {
        text: item.raw?.nama_kategori_jenis_luaran,
      },
      {
        text: item.raw?.jenis_luaran,
      },
      {
        text: item.raw?.status_capaian,
      },
      {
        text: item?.keterangan || "-",
      },
    ]);
  });
  return layout;
};

const buildTblAnggaran = (data = []) => {
  const layout = {
    style: ["mb-10"],
    layout: {
      hLineWidth: (_i, _node) => 0.3,
      vLineWidth: _i => 0.3,
      padding: () => 0,
    },
    table: {
      headerRows: 1,
      widths: ["20%", "15%", "20%", "10%", "5%", "12%", "15%", "10%"],
      body: [
        [
          {
            text: `Jenis Pembelanjaan`,
            style: ["center", "bgColorHeaderTable"],
          },
          {
            text: `Komponen`,
            style: ["center", "bgColorHeaderTable"],
          },
          {
            text: `Item`,
            style: ["center", "bgColorHeaderTable"],
          },
          {
            text: `Satuan`,
            style: ["center", "bgColorHeaderTable"],
          },
          {
            text: `Vol.`,
            style: ["center", "bgColorHeaderTable"],
          },
          {
            text: `Biaya Satuan`,
            style: ["center", "bgColorHeaderTable"],
          },
          {
            text: `Total`,
            style: ["center", "bgColorHeaderTable"],
          },
          {
            text: `URL Hps`,
            style: ["center", "bgColorHeaderTable"],
          },
        ],
      ],
    },
  };

  data.forEach(item => {
    const volume = parseInt(item.volume);
    const harga_satuan = utilities.unmaskMoney(item.harga_satuan);
    const total = volume * harga_satuan;

    layout.table.body.push([
      {
        text: `${item?.raw?.nama_kelompok_rab}`,
      },
      {
        text: `${item?.raw?.rab_komponen_belanja}`,
        style: ["center"],
      },
      {
        text: `${item.nama_item}`,
        style: ["center"],
      },
      {
        text: `${item.satuan || "-"}`,
        style: ["center"],
      },
      {
        text: `${volume || "-"}`,
        style: ["center"],
      },
      {
        text: `${utilities.toCurrency(harga_satuan || 0, ".", 0)}`,
        style: ["right"],
      },
      {
        text: `${utilities.toCurrency(total || 0, ".", 0)}`,
        style: ["right"],
      },
      {
        text: `${item.raw?.url_hps ? "Lihat" : "-"}`,
        link: item.raw?.url_hps || undefined,
        style: ["right", item.raw?.url_hps ? "link" : ""],
      },
    ]);
  });

  return layout;
};

const buildTblQuestionKI = data => {
  let header = [
    {
      text: "Jenis KI pada Teknologi dan Inovasi yang diusulkan",
      property: "jenis_ki",
      style: ["left"],
    },
    {
      text: "Status KI pada Teknologi dan Inovasi yang diusulkan",
      property: "status_ki",
      style: ["left"],
    },
    {
      text: "Status Kepemilikan KI",
      property: "status_kepemilikan_ki",
      property2: "status_kepemilikan_ki_lainnya",
      style: ["left"],
    },
  ];
  let widths = ["*", "*", "*"];

  const layout = {
    style: ["mb-10"],
    layout: {
      hLineWidth: (_i, _node) => 0.3,
      vLineWidth: _i => 0.3,
      padding: () => 0,
    },
    table: {
      headerRows: 1,
      widths: widths,
      body: [
        header.map(h => ({
          text: h.text,
          style: ["center"],
        })),
      ],
    },
  };
  layout.table.body.push(
    header.map(h => {
      let textValue = data[h.property + "_text"];
      if (
        data[h.property] === 1364 &&
        h?.property === "status_kepemilikan_ki"
      ) {
        textValue = `${textValue.split("sebutkan")[0]} ${data[h.property2]}`;
      }

      return {
        text: `${textValue}`,
        style: h.style,
      };
    })
  );

  return layout;
};

const buildTblLokasiSasaran = data => {
  let header = [
    {
      text: "Kategori Wilayah Mitra Sasaran",
      property: "kategori_wilayah_sasaran_text",
      style: ["left"],
    },
    {
      text: "Provinsi",
      property: "provinsi_sasaran_text",
      style: ["left"],
    },
    {
      text: "Kota",
      property: "kota_sasaran_text",
      style: ["left"],
    },
  ];
  let widths = ["*", "*", "*"];

  const layout = {
    style: ["mb-10"],
    layout: {
      hLineWidth: (_i, _node) => 0.3,
      vLineWidth: _i => 0.3,
      padding: () => 0,
    },
    table: {
      headerRows: 1,
      widths: widths,
      body: [
        header.map(h => ({
          text: h.text,
          style: ["center"],
        })),
      ],
    },
  };

  layout.table.body.push(
    header.map(h => ({
      text: `${data[h.property]}`,
      style: h.style,
    }))
  );

  return layout;
};

const buildTblMitra2 = (data, jenis) => {
  const widths = ["40%", "69%"];
  const fieldExcludes = [
    "temp_id",
    "sasaran_no",
    "kota_mitra_pemerintah",
    "provinsi_mitra_pemerintah",
    "kecamatan_mitra_sasaran",
    "kelurahan_mitra_sasaran",
    "kota_mitra_sasaran",
    "provinsi_mitra_sasaran",
  ];
  const fieldLinks = [
    "surat_pernyataan_sharing_dana",
    "bukti_jumlah_keanggotaan",
    "surat_pernyataan_kerjasama",
    "tangkapan_layar_gmap",
    "MoU/Kerja_sama_dengan_calon_pelaksana_program_kosabangsa",
    "Dokumen_dukung_lainnya_(Jika_Ada)",
    "Surat_Penyataan_Orisinalitas_Usulan_yang_ditandatangani_oleh_Ketua Pelaksana_dan_bermeterai_Rp_10.000",
    "bukti_rpjmd_rpjmdes",
  ];
  const fieldCurrency = ["kontribusi_pendanaan"];
  const fieldOrderMitraSasaran = [
    "jenis_kelompok_mitra",
    "nama_mitra",
    "kelompok_mitra",
    "pimpinan_mitra",
    "jumlah_anggota_kelompok",
    "bidang_kewilayahan",
    "aspek_bidang_kewilayahan_1",
    "aspek_bidang_kewilayahan_2",
    "provinsi_mitra_sasaran_text",
    "kota_mitra_sasaran_text",
    "kecamatan_mitra_sasaran_text",
    "kelurahan_mitra_sasaran_text",
    "alamat_lengkap_mitra",
    "kontribusi_pendanaan",
    "surat_pernyataan_kerjasama",
    "bukti_jumlah_keanggotaan",
    "tangkapan_layar_gmap",
    "surat_pernyataan_sharing_dana",
  ];
  const fieldOrderMitraPemerintah = [
    "kelompok_mitra",
    "nama_mitra",
    "pimpinan_mitra",
    "kontribusi_pendanaan",
    "provinsi_mitra_pemerintah_text",
    "kota_mitra_pemerintah_text",
    "alamat_lengkap_mitra",
    "surat_pernyataan_kerjasama",
    "surat_pernyataan_sharing_dana",
    "tangkapan_layar_gmap",
    "bukti_rpjmd_rpjmdes",
  ];
  let fieldOrder = [];
  if (jenis === "sasaran") {
    fieldOrder = fieldOrderMitraSasaran;
  } else if (jenis === "pemerintah") {
    fieldOrder = fieldOrderMitraPemerintah;
  }

  const entries = Object.entries(data.length > 0 ? data[0] : {})
    .filter(([key]) => !fieldExcludes.includes(key))
    .sort(
      ([keyA], [keyB]) => fieldOrder.indexOf(keyA) - fieldOrder.indexOf(keyB)
    )
    .map(([key, value]) => {
      return {
        label: utilities.formatJsonProperty(key).split("Text")[0],
        field: key,
        value,
      };
    });

  const layout =
    entries.length > 0
      ? {
          style: ["mb-10"],
          layout: {
            hLineWidth: (_i, _node) => 0.3,
            vLineWidth: _i => 0.3,
            padding: () => 0,
          },
          table: {
            headerRows: 1,
            widths: widths,
            body: [
              ...entries.map(item => [
                {
                  text: item.label,
                },
                {
                  text: fieldCurrency.includes(item.field)
                    ? utilities.toCurrency(item.value, ".", 2, "")
                    : fieldLinks.includes(item.field)
                    ? item.value
                      ? "Klik Disini"
                      : "-"
                    : item.value || "-",
                  link: fieldLinks.includes(item.field)
                    ? item.value
                    : undefined,
                  style:
                    fieldLinks.includes(item.field) && item.value
                      ? ["link"]
                      : undefined,
                },
              ]),
            ],
          },
        }
      : {};

  return layout;
};

const content = (data, _pageBreak = false) => {
  const { hr } = stylePDF;
  const {
    raw: { identitas },
  } = data;

  return [
    header(),
    hr(5, 0),
    {
      stack: [
        {
          text: "PROTEKSI ISI PROPOSAL",
          style: ["bold"],
        },
        {
          text: "Dilarang menyalin, menyimpan, memperbanyak sebagian atau seluruh isi proposal ini dalam bentuk apapun \n kecuali oleh pengusul dan pengelola administrasi pengabdian kepada masyarakat",
          style: ["fontSizeXs"],
        },
        {
          text: `PROPOSAL KOSABANGSA ${identitas?.tahun_pelaksanaan ?? ""}`,
          color: "#FFFFFF",
          background: "#000",
          style: ["center", "bold"],
        },
        {
          text: `Rencana Pelaksanaan Kosabangsa: tahun ${
            identitas?.tahun_pelaksanaan ?? ""
          } s.d. tahun ${getThnAkhirUsulan(
            identitas.lama_kegiatan,
            identitas.tahun_pelaksanaan
          )}`,
          style: ["fontSizeXs"],
        },
      ],
      style: ["center", "mb-5"],
    },
    ...contentKosabangsa(data),
  ];
};

export const contentKosabangsa = (data, dataRevisi = null) => {
  const {
    personil,
    personil_non_dosen,
    luaran,
    rab,
    raw: { identitas, teknologi_mitra, substansi },
  } = data;

  const anggotaPelaksana = (personil || []).filter(
    row =>
      row.raw.jenis_anggota === "pelaksana" &&
      row.raw.kode_program_pendidikan !== "2"
  );
  const anggotaPelaksanaTambahan = (personil || []).filter(
    row =>
      row.raw.jenis_anggota === "pelaksana" &&
      row.raw.kode_program_pendidikan === "2"
  );

  const anggotaPendamping = (personil || []).filter(
    row =>
      row.raw.jenis_anggota === "pendamping" &&
      row.raw.kode_program_pendidikan !== "2"
  );

  const anggotaMhs = (personil_non_dosen || []).filter(
    item => item.kind === "mahasiswa"
  );

  // const {  mitra_kegiatan, mitra_kerjasama } = mitra;
  const {
    nama_teknologi,
    spesisfikasi_teknologi,
    fungsi_kegunaan,
    deskripsi_wilayah_umum,
    deskripsi_potensi_wilayah,
    deskripsi_permasalahan_wilayah_utama,
    mitra_pemerintah,
    mitra_sasaran,
    kriteria_sasaran,
  } = teknologi_mitra;

  const totalAnggaran = (rab || []).reduce(
    (a, { harga_satuan, volume }) =>
      a + utilities.unmaskMoney(harga_satuan) * parseInt(volume),
    0
  );
  const calculateRabPercentage = rab => {
    const totalPerKelompok = rab.reduce((acc, item) => {
      const totalItemCost = item.volume * item.harga_satuan;
      const kelompokId = item.raw.id_kelompok_rab;

      if (!acc[kelompokId]) {
        acc[kelompokId] = 0;
      }
      acc[kelompokId] += totalItemCost;

      return acc;
    }, {});

    // Step 2: Calculate total overall cost
    const totalOverallCost = Object.values(totalPerKelompok).reduce(
      (acc, cost) => acc + cost,
      0
    );

    // Step 3: Calculate percentage and format output
    const result = Object.entries(totalPerKelompok).map(
      ([kelompokId, totalCost]) => {
        const kelompok = KELOMPOK_RAB_KOSABANGSA.find(
          k => k.id === parseInt(kelompokId)
        );
        const percentage = ((totalCost / totalOverallCost) * 100).toFixed(2);
        return `Total ${kelompok.name}: ${utilities.toCurrency(
          totalCost,
          ".",
          2,
          "Rp. "
        )} (${percentage}%)`;
      }
    );

    // Print result
    return result.join("\n");
  };
  return [
    {
      stack: [
        {
          text: "1. JUDUL",
          style: ["bold"],
        },
        buildBoxArea(identitas?.judul),
        buildTblIdentitasProposal(identitas),
      ],
      style: ["mb-10"],
    },
    {
      stack: [
        {
          text: "2. IDENTITAS TIM PELAKSANA",
          style: ["bold"],
        },
        buildTblAnggota(anggotaPelaksana),
      ],
      style: ["mb-10"],
    },
    {
      stack: [
        {
          text: "3. IDENTITAS TIM PELAKSANA TAMBAHAN VOKASI (Jika Ada)",
          style: ["bold"],
        },
        buildTblAnggota(anggotaPelaksanaTambahan),
      ],
      style: ["mb-10"],
    },
    {
      stack: [
        {
          text: "4. IDENTITAS TIM PENDAMPING",
          style: ["bold"],
        },
        buildTblAnggota(anggotaPendamping),
        {
          text: "Deskripsi Singkat Rencana Pendamping",
          style: ["bold", "mt-5"],
        },
        buildBoxArea(identitas?.deskripsi_rencana_pendampingan),
        {
          text: "Deskripsi Tahapan, Target dan Pelaporan Pendampingan",
          style: ["bold", "mt-5"],
        },
        buildBoxArea(identitas?.deskripsi_tahapan_pendampingan),
        {
          text: [
            "Pembentukan Tim Pendamping ",
            "[",
            {
              text: "klik disini",
              link: identitas?.file_pembentukan_tim_pendamping,
              style: ["link"],
            },
            "]",
          ],
          style: ["bold", "mb-5"],
        },
      ],
      style: ["mb-10"],
    },
    {
      stack: [
        {
          text: "5. IDENTITAS ANGGOTA MAHASISWA",
          style: ["bold"],
        },
        buildTblAnggotaMhs(anggotaMhs),
      ],
      style: ["mb-10"],
    },
    {
      stack: [
        {
          text: "6.SUBSTANSI DAN LUARAN",
          style: ["bold"],
        },
        {
          text: "Ringkasan Substansi",
          style: ["bold", "mt-5"],
        },
        buildBoxArea(substansi?.ringkasan_subtansi || ""),
        {
          text: "Keyword",
          style: ["bold"],
        },
        buildBoxArea(substansi?.keyword || ""),
        {
          text: "IKU",
          style: ["bold", "mt-5"],
        },
        buildTblIKU(substansi?.subtansi_iku || []),
        {
          text: "SDGs",
          style: ["bold", "mt-5"],
        },
        buildTblSDGS(substansi?.sdgs || []),
        {
          text: "Luaran Wajib Pelaksana",
          style: ["bold", "mt-5"],
        },
        buildTblLuaran((luaran || []).filter(x => x.raw.jenis === "pelaksana")),
        {
          text: "Luaran Wajib Pendamping",
          style: ["bold", "mt-5"],
        },
        buildTblLuaran(
          (luaran || []).filter(x => x.raw.jenis === "pendamping")
        ),
      ],
      style: ["mb-10"],
    },
    {
      stack: [
        {
          text: "7. ANGGARAN",
          style: ["bold", "mb-10"],
        },
        {
          text: `Total RAB 1 Tahun ${utilities.toCurrency(
            totalAnggaran,
            ".",
            2,
            "Rp. "
          )}`,
          style: ["bold"],
        },
        {
          text: `${calculateRabPercentage(rab)}`,
          style: ["bold", "mb-10"],
        },
        buildTblAnggaran(rab || []),
      ],
      style: ["mb-10"],
    },
    {
      style: ["mt-5"],
      stack: contentAnggaranRevisi(
        data?.rab_revisi || dataRevisi?.rab_revisi || null,
        data?.dana_disetujui_thn_1 || dataRevisi?.dana_disetujui_thn_1
      ),
    },
    {
      stack: [
        {
          text: `8. IDENTITAS TEKNOLOGI DAN INOVASI YANG DI TERAPKAN`,
          style: ["bold", "mb-5"],
        },
        {
          text: `Nama Teknologi dan Inovasi`,
          style: ["bold"],
        },
        buildBoxArea(nama_teknologi || ""),
        {
          text: [
            "Gambar Teknologi Dan Inovasi ",
            "[",
            {
              text: "klik disini",
              link: teknologi_mitra?.gambar_teknologi,
              style: ["link"],
            },
            "]",
          ],
          style: ["bold", "mb-5"],
        },
        {
          text: `Spesifikasi Teknologi dan Inovasi `,
          style: ["bold"],
        },
        buildBoxArea(spesisfikasi_teknologi || ""),
        {
          text: `Fungsi dan Kegunaan`,
          style: ["bold"],
        },
        buildBoxArea(fungsi_kegunaan || ""),
        {
          text: `Kriteria Lingkungan dan Sasaran Penerapan Teknologi dan Inovasi`,
          style: ["bold"],
        },
        buildBoxArea(kriteria_sasaran || ""),
        buildTblQuestionKI(teknologi_mitra),
        {
          text: [
            "Sertifikat Kepemilikan ",
            "[",
            {
              text: "klik disini",
              link: teknologi_mitra?.sertifikat_kepemilikan,
              style: ["link"],
            },
            "]",
          ],
          style: ["bold", "mb-5"],
        },
      ],
      style: ["mb-10"],
    },
    {
      stack: [
        {
          text: `9. DESKRIPSI LOKASI SASARAN PROGRAM`,
          style: ["bold", "mb-1"],
        },
        buildTblLokasiSasaran(teknologi_mitra),
        {
          text: `Deskripsi Wilayah secara Umum`,
          style: ["bold", "mt-5"],
        },
        buildBoxArea(deskripsi_wilayah_umum || ""),
        {
          text: `Deskripsi Potensi Wilayah`,
          style: ["bold", "mt-5"],
        },
        buildBoxArea(deskripsi_potensi_wilayah || ""),
        {
          text: `Deskripsi Permasalahan Utama Wilayah`,
          style: ["bold", "mt-5"],
        },
        buildBoxArea(deskripsi_permasalahan_wilayah_utama || ""),

        {
          text: `Identitas Mitra Sasaran Pertama`,
          style: ["bold", "mt-5"],
        },
        buildTblMitra2(
          (mitra_sasaran || []).filter(x => x.sasaran_no === 1),
          "sasaran"
        ),
        {
          text: `Identitas Mitra Sasaran Kedua`,
          style: ["bold", "mt-5"],
        },
        buildTblMitra2(
          (mitra_sasaran || []).filter(x => x.sasaran_no === 2),
          "sasaran"
        ),

        {
          text: `Identitas Mitra Pemerintah`,
          style: ["bold", "mt-5"],
        },
        buildTblMitra2(mitra_pemerintah || [], "pemerintah"),

        {
          text: `Identitas Mitra Kerjasama (Jika Ada)`,
          style: ["bold", "mt-5"],
        },
        buildTblMitra2(teknologi_mitra?.mitra_kerjasama || []),
        {
          text: `Dokumen Pendukung Lainnya`,
          style: ["bold", "mt-5"],
        },
        buildTblMitra2([
          {
            "MoU/Kerja_sama_dengan_calon_pelaksana_program_kosabangsa":
              teknologi_mitra.mou_kerjasama,
            "Surat_Penyataan_Orisinalitas_Usulan_yang_ditandatangani_oleh_Ketua Pelaksana_dan_bermeterai_Rp_10.000":
              teknologi_mitra.surat_pernyataan_orisinalitas,
            "Dokumen_dukung_lainnya_(Jika_Ada)":
              teknologi_mitra.dokumen_pendukung_lainnya,
          },
        ]),
      ],
    },
  ];
};

export const contentAnggaranRevisi = (rab_revisi, danaDisetujui = 0) => {
  if (
    rab_revisi === null ||
    !rab_revisi ||
    rab_revisi === undefined ||
    rab_revisi?.length === 0
  ) {
    return [];
  } else {
    const getTotalRab = () => {
      const totalAnggaran = rab_revisi?.reduce(
        (x, y) => x + y.harga_satuan * y.volume,
        0
      );
      return totalAnggaran;
    };

    return [
      {
        text: "9. ANGGARAN PERBAIKAN",
        style: ["bold", "mb-10"],
      },
      {
        text: [
          `Dana Tahun ke-1 : Direncanakan `,
          {
            text: `${utilities.toCurrency(getTotalRab(), ".", 2, "Rp")}`,
            style: ["bold"],
          },
          " | Disetujui ",
          {
            text: `${utilities.toCurrency(danaDisetujui, ".", 2, "Rp")}`,
            style: ["bold"],
          },
        ],
      },
      {
        ...buildTblAnggaran(rab_revisi.filter(row => row.harga_satuan !== 0)),
      },
    ];
  }
};

const contentPersetujuan = (data = {}) => {
  let { approval_raw, approval_status } = data;
  approval_raw = approval_raw ?? {};

  const layout = {
    style: ["mb-10"],
    layout: {
      hLineWidth: (_i, _node) => 0.3,
      vLineWidth: _i => 0.3,
      padding: () => 0,
    },
    table: {
      headerRows: 1,
      widths: ["*", "*", "*", "*", "*"],
      body: [
        [
          {
            text: `Tanggal Pengiriman`,
            style: ["center"],
          },
          {
            text: `Tanggal Persetujuan`,
            style: ["center"],
          },
          {
            text: `Nama Pimpinan Pemberi Persetujuan`,
            style: ["center"],
          },
          {
            text: `Sebutan Jabatan Unit`,
            style: ["center"],
          },
          {
            text: `Nama Unit Lembaga Pengusul`,
            style: ["center"],
          },
        ],
      ],
    },
  };

  layout.table.body.push([
    {
      text: `${
        data?.tgl_updated
          ? utilities.convertDate(data?.tgl_updated, "DD/MM/YYYY")
          : "-"
      }`,
      style: ["center"],
    },
    {
      text: `${
        approval_raw?.approval_date
          ? utilities.convertDate(approval_raw?.approval_date, "DD/MM/YYYY")
          : "-"
      }`,
      style: ["center"],
    },
    {
      text: `${approval_raw?.full_name || "-"}`,
      style: ["center"],
    },
    {
      text: `${approval_raw?.nama_jabatan || "-"}`,
      style: ["center"],
    },
    {
      text: `${approval_raw?.nama_lembaga || "-"}`,
      style: ["center"],
    },
  ]);

  return [
    {
      stack: [
        {
          text: "PERSETUJUAN PENGUSUL",
          style: ["bold"],
        },
        {
          ...layout,
        },
      ],
    },
    {
      style: ["mt-50"],
      stack: [
        {
          text: `Komentar : ${
            approval_status === "approved"
              ? "Disetujui"
              : approval_status === "rejected"
              ? "Ditolak"
              : "-"
          }`,
          style: ["bold", "mb-1"],
        },
        {
          style: ["text"],
          layout: {
            hLineWidth: (_i, _node) => (approval_raw?.comment ? 0.3 : 0),
            vLineWidth: _i => (approval_raw?.comment ? 0.3 : 0),
            padding: () => 1,
          },
          table: {
            widths: ["*"],
            body: [
              [
                {
                  text: approval_raw?.comment,
                },
              ],
            ],
          },
        },
      ],
    },
  ];
};

export { content, contentPersetujuan };
