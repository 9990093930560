import {
  LIST_LAPORAN_KEMAJUAN_FAILURE,
  LIST_LAPORAN_KEMAJUAN_REQUEST,
  LIST_LAPORAN_KEMAJUAN_SUCCESS,
  LAPORAN_KEMAJUAN_DETAIL_FAILURE,
  LAPORAN_KEMAJUAN_DETAIL_REQUEST,
  LAPORAN_KEMAJUAN_DETAIL_SUCCESS,
  POST_LAPORAN_KEMAJUAN_REQUEST,
  POST_LAPORAN_KEMAJUAN_FAILURE,
  POST_LAPORAN_KEMAJUAN_SUCCESS,
  UPDATE_LAPORAN_KEMAJUAN_FAILURE,
  UPDATE_LAPORAN_KEMAJUAN_REQUEST,
  UPDATE_LAPORAN_KEMAJUAN_SUCCESS,
  UPLOAD_DOKUMEN_FAILURE,
  UPLOAD_DOKUMEN_REQUEST,
  UPLOAD_DOKUMEN_SUCCESS,
  UPLOAD_DOKUMEN_SPTB_FAILURE,
  UPLOAD_DOKUMEN_SPTB_REQUEST,
  UPLOAD_DOKUMEN_SPTB_SUCCESS,
  CREATE_LAPORAN_KEMAJUAN_FAILURE,
  CREATE_LAPORAN_KEMAJUAN_REQUEST,
  CREATE_LAPORAN_KEMAJUAN_SUCCESS,
  GET_CRITERIA_FAILURE,
  GET_CRITERIA_REQUEST,
  GET_CRITERIA_SUCCESS,
  GET_TARGET_JENIS_LUARAN_FAILURE,
  GET_TARGET_JENIS_LUARAN_REQUEST,
  GET_TARGET_JENIS_LUARAN_SUCCESS,
  RESET_LAPORAN_KEMAJUAN_DETAIL,
  GET_DOKUMEN_LUARAN_REQUEST,
  GET_DOKUMEN_LUARAN_SUCCESS,
  GET_DOKUMEN_LUARAN_FAILURE,
  UPLOAD_DOKUMEN_MITRA_REQUEST,
  UPLOAD_DOKUMEN_MITRA_SUCCESS,
  UPLOAD_DOKUMEN_MITRA_FAILURE,
  GET_DOKUMEN_MITRA_REQUEST,
  GET_DOKUMEN_MITRA_SUCCESS,
  GET_DOKUMEN_MITRA_FAILURE,
  GET_BERKAS_LAPORAN_KEMAJUAN_FAILURE,
  GET_BERKAS_LAPORAN_KEMAJUAN_REQUEST,
  GET_BERKAS_LAPORAN_KEMAJUAN_SUCCESS,
  GET_LIST_LAPORAN_KEMAJUAN_V2_REQUEST,
  GET_LIST_LAPORAN_KEMAJUAN_V2_SUCCESS,
  GET_LIST_LAPORAN_KEMAJUAN_V2_FAILURE,
  GET_DETAIL_LAPORAN_KEMAJUAN_V2_REQUEST,
  GET_DETAIL_LAPORAN_KEMAJUAN_V2_SUCCESS,
  GET_DETAIL_LAPORAN_KEMAJUAN_V2_FAILURE,
  UPDATE_LAPORAN_KEMAJUAN_V2_REQUEST,
  UPDATE_LAPORAN_KEMAJUAN_V2_SUCCESS,
  UPDATE_LAPORAN_KEMAJUAN_V2_FAILURE,
} from "./actionTypes";



//* reset laporan kemajuan detail
export const resetLaporanKemajuanDetail = () => {
  return {
    type: RESET_LAPORAN_KEMAJUAN_DETAIL,
  };
};
//* Upload File Laporan Kemajuan
export const uploadFileLaporanKemajuan = (request = {}, cb = () => {}) => {
  return {
    type: UPLOAD_DOKUMEN_REQUEST,
    payload: request,
    cb,
  };
};

export const uploadFileLaporanKemajuanSuccess = response => {
  return {
    type: UPLOAD_DOKUMEN_SUCCESS,
    payload: response,
  };
};

export const uploadFileLaporanKemajuanFailure = (message, response) => {
  return {
    type: UPLOAD_DOKUMEN_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};
//* Upload File SPTB
export const uploadDokumenSptb = (request = {}, cb = () => {}) => {
  return {
    type: UPLOAD_DOKUMEN_SPTB_REQUEST,
    payload: request,
    cb,
  };
};

export const uploadDokumenSptbSuccess = response => {
  return {
    type: UPLOAD_DOKUMEN_SPTB_SUCCESS,
    payload: response,
  };
};

export const uploadDokumenSptbFailure = (message, response) => {
  return {
    type: UPLOAD_DOKUMEN_SPTB_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};
//* Create Laporan Kemajuan
export const createLaporanKemajuanSaga = (request = {}, cb = () => {}) => {
  return {
    type: CREATE_LAPORAN_KEMAJUAN_REQUEST,
    payload: request,
    cb,
  };
};

export const createLaporanKemajuanSagaSuccess = response => {
  return {
    type: CREATE_LAPORAN_KEMAJUAN_SUCCESS,
    payload: response,
  };
};

export const createLaporanKemajuanSagaFailure = (message, response) => {
  return {
    type: CREATE_LAPORAN_KEMAJUAN_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

//* List Laporan Kemajuan
export const getListLaporanKemajuan = (request = "") => {
  return {
    type: LIST_LAPORAN_KEMAJUAN_REQUEST,
    payload: request,
  };
};

export const getListLaporanKemajuanSuccess = response => {
  return {
    type: LIST_LAPORAN_KEMAJUAN_SUCCESS,
    payload: response,
  };
};

export const getListLaporanKemajuanFailure = (message, response) => {
  return {
    type: LIST_LAPORAN_KEMAJUAN_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};
// * Get Laporan Kemajuan Detail
export const getLaporanKemajuanDetail = (request = "") => {
  return {
    type: LAPORAN_KEMAJUAN_DETAIL_REQUEST,
    payload: request,
  };
};

export const getLaporanKemajuanDetailSuccess = response => {
  return {
    type: LAPORAN_KEMAJUAN_DETAIL_SUCCESS,
    payload: response,
  };
};

export const getLaporanKemajuanDetailFailure = (message, response) => {
  return {
    type: LAPORAN_KEMAJUAN_DETAIL_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};
// * Get Criteria
export const getCriteria = (request = "") => {
  return {
    type: GET_CRITERIA_REQUEST,
    payload: request,
  };
};

export const getCriteriaSuccess = response => {
  return {
    type: GET_CRITERIA_SUCCESS,
    payload: response,
  };
};

export const getCriteriaFailure = (message, response) => {
  return {
    type: GET_CRITERIA_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};
// * getTargetJenisLuaran
export const getTargetJenisLuaran = (request = "", cb = () => {}) => {
  return {
    type: GET_TARGET_JENIS_LUARAN_REQUEST,
    payload: request,
    cb,
  };
};

export const getTargetJenisLuaranSuccess = response => {
  return {
    type: GET_TARGET_JENIS_LUARAN_SUCCESS,
    payload: response,
  };
};

export const getTargetJenisLuaranFailure = (message, response) => {
  return {
    type: GET_TARGET_JENIS_LUARAN_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};
// * getDokumenLuaran
export const getDokumenLuaran = (request = "") => {
  return {
    type: GET_DOKUMEN_LUARAN_REQUEST,
    payload: request,
  };
};

export const getDokumenLuaranSuccess = response => {
  return {
    type: GET_DOKUMEN_LUARAN_SUCCESS,
    payload: response,
  };
};

export const getDokumenLuaranFailure = (message, response) => {
  return {
    type: GET_DOKUMEN_LUARAN_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};
// ! Yg bawah skip dulu
export const postLaporanKemajuan = (request = "", history) => {
  return {
    type: POST_LAPORAN_KEMAJUAN_REQUEST,
    payload: request,
    history,
  };
};
export const postLaporanKemajuanSuccess = response => {
  return {
    type: POST_LAPORAN_KEMAJUAN_SUCCESS,
    payload: response,
  };
};

export const postLaporanKemajuanFailure = (message, response) => {
  return {
    type: POST_LAPORAN_KEMAJUAN_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};
//
export const updateLaporanKemajuan = (request = "", history) => {
  return {
    type: UPDATE_LAPORAN_KEMAJUAN_REQUEST,
    payload: request,
    history,
  };
};
export const updateLaporanKemajuanSuccess = response => {
  return {
    type: UPDATE_LAPORAN_KEMAJUAN_SUCCESS,
    payload: response,
  };
};

export const updateLaporanKemajuanFailure = (message, response) => {
  return {
    type: UPDATE_LAPORAN_KEMAJUAN_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};
/**
 * Create Dokumen Mitra
 */
export const createDokumenMitraLaporanKemajuan = (
  request = {},
  cb = () => {}
) => {
  return {
    type: UPLOAD_DOKUMEN_MITRA_REQUEST,
    payload: request,
    cb,
  };
};

export const createDokumenMitraLaporanKemajuanSuccess = response => {
  return {
    type: UPLOAD_DOKUMEN_MITRA_SUCCESS,
    payload: response,
  };
};

export const createDokumenMitraLaporanKemajuanFailure = (message, response) => {
  return {
    type: UPLOAD_DOKUMEN_MITRA_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};
/**
 * Get Dokumen Mitra
 */
export const getDokumenMitraLaporanKemajuan = (request = {}, cb = () => {}) => {
  return {
    type: GET_DOKUMEN_MITRA_REQUEST,
    payload: request,
    cb,
  };
};

export const getDokumenMitraLaporanKemajuanSuccess = response => {
  return {
    type: GET_DOKUMEN_MITRA_SUCCESS,
    payload: response,
  };
};

export const getDokumenMitraLaporanKemajuanFailure = (message, response) => {
  return {
    type: GET_DOKUMEN_MITRA_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

export const getBerkasLaporanKemajuan = (request = {}, cb = () => {}) => {
  return {
    type: GET_BERKAS_LAPORAN_KEMAJUAN_REQUEST,
    payload: request,
    cb,
  };
};

export const getBerkasLaporanKemajuanSuccess = response => {
  return {
    type: GET_BERKAS_LAPORAN_KEMAJUAN_SUCCESS,
    payload: response,
  };
};

export const getBerkasLaporanKemajuanFailure = (message, response) => {
  return {
    type: GET_BERKAS_LAPORAN_KEMAJUAN_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

// Laporan kemajuan v2
export const getListLaporanKemajuanV2 = (request = {}, cb = () => {}) => {
  return {
    type: GET_LIST_LAPORAN_KEMAJUAN_V2_REQUEST,
    payload: request,
    cb,
  };
};

export const getListLaporanKemajuanV2Success = response => {
  return {
    type: GET_LIST_LAPORAN_KEMAJUAN_V2_SUCCESS,
    payload: response,
  };
};

export const getListLaporanKemajuanV2Failure = (message, response) => {
  return {
    type: GET_LIST_LAPORAN_KEMAJUAN_V2_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};

export const getDetailLaporanKemajuanV2 = (request = {}, cb = () => {}) => {
  return {
    type: GET_DETAIL_LAPORAN_KEMAJUAN_V2_REQUEST,
    payload: request,
    cb,
  };
};

export const getDetailLaporanKemajuanV2Success = response => {
  return {
    type: GET_DETAIL_LAPORAN_KEMAJUAN_V2_SUCCESS,
    payload: response,
  };
};

export const getDetailLaporanKemajuanV2Failure = (message, response) => {
  return {
    type: GET_DETAIL_LAPORAN_KEMAJUAN_V2_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};
export const updateLaporanKemajuanV2 = (request = {}, cb = () => {}) => {
  return {
    type: UPDATE_LAPORAN_KEMAJUAN_V2_REQUEST,
    payload: request,
    cb,
  };
};

export const updateLaporanKemajuanV2Success = response => {
  return {
    type: UPDATE_LAPORAN_KEMAJUAN_V2_SUCCESS,
    payload: response,
  };
};

export const updateLaporanKemajuanV2Failure = (message, response) => {
  return {
    type: UPDATE_LAPORAN_KEMAJUAN_V2_FAILURE,
    payload: {
      message,
      errors: response,
    },
  };
};
