/* eslint-disable no-unused-vars */
import { buildTblAnggaran } from "../../usulanPenelitian/partials/content";
import { file_rules_mitra } from "./file_rules_mitra";
import headers from "./headers";
import stylePDF from "./styles";
import utilities from "helpers/utilities";
import { tableAnggaranContent } from "./tblContentRAB";

export const ContentDikti = item => {
  const data = item.item;
  const getThnAkhirUsulan = (lamaKegiatan, thnPertamaUsulan) => {
    let thnAkhirUsulan = parseInt(thnPertamaUsulan);
    for (let tahun = 1; tahun < parseInt(lamaKegiatan); tahun++) {
      thnAkhirUsulan += tahun;
    }
    return thnAkhirUsulan;
  };

  const { hr } = stylePDF;

  return [
    // * Header
    headers(),
    hr(5, 0),
    {
      stack: [
        {
          text: "PROTEKSI ISI PROPOSAL",
          style: ["bold"],
        },
        {
          text: "Dilarang menyalin, menyimpan, memperbanyak sebagian atau seluruh isi proposal ini dalam bentuk apapun \n kecuali oleh pengusul dan pengelola administrasi pengabdian kepada masyarakat",
          style: ["fontSizeXs"],
        },
        {
          text: `PROPOSAL PENGABDIAN ${data?.raw?.identitas?.tahun_pelaksanaan}`,
          color: "#FFFFFF",
          background: "#000",
          style: ["center", "bold"],
        },
        {
          text: `ID Proposal: ${data?.id}`,
          style: ["fontSizeXs"],
        },
        {
          text: `Rencana Pelaksanaan Pengabdian : tahun ${
            data?.raw?.identitas?.tahun_pelaksanaan
          } s.d. tahun ${getThnAkhirUsulan(
            data?.raw?.identitas?.lama_kegiatan,
            data?.raw?.identitas?.tahun_pelaksanaan
          )}`,
          style: ["fontSizeXs"],
        },
      ],
      style: ["center", "mb-5"],
    },
    ...contentUsulanPengabdianDikti(data),
  ];
};

export const contentUsulanPengabdianDikti = data => {
  const buildTblIdentitas1 = (data = {}) => {
    const layout = {
      style: ["center", "mb-10"],
      layout: {
        hLineWidth: (_i, _node) => 0.5,
        vLineWidth: _i => 0.5,
        padding: () => 0,
      },
      table: {
        widths: ["22.5%", "22.5%", "20.00%", "15.00%", "20.00%"],
        body: [
          [
            {
              text: `Kelompok Skema`,
              style: ["center"],
            },
            {
              text: `Ruang Lingkup`,
              style: ["center"],
            },
            {
              text: `Bidang Fokus`,
              style: ["center"],
            },
            {
              text: `Lama Kegiatan`,
              style: ["center"],
            },
            {
              text: `Tahun Pertama Usulan`,
              style: ["center"],
            },
          ],
        ],
      },
    };

    layout.table.body.push([
      {
        text: data.nama_kelompok_skema,
      },
      {
        text: `${data.nama_ruang_lingkup}`,
      },
      {
        text: `${data.bidang_fokus_type} - ${data.nama_bidang_fokus}`,
      },
      {
        text: data.lama_kegiatan || "-",
        style: ["center"],
      },
      {
        text: data.tahun_pelaksanaan || "-",
        style: ["center"],
      },
    ]);

    return layout;
  };

  const buildTblPengusul = (identitasPengusul = []) => {
    const layout = {
      layout: {
        hLineWidth: (_i, _node) => 0.2,
        vLineWidth: _i => 0.2,
        padding: () => 0,
      },
      table: {
        widths: [
          "17.68%",
          "16.68%",
          "16.68%",
          "16.68%",
          "10.68%",
          "10.66%",
          "10.68%",
        ],
        body: [
          [
            {
              text: `Nama, Peran`,
              style: ["center"],
            },
            {
              text: `Perguruan Tinggi/ Institusi`,
              style: ["center"],
            },
            {
              text: `Program Studi/Bagian `,
              style: ["center"],
            },
            {
              text: `Bidang Tugas`,
              style: ["center"],
            },
            {
              text: `ID Sinta`,
              style: ["center"],
            },
            {
              text: `H-Index`,
              style: ["center"],
            },
            {
              text: `Rumpun Ilmu`,
              style: ["center"],
            },
          ],
        ],
      },
    };
    identitasPengusul.forEach(item => {
      if (item.kode_peran === "A") {
        item.raw.nameRumpunIlmuLevel2 = data.raw.identitas.nama_rumpun_ilmu_2;
        item.raw.uraian_tugas = data.raw.identitas.tugas_ketua;
      }
    });
    identitasPengusul
      .sort((a, b) => {
        if (a.kode_peran === "A") return -1;
        if (b.kode_peran === "A") return 1;
        return 0;
      })
      .forEach(item => {
        layout.table.body.push([
          {
            text: `${item.nama} \n\n ${item.nama_peran}`,
            style: ["center"],
          },
          {
            text: `${item?.raw?.pt || item?.raw?.institusi || "-"}`,
            style: ["center"],
          },
          {
            text: `${item?.raw?.prodi || "-"}`,
            style: ["center"],
          },
          {
            text: `${item?.raw?.uraian_tugas || "-"}`,
          },
          {
            text: `${item?.id_sinta || "-"}`,
            link: `https://sinta.kemdikbud.go.id/authors/profile/${item?.id_sinta}`,
            style: ["center", { color: "blue", decoration: "underline" }],
          },
          {
            text: `${item?.hindex_scopus || "-"}`,
            style: ["center"],
          },
          {
            text: `${item?.raw?.nameRumpunIlmuLevel2 || "-"}`,
            style: ["center"],
          },
        ]);
      });

    return layout;
  };

  // Identitas Anggota MAHASISWA
  const buildTblMahasiswa = (identitasPengusulMhs = []) => {
    const layout = {
      layout: {
        hLineWidth: (_i, _node) => 0.2,
        vLineWidth: _i => 0.2,
        padding: () => 0,
      },
      table: {
        widths: ["14.2%", "14.2%", "14.2%", "14.2%", "14.2%", "14.2%", "14.2%"],
        body: [
          [
            {
              text: `Nama, Peran`,
              style: ["center"],
            },
            {
              text: `NIM `,
              style: ["center"],
            },
            {
              text: `Perguruan Tinggi/ Institusi`,
              style: ["center"],
            },
            {
              text: `Program Studi/Bagian `,
              style: ["center"],
            },
            {
              text: `Bidang Tugas`,
              style: ["center"],
            },
            {
              text: `Jumlah SKS`,
              style: ["center"],
            },
            {
              text: `Mata Kuliah`,
              style: ["center"],
            },
          ],
        ],
      },
    };
    identitasPengusulMhs
      .filter(item => item.kind === "mahasiswa")
      .forEach(item => {
        layout.table.body.push([
          {
            text: `${item.raw.nama_lengkap} \n\n ${item.raw.nama_peran}`,
            style: ["center"],
          },
          {
            text: `${item.raw.no_identitas || "-"}`,
            style: ["center"],
          },
          {
            text: `${item.raw.instansi || "-"}`,
            style: ["center"],
          },
          {
            text: `${item.raw.prodi || "-"}`,
            style: ["center"],
          },
          {
            text: `${item.raw.tugas || "-"}`,
            style: ["center"],
          },
          {
            text: `${item.raw.jumlah_sks || "-"}`,
            style: ["center"],
          },
          {
            text: `${item.raw.mata_kuliah || "-"}`,
            style: ["center"],
          },
        ]);
      });

    return layout;
  };

  // Luaran
  const buildTblLuaran = (luaran = []) => {
    const layout = {
      style: ["center"],
      layout: {
        hLineWidth: (_i, _node) => 0.5,
        vLineWidth: _i => 0.5,
        padding: () => 1,
      },
      table: {
        widths: ["10%", "25%", "25%", "20%", "20%"],
        body: [
          [
            {
              text: "Tahun Luaran",
              style: ["center"],
            },
            {
              text: "Kelompok Luaran",
              style: ["center"],
            },
            {
              text: "Jenis Luaran",
              style: ["center"],
            },
            {
              text: "Status target capaian",
              style: ["center"],
            },
            {
              text: "Keterangan",
            },
          ],
        ],
      },
    };
    luaran
      .sort((a, b) =>
        a.tahun_kegiatan > b.tahun_kegiatan
          ? 1
          : b.tahun_kegiatan > a.tahun_kegiatan
          ? -1
          : 0
      )
      .forEach(item => {
        layout.table.body.push([
          {
            text: item.tahun_kegiatan || "-",
          },
          {
            text: item.nama_kategori_jenis_luaran || "-",
          },
          {
            text: item.nama_jenis_luaran || "-",
          },
          {
            text: item.raw.status_capaian || "-",
          },
          {
            text: item.keterangan || "-",
          },
        ]);
      });
    return layout;
  };
  const buildTblMitraPerItem = item => {
    const layout = {
      layout: {
        hLineWidth: (_i, _node) => 0.2,
        vLineWidth: _i => 0.2,
        padding: () => 0.5,
      },
      table: {
        widths: ["35%", "65%"],
        body: [],
      },
    };
    const id_skema = data.id_skema;
    const convertTextToColumns = data_mitra => {
      const columns = [];
      data_mitra.forEach(({ label, value, link }) => {
        if (link) {
          const links = Object.keys(value.raw)
            .map(
              key =>
                key.startsWith("url_file") && {
                  link: `${value.raw[key]}`,
                  key: key,
                }
            )
            .filter(item => item !== false);
          links.map((item, index) => {
            columns.push({
              text: `File ${
                file_rules_mitra
                  .filter(x => x.id_skema.includes(id_skema))
                  .find(x => x.key_url_file === item.key)?.label
              }`,
              alignment: "left",
              bold: true,
            });
            columns.push({
              link: `${item.link}`,
              is_link: true,
            });
          });
        } else {
          columns.push({ text: `${label}`, alignment: "left", bold: true });
          columns.push({ text: `: ${value}`, alignment: "left" });
        }
      });

      return columns;
    };
    let rows = [];
    if (item.raw.jenis_mitra === "sasaran") {
      if (id_skema === 109) {
        rows = convertTextToColumns([
          { label: "Jenis Mitra", value: item.raw.jenis_mitra },
          { label: "Kelompok Mitra Sasaran", value: item.raw.kelompok_mitra },
          { label: "Nama Mitra Sasaran", value: item.raw.nama_mitra },
          { label: "Pimpinan Mitra", value: item.raw.pimpinan_mitra },
          {
            label: "Jenis Kelompok Mitra",
            value: item.raw.jenis_kelompok_mitra,
          },
          {
            label: "Lingkup Permasalahan ke 1",
            value: item.raw.lingkup_masalah_1,
          },
          { label: "Jumlah Anggota Kelompok", value: item.raw.jumlah_anggota },
          { label: "Provinsi", value: item.raw.nama_provinsi },
          { label: "Kabupaten/Kota", value: item.raw.nama_kota },
          { label: "Kecamatan", value: item.raw.nama_kecamatan },
          { label: "Desa/Kelurahan", value: item.raw.nama_desa_kelurahan },
          {
            label: "Alamat Lengkap Mitra Sasaran",
            value: item.raw.alamat_lengkap,
          },
          {
            label: "Dana Tahun 1",
            value: utilities.toCurrency(
              utilities.unmaskMoney(item.raw.kontribusi_tahun_1),
              ".",
              2,
              "Rp. "
            ),
          },
          {
            label: "Files",
            value: item,
            link: true,
          },
        ]);
      } else if ([111, 112].includes(id_skema)) {
        rows = convertTextToColumns([
          { label: "Jenis Mitra", value: item.raw.jenis_mitra },
          { label: "Kelompok Mitra Sasaran", value: item.raw.kelompok_mitra },
          { label: "Nama Mitra Sasaran", value: item.raw.nama_mitra },
          { label: "Pimpinan Mitra", value: item.raw.pimpinan_mitra },
          {
            label: "Jenis Kelompok Mitra",
            value: item.raw.jenis_kelompok_mitra,
          },
          {
            label: "Lingkup Permasalahan ke 1",
            value: item.raw.lingkup_masalah_1,
          },
          {
            label: "Lingkup Permasalahan ke 2",
            value: item.raw.lingkup_masalah_2,
          },
          { label: "Jumlah Anggota Kelompok", value: item.raw.jumlah_anggota },
          { label: "Provinsi", value: item.raw.nama_provinsi },
          { label: "Kabupaten/Kota", value: item.raw.nama_kota },
          { label: "Kecamatan", value: item.raw.nama_kecamatan },
          { label: "Desa/Kelurahan", value: item.raw.nama_desa_kelurahan },
          {
            label: "Alamat Lengkap Mitra Sasaran",
            value: item.raw.alamat_lengkap,
          },
          {
            label: "Dana Tahun 1",
            value: utilities.toCurrency(
              utilities.unmaskMoney(item.raw.kontribusi_tahun_1),
              ".",
              2,
              "Rp. "
            ),
          },
          {
            label: "Files",
            value: item,
            link: true,
          },
        ]);
      } else if ([114, 117].includes(id_skema)) {
        rows = convertTextToColumns([
          { label: "Jenis Mitra", value: item.raw.jenis_mitra },
          { label: "Kelompok Mitra Sasaran", value: item.raw.kelompok_mitra },
          { label: "Nama Mitra Sasaran", value: item.raw.nama_mitra },
          { label: "Pimpinan Mitra", value: item.raw.pimpinan_mitra },
          {
            label: "Jenis Kelompok Mitra",
            value: item.raw.jenis_kelompok_mitra,
          },
          {
            label: "Lingkup Permasalahan ke 1 Tahun 1",
            value: item.raw.lingkup_masalah_1_tahun_1,
          },
          {
            label: "Lingkup Permasalahan ke 1 Tahun 2",
            value: item.raw.lingkup_masalah_1_tahun_2,
          },
          {
            label: "Lingkup Permasalahan ke 1 Tahun 3",
            value: item.raw.lingkup_masalah_1_tahun_3,
          },
          {
            label: "Lingkup Permasalahan ke 2 Tahun 1",
            value: item.raw.lingkup_masalah_2_tahun_1,
          },
          {
            label: "Lingkup Permasalahan ke 2 Tahun 2",
            value: item.raw.lingkup_masalah_2_tahun_2,
          },
          {
            label: "Lingkup Permasalahan ke 2 Tahun 3",
            value: item.raw.lingkup_masalah_2_tahun_3,
          },
          { label: "Jumlah Anggota Kelompok", value: item.raw.jumlah_anggota },
          { label: "Provinsi", value: item.raw.nama_provinsi },
          { label: "Kabupaten/Kota", value: item.raw.nama_kota },
          { label: "Kecamatan", value: item.raw.nama_kecamatan },
          { label: "Desa/Kelurahan", value: item.raw.nama_desa_kelurahan },
          {
            label: "Alamat Lengkap Mitra Sasaran",
            value: item.raw.alamat_lengkap,
          },
          {
            label: "Dana Tahun 1",
            value: utilities.toCurrency(
              utilities.unmaskMoney(item.raw.kontribusi_tahun_1),
              ".",
              2,
              "Rp. "
            ),
          },
          {
            label: "Dana Tahun 2",
            value: utilities.toCurrency(
              utilities.unmaskMoney(item.raw.kontribusi_tahun_2),
              ".",
              2,
              "Rp. "
            ),
          },
          {
            label: "Dana Tahun 3",
            value: utilities.toCurrency(
              utilities.unmaskMoney(item.raw.kontribusi_tahun_3),
              ".",
              2,
              "Rp. "
            ),
          },
          {
            label: "Files",
            value: item,
            link: true,
          },
        ]);
      } else if ([116].includes(id_skema)) {
        rows = convertTextToColumns([
          { label: "Jenis Mitra", value: item.raw.jenis_mitra },
          { label: "Kelompok Mitra Sasaran", value: item.raw.kelompok_mitra },
          { label: "Nama Mitra Sasaran", value: item.raw.nama_mitra },
          { label: "Pimpinan Mitra", value: item.raw.pimpinan_mitra },
          {
            label: "Jenis Kelompok Mitra",
            value: item.raw.jenis_kelompok_mitra,
          },
          {
            label: "Lingkup Permasalahan ke 1 Tahun 1",
            value: item.raw.lingkup_masalah_1_tahun_1,
          },
          {
            label: "Lingkup Permasalahan ke 1 Tahun 2",
            value: item.raw.lingkup_masalah_1_tahun_2,
          },
          {
            label: "Lingkup Permasalahan ke 1 Tahun 3",
            value: item.raw.lingkup_masalah_1_tahun_3,
          },
          {
            label: "Lingkup Permasalahan ke 2 Tahun 1",
            value: item.raw.lingkup_masalah_2_tahun_1,
          },
          {
            label: "Lingkup Permasalahan ke 2 Tahun 2",
            value: item.raw.lingkup_masalah_2_tahun_2,
          },
          {
            label: "Lingkup Permasalahan ke 2 Tahun 3",
            value: item.raw.lingkup_masalah_2_tahun_3,
          },
          {
            label: "Bidang Kewilayahan Tahun 1",
            value: item.raw.bidang_kewilayahan_tahun_1,
          },
          {
            label: "Bidang Kewilayahan Tahun 2",
            value: item.raw.bidang_kewilayahan_tahun_2,
          },
          {
            label: "Bidang Kewilayahan Tahun 3",
            value: item.raw.bidang_kewilayahan_tahun_3,
          },
          { label: "Jumlah Anggota Kelompok", value: item.raw.jumlah_anggota },
          { label: "Provinsi", value: item.raw.nama_provinsi },
          { label: "Kabupaten/Kota", value: item.raw.nama_kota },
          { label: "Kecamatan", value: item.raw.nama_kecamatan },
          { label: "Desa/Kelurahan", value: item.raw.nama_desa_kelurahan },
          {
            label: "Alamat Lengkap Mitra Sasaran",
            value: item.raw.alamat_lengkap,
          },
          {
            label: "Dana Tahun 1",
            value: utilities.toCurrency(
              utilities.unmaskMoney(item.raw.kontribusi_tahun_1),
              ".",
              2,
              "Rp. "
            ),
          },
          {
            label: "Dana Tahun 2",
            value: utilities.toCurrency(
              utilities.unmaskMoney(item.raw.kontribusi_tahun_2),
              ".",
              2,
              "Rp. "
            ),
          },
          {
            label: "Dana Tahun 3",
            value: utilities.toCurrency(
              utilities.unmaskMoney(item.raw.kontribusi_tahun_3),
              ".",
              2,
              "Rp. "
            ),
          },
          {
            label: "Files",
            value: item,
            link: true,
          },
        ]);
      }
    } else if (item.raw.jenis_mitra === "pemerintah-pemda") {
      if ([112].includes(id_skema)) {
        rows = convertTextToColumns([
          { label: "Jenis Mitra", value: item.raw.jenis_mitra },
          {
            label: "Kelompok Mitra Pemerintah",
            value: item.raw.kelompok_mitra,
          },
          { label: "Nama Mitra Pemerintah", value: item.raw.nama_mitra },
          { label: "Pimpinan Mitra", value: item.raw.pimpinan_mitra },
          { label: "Provinsi", value: item.raw.nama_provinsi },
          { label: "Kabupaten/Kota", value: item.raw.nama_kota },
          {
            label: "Alamat Lengkap Mitra Pemerintah",
            value: item.raw.alamat_lengkap,
          },
          {
            label: "Dana Tahun 1",
            value: utilities.toCurrency(
              utilities.unmaskMoney(item.raw.kontribusi_tahun_1),
              ".",
              2,
              "Rp. "
            ),
          },
          {
            label: "Files",
            value: item,
            link: true,
          },
        ]);
      } else if ([114, 116, 117].includes(id_skema)) {
        rows = convertTextToColumns([
          { label: "Jenis Mitra", value: item.raw.jenis_mitra },
          {
            label: "Kelompok Mitra Pemerintah",
            value: item.raw.kelompok_mitra,
          },
          { label: "Nama Mitra Pemerintah", value: item.raw.nama_mitra },
          { label: "Pimpinan Mitra", value: item.raw.pimpinan_mitra },
          { label: "Provinsi", value: item.raw.nama_provinsi },
          { label: "Kabupaten/Kota", value: item.raw.nama_kota },
          {
            label: "Alamat Lengkap Mitra Pemerintah",
            value: item.raw.alamat_lengkap,
          },
          {
            label: "Dana Tahun 1",
            value: utilities.toCurrency(
              utilities.unmaskMoney(item.raw.kontribusi_tahun_1),
              ".",
              2,
              "Rp. "
            ),
          },
          {
            label: "Dana Tahun 2",
            value: utilities.toCurrency(
              utilities.unmaskMoney(item.raw.kontribusi_tahun_2),
              ".",
              2,
              "Rp. "
            ),
          },
          {
            label: "Dana Tahun 3",
            value: utilities.toCurrency(
              utilities.unmaskMoney(item.raw.kontribusi_tahun_3),
              ".",
              2,
              "Rp. "
            ),
          },
          {
            label: "Files",
            value: item,
            link: true,
          },
        ]);
      }
    } else if (item.raw.jenis_mitra === "perguruan-tinggi") {
      if ([112].includes(id_skema)) {
        rows = convertTextToColumns([
          { label: "Jenis Mitra", value: item.raw.jenis_mitra },
          { label: "Nama Perguruan Tinggi", value: item.raw.nama_mitra },
          {
            label: "Pimpinan Perguruan Tinggi",
            value: item.raw.pimpinan_mitra,
          },
          { label: "Provinsi", value: item.raw.nama_provinsi },
          { label: "Kabupaten/Kota", value: item.raw.nama_kota },
          {
            label: "Alamat Lengkap Perguruan Tinggi",
            value: item.raw.alamat_lengkap,
          },
          {
            label: "Dana Tahun 1",
            value: utilities.toCurrency(
              utilities.unmaskMoney(item.raw.kontribusi_tahun_1),
              ".",
              2,
              "Rp. "
            ),
          },
          {
            label: "Files",
            value: item,
            link: true,
          },
        ]);
      } else if ([117].includes(id_skema)) {
        rows = convertTextToColumns([
          { label: "Jenis Mitra", value: item.raw.jenis_mitra },
          { label: "Nama Perguruan Tinggi", value: item.raw.nama_mitra },
          {
            label: "Pimpinan Perguruan Tinggi",
            value: item.raw.pimpinan_mitra,
          },
          { label: "Provinsi", value: item.raw.nama_provinsi },
          { label: "Kabupaten/Kota", value: item.raw.nama_kota },
          {
            label: "Alamat Lengkap Perguruan Tinggi",
            value: item.raw.alamat_lengkap,
          },
          {
            label: "Dana Tahun 1",
            value: utilities.toCurrency(
              utilities.unmaskMoney(item.raw.kontribusi_tahun_1),
              ".",
              2,
              "Rp. "
            ),
          },
          {
            label: "Dana Tahun 2",
            value: utilities.toCurrency(
              utilities.unmaskMoney(item.raw.kontribusi_tahun_2),
              ".",
              2,
              "Rp. "
            ),
          },
          {
            label: "Dana Tahun 3",
            value: utilities.toCurrency(
              utilities.unmaskMoney(item.raw.kontribusi_tahun_3),
              ".",
              2,
              "Rp. "
            ),
          },
          {
            label: "Files",
            value: item,
            link: true,
          },
        ]);
      }
    } else if (item.raw.jenis_mitra === "dudi-csr-lsm") {
      rows = convertTextToColumns([
        { label: "Jenis Mitra", value: item.raw.jenis_mitra },
        { label: "Nama Mitra DuDi", value: item.raw.nama_mitra },
        { label: "Pimpinan Mitra DuDi", value: item.raw.pimpinan_mitra },
        { label: "Provinsi", value: item.raw.nama_provinsi },
        { label: "Kabupaten/Kota", value: item.raw.nama_kota },
        { label: "Alamat Lengkap Mitra Dudi", value: item.raw.alamat_lengkap },
        {
          label: "Dana Tahun 1",
          value: utilities.toCurrency(
            utilities.unmaskMoney(item.raw.kontribusi_tahun_1),
            ".",
            2,
            "Rp. "
          ),
        },
        {
          label: "Dana Tahun 2",
          value: utilities.toCurrency(
            utilities.unmaskMoney(item.raw.kontribusi_tahun_2),
            ".",
            2,
            "Rp. "
          ),
        },
        {
          label: "Dana Tahun 3",
          value: utilities.toCurrency(
            utilities.unmaskMoney(item.raw.kontribusi_tahun_3),
            ".",
            2,
            "Rp. "
          ),
        },
        {
          label: "Files",
          value: item,
          link: true,
        },
      ]);
    }
    if (rows?.length > 0) {
      for (let i = 0; i < rows.length; i += 2) {
        if (rows[i].is_link || rows[i + 1].is_link) {
          const row = [
            {
              text: rows[i].text,
              alignment: rows[i].alignment,
              bold: rows[i].bold,
            },
          ];
          if (rows[i + 1]) {
            row.push({
              text: rows[i + 1].text || "Lihat",
              alignment: rows[i + 1].alignment,
              bold: rows[i + 1].bold,
              link: rows[i + 1].link ? rows[i + 1].link : "",
              style: rows[i + 1].link
                ? [{ color: "blue", decoration: "underline" }]
                : [],
            });
          }
          layout.table.body.push(row);
        } else {
          const row = [
            {
              text: rows[i].text,
              alignment: rows[i].alignment,
              bold: rows[i].bold,
            },
          ];

          if (rows[i + 1]) {
            row.push({
              text: rows[i + 1].text,
              alignment: rows[i + 1].alignment,
              bold: rows[i + 1].bold,
            });
          }

          layout.table.body.push(row);
        }
      }
      return layout;
    }
  };

  const buildTableMitra = (items = []) => {
    const stacks = items.map(item => ({
      stack: [
        {
          text: `${item.raw.jenis_mitra}`,
          style: ["bold", "mb-5"],
        },
        {
          text: "",
          ...buildTblMitraPerItem(item),
          style: ["mb-15"],
        },
      ],
    }));
    return stacks;
  };

  return [
    {
      stack: [
        {
          text: "1. JUDUL PENGABDIAN",
          style: ["bold", "mb-5"],
        },
        {
          style: ["text", "mb-10"],
          layout: {
            hLineWidth: (_i, _node) => 0.5,
            vLineWidth: _i => 0.5,
            padding: () => 1,
          },
          table: {
            widths: ["*"],
            body: [
              [
                {
                  text: data.raw.identitas.judul,
                },
              ],
            ],
          },
        },

        buildTblIdentitas1(data.raw.identitas),
      ],
      style: ["mb-10"],
    },
    {
      stack: [
        {
          text: "2. IDENTITAS PENGUSUL",
          style: ["bold", "mb-5"],
        },
        buildTblPengusul(
          data.personil.filter(
            item => item.raw.kd_program_pendidikan.toString() !== "2"
          )
        ),
      ],
      style: ["mb-10"],
    },
    {
      stack: [
        {
          text: "3. IDENTITAS PENGUSUL VOKASI",
          style: ["bold", "mb-5"],
        },
        buildTblPengusul(
          data.personil.filter(
            item => item.raw.kd_program_pendidikan.toString() === "2"
          )
        ),
      ],
      style: ["mb-10"],
    },
    {
      stack: [
        {
          text: "4. IDENTITAS MAHASISWA",
          style: ["bold", "mb-5"],
        },
        buildTblMahasiswa(data.personil_non_dosen),
      ],
      style: ["mb-10"],
    },
    {
      stack: [
        {
          text: "5. MITRA KERJASAMA",
          style: ["bold", "mb-5"],
        },
        {
          text: `Pelaksanaan pengabdian kepada masyarakat dapat melibatkan mitra, yaitu mitra sasaran, mitra pemerintah/pemda, mitra DUDI/CSR/LSM atau mitra perguruan tinggi`,
          style: ["fontSizeXs", "mb-5"],
        },
      ],
    },
    ...buildTableMitra(
      data.personil_non_dosen.filter(item => item.kind === "mitra")
    ),
    {
      stack: [
        {
          text: "6. IKU",
          style: ["bold", "mb-5"],
        },
        {
          style: ["text", "mb-10"],
          layout: {
            hLineWidth: (_i, _node) => 0.3,
            vLineWidth: _i => 0.3,
            padding: () => 0,
          },
          table: {
            widths: ["33.3%", "33.3%", "33.3%"],
            body: [
              [
                {
                  text: `Indikator IKU terkait`,
                  style: ["center"],
                },
                {
                  text: `Uraian IKU`,
                  style: ["center"],
                },
                {
                  text: `Uraian Kegiatan`,
                  style: ["center"],
                },
              ],
              ...data.raw.iku.map(item => {
                return [
                  {
                    text: `${item.name_indikator_iku_terkait}`,
                  },
                  {
                    text: `${item.name_uraian_iku}`,
                  },
                  {
                    text: `${item.uraian_iku_kegiatan}`,
                  },
                ];
              }),
            ],
          },
        },
      ],
      style: ["mb-10"],
    },
    {
      stack: [
        {
          text: "7. (SDGs)",
          style: ["bold", "mb-5"],
        },
        {
          style: ["text", "mb-10"],
          layout: {
            hLineWidth: (_i, _node) => 0.3,
            vLineWidth: _i => 0.3,
            padding: () => 0,
          },
          table: {
            widths: ["30%", "70%"],
            body: [
              [
                {
                  text: `SDGs terkait`,
                  style: ["center"],
                },
                {
                  text: `Uraian Kegiatan`,
                  style: ["center"],
                },
              ],
              ...data.raw.sdgs.map(item => {
                return [
                  {
                    text: `${item.sdgs_terkait}`,
                  },
                  {
                    text: `${item.uraian_kegiatan}`,
                  },
                ];
              }),
            ],
          },
        },
      ],
      style: ["mb-10"],
    },
    {
      stack: [
        {
          text: "8. LUARAN DIJANJIKAN",
          style: ["bold", "mb-5"],
        },
        buildTblLuaran(data.luaran),
      ],
      style: ["mb-10"],
    },
    ...tableAnggaranContent(
      data,
      "9. ANGGARAN",
      "dikti",
      data?.is_revisi_rab ? "revisi" : "normal"
    ),
  ];
};

export const contentPersetujuan = (data = {}) => {
  const layout = {
    style: ["mb-10"],
    layout: {
      hLineWidth: (_i, _node) => 0.3,
      vLineWidth: _i => 0.3,
      padding: () => 0,
    },
    table: {
      headerRows: 1,
      widths: ["*", "*", "*", "*", "*"],
      body: [
        [
          {
            text: `Tanggal Pengiriman`,
            style: ["center"],
          },
          {
            text: `Tanggal Persetujuan`,
            style: ["center"],
          },
          {
            text: `Nama Pimpinan Pemberi Persetujuan`,
            style: ["center"],
          },
          {
            text: `Sebutan Jabatan Unit`,
            style: ["center"],
          },
          {
            text: `Nama Unit Lembaga Pengusul`,
            style: ["center"],
          },
        ],
      ],
    },
  };
  layout.table.body.push([
    {
      text: `${
        data?.tanggal_pengiriman
          ? utilities.convertDate(data?.tanggal_pengiriman, "DD/MM/YYYY")
          : "-"
      }`,
      style: ["center"],
    },
    {
      text: `${
        data?.tgl_created
          ? utilities.convertDate(data?.tgl_created, "DD/MM/YYYY")
          : "-"
      }`,
      style: ["center"],
    },
    {
      text: `${data?.approval_by || "-"}`,
      style: ["center"],
    },
    {
      text: `${data?.approval_peran || "-"}`,
      style: ["center"],
    },
    {
      text: `${data?.lembaga?.nama_lembaga || "-"}`,
      style: ["center"],
    },
  ]);

  return [
    {
      stack: [
        {
          text: "PERSETUJUAN PENGUSUL",
          style: ["bold"],
        },
        {
          ...layout,
        },
      ],
    },
    {
      style: ["mt-10"],
      stack: [
        {
          text: `Komentar : ${
            data?.kd_sts_approvel?.toString() === "0"
              ? "Tidak Disetujui"
              : data?.kd_sts_approvel?.toString() === "1"
              ? "Disetujui"
              : "-"
          } `,
          style: ["bold", "mb-1"],
        },
        {
          style: ["text"],
          layout: {
            hLineWidth: (_i, _node) => (data?.komentar ? 0.3 : 0),
            vLineWidth: _i => (data?.komentar ? 0.3 : 0),
            padding: () => 1,
          },
          table: {
            widths: ["*"],
            body: [
              [
                {
                  text: data?.komentar,
                },
              ],
            ],
          },
        },
      ],
    },
  ];
};
export { contentUsulanPengabdianDikti as contentProposalForLaporanKemajuanPengabdianV2 };
