import { toast } from "react-toastify";
import { call, put, takeEvery } from "redux-saga/effects";
import * as backend from "../../helpers/backend";
import {
   createPengajuanKIFailure,
   createPengajuanKISuccess,
   deletePengajuanKIFailure,
   deletePengajuanKISuccess,
   getCountingPengajuanKIFailure,
   getCountingPengajuanKISuccess,
   getCountingReviewerPlotKIFailure,
   getCountingReviewerPlotKISuccess,
   getDetailsPengajuanKIFailure,
   getDetailsPengajuanKISuccess,
   getListPengajuanKIFailure,
   getListPengajuanKIPusatFailure,
   getListPengajuanKIPusatSuccess,
   getListPengajuanKISuccess,
   getListPenugasanReviewerKIFilure,
   getListPenugasanReviewerKISuccess,
   getListReviewerPlotKIFailure,
   getListReviewerPlotKISuccess,
   updatePengajuanKIFailure,
   updatePengajuanKISuccess,
   getEligibiltyDosenKISuccess,
   getEligibiltyDosenKIFailure,
   getReviewerPlotKISuccess,
   getReviewerPlotKIFailure,
   savePenilaianKISuccess,
   getListKegiatanBimtekKIFailure,
   getListKegiatanBimtekKISuccess
} from "./action";
import {
   CREATE_PENGAJUAN_KI_REQUEST,
   DELETE_PENGAJUAN_KI_REQUEST,
   GET_COUNTING_KI_REQUEST,
   GET_COUNTING_PLOT_KI_REQUEST,
   GET_DETAILS_KI_REQUEST,
   GET_LIST_KI_PUSAT_REQUEST,
   GET_LIST_KI_REQUEST,
   GET_LIST_PENUGASAN_REVIEWER_KI_REQUEST,
   GET_LIST_PLOT_KI_REQUEST,
   UPDATE_PENGAJUAN_KI_REQUEST,
   GET_ELIGIBLITY_DOSEN_KI_REQUEST,
   GET_PLOT_KI_REQUEST,
   SAVE_PENILAIAN_KI_REQUEST,
   GET_LIST_KEGIATAN_BIMTEK_KI_REQUEST,
} from "./actionTypes";


function* getEligibiltyDosenKI({ payload = {}, cb }) {
   try {
      const response = yield call(backend.getEligibiltyDosenKI, payload);
      if (response.data.code == 200) {
         yield put(getEligibiltyDosenKISuccess(response));
         cb(response);
      } else {
         throw new Error(response.data.message);
      }
   } catch (error) {
      const message = error.message;
      cb(null);
      toast.error(message);
      yield put(getEligibiltyDosenKIFailure(message, { ...error }));
   }
}

function* getListPenugasanReviewerKI({ payload = {}, cb }) {
   try {
      const response = yield call(backend.getListPenugasanReviewerKI, payload);
      if (response.data.code == 200) {
         yield put(getListPenugasanReviewerKISuccess(response.data));
         cb(response);
      } else {
         throw new Error(response.data.message);
      }
   } catch (error) {
      const message = error.message;
      cb(null);
      toast.error(message);
      yield put(getListPenugasanReviewerKIFilure(message, { ...error }));
   }
}

function* getListKegiatanBimtekKI({ payload = {} }) {
   try {
      const response = yield call(backend.getListKegiatanBimtekKI, payload);
      if (response.data.code == 200) {
         yield put(getListKegiatanBimtekKISuccess(response.data));
        
      } else {
         throw new Error(response.data.message);
      }
   } catch (error) {
      const message = error.message;
 
      toast.error(message);
      yield put(getListKegiatanBimtekKIFailure(message, { ...error }));
   }
}

function* getCountingReviewerPlotKI({ payload = {} }) {
   try {
      const response = yield call(backend.getCountingReviewerPlotKI, payload);
      if (response.data.code == 200) {
         yield put(getCountingReviewerPlotKISuccess(response.data));
      } else {
         throw new Error(response.data.message);
      }
   } catch (error) {
      const message = error.message;

      toast.error(message);
      yield put(getCountingReviewerPlotKIFailure(message, { ...error }));
   }
}

function* getListReviewerPlotKI({ payload = {} }) {
   try {
      const response = yield call(backend.getListReviewerPlottingKI, payload);
      if (response.data.code == 200) {
         yield put(getListReviewerPlotKISuccess(response.data));
      } else {
         throw new Error(response.data.message);
      }
   } catch (error) {
      const message = error.message;

      toast.error(message);
      yield put(getListReviewerPlotKIFailure(message, { ...error }));
   }
}

function* getReviewerPlotKI({ payload : idPlotting }) {
   try {
      const response = yield call(backend.getListReviewerPlottingKI, {id : idPlotting});
      if (response.data.code == 200) {
         yield put(getReviewerPlotKISuccess(response.data));
      } else {
         throw new Error(response.data.message);
      }
   } catch (error) {
      const message = error.message;

      toast.error(message);
      yield put(getReviewerPlotKIFailure(message, { ...error }));
   }
}

function* savePenilaianEvaluasiKI({ payload, cb = () => {} }) {
   try {
      const response = yield call(backend.savePenilaianEvaluasiKI, payload);
      if (response.data.code == 200) {
         yield put(savePenilaianKISuccess(response.data));
         cb(true);
      } else {
         throw new Error(response.data.message);
      }
   } catch (error) {
      const message = error.message;
      cb(false);

      toast.error(message);
      yield put(savePenilaianKISuccess());
   }
}

function* getCountingPengajuanKI({ payload = {} }) {
   try {
      const response = yield call(backend.getCountingPengajuanKI, payload);
      if (response.data.code == 200) {
         yield put(getCountingPengajuanKISuccess(response.data));
      } else {
         throw new Error(response.data.message);
      }
   } catch (error) {
      const message = error.message;
      console.log(message);
      toast.error(message);
      yield put(getCountingPengajuanKIFailure(message, { ...error }));
   }
}

function* getListKIPengajuanPusat({ payload = {} }) {
   try {
      const response = yield call(backend.getPengajuanKIPusat, payload);
      if (response.data.code == 200) {
         yield put(getListPengajuanKIPusatSuccess(response.data));
      } else {
         throw new Error(response.data.message);
      }
   } catch (error) {
      const message = error.message;
      console.log(message);
      toast.error(message);
      yield put(getListPengajuanKIPusatFailure(message, { ...error }));
   }
}

function* getListKIPengajuan({ payload = {} }) {
   try {
      const response = yield call(backend.getPengajuanKI, payload);
      if (response.data.code == 200) {
         yield put(getListPengajuanKISuccess(response.data.data));
      } else {
         throw new Error(response.data.message);
      }
   } catch (error) {
      const message = error.message;
      toast.error(message);
      yield put(getListPengajuanKIFailure(message, { ...error }));
   }
}

function* getDetailsKIPengajuan({ payload = {}, cb = () => { } }) {
   try {
      const response = yield call(backend.getPengajuanKI, payload);
      if (response.data.code == 200) {
         yield put(getDetailsPengajuanKISuccess(response.data.data));
         cb(response.data.data);
      } else {
         throw new Error(response.data.message);
      }
   } catch (error) {
      const message = error.message;
      cb(null);
      toast.error(message);
      yield put(getDetailsPengajuanKIFailure(message, { ...error }));
   }
}

function* createPengajuanKI({ payload, cb = () => { } }) {
   try {
      const response = yield call(backend.createPengajuanKI, payload);
      if (response.data.code == 200) {
         yield put(createPengajuanKISuccess({ ...response.data.data }));
         cb(response.data);
      } else {
         throw new Error(response.data.message);
      }
   } catch (error) {
      const message = error.message;

      cb(null);
      toast.error(message);
      yield put(createPengajuanKIFailure(message, { ...error }));
   }
}

function* updatePengajuanKI({ payload, cb = () => { } }) {
   try {
      const response = yield call(backend.updatePengajuanKI, payload);
      if (response.data.code == 200) {
         yield put(updatePengajuanKISuccess({ ...response.data.data }));
         cb(response.data);
      } else {
         throw new Error(response.data.message);
      }
   } catch (error) {
      const message = error.message;

      cb(null);
      toast.error(message);
      yield put(updatePengajuanKIFailure(message, { ...error }));
   }
}

function* deletePengajuanKI({ payload, cb = () => { } }) {
   try {
      const response = yield call(backend.deletePengajuanKI, payload);
      if (response.data.code == 200) {
         yield put(deletePengajuanKISuccess({
            id: payload.id
         }));
         cb(response.data);
      } else {
         throw new Error(response.data.message);
      }
   } catch (error) {
      const message = error.message;
      console.log(message);
      cb(null);
      toast.error(message);
      yield put(deletePengajuanKIFailure(message, { ...error }));
   }
}

function* kekayaanIntelektual() {
   yield takeEvery(GET_PLOT_KI_REQUEST, getReviewerPlotKI);
   yield takeEvery(GET_LIST_KI_REQUEST, getListKIPengajuan);
   yield takeEvery(GET_DETAILS_KI_REQUEST, getDetailsKIPengajuan);
   yield takeEvery(CREATE_PENGAJUAN_KI_REQUEST, createPengajuanKI);
   yield takeEvery(UPDATE_PENGAJUAN_KI_REQUEST, updatePengajuanKI);
   yield takeEvery(DELETE_PENGAJUAN_KI_REQUEST, deletePengajuanKI);
   yield takeEvery(GET_COUNTING_KI_REQUEST, getCountingPengajuanKI);
   yield takeEvery(GET_LIST_PLOT_KI_REQUEST, getListReviewerPlotKI);
   yield takeEvery(SAVE_PENILAIAN_KI_REQUEST, savePenilaianEvaluasiKI);
   yield takeEvery(GET_LIST_KI_PUSAT_REQUEST, getListKIPengajuanPusat);
   yield takeEvery(GET_ELIGIBLITY_DOSEN_KI_REQUEST, getEligibiltyDosenKI);
   yield takeEvery(GET_COUNTING_PLOT_KI_REQUEST, getCountingReviewerPlotKI);
   yield takeEvery(GET_LIST_KEGIATAN_BIMTEK_KI_REQUEST, getListKegiatanBimtekKI);
   yield takeEvery(GET_LIST_PENUGASAN_REVIEWER_KI_REQUEST, getListPenugasanReviewerKI);

}

export default kekayaanIntelektual;
