import {
  OPERATOR_MONITORING_LIST_USULAN_PERBAIKAN_FAILURE,
  OPERATOR_MONITORING_LIST_USULAN_PERBAIKAN_REQUEST,
  OPERATOR_MONITORING_LIST_USULAN_PERBAIKAN_SUCCESS,
  OPERATOR_MONITORING_CATATAN_HARIAN_FAILURE,
  OPERATOR_MONITORING_CATATAN_HARIAN_REQUEST,
  OPERATOR_MONITORING_CATATAN_HARIAN_SUCCESS,
  OPERATOR_MONITORING_LIST_LAPORAN_KEMAJUAN_FAILURE,
  OPERATOR_MONITORING_LIST_LAPORAN_KEMAJUAN_REQUEST,
  OPERATOR_MONITORING_LIST_LAPORAN_KEMAJUAN_SUCCESS,
  OPERATOR_MONITORING_LAPORAN_KEMAJUAN_DETAIL_FAILURE,
  OPERATOR_MONITORING_LAPORAN_KEMAJUAN_DETAIL_REQUEST,
  OPERATOR_MONITORING_LAPORAN_KEMAJUAN_DETAIL_SUCCESS,
  RESET_CATATAN_HARIAN,
  CATATAN_HARIAN_LAST_DETAIL,
  GET_ALL_INSTITUSI_REQUEST,
  GET_ALL_INSTITUSI_SUCCESS,
  GET_ALL_INSTITUSI_FAILURE,
  OPERATOR_MONITORING_LIST_LAPORAN_AKHIR_REQUEST,
  OPERATOR_MONITORING_LIST_LAPORAN_AKHIR_SUCCESS,
  OPERATOR_MONITORING_LIST_LAPORAN_AKHIR_FAILURE,
  OPERATOR_MONITORING_DETAIL_LAPORAN_AKHIR_REQUEST,
  OPERATOR_MONITORING_DETAIL_LAPORAN_AKHIR_SUCCESS,
  OPERATOR_MONITORING_DETAIL_LAPORAN_AKHIR_FAILURE,
  OPERATOR_MONITORING_LIST_KONTAK_PIC_REQUEST,
  OPERATOR_MONITORING_LIST_KONTAK_PIC_SUCCESS,
  OPERATOR_MONITORING_LIST_KONTAK_PIC_FAILURE,
  OPERATOR_MONITORING_PUT_KONTAK_PIC_REQUEST,
  OPERATOR_MONITORING_PUT_KONTAK_PIC_SUCCESS,
  OPERATOR_MONITORING_PUT_KONTAK_PIC_FAILURE,
  OPERATOR_MONITORING_LIST_MONEV_REQUEST,
  OPERATOR_MONITORING_LIST_MONEV_SUCCESS,
  OPERATOR_MONITORING_LIST_MONEV_FAILURE,
  OPERATOR_MONITORING_CATATAN_HARIAN_V2_REQUEST,
  OPERATOR_MONITORING_CATATAN_HARIAN_V2_SUCCESS,
  OPERATOR_MONITORING_CATATAN_HARIAN_V2_FAILURE,
  CLEAN_OPERATOR_MONITORING_CATATAN_HARIAN_V2,
} from "./actionTypes";
import utilities from "helpers/utilities";

const initialState = {
  isFetching: false,
  errorMessage: "",
  errors: null,
  listUsulanPerbaikan: [],
  listLaporanKemajuan: [],
  listLaporanKemajuanDetail: [],
  listCatatanHarian: [],
  listCatatanHarianV2: [],
  listCatatanHarianDetail: [],
  listCatatanHarianDetailV2: [],
  catatanHarianDetailTerakhir: [],
  listInstitusi: [],
  listLaporanAkhir: [],
  detailLaporanAkhir: [],
  getAllKontakInstitusi: [],
  putKontakInstitusi: [],
  listOptMonev: [],
};

const operatorMonitoring = (state = initialState, action) => {
  switch (action.type) {
    // * Perbaikan Usulan
    case OPERATOR_MONITORING_LIST_USULAN_PERBAIKAN_REQUEST:
      return {
        ...state,
        isFetching: true,
        listUsulanPerbaikan: [],
      };
    case OPERATOR_MONITORING_LIST_USULAN_PERBAIKAN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        listUsulanPerbaikan:
          action.payload
            ?.map(items =>
              items.usulan
                .map(itemUsulan =>
                  itemUsulan.usulan_kegiatan
                    .map(itemUsulanKegiatan => ({
                      // ini data v1, disesuaikan ulang untuk bisa dimerge ke v2.
                      judul: itemUsulan.judul || "-",
                      nama_ketua: items.dosen.nama || "-",
                      nama_skema: itemUsulan.skema_kegiatan.nama || "-",
                      thn_pelaksanaan:
                        itemUsulanKegiatan.thn_pelaksanaan_kegiatan || "-",
                      nama_bidang_fokus:
                        itemUsulan.bidang_fokus.bidang_fokus || "-",
                      jml_personil: itemUsulan.total_personil || "-",
                      lama_kegiatan: itemUsulan.lama_kegiatan + " Tahun" || "-",
                      tgl_perbaikan: itemUsulanKegiatan.tgl_perbaikan
                        ? utilities.convertDateUTC(
                            itemUsulanKegiatan.tgl_perbaikan,
                            "DD-MM-YYYY"
                          )
                        : "-",
                      dana_disetujui:
                        itemUsulanKegiatan.usulan_didanai.dana_disetujui,
                      thn_usulan: itemUsulanKegiatan.thn_usulan_kegiatan || "-",
                      id_proposal_kegiatan: itemUsulanKegiatan.id || "-",
                      status: itemUsulanKegiatan.status_usulan,
                      // data lama v1
                      is_vokasi: items.dosen.is_vokasi,
                      kode_status_pelaksanaan:
                        itemUsulan.kode_status_pelaksanaan || "-",
                      kode_program_hibah:
                        itemUsulan.skema_kegiatan.kode_program_hibah,
                    }))
                    .flat()
                )
                .flat()
            )
            .flat() || [],
      };
    case OPERATOR_MONITORING_LIST_USULAN_PERBAIKAN_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: action.payload.errors,
        errorMessage: action.payload.message,
      };

    // * Catatan Harian
    case CATATAN_HARIAN_LAST_DETAIL:
      return {
        ...state,
        catatanHarianDetailTerakhir: action.payload,
      };
    case RESET_CATATAN_HARIAN:
      return {
        ...state,
        // listCatatanHarian: [],
      };
    case OPERATOR_MONITORING_CATATAN_HARIAN_V2_REQUEST:
      return {
        ...state,
        isFetching: true,
        listCatatanHarianV2: [],
        listCatatanHarianDetailV2: [],
      };
    case OPERATOR_MONITORING_CATATAN_HARIAN_V2_SUCCESS:
      return {
        ...state,
        isFetching: false,
        listCatatanHarianV2: [],
        listCatatanHarianDetailV2: action.payload || [],
      };
    case OPERATOR_MONITORING_CATATAN_HARIAN_V2_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: action.payload.errors,
        errorMessage: action.payload.message,
        listCatatanHarianV2: [],
        listCatatanHarianDetailV2: [],
      };
    case CLEAN_OPERATOR_MONITORING_CATATAN_HARIAN_V2:
      return {
        ...state,
        listCatatanHarianV2: [],
        listCatatanHarianDetailV2: [],
      };
    case OPERATOR_MONITORING_CATATAN_HARIAN_REQUEST:
      return {
        ...state,
        isFetching: true,
        listCatatanHarian: [],
        listCatatanHarianDetail: [],
      };
    case OPERATOR_MONITORING_CATATAN_HARIAN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        listCatatanHarian:
          action.payload
            .map(items =>
              items.penelitian.map(item => ({
                jumlah: items.total_data,
                id: item.id,
                nama_skema: item.skema_kegiatan.nama,
                thn_pelaksanaan_kegiatan: item.thn_pelaksanaan_kegiatan,
              }))
            )
            .flat() || [],
        listCatatanHarianDetail:
          action.payload
            .map(items =>
              items.penelitian.map(item => ({
                nama_pengusul: items.dosen.nama,
                nidn: items.dosen.nidn,
                jumlah: items.total_data,
                dana_hibah: item.dana_hibah,
                judul: item.judul,
                jml_catatan: item.jml_catatan,
                persentase_capaian: item.persentase_capaian,
                id: item.id_usulan_kegiatan,
                nama_skema: item.skema_kegiatan.nama,
                thn_pelaksanaan_kegiatan: item.thn_pelaksanaan_kegiatan,
                urutan_thn_usulan_kegiatan: item.urutan_thn_usulan_kegiatan,
                lama_kegiatan: item.lama_kegiatan,
              }))
            )
            .flat() || [],
      };
    case OPERATOR_MONITORING_CATATAN_HARIAN_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: action.payload.errors,
        errorMessage: action.payload.message,
        listCatatanHarian: [],
        listCatatanHarianDetail: [],
      };
    // * List Laporan Kemajuan
    case OPERATOR_MONITORING_LIST_LAPORAN_KEMAJUAN_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case OPERATOR_MONITORING_LIST_LAPORAN_KEMAJUAN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        listLaporanKemajuan: action?.payload || [],
      };
    case OPERATOR_MONITORING_LIST_LAPORAN_KEMAJUAN_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: action.payload.errors,
        errorMessage: action.payload.message,
        listLaporanKemajuan: [],
      };
    // * List Laporan Kemajuan DETAIL
    case OPERATOR_MONITORING_LAPORAN_KEMAJUAN_DETAIL_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case OPERATOR_MONITORING_LAPORAN_KEMAJUAN_DETAIL_SUCCESS:
      return {
        ...state,
        isFetching: false,
        listLaporanKemajuanDetail: action.payload || [],
      };
    case OPERATOR_MONITORING_LAPORAN_KEMAJUAN_DETAIL_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: action.payload.errors,
        errorMessage: action.payload.message,
      };
    // * List institusi
    case GET_ALL_INSTITUSI_REQUEST:
      return {
        ...state,
        isFetching: true,
        listInstitusi: [],
        listCatatanHarian: [],
        listCatatanHarianDetail: [],
        listLaporanKemajuan: [],
      };
    case GET_ALL_INSTITUSI_SUCCESS:
      return {
        ...state,
        isFetching: false,
        listInstitusi: action?.payload || [],
      };
    case GET_ALL_INSTITUSI_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: action.payload.errors,
        errorMessage: action.payload.message,
        listInstitusi: [],
      };
    // Operator Monitoring Laporan Akhir Start
    case OPERATOR_MONITORING_LIST_LAPORAN_AKHIR_REQUEST:
      return {
        ...state,
        isFetching: true,
        listLaporanAkhir: [],
      };
    case OPERATOR_MONITORING_LIST_LAPORAN_AKHIR_SUCCESS:
      return {
        ...state,
        isFetching: false,
        listLaporanAkhir: action?.payload || [],
      };
    case OPERATOR_MONITORING_LIST_LAPORAN_AKHIR_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: action.payload.errors,
        errorMessage: action.payload.message,
        listLaporanAkhir: [],
      };

    case OPERATOR_MONITORING_DETAIL_LAPORAN_AKHIR_REQUEST:
      console.log("reducer");
      return {
        ...state,
        isFetching: true,
        detailLaporanAkhir: [],
      };
    case OPERATOR_MONITORING_DETAIL_LAPORAN_AKHIR_SUCCESS:
      return {
        ...state,
        isFetching: false,
        detailLaporanAkhir: action?.payload || [],
      };
    case OPERATOR_MONITORING_DETAIL_LAPORAN_AKHIR_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: action.payload.errors,
        errorMessage: action.payload.message,
        detailLaporanAkhir: [],
      };
    // Operator Monitoring Laporan Akhir End
    // Operator Kontak PIC Start
    case OPERATOR_MONITORING_LIST_KONTAK_PIC_REQUEST:
      return {
        ...state,
        isFetching: true,
        getAllKontakInstitusi: [],
      };
    case OPERATOR_MONITORING_LIST_KONTAK_PIC_SUCCESS:
      return {
        ...state,
        isFetching: false,
        getAllKontakInstitusi: action?.payload || [],
      };
    case OPERATOR_MONITORING_LIST_KONTAK_PIC_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: action.payload.errors,
        errorMessage: action.payload.message,
        getAllKontakInstitusi: [],
      };
    case OPERATOR_MONITORING_PUT_KONTAK_PIC_REQUEST:
      return {
        ...state,
        isFetching: true,
        putKontakInstitusi: [],
      };
    case OPERATOR_MONITORING_PUT_KONTAK_PIC_SUCCESS:
      return {
        ...state,
        isFetching: false,
        putKontakInstitusi: action?.payload || [],
      };
    case OPERATOR_MONITORING_PUT_KONTAK_PIC_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: action.payload.errors,
        errorMessage: action.payload.message,
        putKontakInstitusi: [],
      };
    // Operator Kontak PIC End
    // Operator Monitoring Monev Start
    case OPERATOR_MONITORING_LIST_MONEV_REQUEST:
      return {
        ...state,
        isFetching: true,
        listOptMonev: [],
      };
    case OPERATOR_MONITORING_LIST_MONEV_SUCCESS:
      return {
        ...state,
        isFetching: false,
        listOptMonev: action?.payload || [],
      };
    case OPERATOR_MONITORING_LIST_MONEV_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: action.payload.errors,
      };
    // Operator Monitoring Monev End
    default:
      return state;
  }
};

export default operatorMonitoring;
