import React from "react";
import roleTypeIds from "constants/role-type-ids";
const DashboardECatalog = React.lazy(() =>
  import("../pages/eCatalog/Dashboard/index")
);
const MapsLLDIKTIDetail = React.lazy(() =>
  import("../pages/eCatalog/Maps/DetailLLDIKTI")
);
const MapsLLDIKTIDetail2 = React.lazy(() =>
  import("../pages/eCatalog/Maps/DetailLLDIKTI/Detail")
);

// e-Catalog (Search Page)
const SearchEKatalog = React.lazy(() =>
  import("../pages/eCatalog/Search/index.js")
);
const SearchEKatalogSpesifik = React.lazy(() =>
  import("../pages/eCatalog/Search/Spesifik/index.js")
);
const SearchResultEKatalog = React.lazy(() =>
  import("../pages/eCatalog/Search/SearchResult/index.js")
);
const SearchResultEKatalogDetail = React.lazy(() =>
  import("../pages/eCatalog/Search/SearchResult/Detail.js")
);

// e-Catalog perguruan tinggi
const PerguruanTinggiEKatalog = React.lazy(() =>
  import("../pages/eCatalog/PerguruanTinggi/index.js")
);
const DetailChartPerguruanTinggiEKatalog = React.lazy(() =>
  import("../pages/eCatalog/PerguruanTinggi/DetailChart")
);
const BidangFokusPerguruanTinggiEKatalog = React.lazy(() =>
  import("../pages/eCatalog/PerguruanTinggi/BidangFokus")
);
const BidangFokusDetailPerguruanTinggiEKatalog = React.lazy(() =>
  import("../pages/eCatalog/PerguruanTinggi/BidangFokus/Detail")
);
const BidangFokusDetail2PerguruanTinggiEKatalog = React.lazy(() =>
  import("../pages/eCatalog/PerguruanTinggi/BidangFokus/Detail2")
);
const DataPenelitianPerguruanTinggiEKatalog = React.lazy(() =>
  import("../pages/eCatalog/PerguruanTinggi/DataPenelitian/index.js")
);
const DataPenelitianDetail1PerguruanTinggiEKatalog = React.lazy(() =>
  import("../pages/eCatalog/PerguruanTinggi/DataPenelitian/Detail1")
);
const DataPenelitianDetail2PerguruanTinggiEKatalog = React.lazy(() =>
  import("../pages/eCatalog/PerguruanTinggi/DataPenelitian/Detail2")
);
const DataDosenPerguruanTinggiEKatalog = React.lazy(() =>
  import("../pages/eCatalog/PerguruanTinggi/DataDosen/index.js")
);
const DataDetailDosenPerguruanTinggiEKatalog = React.lazy(() =>
  import("../pages/eCatalog/PerguruanTinggi/DataDosen/Detail/index.js")
);
const DataLuaranPerguruanTinggiEKatalog = React.lazy(() =>
  import("../pages/eCatalog/PerguruanTinggi/DataLuaran/index.js")
);
// e-Catalog Bidang Fokus
const BidangFokusEKatalog = React.lazy(() =>
  import("../pages/eCatalog/BidangFokus/index.js")
);
const BidangFokusDetail1EKatalog = React.lazy(() =>
  import("../pages/eCatalog/BidangFokus/Detail/index.js")
);
const BidangFokusDetail2EKatalog = React.lazy(() =>
  import("../pages/eCatalog/BidangFokus/Detail2/index.js")
);
const BidangFokusDetail3EKatalog = React.lazy(() =>
  import("../pages/eCatalog/BidangFokus/Detail3/index.js")
);
// e-catalog klaster
const KlasterEKatalog = React.lazy(() =>
  import("../pages/eCatalog/Klaster/index.js")
);
const KlasterDetailEKatalog = React.lazy(() =>
  import("../pages/eCatalog/Klaster/Detail/index.js")
);
const KlasterDetail2EKatalog = React.lazy(() =>
  import("../pages/eCatalog/Klaster/Detail2/index.js")
);
const KlasterDetail3EKatalog = React.lazy(() =>
  import("../pages/eCatalog/Klaster/Detail3/index.js")
);
// e-catalog benchmark
const BenchmarkEKatalog = React.lazy(() =>
  import("../pages/eCatalog/Benchmark/index.js")
);
const CompareBenchmarkEKatalog = React.lazy(() =>
  import("../pages/eCatalog/Benchmark/Compare/index.js")
);
const CompareDetailBenchmarkEKatalog = React.lazy(() =>
  import("../pages/eCatalog/Benchmark/Compare/Detail/index.js")
);
// e-Catalog keyword management
const KeywordManagementEKatalog = React.lazy(() =>
  import("../pages/eCatalog/KeywordManagement/index.js")
);
const KeywordMindmapEKatalog = React.lazy(() =>
  import("../pages/eCatalog/KeywordManagement/MindMap/index.js")
);
const KeywordAssignmentEKatalog = React.lazy(() =>
  import("../pages/eCatalog/KeywordManagement/KeywordAssignment/index.js")
);
const DaftarKeywordEKatalog = React.lazy(() =>
  import(
    "../pages/eCatalog/KeywordManagement/KeywordAssignment/DaftarKeyword/index.js"
  )
);
//e-Catalog Library
const LibraryEKatalog = React.lazy(() =>
  import("../pages/eCatalog/Library/index.js")
);
const LibraryDetailEKatalog = React.lazy(() =>
  import("../pages/eCatalog/Library/Detail/index.js")
);
const LibraryDetail2EKatalog = React.lazy(() =>
  import("../pages/eCatalog/Library/Detail2/index.js")
);
//e-Catalog Report Chart
const ReportChartEKatalog = React.lazy(() =>
  import("../pages/eCatalog/ReportChart/index.js")
);
export default [
  {
    id: "home-e-catalog",
    label: "Home",
    sequence: 1,
    path: "/e-catalog/home",
    component: DashboardECatalog,
    roles: [roleTypeIds.OPERATOR_E_CATALOG],
  },
  {
    id: "maps-lldikti-detail",
    path: "/e-catalog/maps/lldikti-detail",
    component: MapsLLDIKTIDetail,
    roles: [roleTypeIds.OPERATOR_E_CATALOG],
  },
  {
    id: "maps-lldikti-detail",
    path: "/e-catalog/maps/lldikti-detail2/:id",
    component: MapsLLDIKTIDetail2,
    roles: [roleTypeIds.OPERATOR_E_CATALOG],
  },
  // * E-Catalog Menu Pencarian
  {
    id: "e-catalog-pencarian",
    label: "Pencarian",
    sequence: 2,
    path: "/e-catalog/search",
    icon: "bx bx-search-alt-2",
    component: SearchEKatalog,
    roles: [roleTypeIds.OPERATOR_E_CATALOG],
  },
  {
    id: "e-catalog-pencarian-spesifik",
    path: "/e-catalog/search/spesifik",
    component: SearchEKatalogSpesifik,
    roles: [roleTypeIds.OPERATOR_E_CATALOG],
  },
  {
    id: "e-catalog-pencarian-search-result",
    path: "/e-catalog/search/search-result",
    component: SearchResultEKatalog,
    roles: [roleTypeIds.OPERATOR_E_CATALOG],
  },
  {
    id: "e-catalog-pencarian-detail-search-result",
    path: "/e-catalog/search/search-result/detail/:id",
    component: SearchResultEKatalogDetail,
    roles: [roleTypeIds.OPERATOR_E_CATALOG],
  },
  // =====================================================

  // * E-Catalog Menu Perguruan Tinggi
  {
    id: "e-catalog-perguruan-tinggi",
    label: "Perguruan Tinggi",
    path: "/e-catalog/perguruan-tinggi",
    component: PerguruanTinggiEKatalog,
    sequence: 2,
    icon: "bx bx-buildings",
    roles: [roleTypeIds.OPERATOR_E_CATALOG],
  },
  // sub menu chart
  {
    id: "e-catalog-perguruan-tinggi-detail-chart",
    path: "/e-catalog/perguruan-tinggi/detail-chart",
    component: DetailChartPerguruanTinggiEKatalog,
    roles: [roleTypeIds.OPERATOR_E_CATALOG],
  },
  // sub menu bidang fokus
  {
    id: "e-catalog-perguruan-tinggi-bidang-fokus",
    path: "/e-catalog/perguruan-tinggi/bidang-fokus",
    component: BidangFokusPerguruanTinggiEKatalog,
    roles: [roleTypeIds.OPERATOR_E_CATALOG],
  },
  {
    id: "e-catalog-perguruan-tinggi-bidang-fokus-detail",
    path: "/e-catalog/perguruan-tinggi/bidang-fokus/detail",
    component: BidangFokusDetailPerguruanTinggiEKatalog,
    roles: [roleTypeIds.OPERATOR_E_CATALOG],
  },
  {
    id: "e-catalog-perguruan-tinggi-bidang-fokus-detail",
    path: "/e-catalog/perguruan-tinggi/bidang-fokus/detail2/:id",
    component: BidangFokusDetail2PerguruanTinggiEKatalog,
    roles: [roleTypeIds.OPERATOR_E_CATALOG],
  },
  // sub menu penelitian
  {
    id: "e-catalog-perguruan-tinggi-data-penelitian",
    path: "/e-catalog/perguruan-tinggi/data-penelitian",
    component: DataPenelitianPerguruanTinggiEKatalog,
    roles: [roleTypeIds.OPERATOR_E_CATALOG],
  },
  {
    id: "e-catalog-perguruan-tinggi-data-penelitian-detail",
    path: "/e-catalog/perguruan-tinggi/data-penelitian/detail",
    component: DataPenelitianDetail1PerguruanTinggiEKatalog,
    roles: [roleTypeIds.OPERATOR_E_CATALOG],
  },
  {
    id: "e-catalog-perguruan-tinggi-data-penelitian-detail",
    path: "/e-catalog/perguruan-tinggi/data-penelitian/detail2/:id",
    component: DataPenelitianDetail2PerguruanTinggiEKatalog,
    roles: [roleTypeIds.OPERATOR_E_CATALOG],
  },
  // sub menu data dosen
  {
    id: "e-catalog-perguruan-tinggi-data-dosen",
    path: "/e-catalog/perguruan-tinggi/data-dosen",
    component: DataDosenPerguruanTinggiEKatalog,
    roles: [roleTypeIds.OPERATOR_E_CATALOG],
  },
  {
    id: "e-catalog-perguruan-tinggi-data-dosen",
    path: "/e-catalog/perguruan-tinggi/data-dosen/detail",
    component: DataDetailDosenPerguruanTinggiEKatalog,
    roles: [roleTypeIds.OPERATOR_E_CATALOG],
  },
  // sub menu data luaran
  {
    id: "e-catalog-perguruan-tinggi-data-luaran",
    path: "/e-catalog/perguruan-tinggi/data-luaran",
    component: DataLuaranPerguruanTinggiEKatalog,
    roles: [roleTypeIds.OPERATOR_E_CATALOG],
  },
  // * =====================================================

  // * E-Catalog Bidang Fokus
  {
    id: "e-catalog-bidang-fokus",
    label: "Bidang Fokus",
    path: "/e-catalog/bidang-fokus",
    sequence: 3,
    icon: "bx bx-book-bookmark",
    component: BidangFokusEKatalog,
    roles: [roleTypeIds.OPERATOR_E_CATALOG],
  },
  {
    id: "e-catalog-bidang-fokus-detail",
    path: "/e-catalog/bidang-fokus/detail",
    component: BidangFokusDetail1EKatalog,
    roles: [roleTypeIds.OPERATOR_E_CATALOG],
  },
  {
    id: "e-catalog-bidang-fokus-detail",
    path: "/e-catalog/bidang-fokus/detail2",
    component: BidangFokusDetail2EKatalog,
    roles: [roleTypeIds.OPERATOR_E_CATALOG],
  },
  {
    id: "e-catalog-bidang-fokus-detail",
    path: "/e-catalog/bidang-fokus/detail3/:id",
    component: BidangFokusDetail3EKatalog,
    roles: [roleTypeIds.OPERATOR_E_CATALOG],
  },
  // * =====================================================

  // * E-Catalog Klaster
  {
    id: "e-catalog-klaster",
    label: "Klaster",
    path: "/e-catalog/klaster",
    component: KlasterEKatalog,
    sequence: 4,
    icon: "bx bx-layer",
    roles: [roleTypeIds.OPERATOR_E_CATALOG],
  },
  {
    id: "e-catalog-klaster-detail",
    path: "/e-catalog/klaster/detail/:kd_klaster",
    component: KlasterDetailEKatalog,
    roles: [roleTypeIds.OPERATOR_E_CATALOG],
  },
  {
    id: "e-catalog-klaster-detail",
    path: "/e-catalog/klaster/detail2",
    component: KlasterDetail2EKatalog,
    roles: [roleTypeIds.OPERATOR_E_CATALOG],
  },
  {
    id: "e-catalog-klaster-detail",
    path: "/e-catalog/klaster/detail3/:id",
    component: KlasterDetail3EKatalog,
    roles: [roleTypeIds.OPERATOR_E_CATALOG],
  },
  // * ======================================================

  // * E-Catalog benchmark
  {
    id: "e-catalog-benchmark",
    label: "Benchmark",
    path: "/e-catalog/benchmark",
    component: BenchmarkEKatalog,
    sequence: 5,
    icon: "bx bx-line-chart",
    roles: [roleTypeIds.OPERATOR_E_CATALOG],
  },
  {
    id: "e-catalog-benchmark-compare",
    path: "/e-catalog/benchmark/compare",
    component: CompareBenchmarkEKatalog,
    roles: [roleTypeIds.OPERATOR_E_CATALOG],
  },
  {
    id: "e-catalog-benchmark-compare-detail",
    path: "/e-catalog/benchmark/compare/detail",
    component: CompareDetailBenchmarkEKatalog,
    roles: [roleTypeIds.OPERATOR_E_CATALOG],
  },

  // * ===========================================================

  // * E-Catalog keyword management
  {
    id: "e-catalog-keyword-management",
    path: "/e-catalog/keyword-management",
    component: KeywordManagementEKatalog,
    label: "Keyword Management",
    icon: "bx bx-spreadsheet",
    sequence: 6,
    roles: [roleTypeIds.OPERATOR_E_CATALOG],
  },
  {
    id: "e-catalog-keyword-management-mindmap",
    path: "/e-catalog/keyword-management/mindmap",
    component: KeywordMindmapEKatalog,
    roles: [roleTypeIds.OPERATOR_E_CATALOG],
  },
  {
    id: "e-catalog-keyword-management-assignment",
    path: "/e-catalog/keyword-management/assignment",
    component: KeywordAssignmentEKatalog,
    roles: [roleTypeIds.OPERATOR_E_CATALOG],
  },
  {
    id: "e-catalog-keyword-management-assignment",
    path: "/e-catalog/keyword-management/assignment/daftar-keyword",
    component: DaftarKeywordEKatalog,
    roles: [roleTypeIds.OPERATOR_E_CATALOG],
  },

  // * More Pages, ===========================================================
  {
    id: "more-pages-e-catalog",
    label: "More Pages",
    icon: "bx bx-file",
    sequence: 7,
    roles: [roleTypeIds.OPERATOR_E_CATALOG],
  },
  // * E-Catalog library
  {
    id: "e-catalog-library",
    path: "/e-catalog/library",
    parent: "more-pages-e-catalog",
    label: "Library",
    component: LibraryEKatalog,
    roles: [roleTypeIds.OPERATOR_E_CATALOG],
  },
  {
    id: "e-catalog-library-detail",
    path: "/e-catalog/library/detail",
    component: LibraryDetailEKatalog,
    roles: [roleTypeIds.OPERATOR_E_CATALOG],
  },
  {
    id: "e-catalog-library-detail",
    path: "/e-catalog/library/detail2/:id",
    component: LibraryDetail2EKatalog,
    roles: [roleTypeIds.OPERATOR_E_CATALOG],
  },

  // * ===========================================================

  // * E-Catalog Report Chart
  {
    id: "e-catalog-report-chart",
    path: "/e-catalog/report-chart",
    parent: "more-pages-e-catalog",
    label: "Report Chart",
    component: ReportChartEKatalog,
    roles: [roleTypeIds.OPERATOR_E_CATALOG],
  },
];
