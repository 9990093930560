import { instances, instancesV2 } from "../api_helper";
import * as url from "../url_helper";

const API_V2_URL =
  process.env.REACT_APP_API_V2_URL || "http://localhost:8088/api/v2";

export const getKomponenPenilaianLuaran = (payload) => {
  let params = "";
  Object.entries(payload).forEach(([key, value], index) => {
    params += `${key}=${value}${index + 1 === Object.entries(payload).length ? "" : "&"
      }`;
  });

  const URL = `${url.ENDPOINT_PENILAIAN_LUARAN}/komponen${params ? '?' + params : ''}`

  return instancesV2.request({
    url: URL
  });
};

export const saveKomponenPenilaianLuaran = ({ id_plotting, payload }) => {

  return instancesV2.request({
    url:
      url.ENDPOINT_PENILAIAN_LUARAN +
      `/${id_plotting}`,
    data: payload,
    method: "POST"
  });
};

export const getListUsulanEvaluasiV2 = (payload) => {
  let params = "";
  Object.entries(payload).forEach(([key, value], index) => {
    params += `${key}=${value}${index + 1 === Object.entries(payload).length ? "" : "&"
      }`;
  });

  return instances.request({
    baseURL: API_V2_URL,
    url:
      url.ENDPOINT_PROPOSAL_EVALUASI_V2 +
      `${params ? '?' + params : ''}`,
  });
};

export const getListInstitusiReviewer = ({
  kode_tahapan,
  id_personal_reviewer,
  thn_pelaksanaan,
}) => {
  return instancesV2.request({
    url:
      url.ENDPOINT_INSTITUSI_EVALUASI +
      `?kode_tahapan=${kode_tahapan}&thn_pelaksanaan=${thn_pelaksanaan}&id_personal_reviewer=${id_personal_reviewer}`,
  });
};

export const getKelompokEvaluasi = ({
  kode_tahapan,
  kode_jenis_kegiatan,
  thn_pelaksanaan,
  id_personal_reviewer,
  id_institusi,
}) =>
  instances.request({
    baseURL: API_V2_URL,
    url:
      url.ENDPOINT_KELOMPOK_EVALUASI_V2 +
      `?kode_tahapan=${kode_tahapan}&thn_pelaksanaan=${thn_pelaksanaan}&id_personal_reviewer=${id_personal_reviewer}&id_institusi=${id_institusi}${kode_jenis_kegiatan ? `&kode_jenis_kegiatan=${kode_jenis_kegiatan}` : ''}`,
  });

export const getKomponenNilaiEvaluasiV2 = ({
  thn_edisi,
  id_skema,
  kode_tahapan,
  kelompok = [],
  urutan_thn_pelaksanaan,
  jenis_thn_penelitian
}) => {
  let URL = url.ENDPOINT_KOMPONEN_EVALUASI_V2 +
    `?kd_tahapan=${kode_tahapan}&thn_edisi=${thn_edisi}&id_skema=${id_skema}`;
  if (urutan_thn_pelaksanaan) {
    URL += `&urutan_thn_pelaksanaan=${urutan_thn_pelaksanaan}`;
  }
  if (jenis_thn_penelitian) {
    URL += `&jenis_thn_penelitian=${jenis_thn_penelitian}`;
  }
  if (kelompok.length > 0) {

    URL += `&kelompok=${kelompok.join(',')}`
  }
  return instancesV2.request({
    url: URL,
  });
};

export const getDetailPenilaianEvaluasiV2 = ({include = null, id_plotting, mode }) => {
  
  let URL = `${url.ENDPOINT_PROPOSAL_DETAIL_PENILAIAN_EVALUASI_V2}/${id_plotting}`;
  let params = '';
  if (mode) {
    params += `?mode=${mode}`;
  }
  if (include) {
    if (params) params += '&'
    else params += '?';
    params += 'with=' + include;
  }
  return instances.request({
    baseURL: API_V2_URL,
    url: `${URL}${params}`,
  });
}
  ;
export const getDetailPenilaianEvaluasiLuaranV2 = ({ id_plotting }) => {
  return instances.request({
    baseURL: API_V2_URL,
    url: `${url.ENDPOINT_PROPOSAL_DETAIL_PENILAIAN_EVALUASI_LUARAN}/${id_plotting}`,
  });
};

export const saveUsulanEvaluasiV2 = payload => {
  return instances.request({
    baseURL: API_V2_URL,
    url: `${url.ENDPOINT_SAVE_PENILAIAN_EVALUASI_V2}/${payload.id_plotting}`,
    method: "POST",
    data: payload.payload,
  });
};

export const updateUsulanEvaluasiV2 = payload => {
  return instances.request({
    baseURL: API_V2_URL,
    url: `${url.ENDPOINT_SAVE_PENILAIAN_EVALUASI_V2}/${payload.id_plotting}`,
    method: "PUT",
    data: payload.payload,
  });
};

export const savePermanentUsulanEvaluasi2 = payload => {
  return instances.request({
    baseURL: API_V2_URL,
    url: `${url.ENDPOINT_SAVE_PERMANEN_EVALUASI_V2}/${payload.id_plotting}`,
    method: "PATCH",
    data: payload.payload,
  });
};
