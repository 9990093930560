/* eslint-disable no-unused-vars */
import utilities from "helpers/utilities";
import { instances, instancesV2 } from "../api_helper";
import * as url from "../url_helper";
import ProposalPDF from "helpers/workers/pdf/usulanPenelitian";
import { toast } from "react-toastify";
import { handleDownloadProposalPengabdian } from "helpers/usulanPengabdian";
import ProposalPenelitianV2 from "helpers/workers/pdf/usulanPenelitanV2";

const getEnumeration = payload => {
  let params = "";
  Object.entries(payload).forEach(([key, value], index) => {
    params += `${key}:${value}${
      index + 1 === Object.entries(payload).length ? "" : ","
    }`;
  });
  return instances.request(`${url.ENDPOINT_ENUMERATION}?criteria=${params}`, {
    method: "GET",
  });
};

const getKategoriPenelitian = _payload =>
  instances.request(url.GET_KATEGORI_PENELITIAN, {
    method: "GET",
  });

const getKategoriIndikatorTKT = _payload =>
  instances.request(url.GET_KATEGORI_INDIKATOR_TKT, {
    method: "GET",
  });

const getIndikatorTKTByKategori = ({ kategori, level }) =>
  instances.request(
    `${url.GET_INDIKATOR_BY_KATEGORI}?criteria=id_level:${level},id_kategori:${kategori}`,
    {
      method: "GET",
    }
  );

const getTema = () =>
  instances.request(`${url.ENDPOINT_TEMA}?page=1:1000`, {
    method: "GET",
  });

const getTopik = ({ tema }) =>
  instances.request(
    `${url.ENDPOINT_TOPIK}${
      tema ? `?criteria=id_tema:${tema}&page=1:1000` : ""
    }`,
    {
      method: "GET",
    }
  );

const getSbk = ({ tkt, id_skema }) =>
  instances.request(
    `${url.ENDPOINT_SBK}?id_skema_tkt=${id_skema}&level_tkt=${tkt}`,
    {
      method: "GET",
    }
  );

const getBidangFokus = ({ kode_status_aktif = 0, id_skema }) => {
  let params = "?page=1:1000";
  let bySkema = "";
  if (kode_status_aktif !== 0)
    params += `&criteria=kode_status_aktif:${kode_status_aktif}`;
  if (id_skema) bySkema += `/by-id-skema/${id_skema}/`;
  return instances.request(`${url.ENDPOINT_BIDANG_FOKUS}${bySkema}${params}`, {
    method: "GET",
  });
};

const getRumpunIlmu = ({ level, id_ref_rumpun_ilmu, base = false }) =>
  instances.request(
    `${url.ENDPOINT_RUMPUN_ILMU}${
      base ? "/base" : null
    }?criteria=level_rumpun_ilmu:${level}${
      id_ref_rumpun_ilmu ? `,id_ref_rumpun_ilmu:${id_ref_rumpun_ilmu}` : ""
    }&page=1:100`,
    {
      method: "GET",
    }
  );

export const getTargetCapaianLuaran = kode_kategori_jenis_luaran => {
  let params = `?page=1:1000`;
  if (kode_kategori_jenis_luaran) {
    params += `&criteria=kode_kategori_jenis_luaran(exact):${kode_kategori_jenis_luaran}`;
  }
  let URL;
  if (window.location.pathname.includes("/penelitian")) {
    URL = `${url.ENDPOINT_TARGET_CAPAIAN_LUARAN}${params}`;
  } else if (window.location.pathname.includes("/pengabdian")) {
    URL = `${`/referensi/target-capaian-luaran-pengabdian`}${params}`;
  } else if (window.location.pathname.includes("/prototipe")) {
    URL = `${url.ENDPOINT_TARGET_CAPAIAN_LUARAN}${params}`;
  } else if (window.location.pathname.includes("/kosabangsa")) {
    URL = `${url.ENDPOINT_TARGET_CAPAIAN_LUARAN}-pengabdian${params}`;
  }
  return instances.request(URL, {
    method: "GET",
  });
};

const getTargetAkhirTKT = ({ tkt }) =>
  instances.request(
    `${url.ENDPOINT_TARGET_AKHIR_TKT}?criteria=current_tkt:${tkt}`,
    {
      method: "GET",
    }
  );

const getSkemaKegiatan = ({ kode_program_hibah }) =>
  instances.request(
    `${url.ENDPOINT_SKEMA_KEGIATAN}?criteria=kode_program_hibah:${kode_program_hibah}`,
    {
      method: "GET",
    }
  );

const getBidangUnggulan = () =>
  instances.request(`${url.ENDPOINT_BIDANG_UNGGULAN}`, {
    method: "GET",
  });

const getDosen = payload => {
  let params = "";
  const entriesPayload = Object.entries(payload).filter(
    ([key]) => key !== "check_eligibility" && key !== "mode"
  );

  entriesPayload.forEach(([key, value], index) => {
    if (key !== "check_eligibility" && value) {
      params += `${key}:${value}${
        index + 1 === entriesPayload.length ? "" : ","
      }`;
    }
  });

  if (payload?.check_eligibility) {
    params += `${params ? "&" : "?"}check_eligibility=${
      payload?.check_eligibility
    }`;
  }
  if (payload?.id_skema_summary) {
    params += `${params ? "&" : "?"}id_skema_summary=${
      payload?.id_skema_summary
    }`;
  }
  if (payload?.mode) {
    params += `${params ? "&" : "?"}mode=${payload?.mode}`;
  }
  return instances.request(
    `${url.ENDPOINT_DOSEN}${params ? "?criteria=" + params : ""}`,
    {
      method: "GET",
    }
  );
};

const validasiKeanggotaanDosen = payload => {
  let URL;
  if (window.location.pathname.includes("/penelitian")) {
    URL = `dosen/${payload.nidn}/availibility-of-skema-penelitian/${payload.id_skema}`;
  } else if (window.location.pathname.includes("/pengabdian")) {
    URL = `dosen/${payload.nidn}/availibility-of-skema-pengabdian/${payload.id_skema}`;
  }
  return instances.request(URL, {
    method: "GET",
  });
};

const getPeranPersonil = () =>
  instances.request(`${url.ENDPOINT_PERSONAL_PERAN}?page=1:100`, {
    method: "GET",
  });

const getKelompokMakroRiset = () =>
  instances.request(`${url.ENDPOINT_KELOMPOK_MAKRO_RISET}`, {
    method: "GET",
  });

const getJenisLuaran = (kd_kategori_luaran = "") => {
  let params = "?page=1:1000";
  if (kd_kategori_luaran) {
    params += `&criteria=kode_kategori_jenis_luaran:${kd_kategori_luaran}`;
  }
  let URL;
  if (window.location.pathname.includes("penelitian")) {
    URL = `${url.ENDPOINT_JENIS_LUARAN}${params}`;
  } else if (window.location.pathname.includes("pengabdian")) {
    URL = `${`/pengabdian/jenis-luaran`}${params}`;
  } else if (window.location.pathname.includes("/prototipe")) {
    URL = `${`/penelitian/jenis-luaran`}${params}`;
  } else if (window.location.pathname.includes("/kosabangsa")) {
    URL = `${`/pengabdian/jenis-luaran`}${params}`;
  }
  return instances.request(URL, {
    method: "GET",
  });
};

const getKategoriLuaran = () => {
  let URL;
  if (window.location.pathname.includes("/penelitian")) {
    URL = `${url.ENDPOINT_KATEGORI_LUARAN}?page=1:1000`;
  } else if (window.location.pathname.includes("/pengabdian")) {
    URL = `${`/referensi/kategori-jenis-luaran-pengabdian?page=1:1000`}`;
  } else if (window.location.pathname.includes("/prototipe")) {
    URL = `${url.ENDPOINT_KATEGORI_LUARAN}?page=1:1000`;
  } else if (window.location.pathname.includes("/kosabangsa")) {
    URL = `${url.ENDPOINT_KATEGORI_LUARAN}-pengabdian?page=1:1000`;
  }
  return instances.request(URL, {
    method: "GET",
  });
};

const getKelompokRabBiaya = payload => {
  let URL;
  if (!payload) {
    if (window.location.pathname.includes("penelitian")) {
      URL = `${url.ENDPOINT_KATEGORI_RAB}?criteria=kode_jenis_kegiatan:1,id(in):1|2|3|4|5`;
    } else if (window.location.pathname.includes("pengabdian")) {
      URL = `${url.ENDPOINT_KATEGORI_RAB}?criteria=kode_jenis_kegiatan:2,id(in):11|12|13|14|15`;
    } else if (window.location.pathname.includes("kosabangsa")) {
      URL = `${url.ENDPOINT_KATEGORI_RAB}?criteria=kode_jenis_kegiatan:2,id(in):21|22|23|24|25|26`;
    } else if (window.location.pathname.includes("prototipe")) {
      URL = `${url.ENDPOINT_KATEGORI_RAB}?criteria=kode_jenis_kegiatan:1,id(in):16|17|18|19|20`;
    }
  } else {
    let params = "";
    Object.entries(payload).forEach(([key, value], index) => {
      params += `${key}:${value}${
        index + 1 === Object.entries(payload).length ? "" : ","
      }`;
    });
    URL = `${url.ENDPOINT_KATEGORI_RAB}?criteria=${params}&page=1:1000`;
  }
  return instances.request(URL, {
    method: "GET",
  });
};

const getPenelitian = ({
  year,
  id,
  url: urls = "",
  jenis_usulan = "penelitian",
}) =>
  instances.request(
    `/${jenis_usulan}/${jenis_usulan}${urls}${
      year ? `?criteria=thn_pelaksanaan_kegiatan:${year}` : ""
    }${id ? `/${id}` : ""}`,
    {
      method: "GET",
    }
  );

const uploadDokumen = payload => {
  const formData = new FormData();
  const path = payload.path;

  formData.append("file", payload.file);
  formData.append("path", path);
  if (payload.id_usulan) {
    formData.append("id_usulan", payload.id_usulan);
  }
  return instances.request(`${payload.url ?? url.ENDPOINT_UPLOAD}`, {
    data: formData,
    method: "POST",
  });
};

const getRABKomponen = ({ id, kode_status_aktif }) => {
  let params = `?page=1:1000`;
  if (id) {
    params += `&criteria=id_rab_kelompok_biaya:${id}`;
  }
  if (kode_status_aktif) {
    if (id) {
      params += `,kode_status_aktif:${kode_status_aktif}`;
    } else {
      params += `&criteria=kode_status_aktif:${kode_status_aktif}`;
    }
  }
  return instances.request(`${url.ENDPOINT_RAB_KOMPONEN}${params}`, {
    method: "GET",
  });
};

const getKelompokSkema = ({ lvl_tkt, jenis_kegiatan = null }) => {
  let params = ``;
  if (lvl_tkt) {
    params += `/by-level-tkt?level_tkt=${lvl_tkt}`;
  }
  let URL = "";
  if (window.location.pathname.includes("penelitian")) {
    URL = `${url.KATEGORI_PENELITIAN}${params}`;
  } else if (window.location.pathname.includes("pengabdian")) {
    URL = `/referensi/kategori-pengabdian${params}`;
  }
  if (jenis_kegiatan === "penelitian") {
    URL = `${url.KATEGORI_PENELITIAN}${params}`;
  } else if (jenis_kegiatan === "pengabdian") {
    URL = `/referensi/kategori-pengabdian${params}`;
  }
  return instances.request(URL, {
    method: "GET",
  });
};

const getKeterlibatanMahasiswa = ({ idUsulanKegiatan, tahapan = "" }) => {
  let urlKeterlibatanMahasiswa = `${url.ENDPOINT_KETERLIBATAN_MAHASISWA}?sort=tgl_created:desc&page=1:1000&criteria=id_usulan_kegiatan:${idUsulanKegiatan}`;
  if (window.location.pathname.includes("laporan-kemajuan")) {
    urlKeterlibatanMahasiswa += ",tahapan:laporan_kemajuan";
  }
  if (window.location.pathname.includes("penelitian/revisi")) {
    urlKeterlibatanMahasiswa += ",tahapan:perbaikan_usulan";
  }
  if (tahapan) {
    urlKeterlibatanMahasiswa += ",tahapan:" + tahapan;
  }
  return instances.request(urlKeterlibatanMahasiswa, {
    method: "GET",
  });
};

const createKeterlibatanMahasiswa = payload => {
  return instances.request(`${url.ENDPOINT_KETERLIBATAN_MAHASISWA}`, {
    method: "POST",
    data: payload,
  });
};

const updateKeterlibatanMahasiswa = payload => {
  return instances.request(
    `${url.ENDPOINT_KETERLIBATAN_MAHASISWA}/${payload.id}`,
    {
      method: "PUT",
      data: payload,
    }
  );
};

const deleteKeterlibatanMahasiswa = id => {
  return instances.request(`${url.ENDPOINT_KETERLIBATAN_MAHASISWA}/${id}`, {
    method: "DELETE",
  });
};

const getJumlahMahasiswaTerlibat = idUsulanKegiatan => {
  return instances.request(
    `${url.ENDPOINT_KETERLIBATAN_MAHASISWA}/jml-mahasiswa-terlibat/${idUsulanKegiatan}`,
    {
      method: "GET",
    }
  );
};

const getKategoriSBK = () => {
  return instances.request(`${url.ENDPOINT_KATEGORI_SBK}`, {
    method: "GET",
  });
};

const getPrioritasRiset = () => {
  return instances.request(`${url.ENDPOINT_PRIORITAS_RISET}`, {
    method: "GET",
  });
};

const getMahasiswa = nim => {
  let params = "";
  if (nim) params = `?nim=${nim}`;
  return instances.request(`${url.ENDPOINT_MAHASISWA}${params}`, {
    method: "GET",
  });
};

const getRuleSkema = id_skema => {
  let URL = "";
  if (window.location.pathname.includes("penelitian")) {
    URL = `${url.ENDPOINT_RULE_SKEMA}?criteria=id_skema:${id_skema}`;
  } else if (window.location.pathname.includes("pengabdian")) {
    URL = `${url.ENDPOINT_RULE_SKEMA_PENGABDIAN}?criteria=id_skema:${id_skema}`;
  }
  return instances.request(URL, {
    method: "GET",
  });
};

const getEligibilitasDosen = ({ id_personal, id_skema }) => {
  return instances.request(
    `${url.ENDPOINT_ELIGIBIILITAS_DOSEN}/${id_personal}/${id_skema}`,
    {
      method: "GET",
    }
  );
};

const getEligibility = ({ mode = "" }) => {
  let URL = "";
  if (window.location.pathname.includes("penelitian")) {
    URL = `${url.ENDPOINT_ELIGIBILITY}${mode === "edit" ? "?mode=edit" : ""}`;
  } else if (window.location.pathname.includes("pengabdian")) {
    URL = `${url.ENDPOINT_ELIGIBILITY_PENGABDIAN}${
      mode === "edit" ? "?mode=edit" : ""
    }`;
  }
  return instances.request(URL, {
    method: "GET",
  });
};

const getAvailableSkemaByEligibility = ({ mode = "create" }) => {
  let URL = "";

  // if (window.location.pathname.includes("/pengabdian/usulan/form")) {
  //   const pathArray = window.location.pathname.split("/");
  //   const idUsulan = pathArray[pathArray.length - 1];
  //   mode = "edit";
  // }

  if (mode === "edit") {
    if (window.location.pathname.includes("/penelitian")) {
      URL = `${url.ENDPOINT_AVAILABLE_SKEMA}?mode=edit`;
    } else if (window.location.pathname.includes("/pengabdian")) {
      URL = `${url.ENDPOINT_AVAILABLE_SKEMA_PENGABDIAN}?mode=edit`;
    }
  } else {
    if (window.location.pathname.includes("/penelitian")) {
      URL = url.ENDPOINT_AVAILABLE_SKEMA;
    } else if (window.location.pathname.includes("/pengabdian")) {
      URL = url.ENDPOINT_AVAILABLE_SKEMA_PENGABDIAN;
    }
  }

  return instances.request(URL, {
    method: "GET",
  });
};

const saveIdentitasUsulan = payload =>
  instances.request(`${url.ENDPOINT_SAVE_IDENTITAS_USULAN}`, {
    method: "POST",
    data: payload,
  });

const updateUsulanPenelitan = ({ payload, id_usulan }) =>
  instances.request(`${url.ENDPOINT_PROPOSAL_PENELITIAN}/${id_usulan}`, {
    method: "PUT",
    data: payload,
  });

const updateIdentitasUsulan = ({ payload, id_usulan }) => {
  return instances.request(
    `${url.ENDPOINT_SAVE_IDENTITAS_USULAN}/${id_usulan}`,
    {
      method: "PUT",
      data: payload,
    }
  );
};

const saveSubtansiUsulan = payload =>
  instances.request(`${url.ENDPOINT_SAVE_SUBTANSI_USULAN}`, {
    method: "POST",
    data: payload,
  });

const updateSubtansiUsulan = ({ payload, id_usulan }) =>
  instances.request(`${url.ENDPOINT_SAVE_SUBTANSI_USULAN}/${id_usulan}`, {
    method: "PUT",
    data: payload,
  });

const saveMitraUsulan = ({ payload, id_usulan }) =>
  instances.request(`${url.ENDPOINT_SAVE_MITRA_USULAN}/${id_usulan}`, {
    method: "POST",
    data: payload,
  });

const updateMitraUsulan = ({ payload, id_usulan }) =>
  instances.request(`${url.ENDPOINT_SAVE_MITRA_USULAN}/${id_usulan}`, {
    method: "PUT",
    data: payload,
  });

const saveRABUsulan = ({ payload, id_usulan }) => {
  return instances.request(`${url.ENDPOINT_SAVE_RAB_USULAN}/${id_usulan}`, {
    method: "POST",
    data: payload,
  });
};

const updateRABUsulan = ({ payload, id_usulan }) =>
  instances.request(`${url.ENDPOINT_SAVE_RAB_USULAN}/${id_usulan}`, {
    method: "PUT",
    data: payload,
  });

const getRABByUsulanId = ({ id_usulan }) =>
  instances.request(`${url.ENDPOINT_SAVE_RAB_USULAN}/${id_usulan}`, {
    method: "GET",
  });

const getListUsulan = (id = "") => {
  let endpoint = "";
  let kd_jenis_kegiatan = new URLSearchParams(window.location.search).get(
    "kd_jenis_kegiatan"
  );

  if (window.location.pathname.includes("/pengabdian")) {
    endpoint = url.ENDPOINT_LIST_USULAN_PENGABDIAN;
  } else if (window.location.pathname.includes("/penelitian")) {
    endpoint = url.ENDPOINT_LIST_USULAN_PENELITIAN;
    // Approval Usulan Penelitian Kepala LPPM
  } else if (
    kd_jenis_kegiatan === "1" &&
    window.location.pathname.includes("/usulan-belum-ditinjau")
  ) {
    endpoint = url.ENDPOINT_LIST_USULAN_PENELITIAN;
  } else if (
    kd_jenis_kegiatan === "1" &&
    window.location.pathname.includes("/usulan-baru")
  ) {
    endpoint = url.ENDPOINT_LIST_USULAN_PENELITIAN;
  } else if (
    kd_jenis_kegiatan === "1" &&
    window.location.pathname.includes("/usulan-dikirim")
  ) {
    endpoint = url.ENDPOINT_LIST_USULAN_PENELITIAN;
  } else if (
    kd_jenis_kegiatan === "1" &&
    window.location.pathname.includes("/usulan-disetujui")
  ) {
    endpoint = url.ENDPOINT_LIST_USULAN_PENELITIAN;
  } else if (
    kd_jenis_kegiatan === "1" &&
    window.location.pathname.includes("/usulan-ditolak")
  ) {
    endpoint = url.ENDPOINT_LIST_USULAN_PENELITIAN;
  }
  // Approval Usulan Pengabdian Kepala LPPM
  else if (
    kd_jenis_kegiatan === "2" &&
    window.location.pathname.includes("/usulan-belum-ditinjau")
  ) {
    endpoint = url.ENDPOINT_LIST_USULAN_PENGABDIAN;
  } else if (
    kd_jenis_kegiatan === "2" &&
    window.location.pathname.includes("/usulan-baru")
  ) {
    endpoint = url.ENDPOINT_LIST_USULAN_PENGABDIAN;
  } else if (
    kd_jenis_kegiatan === "2" &&
    window.location.pathname.includes("/usulan-dikirim")
  ) {
    endpoint = url.ENDPOINT_LIST_USULAN_PENGABDIAN;
  } else if (
    kd_jenis_kegiatan === "2" &&
    window.location.pathname.includes("/usulan-disetujui")
  ) {
    endpoint = url.ENDPOINT_LIST_USULAN_PENGABDIAN;
  } else if (
    kd_jenis_kegiatan === "2" &&
    window.location.pathname.includes("/usulan-ditolak")
  ) {
    endpoint = url.ENDPOINT_LIST_USULAN_PENGABDIAN;
  }
  // Reviewer
  if (
    window.location.pathname.includes("/reviewer") &&
    kd_jenis_kegiatan === "1"
  ) {
    endpoint = url.ENDPOINT_LIST_USULAN_PENELITIAN;
  } else if (
    window.location.pathname.includes("/reviewer") &&
    kd_jenis_kegiatan === "2"
  ) {
    endpoint = url.ENDPOINT_LIST_USULAN_PENGABDIAN;
  }
  if (window.location.pathname.includes("/penelitian")) {
    endpoint = url.ENDPOINT_LIST_USULAN_PENELITIAN;
  }
  if (window.location.pathname.includes("/pengabdian")) {
    endpoint = url.ENDPOINT_LIST_USULAN_PENGABDIAN;
  }

  if (id) {
    endpoint = endpoint + "/" + id;
  }

  return instances.request(`${endpoint}`, {
    method: "GET",
  });
};

const deleteUsulanPenelitian = idUsulan => {
  return instances.request(
    `${url.ENDPOINT_LIST_USULAN_PENELITIAN}/${idUsulan}`,
    {
      method: "DELETE",
    }
  );
};

const getListNegara = () => {
  return instances.request(`${url.ENDPOINT_GET_LIST_NEGARA}?page=1:1000`, {
    method: "GET",
  });
};
const getListProvinsi = () => {
  return instances.request(`${url.ENDPOINT_GET_LIST_PROVINSI}?page=1:1000`, {
    method: "GET",
  });
};
const getListKota = () => {
  return instances.request(`${url.ENDPOINT_GET_LIST_KOTA}?page=1:1000`, {
    method: "GET",
  });
};
const getListOptionMitraKelompok = () => {
  return instances.request(`/enumeration?criteria=group:kelompok-mitra`, {
    method: "GET",
  });
};
const getListOptionMitraJenisMitra = () => {
  return instances.request(`/enumeration?criteria=group(exact):jenis-mitra`, {
    method: "GET",
  });
};
// Pengabdian
const getListTemaPrioritasTematik = () => {
  return instances.request(`/referensi/tema-prioritas`, {
    method: "GET",
  });
};
// Pengabdian
const getJenisLuaranPengabdian = async kd_kategori_jenis_luaran => {
  const res = await instances.request(
    `/pengabdian/jenis-luaran?criteria=kode_kategori_jenis_luaran:${kd_kategori_jenis_luaran}&page=1:1000`,
    {
      method: "GET",
    }
  );
  return res;
};

const getListBidangFokusRirn = async () => {
  const res = await instances.request(
    `/enumeration?criteria=group:config,key:id-bidang-fokus-rirn-2023`,
    {
      method: "GET",
    }
  );
  const filterData = res?.data?.data?.[0]?.val?.split(",")?.join("|");
  return instances.request(
    `/referensi/bidang-fokus?criteria=id_bidang_fokus(in):${filterData}`,
    {
      method: "GET",
    }
  );
};
const postStep1IdentitasPengabdian = payload => {
  // console.log(payload);
  return instances.request(`${url.ENDPOINT_SAVE_STEP_1_IDENTITAS_PENGABDIAN}`, {
    method: "POST",
    data: payload,
  });
};
const putAllStepUsulanPengabdian = payload => {
  // console.log(payload);
  return instances.request(
    `${url.ENDPOINT_LIST_USULAN_PENGABDIAN}/${payload.id_usulan}`,
    {
      method: "PUT",
      data: payload.newPayloadForUpdate,
    }
  );
};

const getReferensiPeranPersonil = async () => {
  const res = await instances.request(`/referensi/peran-personil?page=1:100`, {
    method: "GET",
  });
  return res;
};

const getWhiteListUsulan = payload => {
  const params = `?criteria=id_personal:${payload.id_personal},kd_sts_aktif:1${
    payload.thn_pelaksanaan ? ",thn_pelaksanaan=" + payload.thn_pelaksanaan : ""
  }`;
  return instances.request(`${url.ENDPOINT_WHITELIST_USULAN}${params}`, {
    method: "GET",
  });
};

const getProgramKerjasamaLn = () => {
  const params = `?criteria=group:program_kerjasama_luar_negeri`;
  return instances.request(`${url.ENDPOINT_ENUMERATION}${params}`, {
    method: "GET",
  });
};
const getListKelompokMitraSasaranPengabdianV2Dikti = async () => {
  return await instances.request(
    `/enumeration?criteria=group:kelompok_mitra_sasaran_pengabdian_v2_dikti`,
    {
      method: "GET",
    }
  );
};
const getListKelompokMitraPemerintahPengabdianV2Dikti = async () => {
  return await instances.request(
    `/enumeration?criteria=group:kelompok_mitra_pemerintah_pengabdian_v2_dikti`,
    {
      method: "GET",
    }
  );
};
const getListKelompokIkuTerkaitPengabdianV2Dikti = async () => {
  return await instances.request(
    `/enumeration?criteria=group:indikator_kinerja_utama_pengabdian_v2_dikti&page=1:1000`,
    {
      method: "GET",
    }
  );
};
const getListKelompokUraianIkuPengabdianV2Dikti = async () => {
  return await instances.request(
    `/enumeration?criteria=group:uraian_iku_pengabdian_v2_dikti&page=1:1000`,
    {
      method: "GET",
    }
  );
};

const getListLingkupPermasalahanPengabdianV2Dikti = async () => {
  return await instances.request(
    `/enumeration?criteria=group:lingkup_permasalahan_pengabdian_v2_dikti&page=1:1000`,
    {
      method: "GET",
    }
  );
};
const getListBidangKewilayahanPengabdianV2Dikti = async () => {
  return await instances.request(
    `/enumeration?criteria=group:bidang_kewilayahan_mitra_pengabdian_v2&page=1:1000`,
    {
      method: "GET",
    }
  );
};
const getListBidangSdgsPengabdianV2Dikti = async () => {
  return await instances.request(
    `/enumeration?criteria=group:sdgs_pengabdian_v2_dikti&page=1:1000`,
    {
      method: "GET",
    }
  );
};
const getListKecamatanPengabdianV2Dikti = async (kd_kota = "") => {
  return await instances.request(
    `/pdpt/kecamatan?criteria=kd_kota:${kd_kota.replace(
      /\s/g,
      ""
    )}&page=1:1000`,
    {
      method: "GET",
    }
  );
};
const getListKelurahanPengabdianV2Dikti = async (kd_kecamatan = "") => {
  return await instances.request(
    `/pdpt/kelurahan?criteria=kd_kecamatan:${kd_kecamatan.replace(
      /\s/g,
      ""
    )}&page=1:1000`,
    {
      method: "GET",
    }
  );
};

const saveMinatKatalis = ({ payload, method, id }) => {
  const params = {
    method: method,
    data: payload,
  };

  if (id && method === "PUT") {
    return instancesV2.request(
      `${url.ENDPOINT_MINAT_KATALIS}/minat-katalis/${id}`,
      params
    );
  } else {
    return instancesV2.request(
      `${url.ENDPOINT_MINAT_KATALIS}/minat-katalis`,
      params
    );
  }
};

const getMinatKatalis = (payload = {}) => {
  let params = "";
  Object.entries(payload).forEach(([key, value], index) => {
    params += `${key}=${value}${
      index + 1 === Object.entries(payload).length ? "" : "&"
    }`;
  });

  let URL = `${url.ENDPOINT_MINAT_KATALIS}/minat-katalis`;
  if (params) {
    URL += `?${params}`;
  }

  return instancesV2.request(URL, { method: "GET" });
};

const deleteMinatKatalis = id => {
  let URL = `${url.ENDPOINT_MINAT_KATALIS}/minat-katalis/${id}`;

  return instancesV2.request(URL, { method: "DELETE" });
};

const approvalMemberKatalis = ({ id, approval, id_personal }) => {
  let URL = `${url.ENDPOINT_MINAT_KATALIS}/minat-katalis-personil/${id}/${id_personal}`;

  return instancesV2.request(URL, {
    method: "PUT",
    data: {
      status: approval === 1 ? "Approved" : "Rejected",
    },
  });
};

const approvalPusatKatalis = ({ id, approval, komentar = "" }) => {
  let URL = `${url.ENDPOINT_MINAT_KATALIS}/minat-katalis/status-pusat/${id}`;

  return instancesV2.request(URL, {
    method: "PUT",
    data: {
      pusat_status_approval:
        approval === 1
          ? "Approved"
          : approval === 2
          ? "Waiting Approval"
          : "Rejected",
      pusat_komen: komentar || "",
    },
  });
};

const handleDownloadProposal = async (
  data,
  cb = () => {},
  forceDownload = true
) => {
  let { identitas, substansi, mitra } = data;
  try {
    const fileName = `${identitas?.judul.replace(/[^a-zA-Z0-9 ]/g, "").trim()}`;
    // * Generate Pdf From Client
    const proposalPDF = new ProposalPDF({
      ...data,
      jenis_kegiatan: "Penelitian",
    });

    const blobFileMainContent = await proposalPDF.generate();
    const blobFilePersetContent = await proposalPDF.generatePersetujuan();

    // * Get Pdf File From Server

    const contentMerge = [
      {
        file: blobFileMainContent,
        fileType: "pdf",
      },
    ];

    let extractExtension = "";
    let extractFileName = "";

    if (substansi?.file_url_substansi) {
      extractExtension = substansi?.file_url_substansi?.split(".")?.pop() || "";
      extractFileName =
        substansi?.file_url_substansi?.split("/")?.pop() || "no-name";
      const blobFileSubstansi = await proposalPDF.getSingleFile(
        utilities.getFriendlyURL(substansi?.file_url_substansi)
      );
      if (extractExtension !== "pdf") {
        contentMerge.push({
          file: blobFileSubstansi,
          fileType: extractExtension,
          fileName: extractFileName,
        });
      } else {
        contentMerge.push({
          file: blobFileSubstansi,
          fileType: "pdf",
          fileName: extractFileName,
        });
      }
    }

    if (data?.perbaikan_usulan) {
      if (data?.perbaikan_usulan?.file_substansi?.url) {
        //ekstrak extension file and name file
        extractExtension =
          data?.perbaikan_usulan?.file_substansi?.url?.split(".")?.pop() || "";
        extractFileName =
          data?.perbaikan_usulan?.file_substansi?.url?.split("/")?.pop() ||
          "no-name";

        const blobFileSubstansiPerbaikan = await proposalPDF.getSingleFile(
          utilities.getFriendlyURL(data?.perbaikan_usulan?.file_substansi?.url)
        );
        contentMerge.splice(1, 1); //hapus substansi lama

        if (extractExtension !== "pdf") {
          contentMerge.push({
            file: blobFileSubstansiPerbaikan,
            fileType: extractExtension,
            fileName: extractFileName,
          });
        } else {
          contentMerge.push({
            file: blobFileSubstansiPerbaikan,
            fileType: "pdf",
            fileName: extractFileName,
          });
        }
      }
      if (data?.perbaikan_usulan?.file_kesanggupan?.url) {
        extractExtension =
          data?.perbaikan_usulan?.file_kesanggupan?.url?.split(".")?.pop() ||
          "";
        extractFileName =
          data?.perbaikan_usulan?.file_kesanggupan?.url?.split("/")?.pop() ||
          "no-name";

        const blobFileKesanggupanPerbaikan = await proposalPDF.getSingleFile(
          utilities.getFriendlyURL(
            data?.perbaikan_usulan?.file_kesanggupan?.url
          )
        );

        if (extractExtension !== "pdf") {
          contentMerge.push({
            file: blobFileKesanggupanPerbaikan,
            fileType: extractExtension,
            fileName: extractFileName,
          });
        } else {
          contentMerge.push({
            file: blobFileKesanggupanPerbaikan,
            fileType: "pdf",
            fileName: extractFileName,
          });
        }
      }
    }

    for (const mitraRow of mitra) {
      if (mitraRow?.url_surat_kesanggupan_mitra) {
        extractExtension =
          mitraRow?.url_surat_kesanggupan_mitra?.split(".")?.pop() || "";
        extractFileName =
          mitraRow?.url_surat_kesanggupan_mitra?.split("/")?.pop() || "no-name";

        let blobFileMitra = await proposalPDF.getSingleFile(
          utilities.getFriendlyURL(mitraRow?.url_surat_kesanggupan_mitra)
        );
        if (extractExtension !== "pdf") {
          contentMerge.push({
            file: blobFileMitra,
            fileType: extractExtension,
            fileName: extractFileName,
          });
        } else {
          contentMerge.push({
            file: blobFileMitra,
            fileType: "pdf",
            fileName: extractFileName,
          });
        }
      }
    }

    //push conten persetujuan
    contentMerge.push({ file: blobFilePersetContent, fileType: "pdf" });

    //filter pdf tahap 2
    const pdfDocs = contentMerge.filter(item => item.fileType === "pdf");
    const nonPdfDocs = contentMerge.filter(item => item.fileType !== "pdf");

    const mergingFun = () => {
      //merging version 1 for merge common files
      //merging version 2 for merge files ignoring encrypted file
      //merging version 3 for merge files ignoring encrypted file & lost merged the file
      proposalPDF.mergingv3(
        pdfDocs.map(itm => itm.file),
        result => {
          const resultMerged = result.res;

          if (result.error === false) {
            if (forceDownload)
              proposalPDF.forceDownload(resultMerged, fileName + ".pdf");
            cb({
              success: true,
              result: resultMerged,
              fileName,
            });
          } else {
            mergingFun();
            toast.warn("File mitra tidak dapat di gabung!");

            if (result?.indexBlobSkipped) {
              proposalPDF.forceDownload(
                contentMerge[result.indexBlobSkipped].file,
                `mitra_${result.indexBlobSkipped - 1}.pdf`
              );
            }
          }
        }
      );
    };

    mergingFun();
    if (nonPdfDocs.length > 0) {
      toast.warn("file doc/docx tidak dapat digabung");
      nonPdfDocs.forEach((doc, i) => {
        proposalPDF.forceDownload(doc.file, `${doc.fileName}`);
      });
    }
  } catch (error) {
    console.log(error);

    cb({
      success: false,
      errorMessage: "Terjadi Kesalahan! silahkan coba beberapa saat lagi",
    });
  }
};

export const downloadProposalPenelitanV2 = async (data, cb, flag = 1) => {
  //flag 1 = force download
  //flag 2 = without force download

  let {
    raw: { mitra, identitas, substansi, dokumen_pendukung },
  } = data;
  try {
    const fileName = `${identitas?.judul.replace(/[^a-zA-Z0-9 ]/g, "").trim()}`;
    // * Generate Pdf From Client
    const proposalPDF = new ProposalPenelitianV2({
      ...data,
    });
    const blobFileMainContent = await proposalPDF.generate();
    const blobFilePersetContent = await proposalPDF.generatePersetujuan();
    // * Get Pdf File From Server
    const contentMerge = [blobFileMainContent];

    if (substansi?.file_url_substansi) {
      const blobFileSubstansi = await proposalPDF.getSingleFile(
        utilities.getFriendlyURL(substansi?.file_url_substansi)
      );
      contentMerge.push(blobFileSubstansi);
    }

    if (dokumen_pendukung?.file_url_surat_keputusan) {
      const blobFilePendukung = await proposalPDF.getSingleFile(
        utilities.getFriendlyURL(dokumen_pendukung?.file_url_surat_keputusan)
      );
      contentMerge.push(blobFilePendukung);
    }
    if (dokumen_pendukung?.file_url_artikel_penulis_pertama) {
      const blobFilePendukung = await proposalPDF.getSingleFile(
        utilities.getFriendlyURL(
          dokumen_pendukung?.file_url_artikel_penulis_pertama
        )
      );
      contentMerge.push(blobFilePendukung);
    }

    for (const m of mitra) {
      if (m.url_surat_kesanggupan_mitra) {
        let blobFilePendukung = await proposalPDF.getSingleFile(
          utilities.getFriendlyURL(m.url_surat_kesanggupan_mitra)
        );
        contentMerge.push(blobFilePendukung);
      }
    }

    if (data?.revisi) {
      if (data.revisi?.url_file_surat_kesanggupan) {
        let blobFileKesanggupan = await proposalPDF.getSingleFile(
          utilities.getFriendlyURL(data.revisi?.url_file_surat_kesanggupan)
        );
        contentMerge.push(blobFileKesanggupan);
      }
    }

    contentMerge.push(blobFilePersetContent);

    const mergingFun = () => {
      proposalPDF.merging(contentMerge, result => {
        const resultMerged = result.res;

        if (result.error === false) {
          if (flag !== 2) {
            proposalPDF.forceDownload(resultMerged, fileName + ".pdf");
          }
          cb({
            success: true,
            data: resultMerged,
          });
        } else {
          toast.warn("Beberapa file tidak dapat di gabung!");
          if (result.indexBlobSkipped) {
            mergingFun();
            proposalPDF.forceDownload(
              contentMerge[result.indexBlobSkipped],
              `file_${result.indexBlobSkipped - 1}.pdf`
            );
          }
        }
      });
    };

    mergingFun();
  } catch (error) {
    console.log(error);

    cb({
      success: false,
      errorMessage: "Terjadi Kesalahan! silahkan coba beberapa saat lagi",
    });
  }
};

const downloadPdfUsulanPerbaikan = async (
  { item_perbaikan_usulan, item_usulan, supportData },
  cb
) => {
  try {
    const proposalPDF = new ProposalPDF({
      ...item_usulan,
      jenis_kegiatan: "Penelitian",
    });

    const handleDownloadFun =
      supportData.jenis_kegiatan === 1
        ? handleDownloadProposal
        : handleDownloadProposalPengabdian;

    handleDownloadFun(
      item_usulan,
      async resultProposal => {
        if (resultProposal.success) {
          const blobFileMainContent = await proposalPDF.generatePerbaikanUsulan(
            {
              item: item_perbaikan_usulan,
              kelompok_biaya: supportData.kelompok_biaya,
            }
          );

          const contentMerge = [resultProposal.result, blobFileMainContent];

          // * Get Pdf File From Server
          let blobFileSubstansi = "";
          let blobFileKesanggupan = "";
          if (
            item_perbaikan_usulan?.file_substansi?.url &&
            supportData.jenis_kegiatan === 1
          ) {
            blobFileSubstansi = await proposalPDF.getSingleFile(
              utilities.getFriendlyURL(
                item_perbaikan_usulan?.file_substansi?.url
              )
            );
            contentMerge.push(blobFileSubstansi);
          }
          if (item_perbaikan_usulan?.file_kesanggupan?.url) {
            blobFileKesanggupan = await proposalPDF.getSingleFile(
              utilities.getFriendlyURL(
                item_perbaikan_usulan?.file_kesanggupan?.url
              )
            );
            contentMerge.push(blobFileKesanggupan);
          }

          const mergingFun = () => {
            //merging version 1 for merge common files
            //merging version 2 for merge files ignoring encrypted file
            //merging version 3 for merge files ignoring encrypted file & lost merged the file
            proposalPDF.mergingv3(contentMerge, result => {
              const resultMerged = result.res;
              if (result.error === false) {
                proposalPDF.forceDownload(
                  resultMerged,
                  resultProposal.fileName
                );

                cb({
                  success: true,
                  result: resultMerged,
                });
              } else {
                mergingFun();
                toast.warn(
                  "Terjadi kesalahan saat penggabungan dokumen, silahkan periksa dokumen anda!"
                );
                proposalPDF.forceDownload(
                  contentMerge[result.indexBlobSkipped],
                  `mitra_${result.indexBlobSkipped - 1}.pdf`
                );
              }
            });
          };
          mergingFun();
        }
      },
      false
    );
  } catch (error) {
    console.log(error);

    cb({
      success: false,
      errorMessage: "Terjadi Kesalahan! silahkan coba beberapa saat lagi",
    });
  }
};

export {
  getSbk,
  getTema,
  getTopik,
  getDosen,
  getListKota,
  getMahasiswa,
  getRuleSkema,
  getListNegara,
  getRumpunIlmu,
  uploadDokumen,
  getListUsulan,
  getPenelitian,
  saveRABUsulan,
  getEligibility,
  getBidangFokus,
  getEnumeration,
  getKategoriSBK,
  getRABKomponen,
  getJenisLuaran,
  saveMitraUsulan,
  getListProvinsi,
  getMinatKatalis,
  updateRABUsulan,
  getRABByUsulanId,
  saveMinatKatalis,
  getPeranPersonil,
  getKelompokSkema,
  getSkemaKegiatan,
  updateMitraUsulan,
  getKategoriLuaran,
  getTargetAkhirTKT,
  getPrioritasRiset,
  getBidangUnggulan,
  deleteMinatKatalis,
  getWhiteListUsulan,
  saveSubtansiUsulan,
  saveIdentitasUsulan,
  getKelompokRabBiaya,
  approvalPusatKatalis,
  updateSubtansiUsulan,
  getEligibilitasDosen,
  getKelompokMakroRiset,
  getKategoriPenelitian,
  getProgramKerjasamaLn,
  updateIdentitasUsulan,
  updateUsulanPenelitan,
  approvalMemberKatalis,
  deleteUsulanPenelitian,
  handleDownloadProposal,
  getListBidangFokusRirn,
  getKategoriIndikatorTKT,
  getKeterlibatanMahasiswa,
  validasiKeanggotaanDosen,
  getJenisLuaranPengabdian,
  getReferensiPeranPersonil,
  getIndikatorTKTByKategori,
  getJumlahMahasiswaTerlibat,
  downloadPdfUsulanPerbaikan,
  putAllStepUsulanPengabdian,
  getListOptionMitraKelompok,
  getListTemaPrioritasTematik,
  createKeterlibatanMahasiswa,
  updateKeterlibatanMahasiswa,
  deleteKeterlibatanMahasiswa,
  getListOptionMitraJenisMitra,
  postStep1IdentitasPengabdian,
  getAvailableSkemaByEligibility,
  getListKelompokUraianIkuPengabdianV2Dikti,
  getListKelompokIkuTerkaitPengabdianV2Dikti,
  getListLingkupPermasalahanPengabdianV2Dikti,
  getListKelompokMitraSasaranPengabdianV2Dikti,
  getListKelompokMitraPemerintahPengabdianV2Dikti,
  getListBidangKewilayahanPengabdianV2Dikti,
  getListKecamatanPengabdianV2Dikti,
  getListKelurahanPengabdianV2Dikti,
  getListBidangSdgsPengabdianV2Dikti,
  handleDownloadProposal as downloadProposalPenelitianV1,
};
