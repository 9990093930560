/* eslint-disable no-unused-vars */
import stylePDF from "./styles";
import header from "./header";
import {
  contentAnggaranRevisi,
  contentPrototipe,
} from "../../prototipe/partials/content";
import utilities from "helpers/utilities";
import { contentKosabangsa } from "../../kosabangsa/proposal/partials/content";
import { contentProposalForLaporanKemajuanPenelitianV2 } from "../../usulanPenelitanV2/partials/content";
import { contentProposalForLaporanKemajuanPengabdianV2 } from "../../usulanBaruPengabdianV2/partials/contentDikti";
const getThnAkhirUsulan = (lamaKegiatan, thnPertamaUsulan) => {
  let thnAkhirUsulan = parseInt(thnPertamaUsulan);
  for (let tahun = 1; tahun < parseInt(lamaKegiatan); tahun++) {
    thnAkhirUsulan += tahun;
  }
  return thnAkhirUsulan;
};

//flag jenis kegiatan 1 = regular, 2 = kosabangsa, 3 = prototipe;
const content = (data, flagJenisKegiatan, jenisKegiatan) => {
  const { hr } = stylePDF;

  const {
    raw: { identitas },
  } = data.proposal;

  const { nama_skema } = data.laporan_kemajuan;
  let mainContent;
  if (flagJenisKegiatan === 1 && jenisKegiatan === "penelitian") {
    // Penelitian
    mainContent = [
      ...contentProposalForLaporanKemajuanPenelitianV2(data.proposal),
    ];
  } else if (flagJenisKegiatan === 2 && jenisKegiatan === "pengabdian") {
    // Pengabdian
    mainContent = [
      ...contentProposalForLaporanKemajuanPengabdianV2(data.proposal),
    ];
  } else if (flagJenisKegiatan === 1 && jenisKegiatan === "prototipe") {
    // Prototipe
    mainContent = [
      ...contentPrototipe(data.proposal),
      {
        style: ["mt-10"],
        stack: contentAnggaranRevisi(data.revisi_proposal),
      },
    ];
  } else if (flagJenisKegiatan === 2 && jenisKegiatan === "kosabangsa") {
    // Kosabangsa
    mainContent = [
      ...contentKosabangsa(data.proposal, data?.revisi_proposal || null),
    ];
  }

  return [
    header(),
    hr(5, 0),
    {
      stack: [
        {
          text: "PROTEKSI ISI LAPORAN KEMAJUAN " + nama_skema?.toUpperCase(),
          style: ["bold"],
        },
        {
          text: "Dilarang menyalin, menyimpan, memperbanyak sebagian atau seluruh isi proposal ini dalam bentuk apapun \n kecuali oleh pengusul dan pengelola administrasi pengabdian kepada masyarakat",
          style: ["fontSizeXs"],
        },
        {
          text: `LAPORAN KEMAJUAN ${identitas?.tahun_pelaksanaan ?? ""}`,
          color: "#FFFFFF",
          background: "#000",
          style: ["center", "bold"],
        },
        {
          text: `Rencana Pelaksanaan ${nama_skema}: tahun ${
            identitas?.tahun_pelaksanaan ?? ""
          } s.d. tahun ${getThnAkhirUsulan(
            identitas?.lama_kegiatan,
            identitas.tahun_pelaksanaan
          )}`,
          style: ["fontSizeXs"],
        },
      ],
      style: ["center", "mb-5"],
    },
    ...mainContent,
  ];
};

const contentSptb = (data = {}) => {
  const sumNilai = (data = [], property) => {
    return data.reduce((accumulator, currentExpense) => {
      return accumulator + currentExpense[property];
    }, 0);
  };
  const id_rab_kelompok_biaya_list =
    data.type === "kosabangsa"
      ? [21, 22, 23, 24, 25, 26]
      : data.type === "prototipe"
      ? [16, 17, 18, 19, 20]
      : data.type === "pengabdian"
      ? [28, 29, 30, 31, 32]
      : [];
  const sumNilaiRab = (data = [], property) => {
    return data
      .filter(item =>
        id_rab_kelompok_biaya_list.includes(item.id_rab_kelompok_biaya)
      )
      .reduce((accumulator, currentExpense) => {
        return accumulator + currentExpense[property] * currentExpense.volume;
      }, 0);
  };
  const catatanHarian = data.dataCatatanHarian;
  const laporanKemajuan = data.dataLaporanKemajuan;
  const reMapped = catatanHarian.map(item => ({
    ...item,
    kelompok_biaya: item?.nama_rab_kelompok_biaya,
  }));
  const grouppedCatatanHarian = utilities.groupBy(reMapped, "kelompok_biaya");
  const grouppedRab = utilities.groupBy(
    catatanHarian[0]?.rab_komponen_belanja_revisi || [],
    "id_rab_kelompok_biaya"
  );
  const layout = {
    style: ["mb-10"],
    layout: {
      hLineWidth: (_i, _node) => 0.3,
      vLineWidth: _i => 0.3,
      padding: () => 0,
    },
    table: {
      headerRows: 1,
      widths: ["5%", "23.75%", "23.75%", "23.75%", "23.75%"],
      body: [
        [
          {
            text: `No`,
            style: ["center", "bgColorHeaderTable"],
          },
          {
            text: `Komponen Biaya`,
            style: ["center", "bgColorHeaderTable"],
          },
          {
            text: `No. Berkas`,
            style: ["center", "bgColorHeaderTable"],
          },
          {
            text: `RAB`,
            style: ["center", "bgColorHeaderTable"],
          },
          {
            text: `Jumlah Terealisasi`,
            style: ["center", "bgColorHeaderTable"],
          },
        ],
      ],
    },
  };
  Object.keys(grouppedCatatanHarian).forEach((property, indexProperty) => {
    grouppedCatatanHarian[property].forEach((item, indexItem) => {
      if (indexItem === 0) {
        layout.table.body.push([
          {
            text: `${indexProperty + 1}`,
            style: ["center", "bold"],
          },
          {
            text: `${property}`,
            style: ["center", "bold"],
          },
          {
            text: ``,
            style: ["center"],
          },
          {
            text: `${utilities.toCurrency(
              sumNilaiRab(
                grouppedRab[item.id_rab_kelompok_biaya],
                "harga_satuan"
              ),
              ".",
              0,
              "Rp. "
            )}`,
            style: ["center", "bold"],
          },
          {
            text: `${utilities.toCurrency(
              sumNilai(grouppedCatatanHarian[property], "biaya_terpakai"),
              ".",
              0,
              "Rp. "
            )}`,
            style: ["center", "bold"],
          },
        ]);
      }
      layout.table.body.push([
        {
          text: ``,
          style: ["center"],
        },
        {
          text: `${item.kegiatan}`,
          style: ["center"],
        },
        {
          text: `${item.no_berkas}`,
          style: ["center"],
        },
        {
          text: ``,
          style: ["center"],
        },
        {
          text: `${utilities.toCurrency(item.biaya_terpakai, ".", 0, "Rp. ")}`,
          style: ["center"],
        },
      ]);
    });
  });
  layout.table.body.push([
    {
      text: `Total`,
      style: ["center", "bold"],
      colSpan: 3,
    },
    {
      text: ``,
      style: ["center"],
    },
    {
      text: ``,
      style: ["center"],
    },
    {
      text: `${utilities.toCurrency(
        sumNilaiRab(
          catatanHarian[0]?.rab_komponen_belanja_revisi || [],
          "harga_satuan"
        ),
        ".",
        0,
        "Rp. "
      )}`,
      style: ["center", "bold"],
    },
    {
      text: `${utilities.toCurrency(
        sumNilai(catatanHarian, "biaya_terpakai"),
        ".",
        0,
        "Rp. "
      )}`,
      style: ["center", "bold"],
    },
  ]);
  const nameKetuaLembaga =
    data.type === "prototipe"
      ? laporanKemajuan.dosen_ketua.institusi?.additional?.find(
          item => item.peran.id === 9
        )?.personal?.nama
      : data.type === "kosabangsa"
      ? laporanKemajuan.dosen_ketua.institusi?.additional?.find(
          item => item.peran.id === 10
        )?.personal?.nama
      : "-";
  const nidnKetuaLembaga =
    data.type === "prototipe"
      ? laporanKemajuan.dosen_ketua.institusi?.additional?.find(
          item => item.peran.id === 9
        )?.nidn
      : data.type === "kosabangsa"
      ? laporanKemajuan.dosen_ketua.institusi?.additional?.find(
          item => item.peran.id === 10
        )?.nidn
      : "-";
  return [
    {
      stack: [
        {
          text: "Penggunaan Anggaran",
          style: ["bold", "center", "mb-10"],
        },
      ],
    },
    {
      stack: [
        {
          text: `Nama Ketua : ${laporanKemajuan.dosen_ketua.nama}`,
          style: ["bold", "mb-5"],
        },
        {
          text: `Judul : ${laporanKemajuan.judul}`,
          style: ["bold", "mb-5"],
        },
        {
          text: `Tahun Pelaksanaan : ${laporanKemajuan.thn_pelaksanaan}`,
          style: ["bold", "mb-5"],
        },
        {
          text: `Dana Disetujui : ${utilities.toCurrency(
            laporanKemajuan.dana_disetujui,
            ".",
            0,
            "Rp. "
          )}`,
          style: ["bold", "mb-5"],
        },
      ],
    },
    {
      stack: [
        {
          ...layout,
        },
      ],
    },
    {
      stack: [
        {
          text: `Sisa Dana : ${utilities.toCurrency(
            sumNilaiRab(
              catatanHarian[0]?.rab_komponen_belanja_revisi || [],
              "harga_satuan"
            ) - sumNilai(catatanHarian, "biaya_terpakai"),
            ".",
            0,
            "Rp. "
          )}`,
          style: ["mt-10", "bold"],
        },
        {
          text: `Dana yang sudah digunakan : ${(
            (sumNilai(catatanHarian, "biaya_terpakai") /
              sumNilaiRab(
                catatanHarian[0]?.rab_komponen_belanja_revisi || [],
                "harga_satuan"
              )) *
            100
          ).toFixed()} %
           `,
          style: ["mt-5", "bold"],
        },
      ],
    },
    {
      style: ["mt-20"],
      stack: [
        {
          style: ["text"],
          layout: "noBorders", // Menghilangkan border tabel,
          table: {
            widths: ["50%", "50%"],
            body: [
              [
                {
                  text: "Mengetahui, Ketua LPPM",
                  style: ["left"],
                },
                {
                  text: "Ketua Pelaksana",
                  style: ["right"],
                },
              ],
              [
                {
                  text: `\n${nameKetuaLembaga}`,
                  style: ["left", "mt-20"],
                },
                {
                  text: `\n${laporanKemajuan.dosen_ketua.nama}`,
                  style: ["right", "mt-20"],
                },
              ],
              [
                {
                  text: `${nidnKetuaLembaga}`,
                  style: ["left", "mt-2"],
                },
                {
                  text: `${laporanKemajuan.dosen_ketua.nidn}`,
                  style: ["right", "mt-2"],
                },
              ],
            ],
          },
        },
      ],
    },
  ];
};
export { content, contentSptb };
