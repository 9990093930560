import {
   ACTION_BATCH_KI_FAILURE,
   ACTION_BATCH_KI_REQUEST,
   ACTION_BATCH_KI_SUCCESS,
   CLEAN_DETAILS_BATCH_KI,
   CREATE_BATCH_KI_FAILURE,
   CREATE_BATCH_KI_REQUEST,
   CREATE_BATCH_KI_SUCCESS,
   DELETE_BATCH_KI_FAILURE,
   DELETE_BATCH_KI_REQUEST,
   DELETE_BATCH_KI_SUCCESS,
   GET_DETAILS_BATCH_KI_FAILURE,
   GET_DETAILS_BATCH_KI_REQUEST,
   GET_DETAILS_BATCH_KI_SUCCESS,
   GET_LIST_BATCH_KI_FAILURE,
   GET_LIST_BATCH_KI_REQUEST,
   GET_LIST_BATCH_KI_SUCCESS,
   GET_LIST_WHITELIST_KI_FAILURE,
   GET_LIST_WHITELIST_KI_REQUEST,
   GET_LIST_WHITELIST_KI_SUCCESS,
   UPDATE_BATCH_KI_FAILURE,
   UPDATE_BATCH_KI_REQUEST,
   UPDATE_BATCH_KI_SUCCESS
} from "./actionTypes";

import * as backend from "../../helpers/backend";
import { toast } from "react-toastify";


export const getListWhitelistKI = (request = {}) => {
   return {
      type: GET_LIST_WHITELIST_KI_REQUEST,
      payload: request
   };
}
export const getListWhitelistKISuccess = (response = {}) => {
   return {
      type: GET_LIST_WHITELIST_KI_SUCCESS,
      payload: response
   };
}
export const getListWhitelistKIFailure = (message, response) => {
   return {
      type: GET_LIST_WHITELIST_KI_FAILURE,
      payload: {
         message,
         errors: response,
      },
   };
}

export const createWhitelistKI = async (request = {}, cb = () => { }) => {
   try {
      const response = await backend.createWhitelistKI(request);

      if (response.data.code === 200) {
         cb(true);
      } else {
         throw new Error(response.data.message);
      }
   } catch (error) {
      cb(false);
      toast.error(error.message);
   }
}

export const updateWhitelistKI = async (request = {}, cb = () => { }) => {
   try {
      const response = await backend.updateWhitelistKI(request);

      if (response.data.code === 200) {
         cb(true);
      } else {
         throw new Error(response.data.message);
      }
   } catch (error) {
      cb(false);
      toast.error(error.message);
   }
}


export const getListBatchKI = (request = {}) => {
   return {
      type: GET_LIST_BATCH_KI_REQUEST,
      payload: request
   };
}
export const getListBatchKISuccess = (response = {}) => {
   return {
      type: GET_LIST_BATCH_KI_SUCCESS,
      payload: response
   };
}
export const getListBatchKIFailure = (message, response) => {
   return {
      type: GET_LIST_BATCH_KI_FAILURE,
      payload: {
         message,
         errors: response,
      },
   };
}

export const cleanDetailsBatchKI = () => {
   return {
      type: CLEAN_DETAILS_BATCH_KI,
   };
}
export const getDetailsBatchKI = (request = {}, cb = () => { }) => {
   return {
      type: GET_DETAILS_BATCH_KI_REQUEST,
      payload: request,
      cb
   };
}
export const getDetailsBatchKISuccess = (response = {}, cb = () => { }) => {
   return {
      type: GET_DETAILS_BATCH_KI_SUCCESS,
      payload: response,
      cb
   };
}
export const getDetailsBatchKIFailure = (message, response) => {
   return {
      type: GET_DETAILS_BATCH_KI_FAILURE,
      payload: {
         message,
         errors: response,
      },
   };
}

export const createBatchKI = (request = {}, cb = () => { }) => {
   return {
      type: CREATE_BATCH_KI_REQUEST,
      payload: request,
      cb
   };
}
export const createBatchKISuccess = (response = {}) => {
   return {
      type: CREATE_BATCH_KI_FAILURE,
      payload: response,
   };
}
export const createBatchKIFailure = (message, response) => {
   return {
      type: CREATE_BATCH_KI_SUCCESS,
      payload: {
         message,
         errors: response,
      },
   };
}

export const updateBatchKI = (request = {}, cb = () => { }) => {
   return {
      type: UPDATE_BATCH_KI_REQUEST,
      payload: request,
      cb
   };
}
export const updateBatchKISuccess = (response = {},) => {
   return {
      type: UPDATE_BATCH_KI_SUCCESS,
      payload: response,

   };
}
export const updateBatchKIFailure = (message, response) => {
   return {
      type: UPDATE_BATCH_KI_FAILURE,
      payload: {
         message,
         errors: response,
      },
   };
}

export const actionBatchKI = (request = {}, cb = () => { }) => {
   return {
      type: ACTION_BATCH_KI_REQUEST,
      payload: request,
      cb
   };
}
export const actionBatchKISuccess = (response = {},) => {
   return {
      type: ACTION_BATCH_KI_SUCCESS,
      payload: response,

   };
}
export const actionBatchKIFailure = (message, response) => {
   return {
      type: ACTION_BATCH_KI_FAILURE,
      payload: {
         message,
         errors: response,
      },
   };
}

export const deleteBatchKI = (request = {}) => {
   return {
      type: DELETE_BATCH_KI_REQUEST,
      payload: request
   };
}
export const deleteBatchKISuccess = (response = {}, cb = () => { }) => {
   return {
      type: DELETE_BATCH_KI_SUCCESS,
      payload: response,
      cb
   };
}
export const deleteBatchKIFailure = (message, response) => {
   return {
      type: DELETE_BATCH_KI_FAILURE,
      payload: {
         message,
         errors: response,
      },
   };
}