import handleToast from "components/handleToast/handleToast";
import { instances } from "../api_helper";
import LaporanAkhirPDF from "helpers/workers/pdf/laporanAkhir";
export const getListInstitusiPenilaianLuaran = ({
  tahun_usulan,
  tahun_pelaksanaan,
}) => {
  return instances.request(
    `/evaluasi/penilaian-luaran/institusi?thn_usulan=${tahun_usulan}&thn_pelaksanaan=${tahun_pelaksanaan}`,
    {
      method: "GET",
    }
  );
};
export const getListInstitusiPenilaianLuaranDetail1 = ({
  tahun_usulan,
  tahun_pelaksanaan,
  id_skema,
  id_institusi,
}) => {
  return instances.request(
    `/evaluasi/penilaian-luaran/usulan?thn_usulan=${tahun_usulan}&thn_pelaksanaan=${tahun_pelaksanaan}&id_skema=${id_skema}&id_institusi=${id_institusi}`,
    {
      method: "GET",
    }
  );
};
export const getListInstitusiPenilaianLuaranDetail2 = ({
  id_usulan_kegiatan,
}) => {
  return instances.request(
    `/evaluasi/penilaian-luaran/komponen?id_usulan_kegiatan=${id_usulan_kegiatan}`,
    {
      method: "GET",
    }
  );
};

export const savePenilaianLuaranEvaluasi = payload => {
  return instances.request(`/evaluasi/penilaian-luaran/usulan`, {
    method: "POST",
    data: payload,
  });
};
export const savePenilaianLuaranToPermanen = payload => {
  return instances.request(`/evaluasi/penilaian-luaran/set-permanen`, {
    method: "POST",
    data: payload,
  });
};
export const downloadPDFPenilaianLuaranPenelitian = async (
  item,
  blobUsulanPerbaikan,
  idToast
) => {
  // * Generate Pdf From Client
  const laporanAkhirPdf = new LaporanAkhirPDF(item);
  const blobFileFromClient = await laporanAkhirPdf.generate();

  // * Get Pdf File From Server
  const blobFileFromServer = await laporanAkhirPdf.getSingleFile(
    item?.url_file_substansi || ""
  );
  try {
    // * Start Merger
    const mergedPdf = await laporanAkhirPdf.merging([
      blobUsulanPerbaikan,
      blobFileFromClient,
      blobFileFromServer,
    ]);
    // * Force Download
    laporanAkhirPdf.forceDownload(mergedPdf, item.title);
    handleToast(idToast, false, "Success");
  } catch (err) {
    handleToast(idToast, true, "Failed");
  }
};
