// Import Saga
import { call, put, takeEvery } from "redux-saga/effects";

// Backend Helper
import * as backend from "../../helpers/backend";

// Action Types = constan
import {
  DAFTAR_PROGRAM_STUDI_REQUEST,
  DATA_PERSONAL_DOSEN_REQUEST,
  GET_DOSEN_TO_PDDIKTI_REQUEST,
  GET_PERSONAL_SEARCH_REQUEST,
  GET_STATUS_DOSEN_REQUEST,
  INSTITUSI_REQUEST,
  KETUA_LPPM_REQUEST,
  PT_DOSEN_BY_ID_REQUEST,
  PT_DOSEN_REQUEST,
  PT_NIDN_REQUEST,
  PUT_INSTITUSI_EDIT_NAMA_JABATAN_REQUEST,
  PUT_INSTITUSI_REQUEST,
  PUT_STATUS_DOSEN_REQUEST,
  RESET_PWD_REQUEST,
  SEARCH_DOSEN_BY_NIDN_REQUEST,
  SEARCH_PRODI_BY_KODE_REQUEST,
  SYNC_DATA_DOSEN_REQUEST,
  SYNC_DATA_PRODI_REQUEST,
  TAMBAH_DATA_DOSEN_REQUEST,
} from "./actionTypes";

// action = Function
import {
  daftarProgramStudiFailure,
  daftarProgramStudiSuccess,
  getAllStatusDosenFailure,
  getAllStatusDosenSuccess,
  getDosenToPddiktiFailure,
  getDosenToPddiktiSuccess,
  getInstitusiFailure,
  getInstitusiSuccess,
  getPersonalSearchFailure,
  getPersonalSearchSuccess,
  getPtByIdFailure,
  getPtByIdSuccess,
  getPtDosenFailure,
  getPtDosenSuccess,
  getPtNidnFailure,
  getPtNidnSuccess,
  ketuaLppmFailure,
  ketuaLppmSuccess,
  pusStatusDosenFailure,
  putDataPersonalDosenFailure,
  putDataPersonalDosenSuccess,
  putInstitusiEditNamaJabatanFailure,
  putInstitusiEditNamaJabatanSuccess,
  putInstitusiFailure,
  putInstitusiSuccess,
  putStatusDosenSuccess,
  resetPwdFailure,
  resetPwdSuccess,
  searchDosenByNidnFailure,
  searchDosenByNidnSuccess,
  searchProdiByKodeFailure,
  searchProdiByKodeSuccess,
  syncDataDosenFailure,
  syncDataDosenSuccess,
  syncDataProdiFailure,
  syncDataProdiSuccess,
  tambahDataDosenFailure,
  tambahDataDosenSuccess
} from "./action";

// Notification
import { toast } from "react-toastify";

function* getPersonalSearch({ payload }) {
  try {
    const response = yield call(backend.getPersonalSearch, payload);
    if (response.data.code == 200) {
      yield put(getPersonalSearchSuccess(response.data.data));
      cb(response.data.data);
      // console.log("getDosenToPddikti", response);
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    cb(null);
    yield put(getPersonalSearchFailure(message, error));
  }
}

function* getDosenToPddikti({ payload }) {
  try {
    const response = yield call(backend.getDosenToPddikti, payload);
    if (response.data.code == 200) {
      yield put(getDosenToPddiktiSuccess(response.data.data));
      // console.log("getDosenToPddikti", response);
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    yield put(getDosenToPddiktiFailure(message, error));
  }
}

function* tambahDataDosen({ payload, cb }) {
  try {
    const response = yield call(backend.tambahDataDosen, payload);
    if (response.data.code == 200) {
      yield put(tambahDataDosenSuccess(response.data.data));
      cb();
      toast.success("Berhasil menambah data dosen");
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    yield put(tambahDataDosenFailure(message, error));
    toast.error(message);
  }
}

function* searchDosenByNidn({ payload, cb }) {
  try {
    const response = yield call(backend.searchDosenByNidn, payload);
    if (response.data.code == 200) {
      yield put(searchDosenByNidnSuccess(response.data.data));
      cb(response.data);
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const { message } = error;
    cb({
      success: false,
      message,
    });
    yield put(searchDosenByNidnFailure(message, error));
  }
}

// PRODI START
function* searchProdiByKode({ payload }) {
  try {
    const response = yield call(backend.searchProdiByKode, payload);
    if (response.data.code == 200) {
      yield put(searchProdiByKodeSuccess(response.data.data));
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    yield put(searchProdiByKodeFailure(message, error));
  }
}

function* daftarProgramStudi({ payload }) {
  try {
    const response = yield call(backend.daftarProgramStudi, payload);
    if (response.data.code == 200) {
      yield put(daftarProgramStudiSuccess(response.data.data));
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    yield put(daftarProgramStudiFailure(message, error));
  }
}
// PRODI END

function* ketuaLppm({ payload }) {
  try {
    const response = yield call(backend.ketuaLppm, payload);
    if (response.data.code == 200) {
      yield put(ketuaLppmSuccess(response.data.data));
      toast.success("Berhasil mengubah data ketua LPPM");
      window.location.href = "/operator/data-pendukung/profil-lembaga";
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    yield put(ketuaLppmFailure(message, error));
  }
}

function* putInstitusi({ payload, cb }) {
  try {
    const response = yield call(backend.putInstitusi, payload);
    if (response.data.code == 200) {
      yield put(putInstitusiSuccess(response.data.data));
      // toast.success("Berhasil mengubah data institusi");
      // window.location.reload();
      cb(response.data);
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    yield put(putInstitusiFailure(message, error));
    cb(null);
  }
}

function* putDataPersonalDosen({ payload, cb }) {
  try {
    const response = yield call(backend.putDataPersonalDosen, payload);
    if (response.data.code == 200) {
      yield put(putDataPersonalDosenSuccess(response.data.data));
      toast.success("Berhasil mengubah data personal dosen");
      cb();
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    yield put(putDataPersonalDosenFailure(message, error));
  }
}

function* syncDataDosen({ payload }) {
  try {
    const response = yield call(backend.syncDataDosen, payload);
    if (response.data.code == 200) {
      yield put(syncDataDosenSuccess(response.data.data));
      toast.success("Berhasil menyinkronkan data dengan PDDIKTI");
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    yield put(syncDataDosenFailure(message, error));
  }
}

function* syncDataProdi({ payload, cb = () => {} }) {
  try {
    const response = yield call(backend.syncDataProdi, payload);
    if (response.data.code == 200) {
      yield put(syncDataProdiSuccess(response.data.data));
      toast.success("Berhasil menyinkronkan data dengan PDDIKTI");
      cb(response.data);
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    yield put(syncDataProdiFailure(message, error));
  }
}

function* getPtDosen({ payload }) {
  try {
    const response = yield call(backend.getPtDosen, payload);
    if (response.data.code == 200) {
      yield put(getPtDosenSuccess(response.data.data));
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    yield put(getPtDosenFailure(message, error));
  }
}

function* getPtById({ payload }) {
  try {
    const response = yield call(backend.getPtById, payload);
    if (response.data.code == 200) {
      yield put(getPtByIdSuccess(response.data.data));
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    yield put(getPtByIdFailure(message, error));
  }
}

function* getPtNidn({ payload }) {
  try {
    const response = yield call(backend.getPtNidn, payload);
    if (response.data.code == 200) {
      yield put(getPtNidnSuccess(response.data.data));
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    yield put(getPtNidnFailure(message, error));
  }
}

function* getInstitusi({ payload }) {
  try {
    const response = yield call(backend.getInstitusi, payload);
    if (response.data.code == 200) {
      yield put(getInstitusiSuccess(response.data.data));
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    yield put(getInstitusiFailure(message, error));
  }
}

function* resetPwd({ payload }) {
  try {
    const response = yield call(backend.resetPwd, payload);
    if (response.data.code == 200) {
      yield put(resetPwdSuccess(response.data.data));
      toast.success("reset password berhasil");
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    yield put(resetPwdFailure(message, error));
  }
}

function* getAllStatusDosen({ payload }) {
  try {
    const response = yield call(backend.getAllStatusDosen, payload);
    if (response.data.code == 200) {
      yield put(getAllStatusDosenSuccess(response.data.data));
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    yield put(getAllStatusDosenFailure(message, error));
  }
}

function* putStatusDosen({ payload }) {
  try {
    const response = yield call(backend.putStatusDosen, payload);
    if (response.data.code == 200) {
      yield put(putStatusDosenSuccess(response.data.data));
      toast.success("Berhasil mengubah data personal dosen");
      window.location.reload();
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    yield put(pusStatusDosenFailure(message, error));
  }
}
function* putInstitusiEditNamaJabatan({ payload, cb }) {
  try {
    const response = yield call(backend.putInstitusiEditNamaJabatan, payload);
    if (response.data.code == 200) {
      yield put(putInstitusiEditNamaJabatanSuccess(response.data.data));
      cb(response.data);
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    yield put(putInstitusiEditNamaJabatanFailure(message, error));
    cb(null);
  }
}

function* notificationSaga() {
  yield takeEvery(PT_DOSEN_REQUEST, getPtDosen);
  yield takeEvery(PT_NIDN_REQUEST, getPtNidn);
  yield takeEvery(INSTITUSI_REQUEST, getInstitusi);
  yield takeEvery(RESET_PWD_REQUEST, resetPwd);
  yield takeEvery(PT_DOSEN_BY_ID_REQUEST, getPtById);
  yield takeEvery(SYNC_DATA_DOSEN_REQUEST, syncDataDosen);
  yield takeEvery(SEARCH_DOSEN_BY_NIDN_REQUEST, searchDosenByNidn);
  yield takeEvery(DATA_PERSONAL_DOSEN_REQUEST, putDataPersonalDosen);
  yield takeEvery(PUT_INSTITUSI_REQUEST, putInstitusi);
  yield takeEvery(DAFTAR_PROGRAM_STUDI_REQUEST, daftarProgramStudi);
  yield takeEvery(SEARCH_PRODI_BY_KODE_REQUEST, searchProdiByKode);
  yield takeEvery(TAMBAH_DATA_DOSEN_REQUEST, tambahDataDosen);
  yield takeEvery(KETUA_LPPM_REQUEST, ketuaLppm);
  yield takeEvery(SYNC_DATA_PRODI_REQUEST, syncDataProdi);
  yield takeEvery(GET_DOSEN_TO_PDDIKTI_REQUEST, getDosenToPddikti);
  yield takeEvery(GET_STATUS_DOSEN_REQUEST, getAllStatusDosen);
  yield takeEvery(PUT_STATUS_DOSEN_REQUEST, putStatusDosen);
  yield takeEvery(GET_PERSONAL_SEARCH_REQUEST, getPersonalSearch);
  yield takeEvery(
    PUT_INSTITUSI_EDIT_NAMA_JABATAN_REQUEST,
    putInstitusiEditNamaJabatan
  );
}

export default notificationSaga;
