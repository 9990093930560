import { call, put, takeEvery } from "redux-saga/effects";
import {
  getLaporanKemajuanDetailFailure,
  getLaporanKemajuanDetailSuccess,
  getListLaporanKemajuanFailure,
  getListLaporanKemajuanSuccess,
  postLaporanKemajuanFailure,
  updateLaporanKemajuanFailure,
  uploadFileLaporanKemajuanFailure,
  // uploadFileLaporanKemajuanSuccess,
  uploadDokumenSptbSuccess,
  uploadDokumenSptbFailure,
  // updateLaporanKemajuanSuccess,
  // postLaporanKemajuanSuccess,
  createLaporanKemajuanSagaSuccess,
  createLaporanKemajuanSagaFailure,
  getCriteriaSuccess,
  getCriteriaFailure,
  getTargetJenisLuaranFailure,
  getTargetJenisLuaranSuccess,
  getDokumenLuaranSuccess,
  getDokumenLuaranFailure,
  createDokumenMitraLaporanKemajuanFailure,
  getDokumenMitraLaporanKemajuanSuccess,
  getDokumenMitraLaporanKemajuanFailure,
  getBerkasLaporanKemajuanSuccess,
  getBerkasLaporanKemajuanFailure,
  getListLaporanKemajuanV2Success,
  getListLaporanKemajuanV2Failure,
  getDetailLaporanKemajuanV2Success,
  getDetailLaporanKemajuanV2Failure,
  updateLaporanKemajuanV2Success,
  updateLaporanKemajuanV2Failure,
  // createDokumenMitraLaporanKemajuanSuccess,
  // resetLaporanKemajuanDetail,
} from "./action";
import {
  LAPORAN_KEMAJUAN_DETAIL_REQUEST,
  LIST_LAPORAN_KEMAJUAN_REQUEST,
  POST_LAPORAN_KEMAJUAN_REQUEST,
  UPDATE_LAPORAN_KEMAJUAN_REQUEST,
  UPLOAD_DOKUMEN_REQUEST,
  UPLOAD_DOKUMEN_MITRA_REQUEST,
  UPLOAD_DOKUMEN_SPTB_REQUEST,
  CREATE_LAPORAN_KEMAJUAN_REQUEST,
  GET_CRITERIA_REQUEST,
  GET_TARGET_JENIS_LUARAN_REQUEST,
  GET_DOKUMEN_LUARAN_REQUEST,
  GET_DOKUMEN_MITRA_REQUEST,
  GET_BERKAS_LAPORAN_KEMAJUAN_REQUEST,
  GET_LIST_LAPORAN_KEMAJUAN_V2_REQUEST,
  GET_DETAIL_LAPORAN_KEMAJUAN_V2_REQUEST,
  UPDATE_LAPORAN_KEMAJUAN_V2_REQUEST,
} from "./actionTypes";
import * as backend from "../../helpers/backend";
import { toast } from "react-toastify";
//* Upload File Laporan Kemajuan
function* uploadFileLaporanKemajuan({ payload, cb }) {
  try {
    const response = yield call(backend.uploadDokumenLaporanKemajuan, payload);

    if (response.data.code == 200) {
      // yield put(
      //   uploadFileLaporanKemajuanSuccess({
      //     data: response.data.data,
      //   })
      // );
      cb(response.data);
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    toast.error(message);
    yield put(uploadFileLaporanKemajuanFailure(message, error));
    cb(null);
  }
}
//* Upload File SPTB
function* uploadDokumenSptb({ payload, cb }) {
  try {
    const response = yield call(backend.uploadDokumenSptb, payload);

    if (response.data.code == 200) {
      yield put(
        uploadDokumenSptbSuccess({
          data: response.data.data,
        })
      );
      cb(response.data);
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    toast.error(message);
    yield put(uploadDokumenSptbFailure(message, error));
    cb(null);
  }
}
// * Create Laporan Kemajuan Saga
function* createLaporanKemajuanSaga({ payload, cb }) {
  // console.log(payload);
  try {
    const response = yield call(backend.createLaporanKemajuanSaga, payload);
    if (response.data.code == 200) {
      yield put(
        createLaporanKemajuanSagaSuccess({
          data: response.data.data,
        })
      );
      cb(response.data);
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    toast.error(message);
    yield put(createLaporanKemajuanSagaFailure(message, error));
    cb(null);
  }
}
// * List Laporan Kemajuan
function* getListLaporanKemajuan({ payload }) {
  try {
    const response = yield call(backend.getListLaporanKemajuan, payload);
    if (response.data.code == 200) {
      yield put(getListLaporanKemajuanSuccess(response.data.data));
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    yield put(getListLaporanKemajuanFailure(message, error));
  }
}
// * Detail Laporan Kemajuan
function* getLaporanKemajuanDetail({ payload }) {
  // yield put(resetLaporanKemajuanDetail());
  try {
    const response = yield call(backend.getLaporanKemajuanDetail, payload);
    if (response.data.code == 200) {
      yield put(getLaporanKemajuanDetailSuccess(response.data.data));
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    yield put(getLaporanKemajuanDetailFailure(message, error));
  }
}
// * Get Criteria
function* getCriteria({ payload }) {
  try {
    const response = yield call(backend.getCriteria, payload.criteria);
    if (response.data.code == 200) {
      yield put(getCriteriaSuccess(response.data.data));
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    yield put(getCriteriaFailure(message, error));
  }
}
// * getTargetJenisLuaran
function* getTargetJenisLuaran({ payload, cb }) {
  try {
    const response = yield call(backend.getTargetJenisLuaran, payload);
    if (response.data.code == 200) {
      yield put(getTargetJenisLuaranSuccess(response.data.data));
      cb(response.data);
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    db({ success: false, message });
    yield put(getTargetJenisLuaranFailure(message, error));
  }
}
function* getDokumenLuaran({ payload }) {
  try {
    const response = yield call(backend.getDokumenLuaran, payload);
    if (response.data.code == 200) {
      yield put(getDokumenLuaranSuccess(response.data.data));
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    yield put(getDokumenLuaranFailure(message, error));
  }
}
function* postLaporanKemajuan({ payload, history }) {
  console.log(payload, history);
  try {
    const response = yield call(backend.postLaporanKemajuan, payload);
    console.log(response);
    // if (response.data.code == 200) {
    //   yield put(postLaporanKemajuanSuccess(response.data.data));
    //   toast.success("Berhasil menyimpan data");
    //   history.push("/penelitian/laporan-kemajuan");
    // } else {
    //   throw new Error(response.data.message);
    // }
  } catch (error) {
    const message = error.message;
    yield put(postLaporanKemajuanFailure(message, error));
  }
}
function* updateLaporanKemajuan({ payload, history }) {
  console.log(payload, history);
  try {
    const response = yield call(backend.updateLaporanKemajuan, payload);
    console.log(response);
    // if (response.data.code == 200) {
    //   yield put(updateLaporanKemajuanSuccess(response.data.data));
    //   toast.success("Berhasil memperbarui data");
    //   history.push("/penelitian/laporan-kemajuan");
    // } else {
    //   throw new Error(response.data.message);
    // }
  } catch (error) {
    const message = error.message;
    yield put(updateLaporanKemajuanFailure(message, error));
  }
}

// Dokumen Mitra
function* createDokumenMitra({ payload, cb }) {
  try {
    const response = yield call(backend.createDokumenMitra, payload);

    if (response.data.code == 200) {
      // yield put(
      // createDokumenMitraLaporanKemajuanSuccess({
      //     data: response.data.data,
      //   })
      // );
      cb(response.data);
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    toast.error(message);
    yield put(createDokumenMitraLaporanKemajuanFailure(message, error));
    cb(null);
  }
}
function* getDokumenMitra({ payload, cb }) {
  try {
    const response = yield call(backend.getDokumenMitra, payload);
    if (response.data.code == 200) {
      cb(response.data);
      yield put(getDokumenMitraLaporanKemajuanSuccess(response.data.data));
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    yield put(getDokumenMitraLaporanKemajuanFailure(message, error));
    cb(null);
  }
}
function* getBerkasLaporanKemajuan({ payload, cb }) {
  try {
    const response = yield call(backend.getBerkasLaporanKemajuan, payload);
    if (response.data.code == 200) {
      cb(response.data);
      yield put(getBerkasLaporanKemajuanSuccess(response.data.data));
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    yield put(getBerkasLaporanKemajuanFailure(message, error));
    cb(null);
  }
}
// laporan kemajuan v2
function* getListLaporanKemajuanV2({ payload, cb }) {
  try {
    const response = yield call(backend.getListLaporanKemajuanV2, payload);
    if (response.data.code == 200) {
      yield put(getListLaporanKemajuanV2Success({ data: response.data.data }));
      cb(response.data);
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    toast.error(message);
    yield put(getListLaporanKemajuanV2Failure(message, error));
  }
}
function* getDetailLaporanKemajuanV2({ payload, cb }) {
  try {
    const response = yield call(backend.getDetailLaporanKemajuanV2, payload);
    if (response.data.code == 200) {
      yield put(
        getDetailLaporanKemajuanV2Success({ data: response.data.data })
      );
      cb(response.data);
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    cb(null);
    toast.error(message);
    yield put(getDetailLaporanKemajuanV2Failure(message, error));
  }
}
function* updateLaporanKemajuanV2({ payload, cb }) {
  try {
    const response = yield call(backend.updateLaporanKemajuanV2, payload);
    if (response.data.code == 200) {
      yield put(updateLaporanKemajuanV2Success({ data: response.data.data }));
      cb(response.data);
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    const message = error.message;
    toast.error(message);
    yield put(updateLaporanKemajuanV2Failure(message, error));
    cb(null);
  }
}
function* laporanKemajuanSaga() {
  yield takeEvery(UPLOAD_DOKUMEN_REQUEST, uploadFileLaporanKemajuan);
  yield takeEvery(LIST_LAPORAN_KEMAJUAN_REQUEST, getListLaporanKemajuan);
  yield takeEvery(LAPORAN_KEMAJUAN_DETAIL_REQUEST, getLaporanKemajuanDetail);
  yield takeEvery(POST_LAPORAN_KEMAJUAN_REQUEST, postLaporanKemajuan);
  yield takeEvery(UPDATE_LAPORAN_KEMAJUAN_REQUEST, updateLaporanKemajuan);
  yield takeEvery(UPLOAD_DOKUMEN_SPTB_REQUEST, uploadDokumenSptb);
  yield takeEvery(CREATE_LAPORAN_KEMAJUAN_REQUEST, createLaporanKemajuanSaga);
  yield takeEvery(GET_CRITERIA_REQUEST, getCriteria);
  yield takeEvery(GET_TARGET_JENIS_LUARAN_REQUEST, getTargetJenisLuaran);
  yield takeEvery(GET_DOKUMEN_LUARAN_REQUEST, getDokumenLuaran);
  yield takeEvery(UPLOAD_DOKUMEN_MITRA_REQUEST, createDokumenMitra);
  yield takeEvery(GET_DOKUMEN_MITRA_REQUEST, getDokumenMitra);
  yield takeEvery(
    GET_BERKAS_LAPORAN_KEMAJUAN_REQUEST,
    getBerkasLaporanKemajuan
  );
  // Laporan kemajuan v2
  yield takeEvery(
    GET_LIST_LAPORAN_KEMAJUAN_V2_REQUEST,
    getListLaporanKemajuanV2
  );
  yield takeEvery(
    GET_DETAIL_LAPORAN_KEMAJUAN_V2_REQUEST,
    getDetailLaporanKemajuanV2
  );
  yield takeEvery(UPDATE_LAPORAN_KEMAJUAN_V2_REQUEST, updateLaporanKemajuanV2);
}

export default laporanKemajuanSaga;
