import utilities from "helpers/utilities";
import Logo from "../../settings/tutwuriBase64";
const sumNilai = (data = [], property) => {
  return data.reduce((accumulator, currentExpense) => {
    return accumulator + currentExpense[property];
  }, 0);
};

const sumNilaiRab = (data = [], property) => {
  return data
    .filter(item => [11, 12, 13, 14, 15].includes(item.id_rab_kelompok_biaya))
    .reduce((accumulator, currentExpense) => {
      return accumulator + currentExpense[property] * currentExpense.volume;
    }, 0);
};

export default function Content(data) {
  // * Judul Penelitian (1)
  const buildTableJudulPenelitian = () => {
    const section = () => {
      // const data = {
      //   title:
      //     "MODEL PEMBANGUNAN RENDAH KARBON ASEAN-5 BERBASIS INPUT-OUTPUT (INDONESIA, MALAYSIA, THE PHILIPPINES, THAILAND AND VIETNAM)",
      // };

      const rows = [];
      rows.push([{ text: data.title, style: ["left"] }]);
      return rows;
    };
    const section1 = () => {
      // const data = [
      //   {
      //     bidang_fokus:
      //       "Sosial Humaniora, Seni Budaya,Pendidikan Desk Study Dalam Negeri",
      //     tema: "Sustainable mobility",
      //     topik:
      //       "Mobilitas orang, nilai, dan barang serta implikasinya pada transformasi nilai budaya dan perilaku komsumtif dalam era global",
      //     rumpun_bidang_ilmu: "Teknik Lingkungan",
      //   },
      // ];
      const rows = [
        [
          {
            text: "Bidang Fokus RIRN / Bidang Unggulan Perguruan Tinggi",
            style: ["bgColorHeaderTable"],
          },
          {
            text: "Tema",
            style: ["bgColorHeaderTable"],
          },
          {
            text: "Topik (jika ada)",
            style: ["bgColorHeaderTable"],
          },
          {
            text: "Rumpun Bidang Ilmu",
            style: ["bgColorHeaderTable"],
          },
        ],
      ];

      rows.push([
        { text: data.bidang.bidang_fokus, style: ["center"] },
        {
          text: data.bidang.tema,
          style: ["center"],
        },
        {
          text: data.bidang.topik,
          style: ["center"],
        },
        {
          text: data.bidang.rumpun_bidang_ilmu,
          style: ["center"],
        },
      ]);

      return rows;
    };
    const section2 = () => {
      // const data = [
      //   {
      //     kategori: "Penelitian Kompetitif Nasional",
      //     skema_penelitian: "Penelitian Dasar",
      //     strata: "SBK Riset Dasar",
      //     sbk: "SBK Riset Dasar",
      //     tgl_akhir_tkt: 3,
      //     lama_penelitian: 3,
      //   },
      // ];
      const rows = [
        [
          {
            text: "Kategori (Kompetitif Nasional/ Desentralisasi/ Penugasan)",
            style: ["bgColorHeaderTable"],
          },
          {
            text: "Skema Penelitian",
            style: ["bgColorHeaderTable"],
          },
          {
            text: "Strata (Dasar/ Terapan/ Pengembangan)",
            style: ["bgColorHeaderTable"],
          },
          {
            text: "SBK (Dasar, Terapan, Pengembangan)",
            style: ["bgColorHeaderTable"],
          },
          {
            text: "Target Akhir TKT",
            style: ["bgColorHeaderTable"],
          },
          {
            text: "Lama Penelitian (Tahun)",
            style: ["bgColorHeaderTable"],
          },
        ],
      ];

      rows.push([
        { text: data.kategori.kategori, style: ["center"] },
        {
          text: data.kategori.skema_penelitian,
          style: ["center"],
        },
        {
          text: data.kategori.strata,
          style: ["center"],
        },
        {
          text: data.kategori.sbk,
          style: ["center"],
        },
        {
          text: data.kategori.target_akhir_tkt,
          style: ["center"],
        },
        {
          text: data.kategori.lama_penelitian,
          style: ["center"],
        },
      ]);

      return rows;
    };
    return {
      section,
      section1,
      section2,
    };
  };
  // * Identias pengusul (2)
  const buildTableIdentitasPengusul = () => {
    const section = () => {
      // const data = [
      //   {
      //     nama: "ADRIANUS AMHEKA (Ketua Pengusul)",
      //     perguruan_tinggi: "Politeknik Negeri Kupang",
      //     program_studi: "Teknik Mesin",
      //     bidang_tugas: "Melakukan pengumpulan dan tabulasi data",
      //     id_sinta: 5980612,
      //     h_index: 3,
      //   },
      //   {
      //     nama: "ADRIANUS AMHEKA (Ketua Pengusul)",
      //     perguruan_tinggi: "Politeknik Negeri Kupang",
      //     program_studi: "Teknik Mesin",
      //     bidang_tugas: "Melakukan pengumpulan dan tabulasi data",
      //     id_sinta: 5980612,
      //     h_index: 3,
      //   },
      // ];
      const rows = [
        [
          {
            text: "Nama (Peran)",
            style: ["bgColorHeaderTable"],
          },
          {
            text: "Perguruan Tinggi/ Institusi",
            style: ["bgColorHeaderTable"],
          },
          {
            text: "Program Studi/ Bagian",
            style: ["bgColorHeaderTable"],
          },
          {
            text: "Bidang Tugas",
            style: ["bgColorHeaderTable"],
          },
          {
            text: "ID Sinta",
            style: ["bgColorHeaderTable"],
          },
          {
            text: "H-Index",
            style: ["bgColorHeaderTable"],
          },
        ],
      ];
      data.identitas_pengusul.forEach(item => {
        rows.push([
          { text: `${item.nama} - ${item.peran}`, style: ["left"] },
          {
            text: item.perguruan_tinggi,
            style: ["center"],
          },
          {
            text: item.program_studi,
            style: ["center"],
          },
          {
            text: item.bidang_tugas,
            style: ["center"],
          },
          {
            text: item.id_sinta,
            style: ["center"],
          },
          {
            text: item.h_index,
            style: ["center"],
          },
        ]);
      });

      return rows;
    };
    return {
      section,
    };
  };
  const buildTableMitraKerjasama = () => {
    const section = () => {
      // const data = [
      //   {
      //     mitra: "Mitra Pelaksana Penelitian",
      //     nama_mitra: "Profesor Kathleen Aviso",
      //   },
      // ];
      const rows = [
        [
          {
            text: "Mitra",
            style: ["bgColorHeaderTable"],
          },
          {
            text: "Nama Mitra",
            style: ["bgColorHeaderTable"],
          },
        ],
      ];
      data.mitra.forEach(item => {
        rows.push([
          { text: item.mitra, style: ["left"] },
          {
            text: item.nama_mitra,
            style: ["left"],
          },
        ]);
      });

      return rows;
    };
    return {
      section,
    };
  };

  const buildTableLuaranDanTargetCapaian = () => {
    const sectionLuaranWajib = () => {
      // const data = [
      //   {
      //     tahun_luaran: 1,
      //     jenis_luaran:
      //       "Artikel di Jurnal Internasional Terindeks di Pengindeks Bereputasi",
      //     status: "Accepted",
      //     keterangan: "Heliyon",
      //   },
      // ];
      const rows = [
        [
          {
            text: "Tahun Luaran",
            style: ["bgColorHeaderTable"],
          },
          {
            text: "Jenis Luaran",
            style: ["bgColorHeaderTable"],
          },
          {
            text: "Status target capaian (accepted, published, terdaftar atau granted, atau status lainnya)",
            style: ["bgColorHeaderTable"],
            italics: true,
          },
          {
            text: "Keterangan (url dan nama jurnal, penerbit, url paten, keterangan sejenis lainnya)",
            style: ["bgColorHeaderTable"],
            italics: true,
          },
        ],
      ];
      data.luaran_wajib.forEach(item => {
        rows.push([
          { text: item.thn_luaran, style: ["center"] },
          {
            text: item.jenis_luaran,
            style: ["center"],
          },
          {
            text: item.status,
            style: ["center"],
          },
          {
            text: item.keterangan,
            style: ["center"],
          },
        ]);
      });

      return rows;
    };
    const sectionLuaranTambahan = () => {
      // const data = [
      //   {
      //     tahun_luaran: 1,
      //     jenis_luaran:
      //       "Artikel pada Conference/Seminar Internasional di Pengindeks Bereputasi",
      //     status: "Terbit dalam Prosiding",
      //     keterangan:
      //       "International Conference on Cleaner Production and Sustainability (CPS 2020)",
      //   },
      // ];
      const rows = [
        [
          {
            text: "Tahun Luaran",
            style: ["bgColorHeaderTable"],
          },
          {
            text: "Jenis Luaran",
            style: ["bgColorHeaderTable"],
          },
          {
            text: "Status target capaian (accepted, published, terdaftar atau granted, atau status lainnya)",
            style: ["bgColorHeaderTable"],
            italics: true,
          },
          {
            text: "Keterangan (url dan nama jurnal, penerbit, url paten, keterangan sejenis lainnya)",
            style: ["bgColorHeaderTable"],
            italics: true,
          },
        ],
      ];
      data.luaran_tambahan.forEach(item => {
        rows.push([
          { text: item.thn_luaran, style: ["center"] },
          {
            text: item.jenis_luaran,
            style: ["center"],
          },
          {
            text: item.status,
            style: ["center"],
          },
          {
            text: item.keterangan,
            style: ["center"],
          },
        ]);
      });

      return rows;
    };
    return {
      sectionLuaranWajib,
      sectionLuaranTambahan,
    };
  };

  const buildtableRAB = () => {
    const rabTahun1 = data.anggaran.detail.filter(
      item => item.thn_urutan === "1"
    );
    const rabTahun2 = data.anggaran.detail.filter(
      item => item.thn_urutan === "2"
    );
    const rabTahun3 = data.anggaran.detail.filter(
      item => item.thn_urutan === "3"
    );
    const sectionTahun1 = () => {
      const rows = [
        [
          {
            text: "Jenis Pembelanjaan",
            style: ["bgColorHeaderTable"],
            bold: true,
          },
          {
            text: "Komponen",
            style: ["bgColorHeaderTable"],
            bold: true,
          },
          {
            text: "Item",
            style: ["bgColorHeaderTable"],
            bold: true,
          },
          {
            text: "Satuan",
            style: ["bgColorHeaderTable"],
            bold: true,
          },
          {
            text: "Vol.",
            style: ["bgColorHeaderTable"],
            bold: true,
          },
          {
            text: "Biaya Satuan",
            style: ["bgColorHeaderTable"],
            bold: true,
          },
          {
            text: "Total",
            style: ["bgColorHeaderTable"],
            bold: true,
          },
        ],
      ];
      rabTahun1.forEach(item => {
        rows.push([
          { text: item.jenis_pembelanjaan, style: ["center"] },
          {
            text: item.komponen,
            style: ["center"],
          },
          {
            text: item.item_belanja,
            style: ["center"],
          },
          {
            text: item.satuan,
            style: ["center"],
          },
          {
            text: item.volume,
            style: ["center"],
          },
          {
            text: item.biaya_satuan.toLocaleString(),
            style: ["center"],
          },
          {
            text: item.total.toLocaleString(),
            style: ["center"],
          },
        ]);
      });

      return rows;
    };
    const sectionTahun2 = () => {
      const rows = [
        [
          {
            text: "Jenis Pembelanjaan",
            style: ["bgColorHeaderTable"],
            bold: true,
          },
          {
            text: "Komponen",
            style: ["bgColorHeaderTable"],
            bold: true,
          },
          {
            text: "Item",
            style: ["bgColorHeaderTable"],
            bold: true,
          },
          {
            text: "Satuan",
            style: ["bgColorHeaderTable"],
            bold: true,
          },
          {
            text: "Vol.",
            style: ["bgColorHeaderTable"],
            bold: true,
          },
          {
            text: "Biaya Satuan",
            style: ["bgColorHeaderTable"],
            bold: true,
          },
          {
            text: "Total",
            style: ["bgColorHeaderTable"],
            bold: true,
          },
        ],
      ];
      rabTahun2.forEach(item => {
        rows.push([
          { text: item.jenis_pembelanjaan, style: ["center"] },
          {
            text: item.komponen,
            style: ["center"],
          },
          {
            text: item.item_belanja,
            style: ["center"],
          },
          {
            text: item.satuan,
            style: ["center"],
          },
          {
            text: item.volume,
            style: ["center"],
          },
          {
            text: item.biaya_satuan.toLocaleString(),
            style: ["center"],
          },
          {
            text: item.total.toLocaleString(),
            style: ["center"],
          },
        ]);
      });

      return rows;
    };
    const sectionTahun3 = () => {
      const rows = [
        [
          {
            text: "Jenis Pembelanjaan",
            style: ["bgColorHeaderTable"],
            bold: true,
          },
          {
            text: "Komponen",
            style: ["bgColorHeaderTable"],
            bold: true,
          },
          {
            text: "Item",
            style: ["bgColorHeaderTable"],
            bold: true,
          },
          {
            text: "Satuan",
            style: ["bgColorHeaderTable"],
            bold: true,
          },
          {
            text: "Vol.",
            style: ["bgColorHeaderTable"],
            bold: true,
          },
          {
            text: "Biaya Satuan",
            style: ["bgColorHeaderTable"],
            bold: true,
          },
          {
            text: "Total",
            style: ["bgColorHeaderTable"],
            bold: true,
          },
        ],
      ];
      rabTahun3.forEach(item => {
        rows.push([
          { text: item.jenis_pembelanjaan, style: ["center"] },
          {
            text: item.komponen,
            style: ["center"],
          },
          {
            text: item.item_belanja,
            style: ["center"],
          },
          {
            text: item.satuan,
            style: ["center"],
          },
          {
            text: item.volume,
            style: ["center"],
          },
          {
            text: item.biaya_satuan.toLocaleString(),
            style: ["center"],
          },
          {
            text: item.total.toLocaleString(),
            style: ["center"],
          },
        ]);
      });

      return rows;
    };

    return {
      sectionTahun1,
      sectionTahun2,
      sectionTahun3,
    };
  };
  
  const sumRAB = (thn_urutan = 1) => {
    const totalRAB = data.anggaran.detail
      .filter(item => item.thn_urutan === thn_urutan.toString())
      .reduce((acc, current) => acc + current.total, 0);
    return totalRAB.toLocaleString();
  };

  return [
    // * Header
    {
      columns: [
        {
          width: 50,
          image: Logo,
        },
        {
          text: "Direktorat Riset dan Pengabdian Masyarakat Direktorat Jenderal Riset dan Pengembangan \n Kementerian Riset, Teknologi, dan Pendidikan Tinggi \n Gedung BPPT II Lantai 19, Jl. MH. Thamrin No. 8 Jakarta Pusat \n https://simlitabmas.ristekdikti.go.id/",
          width: "auto",
          style: ["fontSizeS"],
        },
      ],
    },
    {
      canvas: [
        {
          type: "line",
          x1: 0,
          y1: 15,
          x2: 480,
          y2: 15,
          lineWidth: 1,
        },
      ],
      marginBottom: 20,
    },
    {
      text: `PROTEKSI ISI LAPORAN KEMAJUAN ${data.jenis_kegiatan.toUpperCase()}`,
      bold: true,
      alignment: "center",
      fontSize: 11,
    },
    {
      text: "Dilarang menyalin, menyimpan, memperbanyak sebagian atau seluruh isi laporan ini dalam bentuk apapun kecuali oleh peneliti dan pengelola administrasi penelitian",
      style: ["fontSizeS", "center"],
    },
    {
      text: `LAPORAN KEMAJUAN  ${data.jenis_kegiatan.toUpperCase()} MULTI TAHUN`,
      bold: true,
      alignment: "center",
      fontSize: 12,
      fillColor: "black",
      color: "black",
      marginTop: 5,
    },
    {
      text: `ID Proposal: ${data.id_proposal}`,
      alignment: "center",
      fontSize: 9,
    },
    {
      text: `Laporan Kemajuan ${
        data.jenis_kegiatan[0]?.toUpperCase() +
        data.jenis_kegiatan?.substring(1)
      }: tahun ke-${data.thn_urutan_pelaksanaan} dari ${
        data.lama_kegiatan
      } tahun`,
      alignment: "center",
      fontSize: 9,
    },

    // * Content
    // * Identitas Penelitian (1)
    {
      marginTop: 20,
      text: ["1. IDENTITAS PENELITIAN"],
      style: ["bold"],
    },
    {
      marginTop: 10,
      textIndent: 10,
      text: {
        text: "\u200B\t A. JUDUL PENELITIAN",
        preserveLeadingSpaces: true,
      },
      style: ["bold"],
    },

    {
      marginTop: 5,
      style: ["text"],
      layout: {
        hLineWidth: (_i, _node) => 1,
        vLineWidth: _i => 1,
        paddingLeft: _i => 2,
        paddingTop: _i => 2,
      },

      table: {
        widths: ["*"],
        body: [...buildTableJudulPenelitian().section()],
      },
    },
    {
      marginTop: 10,
      textIndent: 10,
      text: {
        text: "\u200B\t B. BIDANG, TEMA, TOPIK, DAN RUMPUN BIDANG ILMU",
        preserveLeadingSpaces: true,
      },
      style: ["bold"],
    },
    {
      marginTop: 5,
      style: ["text"],
      layout: {
        hLineWidth: (_i, _node) => 1,
        vLineWidth: _i => 1,
        paddingLeft: _i => 2,
        paddingTop: _i => 2,
      },

      table: {
        widths: ["*", "auto", "auto", "auto"],
        body: [...buildTableJudulPenelitian().section1()],
      },
    },
    {
      marginTop: 10,
      textIndent: 10,
      text: {
        text: "\u200B\t C. KATEGORI, SKEMA, SBK, TARGET TKT DAN LAMA PENELITIAN",
        preserveLeadingSpaces: true,
      },
      style: ["bold"],
    },
    {
      marginTop: 5,
      style: ["text"],
      layout: {
        hLineWidth: (_i, _node) => 1,
        vLineWidth: _i => 1,
        paddingLeft: _i => 2,
        paddingTop: _i => 2,
      },

      table: {
        widths: ["*", "auto", "auto", "auto", "auto", "auto"],
        body: [...buildTableJudulPenelitian().section2()],
      },
    },

    // * Identitas Pengusul (2)
    {
      marginTop: 15,
      text: ["2. IDENTITAS PENGUSUL"],
      style: ["bold"],
    },
    {
      marginTop: 5,
      style: ["text"],
      layout: {
        hLineWidth: (_i, _node) => 1,
        vLineWidth: _i => 1,
        paddingLeft: _i => 2,
        paddingTop: _i => 2,
      },

      table: {
        widths: ["*", "auto", "auto", "auto", "auto", "auto"],
        body: [...buildTableIdentitasPengusul().section()],
      },
    },

    // * Mitra Kerjasama (3)
    {
      marginTop: 15,
      text: ["3. MITRA KERJASAMA PENELITIAN (JIKA ADA)"],
      style: ["bold"],
    },
    {
      style: ["text"],
      text: "Pelaksanaan penelitian dapat melibatkan mitra kerjasama, yaitu mitra kerjasama dalam melaksanakan penelitian, mitra sebagai calon pengguna hasil penelitian, atau mitra investor",
    },

    {
      marginTop: 5,
      style: ["text"],
      layout: {
        hLineWidth: (_i, _node) => 1,
        vLineWidth: _i => 1,
        paddingLeft: _i => 2,
        paddingTop: _i => 2,
      },

      table: {
        widths: ["*", "*"],
        body: [...buildTableMitraKerjasama().section()],
      },
    },
    // *  LUARAN DAN TARGET CAPAIAN (4)
    {
      marginTop: 15,
      text: ["4. LUARAN DAN TARGET CAPAIAN"],
      style: ["bold"],
    },
    {
      marginTop: 5,
      text: "Luaran Wajib",
      style: "bold",
    },
    {
      marginTop: 5,
      style: ["text"],
      layout: {
        hLineWidth: (_i, _node) => 1,
        vLineWidth: _i => 1,
        paddingLeft: _i => 2,
        paddingTop: _i => 2,
      },

      table: {
        widths: ["*", "auto", "auto", "auto"],
        body: [...buildTableLuaranDanTargetCapaian().sectionLuaranWajib()],
      },
    },
    {
      text: "Luaran Tambahan",
      style: "bold",
      marginTop: 5,
    },
    {
      marginTop: 5,
      style: ["text"],
      layout: {
        hLineWidth: (_i, _node) => 1,
        vLineWidth: _i => 1,
        paddingLeft: _i => 2,
        paddingTop: _i => 2,
      },

      table: {
        widths: ["*", "auto", "auto", "auto"],
        body: [...buildTableLuaranDanTargetCapaian().sectionLuaranTambahan()],
      },
    },
    // *  RAB (5)
    {
      marginTop: 5,
      text: ["5. ANGGARAN"],
      style: ["bold"],
    },
    {
      marginTop: 5,
      text: [
        "Rencana anggaran biaya penelitian mengacu pada PMK yang berlaku dengan besaran minimum dan maksimum sebagaimana diatur pada buku Panduan Penelitian dan Pengabdian kepada Masyarakat",
      ],
      style: ["text"],
    },
    {
      marginTop: 5,
      text: `Total RAB ${
        data.lama_kegiatan
      } Tahun Rp. ${data.anggaran.total_rab.toLocaleString()}`,
      style: ["bold", "text"],
    },
    {
      marginTop: 5,
      text: `Tahun 1 Total Rp. ${sumRAB(1)}`,
      style: ["bold", "text"],
    },
    {
      marginTop: 5,
      style: ["text"],
      layout: {
        hLineWidth: (_i, _node) => 1,
        vLineWidth: _i => 1,
        paddingLeft: _i => 2,
        paddingTop: _i => 2,
      },

      table: {
        widths: ["*", "auto", "auto", "auto", "auto", "auto", "auto"],
        body: [...buildtableRAB().sectionTahun1()],
      },
    },
    {
      marginTop: 5,
      text: `Tahun 2 Total Rp. ${sumRAB(2)}`,
      style: ["bold", "text"],
    },
    {
      marginTop: 5,
      style: ["text"],
      layout: {
        hLineWidth: (_i, _node) => 1,
        vLineWidth: _i => 1,
        paddingLeft: _i => 2,
        paddingTop: _i => 2,
      },

      table: {
        widths: ["*", "auto", "auto", "auto", "auto", "auto", "auto"],
        body: [...buildtableRAB().sectionTahun2()],
      },
    },
    {
      marginTop: 5,
      text: `Tahun 3 Total Rp. ${sumRAB(3)}`,
      style: ["bold", "text"],
    },
    {
      marginTop: 5,
      style: ["text"],
      layout: {
        hLineWidth: (_i, _node) => 1,
        vLineWidth: _i => 1,
        paddingLeft: _i => 2,
        paddingTop: _i => 2,
      },

      table: {
        widths: ["*", "auto", "auto", "auto", "auto", "auto", "auto"],
        body: [...buildtableRAB().sectionTahun3()],
      },
    },

    // * Kemajuan Penelitian (6)
    {
      marginTop: 5,
      text: ["6. KEMAJUAN PENELITIAN"],
      style: ["bold"],
    },
    {
      marginTop: 10,
      textIndent: 10,
      text: {
        text: "\u200B\t A. RINGKASAN",
        preserveLeadingSpaces: true,
      },
      style: ["bold"],
    },
    {
      marginTop: 5,
      text: `\u200B\t\t ${data.ringkasan}`,
      style: ["text", "fontSizeM"],
    },
    {
      marginTop: 10,
      textIndent: 10,
      text: {
        text: "\u200B\t B. KATA KUNCI",
        preserveLeadingSpaces: true,
      },
      style: ["bold", "text"],
    },
    {
      marginTop: 5,
      text: `\u200B\t\t ${data.keyword}`,
      style: ["text"],
    },
  ];
}

export const contentSptbPengabdian = (data = {}) => {
  const catatanHarian = data.dataCatatanHarian;
  const laporanKemajuan = data.dataLaporanKemajuan;
  const reMapped = catatanHarian.map(item => ({
    ...item,
    kelompok_biaya: item.rab_kelompok_biaya.kelompok_biaya,
  }));
  const grouppedCatatanHarian = utilities.groupBy(reMapped, "kelompok_biaya");
  const grouppedRab = utilities.groupBy(
    catatanHarian[0]?.rab_komponen_belanja_revisi || [],
    "id_rab_kelompok_biaya"
  );
  const layout = {
    style: ["mb-10"],
    layout: {
      hLineWidth: (_i, _node) => 0.3,
      vLineWidth: _i => 0.3,
      padding: () => 0,
    },
    table: {
      headerRows: 1,
      widths: ["5%", "23.75%", "23.75%", "23.75%", "23.75%"],
      body: [
        [
          {
            text: `No`,
            style: ["center", "bgColorHeaderTable"],
          },
          {
            text: `Komponen Biaya`,
            style: ["center", "bgColorHeaderTable"],
          },
          {
            text: `No. Berkas`,
            style: ["center", "bgColorHeaderTable"],
          },
          {
            text: `RAB`,
            style: ["center", "bgColorHeaderTable"],
          },
          {
            text: `Jumlah Terealisasi`,
            style: ["center", "bgColorHeaderTable"],
          },
        ],
      ],
    },
  };
  Object.keys(grouppedCatatanHarian).forEach((property, indexProperty) => {
    grouppedCatatanHarian[property].forEach((item, indexItem) => {
      if (indexItem === 0) {
        layout.table.body.push([
          {
            text: `${indexProperty + 1}`,
            style: ["center", "bold"],
          },
          {
            text: `${property}`,
            style: ["center", "bold"],
          },
          {
            text: ``,
            style: ["center"],
          },
          {
            text: `${utilities.toCurrency(
              sumNilaiRab(
                grouppedRab[item.rab_kelompok_biaya_id],
                "harga_satuan"
              ),
              ".",
              0,
              "Rp. "
            )}`,
            style: ["center", "bold"],
          },
          {
            text: `${utilities.toCurrency(
              sumNilai(grouppedCatatanHarian[property], "biaya_yg_terpakai"),
              ".",
              0,
              "Rp. "
            )}`,
            style: ["center", "bold"],
          },
        ]);
      }
      layout.table.body.push([
        {
          text: ``,
          style: ["center"],
        },
        {
          text: `${item.kegiatan_yg_dilakukan}`,
          style: ["center"],
        },
        {
          text: `${item.no_berkas}`,
          style: ["center"],
        },
        {
          text: ``,
          style: ["center"],
        },
        {
          text: `${utilities.toCurrency(
            item.biaya_yg_terpakai,
            ".",
            0,
            "Rp. "
          )}`,
          style: ["center"],
        },
      ]);
    });
  });
  layout.table.body.push([
    {
      text: `Total`,
      style: ["center", "bold"],
      colSpan: 3,
    },
    {
      text: ``,
      style: ["center"],
    },
    {
      text: ``,
      style: ["center"],
    },
    {
      text: `${utilities.toCurrency(
        sumNilaiRab(
          catatanHarian[0]?.rab_komponen_belanja_revisi || [],
          "harga_satuan"
        ),
        ".",
        0,
        "Rp. "
      )}`,
      style: ["center", "bold"],
    },
    {
      text: `${utilities.toCurrency(
        sumNilai(catatanHarian, "biaya_yg_terpakai"),
        ".",
        0,
        "Rp. "
      )}`,
      style: ["center", "bold"],
    },
  ]);
  const nameKetuaLembaga =
    laporanKemajuan.dosen_ketua.institusi?.additional?.find(
      item => item.peran.id === 10
    )?.personal?.nama;
  const nidnKetuaLembaga =
    laporanKemajuan.dosen_ketua.institusi?.additional?.find(
      item => item.peran.id === 10
    )?.nidn;
  return [
    {
      stack: [
        {
          text: "Penggunaan Anggaran",
          style: ["bold", "center", "mb-10"],
        },
      ],
    },
    {
      stack: [
        {
          text: `Nama Ketua : ${laporanKemajuan.dosen_ketua.nama}`,
          style: ["bold", "mb-5"],
        },
        {
          text: `Judul : ${laporanKemajuan.judul}`,
          style: ["bold", "mb-5"],
        },
        {
          text: `Tahun Pelaksanaan : ${
            laporanKemajuan.usulan.usulan_kegiatan.find(
              item => item.id == laporanKemajuan.id_usulan_kegiatan
            )?.thn_pelaksanaan_kegiatan
          }`,
          style: ["bold", "mb-5"],
        },
        {
          text: `Dana Disetujui : ${utilities.toCurrency(
            laporanKemajuan.usulan_di_danai.dana_disetujui,
            ".",
            0,
            "Rp. "
          )}`,
          style: ["bold", "mb-5"],
        },
      ],
    },
    {
      stack: [
        {
          ...layout,
        },
      ],
    },
    {
      stack: [
        {
          text: `Sisa Dana : ${utilities.toCurrency(
            sumNilaiRab(
              catatanHarian[0]?.rab_komponen_belanja_revisi || [],
              "harga_satuan"
            ) - sumNilai(catatanHarian, "biaya_yg_terpakai"),
            ".",
            0,
            "Rp. "
          )}`,
          style: ["mt-10", "bold"],
        },
        {
          text: `Dana yang sudah digunakan : ${(
            (sumNilai(catatanHarian, "biaya_yg_terpakai") /
              sumNilaiRab(
                catatanHarian[0]?.rab_komponen_belanja_revisi || [],
                "harga_satuan"
              )) *
            100
          ).toFixed()} %
          `,
          style: ["mt-5", "bold"],
        },
      ],
    },
    {
      style: ["mt-20"],
      stack: [
        {
          style: ["text"],
          layout: "noBorders", // Menghilangkan border tabel,
          table: {
            widths: ["50%", "50%"],
            body: [
              [
                {
                  text: "Mengetahui, Ketua LPPM",
                  style: ["left"],
                },
                {
                  text: "Ketua Pelaksana",
                  style: ["right"],
                },
              ],
              [
                {
                  text: `\n${nameKetuaLembaga}`,
                  style: ["left", "mt-20"],
                },
                {
                  text: `\n${laporanKemajuan.dosen_ketua.nama}`,
                  style: ["right", "mt-20"],
                },
              ],
              [
                {
                  text: `${nidnKetuaLembaga}`,
                  style: ["left", "mt-2"],
                },
                {
                  text: `${laporanKemajuan.dosen_ketua.nidn}`,
                  style: ["right", "mt-2"],
                },
              ],
            ],
          },
        },
      ],
    },
  ];
};
