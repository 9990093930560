import { content, contentSptb } from "./content";
import stylePDF from "./styles";

export const pdfCreateMain = (item, flagJenisKegiatan, jenisKegiatan) => {
  const { pageMargins, pageSize, styles, defaultStyle } = stylePDF;
  return {
    content: [...content(item, flagJenisKegiatan, jenisKegiatan)],
    defaultStyle,
    pageMargins,
    pageSize,
    styles,
  };
};

export const pdfCreateSptb = item => {
  const { pageSize, pageMargins, styles, defaultStyle } = stylePDF;
  return {
    content: [...contentSptb(item)],
    defaultStyle,
    pageMargins,
    pageSize,
    styles,
  };
};
