/* eslint-disable no-unused-vars */
import {
  getMultiFileFromBucket,
  getSingleFileFromBucket,
} from "helpers/getFileFromBucket";
import PDFMerger from "pdf-merger-js/browser";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { toast } from "react-toastify";
import util from "helpers/utilities";
import engine from "./partials";
import { PDFDocument, rgb, PageSizes } from "pdf-lib";
import { pdfCreateSptbPengabdian as engine2 } from "./partials/index";
import utilities from "helpers/utilities";
import { getDokumenMitra } from "helpers/laporanKemajuan";
import { getSptbLaporanAkhir } from "helpers/laporanAkhir";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

class CetakLaporanAkhir {
  constructor(data) {
    this.data = data;
    this.indexBlobSkiped = [];
  }
  async getSingleFile(fileUrl = "") {
    const friendlyURL = util.getFriendlyURL(fileUrl);
    const response = await getSingleFileFromBucket(friendlyURL);
    if (response.error) {
      toast.error(response.message);
    } else {
      return response.data;
    }
  }

  async getMultiplefilePengabdian(dataUrl) {
    let files = [];
    let dataBlob = [];
    files.push({
      NamaJenisLuaran: "Substansi",
      URLDokumen: dataUrl?.url_file_substansi || "",
    });
    const luaranArtikel =
      dataUrl?.luaran_artikel?.filter(item => item.URLDokumen !== "") || [];
    files.push(...luaranArtikel);
    for (const file of files) {
      const res = await getMultiFileFromBucket(
        util.getFriendlyURL(file.URLDokumen)
      );
      if (!res.error) {
        dataBlob.push(res.data);
      }
    }
    return dataBlob;
  }

  async mergingv3(docs = [], cb) {

    const pdf = await PDFDocument.create();
    let i = 0;

    try {
      for (const file of docs) {

        const bufferFile = await utilities.blobToUint8Array(file);

        if (!this.indexBlobSkiped.includes(i)) {

          const doc = await PDFDocument.load(bufferFile, { ignoreEncryption: true, throwOnInvalidObject: true })

          if (doc) {
            const contentPage = await pdf.copyPages(doc, doc.getPageIndices());
            for (const page of contentPage) {
              pdf.addPage(page);
            }
          }
        }
        i++;
      }
      const pdfMerged = await pdf.saveAsBase64()
      const pdfMergedAsBlob = utilities.b64toBlob(pdfMerged, 'application/pdf');
      cb({ res: pdfMergedAsBlob, error: false })

    } catch (error) {
      console.log(error.message);
      if (error.message.includes('invalid object') || error.message.includes('Failed to parse PDF document')) {
        this.indexBlobSkiped.push(i);
        cb({ res: undefined, error: true, indexBlobSkipped: i })
      } else {
        cb({ res: undefined, error: true, errMsg: error.message })
      }
    }

  }

  async getMultiplefileWithDocx(dataUrl) {
    let files = [];
    let dataBlob = [];
    files.push({
      NamaJenisLuaran: "Substansi",
      URLDokumen: dataUrl?.url_file_substansi || "",
    });
    const luaranArtikel =
      dataUrl?.luaran_artikel?.filter(item => item.URLDokumen !== "") || [];
    files.push(...luaranArtikel);
    for (const file of files) {
      const extension = util.getFriendlyURL(file.URLDokumen).split(".").pop();
      if (extension === "docx") {
        const res = await getMultiFileFromBucket(
          util.getFriendlyURL(file.URLDokumen)
        );
        if (!res.error) {
          this.forceDownload(res.data, dataUrl.title);
        }
      } else {
        const res = await getMultiFileFromBucket(
          util.getFriendlyURL(file.URLDokumen)
        );
        if (!res.error) {
          dataBlob.push(res.data);
        }
      }
    }
    return dataBlob;
  }
  async getMultiplefile(item, files_luaran) {
    let files = [];
    let dataBlob = [];
    const substansi = item.data1.url_file_substansi;
    if (substansi) {
      files.push(substansi);
    }
    const feasibility_study = item.data2.url_file_feasibility_study;
    if (feasibility_study) {
      files.push(feasibility_study);
    }

    const res = await getDokumenMitra({
      id_transaksi_kegiatan: item.data2.id_transaksi_kegiatan,
    });
    const mitra = res?.data?.data?.[0]?.lokasi_file;
    if (mitra) {
      files.push(mitra);
    }

    if (files_luaran) {
      files = files.concat(files_luaran);
    }

    const ppt = item.data1.url_file_ppt;
    if (ppt) {
      files.push(ppt);
    }

    const sptbres = await getSptbLaporanAkhir({
      id_usulan_kegiatan: item.data2.id_usulan_kegiatan,
    });
    const sptb = sptbres?.data?.data?.url_file;
    if (sptb) {
      files.push(sptb);
    }
    for (const file of files) {
      const res = await getMultiFileFromBucket(util.getFriendlyURL(file));
      if (!res.error) {
        dataBlob.push(res.data);
      }
    }
    return dataBlob;
  }
  async getMultiplefileSptb(files = []) {
    let dataBlob = [];
    for (const file of files) {
      const res = await getMultiFileFromBucket(
        util.getFriendlyURL(file.URLDokumen)
      );
      if (!res.error) {
        dataBlob.push(res.data);
      }
    }
    return dataBlob;
  }
  async generate() {
    return await new Promise((resolve, _reject) => {
      const pdfDoc = pdfMake.createPdf(engine(this.data));
      pdfDoc.getBlob(async blob => {
        resolve(blob);
      });
    });
  }
  async generateSptbPengabdian(data) {
    return await new Promise((resolve, _reject) => {
      const pdfDoc = pdfMake.createPdf(engine2(data));
      pdfDoc.getBlob(async blob => {
        resolve(blob);
      });
    });
  }
  // Jangan dihapus yaa, buat sptb laporan kemajuan / akhir reguler, tidak ada callback.
  async mergingWithImage(docs = []) {
    const pdf = await PDFDocument.create();
    for (const file of docs) {
      const bufferFile = await utilities.blobToUint8Array(file);
      if (file.type === "image/jpeg" || file.type === "image/png") {
        const page = pdf.addPage(PageSizes.Letter);
        let imageEmbed;
        if (file.type === "image/jpeg") {
          imageEmbed = await pdf.embedJpg(bufferFile);
        } else {
          imageEmbed = await pdf.embedPng(bufferFile);
        }
        const { width, height } = imageEmbed.scaleToFit(
          page.getWidth(),
          page.getHeight()
        );
        // Draw the image on the PDF page.
        page.drawImage(imageEmbed, {
          x: page.getWidth() / 2 - width / 2, // Center the image horizontally.
          y: page.getHeight() / 2 - height / 2, // Center the image vertically.
          width,
          height,
          color: rgb(0, 0, 0), // Set the image color to black.
        });
      } else {
        const doc = await PDFDocument.load(bufferFile, {
          ignoreEncryption: true,
        });
        const contentPage = await pdf.copyPages(doc, doc.getPageIndices());
        for (const page of contentPage) {
          pdf.addPage(page);
        }
      }
    }
    const pdfMerged = await pdf.saveAsBase64();
    const pdfMergedAsBlob = utilities.b64toBlob(pdfMerged, "application/pdf");
    return pdfMergedAsBlob;
  }
  async merging(docs = []) {
    const merger = new PDFMerger();
    for (const file of docs) {
      await merger.add(file);
    }
    const mergerPdf = await merger.saveAsBlob();
    return mergerPdf;
  }
  forceDownload(file, fileName) {
    // * Force Download
    const a = document.createElement("a");
    a.href = window.URL.createObjectURL(file);
    a.setAttribute("download", `${fileName}`);
    document.body.appendChild(a);
    a.click();
    a.parentNode.removeChild(a);
  }
  openAsWindow(file, _filename, type) {
    let binaryData = [];
    binaryData.push(file);

    window.open(
      URL.createObjectURL(new Blob(binaryData, { type: type })),
      "_blank"
    );
  }
}

export default CetakLaporanAkhir;
