import React, { Component } from "react";
import PropTypes from "prop-types";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { connect } from "react-redux";

// Import Routes
import { authProtectedRoutes, publicRoutes } from "./routes/";
const AppRoute = React.lazy(() => import("./routes/route"));

// layouts
const VerticalLayout = React.lazy(() => import("./components/VerticalLayout/"));
const HorizontalLayout = React.lazy(() =>
  import("./components/HorizontalLayout/")
);
const NonAuthLayout = React.lazy(() => import("./components/NonAuthLayout"));

// Import scss
import "./assets/scss/theme.scss";
import encStorage from "helpers/encStorage";
import { QueryClient, QueryClientProvider } from "react-query";
const Pages404 = React.lazy(() => import("pages/Errors/pages-404"));

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      auth: {},
    };
    this.getLayout = this.getLayout.bind(this);
  }

  componentDidMount() {
    const auth = encStorage.getItem("authUser");
    if (auth) {
      this.setState({ auth: JSON.parse(auth) });
    }
    
    if (
      window.location.protocol === "http:" &&
      process.env.REACT_APP_NODE === "production" && window.location.hostname !== 'localhost'
    ) {
      console.log(`https://${window.location.host}`);
      window.location.href = `https://${window.location.host}`;
    }
  }

  /**
   * Returns the layout
   */
  getLayout = () => {
    let layoutCls = VerticalLayout;

    switch (this.props.layout.layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout;
        break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  };

  render() {
    const Layout = this.getLayout();

    const loading = () => (
      <div id="preloader">
        <div id="status">
          <div className="spinner-chase">
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
          </div>
        </div>
      </div>
    );

	const queryClient = new QueryClient({
		defaultOptions: {
			queries: {
			refetchOnWindowFocus: false,
			},
		},
	});

    return (
	<QueryClientProvider client={queryClient}>
      <React.Fragment>
        <React.Suspense fallback={loading()}>
          <Router>
            <Switch>
              {authProtectedRoutes(this.state.auth.data).map((route, idx) => (
                <AppRoute
                  path={route.path}
                  layout={Layout}
                  component={route.component}
                  key={idx}
                  isAuthProtected={true}
                  exact
                  authData={this.state.auth}
                />
              ))}

              {publicRoutes.map((route, idx) => (
                <AppRoute
                  path={route.path}
                  layout={NonAuthLayout}
                  component={route.component}
                  key={idx}
                  exact={route.exact}
                  isAuthProtected={false}
                />
              ))}

              <Route path="*" exact={true} component={Pages404} />
            </Switch>
          </Router>
        </React.Suspense>
      </React.Fragment>
	  </QueryClientProvider>
    );
  }
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  };
};

App.propTypes = {
  layout: PropTypes.object,
};

export default connect(mapStateToProps, null)(App);
